import React from "react";

import { Input } from "..";
import { Validations } from "../../lib";
class InputPassword extends React.Component {
  state = {
    showPassword: false,
  };
  render() {
    return (
      <Input
        {...this.props}
        withHide={() => {
          this.setState({ showPassword: !this.state.showPassword });
        }}
        type={
          this.state.showPassword
            ? "text"
            : this.props?.$type === "newPassword"
            ? "newPassword"
            : "password"
        }
        validation={
          this.props?.doubleValidation
            ? this.props?.doubleValidation
            : Validations.validPassword
        }
      />
    );
  }
}
export default InputPassword;
