import React from "react";
import styled from "styled-components";
import { Chip, withTheme } from "@material-ui/core";
import FontStyle from "../styles/fontsStyles";

const StyledChip = styled(Chip)(({ theme, variant }) => ({
  backgroundColor:
    variant === "outlined"
      ? theme.palette.primary.main + "20"
      : theme.palette.colors.text,
  ...FontStyle.medium12,
}));

function Tag({ outlined, ...props }) {
  return (
    <StyledChip
      variant={outlined ? "outlined" : undefined}
      color="primary"
      {...props}
    />
  );
}

export default withTheme(Tag);
