import React from "react";
import styled from "styled-components";
import FontStyles from "../styles/fontsStyles";

import { Button } from "@material-ui/core";
import GridItemTable from "./GridItemTable";

let invertOrder = false;

const TableWrapper = styled.div(() => ({
  overflow: "auto",
}));

const Table = styled.table(({ theme }) => ({
  borderSpacing: theme.spacing(0, 0.5),
  width: "100%",
  borderCollapse: "collapse",
}));

const HeaderRow = styled.tr(({ theme }) => ({
  ...FontStyles.bold10,
  backgroundColor: theme.palette.background.paper,
  textTransform: "uppercase",
  padding: theme.spacing(2),
  textAlign: "left",
  position: "sticky",
  top: 0,
  zIndex: 10,
}));

const HeaderItem = styled.th(({ theme, $first, $last }) => ({
  fontWeight: "normal",
  padding: $first ? theme.spacing(1, 1, 1, 2) : theme.spacing(1),
  color: theme.palette.text.primary,
  display: $last ? "flex" : null,
  justifyContent: $last ? "flex-end" : "flex-start",

  whiteSpace: "normal",
  wordWrap: "break-word",
  overflowWrap: "break-word",
}));

const ButtonT = styled(Button)(({ theme, $small }) => ({
  justifyContent: "space-between",
  padding: "0",
  ...($small ? FontStyles.bold10 : FontStyles.bold14),
  color: theme.palette.text.primary,
  lineHeight: "normal",
  textAlign: "left",
  width: "auto",
  height: "auto",
  //backgroundColor: "red",
}));

function TableComponent({
  data,
  renderItemColumns,
  renderSubColumns,
  headers,
  loading,
  id,
  sortContent = null,
  clickPosition = 5, ///null pra remover
}) {
  return (
    <TableWrapper>
      <Table id={id}>
        <thead style={{ position: "sticky", top: 0, zIndex: 1 }}>
          <HeaderRow>
            {headers.table.map((text, key) => (
              <HeaderItem
                $first={key === 0 ? true : false}
                key={key}
                $last={key === headers.table.length - 1}
              >
                <ButtonT
                  $small={headers.table.length > 7 ? true : false}
                  onClick={() => {
                    sortContent !== null && sortContent(key, data);
                    invertOrder = !invertOrder;
                  }}
                >
                  {text}
                </ButtonT>
              </HeaderItem>
            ))}
          </HeaderRow>
        </thead>
        <tbody style={{ borderSpacing: 0 }}>
          {data &&
            !loading &&
            data.map((item, index) => {
              return (
                <GridItemTable
                  renderItemColumns={renderItemColumns}
                  item={item}
                  index={index}
                  headers={headers}
                  clickPosition={clickPosition}
                  renderSubColumns={renderSubColumns}
                />
              );
            })}
        </tbody>
      </Table>
    </TableWrapper>
  );
}

export default React.memo(TableComponent);
