//import { ReactComponent as Logo } from "../assets/svg/Logo.svg";
import { ReactComponent as Logo } from "../assets/svg/Logo.svg";
import { ReactComponent as LogoWhite } from "../assets/svg/Logo_White.svg";
import { ReactComponent as AddDocument } from "../assets/icons/ico-doc-plus.svg";
import { ReactComponent as Export } from "../assets/icons/export.svg";

const SVG = {
  Logo,
  LogoWhite,
  AddDocument,
  Export,
};

export default SVG;
