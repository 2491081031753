import React from "react";
import Styles from "../styles/Styles";
import { connect } from "react-redux";
import { MenuGroupWithPages } from "../components";
import { Creators as GlobalCreators } from "../../globalReduxSagas";
import Drawer from "@material-ui/core/Drawer";
import { CircularProgress, makeStyles } from "@material-ui/core";
import Hidden from "@material-ui/core/Hidden";
import { CustomText, DefaultLogo } from "../../../components";
import { Colors, Fonts, Spacing } from "../../../config";
import { GetRoleByName } from "../../../services";
import { useVersion } from "../../../lib";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },

  drawerPaper: {
    borderRight: "none",
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
}));

function Menu(
  { pathname, globalLogout, getUserInfos, openMenu, isOpen, userInfos },
  props
) {
  const { window } = props;
  const classes = useStyles();
  const version = useVersion();
  const drawer = (
    <>
      <Styles.Container $color={GetRoleByName(userInfos?.role?.name)}>
        <Styles.ScrollContainer>
          <Styles.Header>
            <DefaultLogo white maxWidth={Spacing(14)} />
          </Styles.Header>

          {userInfos ? (
            userInfos?.role?.sessions?.map((item, index) => (
              <MenuGroupWithPages
                key={item.name + index}
                headerName={item.name}
                item={item.pages}
                index={index}
                firstItem={index === 0}
                globalLogout={globalLogout}
                pathname={pathname}
                user={userInfos}
              />
            ))
          ) : (
            <div
              style={{
                width: "96%",
                marginTop: "10%",
                justifyContent: "center",
                alignContent: "center",
                display: "flex",
              }}
            >
              <CircularProgress color="secondary" />
            </div>
          )}
        </Styles.ScrollContainer>
        <CustomText
          fontSize={1.5}
          fontFamily={Fonts.medium}
          textColor={Colors.white}
          styles={{
            display: "flex",
            justifyContent: "center",
            paddingBlock: Spacing(3),
          }}
        >
          AMCRED {version}
        </CustomText>
      </Styles.Container>
    </>
  );

  const container =
    window !== undefined ? () => window().document.body : undefined;

  return (
    <Styles.ShowQuery>
      <nav className={classes.drawer} aria-label="mailbox folders">
        <Hidden smUp implementation="css">
          <Drawer
            container={container}
            variant="temporary"
            anchor={"left"}
            open={isOpen}
            onClose={() => openMenu(!isOpen)}
            classes={{
              paper: classes.drawerPaper,
            }}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
          >
            {drawer}
          </Drawer>
        </Hidden>
        <Hidden xsDown implementation="css">
          <Drawer
            classes={{
              paper: classes.drawerPaper,
            }}
            anchor={"left"}
            variant="permanent"
            open
          >
            {drawer}
          </Drawer>
        </Hidden>
      </nav>
    </Styles.ShowQuery>
  );
}

function mapStateToProps({ router, global }) {
  const { problensList, userInfos } = global;
  return {
    pathname: router.location.pathname,
    userInfos,
    problensList,
  };
}

function mapDispatchToProps(dispatch) {
  const { globalLogout, getUserInfos } = GlobalCreators;
  return {
    globalLogout: function () {
      return dispatch(globalLogout());
    },
    getUserInfos: function () {
      return dispatch(getUserInfos());
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(React.memo(Menu));
