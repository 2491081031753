import React from "react";
import Styles from "../styles/Styles";
import { connect } from "react-redux";
import ResetPassword from "../components/ResetPassword";
import BasicData from "../components/BasicData";
import { CircularProgress, Grid } from "@material-ui/core";
import { Creators as GlobalCreators } from "../../globalReduxSagas";
import { Creators } from "../reduxSagas";
import { GetRoleByName } from "../../../services";

function ProfilePage({
  userNewPasswordRequest,
  isFetching,
  getUserInfos,
  userInfos,
}) {
  const [form, setForm] = React.useState(null);

  const submitNewPassword = (data) => {
    let newPasswordData = {
      password: data.password,
      newPassword: data.newPassword,
    };
    userNewPasswordRequest(
      newPasswordData,
      () => {
        form?.clearAllValues();
      },
      color
    );
  };
  const color = GetRoleByName(userInfos?.role?.name);
  return (
    <Styles.Container>
      <Grid container spacing={1}>
        {isFetching ? (
          <CircularProgress />
        ) : (
          <>
            <Grid item xs={12}>
              <BasicData userInfos={userInfos} color={color} />
            </Grid>
            <Grid item xs={12}>
              <ResetPassword
                onSubmit={submitNewPassword}
                setForm={setForm}
                color={color}
              />
            </Grid>
          </>
        )}
      </Grid>
    </Styles.Container>
  );
}

function mapStateToProps(state) {
  const { isFetching: isFetchingPage } = state?.profile;
  const { userInfos, isFetchingUserInfos } = state.global;
  return {
    userInfos,
    isFetching: isFetchingUserInfos || isFetchingPage,
  };
}

function mapDispatchToProps(dispatch) {
  const { userNewPasswordRequest } = Creators;
  const { getUserInfos } = GlobalCreators;
  return {
    getUserInfos: function () {
      return dispatch(getUserInfos());
    },
    userNewPasswordRequest: function (data, form, color) {
      return dispatch(userNewPasswordRequest(data, form, color));
    },
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(React.memo(ProfilePage));
