import React, { useState } from "react";
import Styles from "../styles/Styles";
import SearchReports from "../components/SearchReports";
import { CircularProgress, Grid } from "@material-ui/core";
import ReportList from "../components/ReportList";
import { connect } from "react-redux";
import { Creators } from "../reduxSagas";
import { Creators as GlobalCreators } from "../../globalReduxSagas";
import { IntegratedComponentHolder } from "../../../components";
import { GetRoleByName } from "../../../services";
import { ToCSV } from "../../../lib";
import { Creators as DetailCreators } from "../../detailReport/reduxSagas";
const pageSize = 10;

function GeneralReportPage({
  isFetching,
  userInfos,
  reportGeneralListRequest,
  generalReportList,
  lastFilter,
  getCompanies,
  companiesList,

  reportGeneralListInstitutionRequest,
  getUsernameAndRoles,
  usernameAndRolesList,
  fullReport,
  totalValues,
  getValuesDatail,
  getValuesInstitutionDatail,
}) {
  const rowsWithSort = [];
  const [page, setPage] = React.useState(0);
  const [sortKey, setSortKey] = useState(null);
  const [sortDirection, setSortDirection] = useState(true);

  React.useEffect(() => {
    const roleId = GetRoleByName(userInfos?.role?.name);
    const now = new Date();
    const year = now.getFullYear();
    const month = now.getMonth() + 1; // Os meses em JavaScript são indexados de 0 a 11

    const begin = new Date(year, month - 1, 1);
    const end = now;
    setSortKey(roleId === 1 ? "fullName" : "companyName");
    if (roleId === 1) {
      getUsernameAndRoles();
      reportGeneralListInstitutionRequest({
        searchParameters: {
          order: "fullName",
          typeOrder: "ASC",
          begin: begin,
          end: end,
        },
        page: { size: pageSize, page: 0 },
      });
      getValuesInstitutionDatail(
        {
          searchParameters: { begin: begin, end: end },
          page: { size: pageSize, page: 0 },
        },
        roleId
      );
    } else {
      getCompanies();
      reportGeneralListRequest({
        searchParameters: {
          order: "companyName",
          typeOrder: "ASC",
          begin: begin,
          end: end,
        },
        page: { size: pageSize, page: 0 },
      });
      getValuesDatail({
        searchParameters: { begin: begin, end: end },
        page: { size: pageSize, page: 0 },
      });
    }
  }, [
    getUsernameAndRoles,
    reportGeneralListInstitutionRequest,
    getCompanies,
    reportGeneralListRequest,
    setSortKey,
    userInfos,
    getValuesDatail,
    getValuesInstitutionDatail,
  ]);

  const submit = (filters, reset) => {
    const roleId = GetRoleByName(userInfos?.role?.name);
    setPage(0);

    if (roleId === 1) {
      reportGeneralListInstitutionRequest({
        searchParameters: {
          ...filters,
          order: sortKey,
          typeOrder: sortDirection === true ? "ASC" : "DESC",
        },
        page: { size: pageSize, page: 0 },
      });
      getValuesInstitutionDatail(
        {
          searchParameters: filters,
          page: { size: pageSize, page: 0 },
        },
        roleId
      );
    } else {
      reportGeneralListRequest({
        searchParameters: {
          ...filters,
          order: sortKey,
          typeOrder: sortDirection === true ? "ASC" : "DESC",
        },
        page: { size: pageSize, page: 0 },
      });
      getValuesDatail({
        searchParameters: filters,
        page: { size: pageSize, page: 0 },
      });
    }
  };

  const sortContent = (filter, user, order, type) => {
    const roleId = GetRoleByName(userInfos?.role?.name);
    if (roleId === 1) {
      reportGeneralListInstitutionRequest({
        searchParameters: { ...filter, order: order, typeOrder: type },
        page: {
          size: pageSize,
          page:
            user?.pageTotal > 0 &&
            generalReportList?.pageTotal <= page + 1 &&
            (generalReportList === null || generalReportList === undefined)
              ? generalReportList?.pageTotal - 1
              : page,
        },
      });
    } else {
      reportGeneralListRequest({
        searchParameters: { ...filter, order: order, typeOrder: type },
        page: {
          size: pageSize,
          page:
            user?.pageTotal > 0 &&
            generalReportList?.pageTotal <= page + 1 &&
            (generalReportList === null || generalReportList === undefined)
              ? generalReportList?.pageTotal - 1
              : page,
        },
      });
    }
  };

  const formatTime = (date) => {
    var data = new Date(date);

    var dia = data.getDate();
    var mes = data.getMonth() + 1;
    var ano = data.getFullYear();
    var horas = data.getHours();
    var minutos = data.getMinutes();

    dia = dia < 10 ? "0" + dia : dia;
    mes = mes < 10 ? "0" + mes : mes;
    horas = horas < 10 ? "0" + horas : horas;
    minutos = minutos < 10 ? "0" + minutos : minutos;

    var dataString =
      dia + "/" + mes + "/" + ano + " - " + horas + ":" + minutos;
    return dataString;
  };

  const formatFilter = (obj) => {
    function formatDate(date) {
      const day = String(date.getDate()).padStart(2, "0");
      const month = String(date.getMonth() + 1).padStart(2, "0");
      const year = String(date.getFullYear());
      return day + "/" + month + "/" + year;
    }
    const result = [];
    if (obj.companies) {
      const companiesNames = obj.companies?.map((i) => {
        return i?.label;
      });
      companiesNames.join(", ");
      const companyIdsStr = "Instituições: " + companiesNames;
      result.push(companyIdsStr);
      result.push(";");
    }

    if (obj.cnpj) {
      const companyIdsStr = "CNPJ: " + obj.cnpj;
      result.push(companyIdsStr);
      result.push(";");
    }

    if (obj.userIds) {
      const companiesNames = obj.userIds?.map((i) => {
        return i?.label;
      });
      companiesNames.join(", ");
      const companyIdsStr = "Usuários: " + companiesNames;
      result.push(companyIdsStr);
      result.push(";");
    }

    if (obj.roleId) {
      const companyIdsStr = "Perfis: " + obj.roleId;
      result.push(companyIdsStr);
      result.push(";");
    }

    if (obj.begin && obj.begin instanceof Date) {
      result.push("Período: ");
      const formattedBegin = formatDate(obj.begin);

      if (obj.end && obj.end instanceof Date) {
        const formattedEnd = formatDate(obj.end);
        result.push(formattedBegin + " até " + formattedEnd);
      } else {
        result.push(formattedBegin);
      }
    }

    return result.join("");
  };

  const nextPageReport = (filters, pageN) => {
    const roleId = GetRoleByName(userInfos?.role?.name);

    if (roleId === 1) {
      reportGeneralListInstitutionRequest({
        searchParameters: filters,
        page: { size: pageSize, page: pageN },
      });
      getValuesInstitutionDatail(
        {
          searchParameters: filters,
          page: { size: pageSize, page: pageN },
        },
        roleId
      );
    } else {
      reportGeneralListRequest({
        searchParameters: filters,
        page: { size: pageSize, page: pageN },
      });
      getValuesDatail({
        searchParameters: filters,
        page: { size: pageSize, page: pageN },
      });
    }
  };

  const exportData = (list) => {
    let filters = formatFilter(lastFilter?.searchParameters);

    let headers =
      GetRoleByName(userInfos?.role?.name) === 2
        ? [
            "NOME DA INSTITUIÇÃO ASSOCIADA",
            "CNPJ",
            "Nº CONSULTAS",
            "VALOR (R$)",
          ]
        : GetRoleByName(userInfos?.role?.name) === 1
        ? ["NOME DO USUÁRIO", "PERFIL", "Nº CONSULTAS", "VALOR (R$)"]
        : [
            "NOME DA INSTITUIÇÃO ASSOCIADA",
            "CNPJ",
            "Nº CONSULTAS",
            "VALOR (R$)",
          ];

    let rows =
      GetRoleByName(userInfos?.role?.name) === 1
        ? ["fullName", "roleName", "queryQuantity", "exportMonetary"]
        : ["companyName", "companyCnpj", "queryQuantity", "exportMonetary"];

    let data = Array.from(list);

    data.map((i) => {
      let a = i;
      i.createAt = formatTime(i?.createAt);
      return a;
    });
    let extra = undefined;
    if (totalValues) {
      extra = `TOTAL A PAGAR (R$): ; ${totalValues.totalAllCompanies
        ?.toString()
        ?.replace(".", ",")}`;
    }
    ToCSV.report(
      data,
      headers,
      rows,
      `Relatório_Geral`,
      `Relatório Geral`,
      filters,
      extra
    );
  };

  const getFullData = () => {
    const roleId = GetRoleByName(userInfos?.role?.name);
    fullReport(
      {
        searchParameters: lastFilter?.searchParameters,
      },
      roleId,
      (data) => {
        exportData(data);
      }
    );
  };
  const onClear = () => {
    const roleId = GetRoleByName(userInfos?.role?.name);
    setSortKey(roleId === 1 ? "fullName" : "companyName");
    setSortDirection(true);
  };
  return (
    <Styles.Container>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <SearchReports
            onSubmit={(data) => submit(data)}
            userInfos={userInfos}
            companiesList={companiesList}
            usernameAndRolesList={usernameAndRolesList}
            onClear={onClear}
          />
        </Grid>
        <Grid item xs={12}>
          <IntegratedComponentHolder
            showReload={!isFetching && generalReportList?.elements?.length < 1}
            reloadMessage={"Não encontrado"}
            showEmpty={!isFetching && generalReportList?.elements?.length < 1}
            emptyMessage={"Lista Vazia"}
            maxSize={"62rem"}
          >
            {isFetching ? (
              <div
                style={{
                  width: "100%",
                  height: "40vh",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <CircularProgress color="primary" />
              </div>
            ) : (
              <ReportList
                page={page + 1}
                setPage={(a) => {
                  setPage(a - 1);
                  nextPageReport(lastFilter?.searchParameters, a - 1);
                }}
                totalPages={generalReportList?.pageTotal}
                userInfos={userInfos}
                isFetching={isFetching}
                generalReportList={generalReportList?.list}
                lastFilter={lastFilter?.searchParameters}
                user={generalReportList}
                sort={sortContent}
                exportData={() => getFullData()}
                sortList={rowsWithSort}
                roleId={GetRoleByName(userInfos?.role?.name)}
                setSortKey={setSortKey}
                setSortDirection={setSortDirection}
                sortKey={sortKey}
                sortDirection={sortDirection}
                totalValues={totalValues}
              />
            )}
          </IntegratedComponentHolder>
        </Grid>
      </Grid>
    </Styles.Container>
  );
}

function mapStateToProps(redux) {
  const {
    isFetching: isFetchingPage,
    generalReportList,
    lastFilter,
  } = redux.generalReport;
  const {
    isFetching: isFetchingGlobal,
    userInfos,
    companiesList,
    usernameAndRolesList,
  } = redux.global;

  const { totalValues } = redux.detailReport;
  const { isFetchingFullValue } = redux.detailReport;
  return {
    isFetching: isFetchingPage || isFetchingGlobal || isFetchingFullValue,
    companiesList,
    userInfos,
    generalReportList,
    lastFilter,
    usernameAndRolesList,
    totalValues,
  };
}

function mapDispatchToProps(dispatch) {
  const {
    reportGeneralListRequest,
    reportGeneralListInstitutionRequest,
    fullReport,
  } = Creators;
  const { getCompanies, getUsernameAndRoles } = GlobalCreators;

  const { getValuesDatail, getValuesInstitutionDatail } = DetailCreators;
  return {
    getValuesDatail: function (data) {
      return dispatch(getValuesDatail(data));
    },
    getValuesInstitutionDatail: function (data, id) {
      return dispatch(getValuesInstitutionDatail(data, id));
    },
    reportGeneralListRequest: function (data) {
      return dispatch(reportGeneralListRequest(data));
    },
    getCompanies: function () {
      return dispatch(getCompanies());
    },
    reportGeneralListInstitutionRequest: function (data) {
      return dispatch(reportGeneralListInstitutionRequest(data));
    },
    getUsernameAndRoles: function () {
      return dispatch(getUsernameAndRoles());
    },
    fullReport: function (data, id, callback) {
      return dispatch(fullReport(data, id, callback));
    },
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(React.memo(GeneralReportPage));
