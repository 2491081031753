import { call, put } from "redux-saga/effects";
import { api } from "../../../services";
import { Creators } from "./index";

import { Alerts } from "../../../lib";

export default function* blockUserAmcred({ data, callback }) {
  try {
    yield call(api.blockUserAmcred, data);

    yield put(Creators.newUserAmcredSuccess());

    callback();
  } catch (response) {
    Alerts.alertError(
      response?.data?.log
        ? response?.data?.log
        : "Não foi possível bloquear o usuário"
    );

    yield put(Creators.newUserAmcredFailure());
  }
}
