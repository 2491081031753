import React from "react";
import { ForgotPassword } from "../features";

function ForgotPasswordPage() {
  return (
    <>
      <ForgotPassword />
    </>
  );
}
export default ForgotPasswordPage;
