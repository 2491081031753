const colors = {
  // TODO/HINT REMOVER Cores projeto anterior
  BlueSuccess: "#6EBe44",
  redError: "#F0166D",
  darkOpacity: "#00000020",

  // TODO/HINT pegar cores totais e mesclar aqui
  //
  //
  darkGray: "#303036",
  //
  lightGray: "#C5C5C5",
  white: "#FFFFFF",
  softGray: "#F6F6F6",
  gray: "#E3E6E9",
  grayDisabled: "#ECECEC",
  carbon: "#393939",
  black: "#000000",
  blue: "#0099FF",
  darkBlue: "#627502",
  subGray: "#A7A7A7",
  GREEN: "#42B14C",

  // Cores AMCRED
  red: "#A42328",
  text: "#49484D",
  yellow: "#FFCC29",
  orange: "#F58634",
};

export default colors;
