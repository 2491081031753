import React from "react";
import { connect } from "react-redux";
import { Styles } from "../styles";

import { CustomText, SkeletonLoader } from "../../../components";
import { Fonts } from "../../../config";
import { GetRoleByName } from "../../../services";

function ValueCard({ isFetching, value, label, userInfos }) {
  return (
    <Styles.Content>
      <Styles.HeaderCard>
        <CustomText fontFamily={Fonts.bold} fontSize={1.75}>
          {label}
        </CustomText>
      </Styles.HeaderCard>
      <Styles.Line style={{ opacity: 0.2 }} />
      {isFetching ? (
        <SkeletonLoader height="150px" width="100%">
          <></>
        </SkeletonLoader>
      ) : (
        <>
          {value !== null && (
            <>
              <Styles.ValueCards $color={GetRoleByName(userInfos?.role?.name)}>
                <CustomText fontFamily={Fonts.bold} fontSize={3.5}>
                  {value}
                </CustomText>
              </Styles.ValueCards>
            </>
          )}
        </>
      )}
    </Styles.Content>
  );
}

function mapStateToProps(redux) {
  const { isFetching: isFetchingPage } = redux.dashboardAmcred;
  const { isFetching: isFetchingGlobal } = redux.global;
  return { isFetching: isFetchingGlobal || isFetchingPage };
}

function mapDispatchToProps(dispatch) {
  return {};
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(React.memo(ValueCard));
