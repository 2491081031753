import { call, put } from "redux-saga/effects";
import { api } from "../../../services";
import { Creators } from "./index";
import { Alerts } from "../../../lib";

export default function* getValuesDatail({ data }) {
  try {
    const companiesIds = data?.searchParameters?.companies?.map((item) => {
      return item.value;
    });

    const newData = {
      begin: !data?.searchParameters?.begin
        ? null
        : data?.searchParameters?.begin,
      end: !data?.searchParameters?.end ? null : data?.searchParameters?.end,
      cnpj:
        !data?.searchParameters?.cnpj || data?.searchParameters?.cnpj === "-1"
          ? null
          : data?.searchParameters?.cnpj,
      companies: companiesIds?.length > 0 ? companiesIds : null,
    };

    const response = yield call(api.getValuesDatail, newData);

    yield put(Creators.getValuesDatailSuccess(response?.data?.response));
  } catch (response) {
    Alerts.alertError(
      response?.data?.log
        ? response?.data?.log
        : "Não foi possível encontrar as solicitações de pesquisa de relatório"
    );
    yield put(Creators.getValuesDatailFailure());
  }
}
