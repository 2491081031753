import React from "react";
import { DetailReportPage } from "../features/detailReport";

function DetailReport() {
  return (
    <>
      <DetailReportPage />
    </>
  );
}

export default DetailReport;
