import styled from "styled-components";
import {
  ButtonContained,
  ButtonOutlined,
  FontStyles,
} from "../../../components";
import { IconButton } from "@material-ui/core";

const Container = styled.div(({ theme }) => {
  const { spacing } = theme;
  return {
    display: "flex",
    width: "100%",
    paddingInline: spacing(3),
    paddingBlock: spacing(2.5),
    overflow: "auto",
  };
});

const Content = styled.div(({ theme }) => {
  const { spacing } = theme;
  return {
    backgroundColor: "white",
    borderRadius: spacing(0.625),
    // width: "100%",
    overflow: "auto",
  };
});

const Line = styled.div(({ theme }) => {
  const { spacing, palette: colors } = theme;
  return {
    backgroundColor: colors.carbon,
    width: "100%",
    height: 1,
    marginBottom: spacing(2),
    opacity: 0.1,
  };
});

const CardContainer = styled.div(({ theme }) => {
  const { spacing, palette: colors } = theme;
  return {
    backgroundColor: colors.white,
    width: "100%",
    height: "100%",
    padding: spacing(2, 2, 3, 2),
    borderRadius: spacing(0.625),
    marginTop: spacing(1),
  };
});

const CardHeader = styled.div(({ theme }) => {
  return {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: theme.spacing(1),
  };
});

const CardTitle = styled.p(({ theme }) => {
  const { palette: colors } = theme;
  return {
    ...FontStyles.semibold14,
    color: colors.text.primary,
    textTransform: "uppercase",
    margin: 0,
  };
});

const FormButtons = styled.div(({ theme }) => {
  return {
    flexDirection: "row",
    display: "flex",
    gap: 8,
    justifyContent: "flex-end",
  };
});

const FormButton = styled(ButtonContained)(({ theme, $color }) => {
  const { palette: colors } = theme;
  return {
    ...FontStyles.medium14,
    textTransform: "capitalize",
    background:
      $color === 2
        ? colors.text.primary
        : $color === 1
        ? colors.orange
        : colors.text.secondary,
    color:
      $color === 2
        ? colors.text.secondary
        : $color === 1
        ? colors.white
        : colors.white,
  };
});

const FormButtonOutlined = styled(ButtonOutlined)(({ theme, $color }) => {
  const { palette: colors } = theme;
  return {
    ...FontStyles.medium14,
    textTransform: "capitalize",
    color:
      $color === 2
        ? colors.text.primary
        : $color === 1
        ? colors.orange
        : colors.text.secondary,
    borderColor:
      $color === 2
        ? colors.text.primary
        : $color === 1
        ? colors.orange
        : colors.text.secondary,
  };
});

const Hide = styled(IconButton)(({ theme }) => {
  const { spacing, palette: colors } = theme;
  return {
    padding: spacing(0.5),
    backgroundColor: "transparent",
    color: colors.text.primary,
    "& .MuiSvgIcon-root": {
      width: spacing(1.875),
      height: spacing(1.875),
    },
  };
});

const Export = styled(ButtonContained)(({ theme, alternative }) => {
  const { spacing, palette: colors } = theme;
  return {
    ...FontStyles.semibold12,
    padding: spacing(0.9, 2),
    lineHeight: "8px",
    backgroundColor: alternative ? colors.text.secondary : colors.text.primary,
    textTransform: "capitalize",

    "& .MuiSvgIcon-root": {
      maxHeight: spacing(1.875),
    },
  };
});

const Styles = {
  Container,
  Content,
  Line,
  CardTitle,
  FormButtons,
  FormButton,
  FormButtonOutlined,
  CardContainer,
  CardHeader,
  Hide,
  Export,
};

export default Styles;
