import { call, put } from "redux-saga/effects";
import { Creators } from ".";
import { api, ResponseError } from "../../services";

export default function* getCpfsCnpj() {
  try {
    const response = yield call(api.getCpfsCnpj);

    yield put(
      Creators.getCpfsCnpjSuccess(
        response?.data?.response?.map((item) => {
          return { value: item, label: item };
        })
      )
    );
  } catch (response) {
    const errorResponse = new ResponseError(response);
    errorResponse.alertMessage();
    yield put(Creators.getCpfsCnpjFailure());
  }
}
