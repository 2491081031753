import { call, put } from "redux-saga/effects";
import { api } from "../../../services";
import { Creators } from "./index";
import { Alerts } from "../../../lib";

export default function* sendAnalysisResponse({ data, form }) {
  try {
    const requestData = {
      id: data?.id,
      cpfOrCnpj: data?.cpfOrCnpj,
      signalCreditRelease: data?.signalCreditRelease
        ? parseInt(data?.signalCreditRelease)
        : 2,
    };
    const response = yield call(api.sendAnalysisResponse, requestData);

    yield put(Creators.sendAnalysisResponseSuccess(response?.data?.response));
    form();
  } catch (response) {
    Alerts.alertError("Não foi possível enviar a resposta da analise.");
    yield put(Creators.sendAnalysisResponseFailure());
  }
}
