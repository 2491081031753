import React from "react";
import Styles from "../styles/Styles";
import { connect } from "react-redux";
import { Creators } from "../reduxSagas";
import { Creators as GlobalCreators } from "../../globalReduxSagas";
import { Grid } from "@material-ui/core";
import { CustomText, IntegratedComponentHolder } from "../../../components";
import { Fonts, SVG } from "../../../config";
import { DataCards, Filters } from "../components";
import toCSV from "../../../lib/toCSV";

function DashboardInstitutions({
  searchDashboardInstitutions,
  dashboardData,
  dashLastFilter,
  isFetching,
  allUserFullnameList,
  getAllUserFullname,
  userInfos,
}) {
  const [isMounted, setIsMounted] = React.useState(false);
  const [minH, setHeight] = React.useState("100vh");

  const searchUsers = (filters, user) => {
    searchDashboardInstitutions(filters);
  };

  const Mount = React.useCallback(() => {
    const now = new Date();
    const year = now.getFullYear();
    const month = now.getMonth() + 1; // Os meses em JavaScript são indexados de 0 a 11

    const begin = new Date(year, month - 1, 1);
    const end = now;

    setIsMounted(true);
    getAllUserFullname();
    searchDashboardInstitutions({
      begin: begin,
      end: end,
    });
  }, [searchDashboardInstitutions, getAllUserFullname]);

  React.useEffect(Mount, [Mount]);

  React.useEffect(() => {
    if (isMounted) {
      const newH = document.getElementById("header-menu");
      setHeight(newH?.offsetHeight);
    }
  }, [isMounted]);

  const clearFilter = () => {};

  const formatFilter = (obj) => {
    function formatDate(date) {
      const day = String(date.getDate()).padStart(2, "0");
      const month = String(date.getMonth() + 1).padStart(2, "0");
      const year = String(date.getFullYear());
      return day + "/" + month + "/" + year;
    }
    const result = [];

    if (obj.userIds && Array.isArray(obj.userIds)) {
      const usersFullnames = obj.userIds?.map((item) => {
        const itemName = allUserFullnameList?.find((i) => i?.value === item);
        if (itemName !== -1) {
          return itemName?.label;
        } else {
          return "";
        }
      });
      const UsersIdsStr = "Analistas: " + usersFullnames.join(", ");
      result.push(UsersIdsStr);
    }

    if (obj.begin && obj.begin instanceof Date) {
      const formattedBegin = formatDate(obj.begin);

      if (obj.end && obj.end instanceof Date) {
        const formattedEnd = formatDate(obj.end);
        result.push("Período: " + formattedBegin + " até " + formattedEnd);
      } else {
        result.push("Período: " + formattedBegin);
      }
    }

    return result;
  };

  const exportData = (data) => {
    let filters = formatFilter(dashLastFilter);

    let newData = {
      total: data?.total,
      totalAccess: data?.totalAccess,
      totalReceivable: data?.totalReceivable?.toString()?.replace(".", ","),
      accessNumber: data?.accessNumber,
      accessValue: data?.accessValue?.map((i) => {
        return {
          name: i?.name,
          value: i?.value?.toString()?.replace(".", ","),
        };
      }),
    };

    toCSV.dash(
      newData,
      "Dados analistas",
      "Dashboard_Analistas",
      {
        total: "Total de analistas",
        totalAccess: "Total de consultas",
        totalReceivable: "Total a pagar (R$)",
        accessNumber: [
          "Analistas",
          "Quantidade",
          "NÚMERO DE CONSULTAS POR ANALISTA",
        ],
        accessValue: [
          "Analistas",
          "Valor (R$)",
          "VALOR TOTAL DE CONSULTAS POR ANALISTA",
        ],
      },
      filters
    );
  };

  return (
    <Styles.Container minH={minH}>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Filters
            onSubmit={(data) => searchUsers(data, dashboardData)}
            onClear={clearFilter}
            allUserFullnameList={allUserFullnameList}
            userInfos={userInfos}
          />
        </Grid>
        <Grid item xs={12}>
          <Styles.Content>
            <Styles.HeaderCard>
              <CustomText fontFamily={Fonts.bold} fontSize={1.75}>
                EXPORTAÇÃO DE DADOS
              </CustomText>
              <Styles.NewUser
                startIcon={<SVG.Export />}
                fullWidth={false}
                onClick={() => exportData(dashboardData?.response)}
                disabled={!Boolean(dashboardData?.response) || isFetching}
                loading={isFetching}
              >
                Exportar
              </Styles.NewUser>
            </Styles.HeaderCard>
          </Styles.Content>
        </Grid>
        <Grid item xs={12}>
          <IntegratedComponentHolder
            showReload={!isFetching && !dashboardData?.data}
            reloadMessage={"Nenhum dado encontrado"}
            showEmpty={!isFetching && !dashboardData?.data}
            emptyMessage={"Nenhum dado encontrado"}
            maxSize={"62rem"}
          >
            <DataCards data={dashboardData?.data} userInfos={userInfos} />
          </IntegratedComponentHolder>
        </Grid>
      </Grid>
    </Styles.Container>
  );
}

function mapStateToProps(redux) {
  const {
    isFetching: isFetchingPage,
    dashboardData,
    dashLastFilter,
  } = redux.dashboardInstitutions;
  const {
    isFetching: isFetchingGlobal,
    allUserFullnameList,
    userInfos,
  } = redux.global;

  return {
    isFetching: isFetchingPage || isFetchingGlobal,
    dashboardData,
    dashLastFilter,
    allUserFullnameList,
    userInfos,
  };
}

function mapDispatchToProps(dispatch) {
  const { searchDashboardInstitutions } = Creators;
  const { getAllUserFullname } = GlobalCreators;
  return {
    searchDashboardInstitutions: function (data) {
      return dispatch(searchDashboardInstitutions(data));
    },
    getAllUserFullname: function () {
      return dispatch(getAllUserFullname());
    },
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(React.memo(DashboardInstitutions));
