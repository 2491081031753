export default function getGeneralReport(response) {
  const data = response?.data?.response?.elements?.map((item) => {
    return {
      companyCnpj: item?.companyCnpj,
      companyName: item?.companyName,
      queryQuantity: item?.queryQuantity,
      totalValue: item?.totalValue,
    };
  });

  return { ...response?.data?.response, list: data };
}
