import React from "react";
import { connect } from "react-redux";
import { Styles } from "../styles";

import Chart from "chart.js";
import {
  ChartDescription,
  CustomText,
  SkeletonLoader,
} from "../../../components";
import { Fonts } from "../../../config";
import colors from "../../../config/colors";
function BarChart({ isFetching, data, small, label, type }) {
  const chartRef = React.useRef(null);
  const [, setChart] = React.useState();

  React.useEffect(() => {
    if (data?.chartData && !isFetching) {
      setChart((r) => {
        if (r != null) {
          r.destroy();
        }
        return new Chart(chartRef.current, data?.chartData);
      });
    }
  }, [isFetching, data, setChart]);

  return (
    <Styles.Content>
      <Styles.HeaderCard>
        <CustomText fontFamily={Fonts.bold} fontSize={1.75}>
          {label}
        </CustomText>
      </Styles.HeaderCard>
      <Styles.Line style={{ opacity: 0.2 }} />
      {isFetching ? (
        <SkeletonLoader height="250px" width="100%">
          <></>
        </SkeletonLoader>
      ) : (
        <>
          {chartRef !== null && (
            <>
              <CustomText
                styles={{ justifyContent: "flex-end" }}
                fontFamily={Fonts.light}
                fontSize={1.5}
                color="#00000070"
              >
                *Analistas sem consultas foram ocultados
              </CustomText>
              <canvas
                style={{
                  maxWidth: "100%",
                  maxHeight: "250px",
                }}
                ref={chartRef}
              ></canvas>
              <ChartDescription box color={colors.orange} loading={isFetching}>
                {`Média de ${
                  type === "value" ? "Valor" : "Consultas"
                }: ${data?.lineAverage?.toFixed(2)?.replace(".", ",")}`}
              </ChartDescription>
            </>
          )}
        </>
      )}
    </Styles.Content>
  );
}

function mapStateToProps(redux) {
  const { isFetching: isFetchingPage } = redux.dashboardAmcred;
  const { isFetching: isFetchingGlobal } = redux.global;
  return { isFetching: isFetchingGlobal || isFetchingPage };
}

function mapDispatchToProps(dispatch) {
  return {};
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(React.memo(BarChart));
