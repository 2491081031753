import createReducers from "../../../store/helpers/createPageReducer";
import getInputValues from "./getInputValues";
import getLastAnalysis from "./getLastAnalysis";
import newCreditRequest from "./newCreditRequest";
import sendAnalysisResponse from "./sendAnalysisResponse";

const { Creators, reducers, sagas } = createReducers(
  [
    {
      name: "getCreditAnalysis",
      params: ["data", "form"],
      function: (state, { data }) => {
        return {
          ...state,
          isFetching: true,
          lastInputs: data,
        };
      },
      sagaFunction: newCreditRequest,
    },
    {
      name: "getCreditAnalysisSuccess",
      params: ["data"],
      function: (state, { data }) => ({
        ...state,
        isFetching: false,
        analysisScore: data,
      }),
    },
    {
      name: "getCreditAnalysisFailure",
      function: (state) => ({ ...state, isFetching: false }),
    },

    {
      name: "getInputValues",
      function: (state) => {
        return {
          ...state,
          isFetching: true,
        };
      },
      sagaFunction: getInputValues,
    },
    {
      name: "getInputValuesSuccess",
      params: ["data"],
      function: (state, { data }) => ({
        ...state,
        isFetching: false,
        inputs: data,
      }),
    },
    {
      name: "getInputValuesFailure",
      function: (state) => ({ ...state, isFetching: false }),
    },

    {
      name: "getLastAnalysis",
      function: (state) => {
        return {
          ...state,
          isFetching: true,
        };
      },
      sagaFunction: getLastAnalysis,
    },
    {
      name: "getLastAnalysisSuccess",
      params: ["data"],
      function: (state, { data }) => ({
        ...state,
        isFetching: false,
        analysisScore: data,
        lastInputs: data,
      }),
    },
    {
      name: "getLastAnalysisFailure",
      function: (state) => ({ ...state, isFetching: false }),
    },

    {
      name: "sendAnalysisResponse",
      params: ["data", "form"],
      function: (state) => {
        return {
          ...state,
          isFetching: true,
        };
      },
      sagaFunction: sendAnalysisResponse,
    },
    {
      name: "sendAnalysisResponseSuccess",

      function: (state) => ({
        ...state,
        isFetching: false,

        analysisScore: null,
        lastInputs: null,
      }),
    },
    {
      name: "sendAnalysisResponseFailure",
      function: (state) => ({ ...state, isFetching: false }),
    },
  ],
  {
    isFetching: false,
    inputs: null,
    analysisScore: null,
    lastInputs: null,
  }
);

export { Creators, reducers, sagas };
