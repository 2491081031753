import { call, put } from "redux-saga/effects";
import { api } from "../../../services";
import { Creators } from "./index";

import { Alerts } from "../../../lib";

export default function* editUserAssociated({ data, callback }) {
  try {
    let sendData = {
      firstName: data?.firstName ? data?.firstName : null,
      lastName: data?.lastName ? data?.lastName : null,
      roleID: data?.roleId ? data?.roleId : null,
      email: data?.email ? data?.email : null,
    };

    yield call(api.editUserAssociated, { data: sendData, id: data?.id });

    yield put(Creators.editUserAssociatedSuccess());

    callback();
  } catch (response) {
    Alerts.alertError(
      response?.data?.log
        ? response?.data?.log
        : "Erro ao cadastrar novo usuário",
      1
    );

    yield put(Creators.editUserAssociatedFailure());
  }
}
