import { CircularProgress, Grid } from "@material-ui/core";
import React, { useCallback, useEffect, useState } from "react";
import { connect } from "react-redux";
import { IntegratedComponentHolder } from "../../../components";
import { customModal } from "../../modals/utils";

import { HeaderRegister, Filters, TableUsers, ModalEdit } from "../components";
import ModalBlock from "../components/ModalBlock";
import ModalRegister from "../components/ModalRegister";
import { Creators } from "../reduxSagas";

import { Creators as GlobalCreators } from "../../globalReduxSagas";

import Styles from "../styles/Styles";
const pageSize = 8;
function RegisterAndQuery({
  searchUsersAssociated,
  userAssociatedList,
  lastFilter,
  isFetching,
  getRolesAssociatedList,
  rolesAssociatedList,
  newUserAssociated,
  unblockUserAssociated,
  blockUserAssociated,
  getFilterAssociatedUsers,
  filterAssociatedUsers,
  userInfos,
  editUserAssociated,
}) {
  const [isMounted, setIsMounted] = useState(false);
  const [page, setPage] = useState(0);
  const [minH, setHeight] = useState("100vh");

  const searchUsers = (filters, user) => {
    if (!user) {
      setPage(0);
    }

    searchUsersAssociated({
      searchParameters: filters,
      page: {
        size: pageSize,
        page: !user ? 0 : page,
      },
    });
  };

  const Mount = useCallback(() => {
    setIsMounted(true);
    getRolesAssociatedList();
    getFilterAssociatedUsers();
    searchUsersAssociated({
      searchParameters: {},
      page: { size: pageSize, page: 0 },
    });
  }, [getRolesAssociatedList, searchUsersAssociated, getFilterAssociatedUsers]);

  useEffect(Mount, [Mount]);

  useEffect(() => {
    if (isMounted) {
      const newH = document.getElementById("header-menu");
      setHeight(newH?.offsetHeight);
    }
  }, [isMounted]);

  const openNewUserModal = () => {
    customModal.setInfos(
      "REALIZAR NOVO CADASTRO DE USUÁRIO ",
      [],
      null,
      null,
      <>
        <ModalRegister
          userInfos={userInfos}
          roleList={filterAssociatedUsers?.roleNames}
          onSubmit={(data) => {
            customModal.close();
            newUserAssociated(data, () => {
              customModal.setInfos(
                "CADASTRO REALIZADO",
                [],
                null,
                null,
                <ModalBlock
                  rolesAssociatedList={rolesAssociatedList}
                  user={{
                    ...data,
                    fullName: data?.firstName + " " + data?.lastName,
                  }}
                  viewOnly
                  onSubmit={() => customModal.close()}
                />,
                null,
                null,
                1
              );
              getFilterAssociatedUsers();
              searchUsers(lastFilter?.searchParameters);
            });
          }}
        />
      </>,
      null,
      null,
      1
    );
  };

  const openBlockModal = (user) => {
    customModal.setInfos(
      "BLOQUEAR ACESSO",
      [],
      null,
      null,
      <>
        <ModalBlock
          block
          rolesList={filterAssociatedUsers?.roleNames}
          user={user}
          onSubmit={() => {
            blockUserAssociated(user?.id, () => {
              customModal.close();
              customModal.setInfos(
                "Sucesso!",
                ["Usuário bloqueado com sucesso!"],
                {
                  onClick: () => {
                    customModal.close();
                  },
                  label: "Ok",
                },
                null,
                null,
                null,
                null,
                1
              );
              searchUsers(lastFilter?.searchParameters, true);
            });
          }}
        />
      </>,
      null,
      null,
      1
    );
  };

  const openUnblock = (user) => {
    customModal.setInfos(
      "DESBLOQUEAR ACESSO",
      [],
      null,
      null,
      <>
        <ModalBlock
          rolesList={filterAssociatedUsers?.roleNames}
          user={user}
          onSubmit={() => {
            unblockUserAssociated(user?.id, () => {
              customModal.close();
              customModal.setInfos(
                "Sucesso!",
                ["Usuário Desbloqueado com sucesso!"],
                {
                  onClick: () => {
                    customModal.close();
                  },
                  label: "Ok",
                },
                null,
                null,
                null,
                null,
                1
              );
              searchUsers(lastFilter?.searchParameters, true);
            });
          }}
        />
      </>,
      null,
      null,
      1
    );
  };

  const openEditUserModal = (user) => {
    customModal.setInfos(
      "EDITAR USUÁRIO",
      [],
      null,
      null,
      <>
        <ModalEdit
          user={user}
          userInfos={userInfos}
          roleList={filterAssociatedUsers?.roleNames}
          onSubmit={(data) => {
            customModal.close();
            editUserAssociated({ ...data, id: user?.id }, () => {
              customModal.setInfos(
                "EDIÇÃO REALIZADA",
                [`Usuário editado com sucesso!`],
                {
                  label: "Ok",
                  onClick: () => {
                    customModal.close();
                  },
                },
                null,
                null,
                null,
                null,
                1
              );
              getFilterAssociatedUsers();
              searchUsers(lastFilter?.searchParameters);
            });
          }}
        />
      </>,
      null,
      null,
      1
    );
  };
  const clearFilter = () => {};

  return (
    <Styles.Container minH={minH}>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <HeaderRegister onClickRegister={openNewUserModal} />
        </Grid>
        <Grid item xs={12}>
          <Filters
            filterAssociatedUsers={filterAssociatedUsers}
            roleList={rolesAssociatedList}
            onSubmit={(data) => searchUsers(data)}
            onClear={clearFilter}
            userInfos={userInfos}
          />
        </Grid>
        <Grid item xs={12}>
          <IntegratedComponentHolder
            showReload={
              !isFetching && !Array.isArray(userAssociatedList?.elements)
            }
            reloadMessage={"Nenhum dado encontrado"}
            showEmpty={!isFetching && userAssociatedList?.elements?.length < 1}
            emptyMessage={"Lista Vazia"}
            maxSize={"62rem"}
          >
            {isFetching ? (
              <div
                style={{
                  width: "100%",
                  height: "40vh",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <CircularProgress color="primary" />
              </div>
            ) : (
              <TableUsers
                list={userAssociatedList?.elements}
                onBlock={openBlockModal}
                onUnblock={openUnblock}
                onEdit={openEditUserModal}
                page={page + 1}
                setPage={(a) => {
                  setPage(a - 1);

                  searchUsersAssociated(
                    {
                      searchParameters: lastFilter?.searchParameters,
                      page: { size: pageSize, page: a - 1 },
                    },
                    userAssociatedList
                  );
                }}
                totalPages={userAssociatedList?.pageTotal}
              />
            )}
          </IntegratedComponentHolder>
        </Grid>
      </Grid>
    </Styles.Container>
  );
}

function mapStateToProps(redux) {
  const {
    isFetching: isFetchingPage,
    userAssociatedList,
    lastFilter,
  } = redux.registerAndQueryAssociated;
  const {
    isFetching: isFetchingGlobal,
    rolesAssociatedList,
    filterAssociatedUsers,
    userInfos,
  } = redux.global;

  return {
    isFetching: isFetchingPage || isFetchingGlobal,
    userAssociatedList,
    lastFilter,
    rolesAssociatedList,
    filterAssociatedUsers,
    userInfos,
  };
}

function mapDispatchToProps(dispatch) {
  const {
    searchUsersAssociated,
    newUserAssociated,
    unblockUserAssociated,
    blockUserAssociated,
    editUserAssociated,
  } = Creators;
  const { getRolesAssociatedList, getFilterAssociatedUsers } = GlobalCreators;

  return {
    searchUsersAssociated: function (data) {
      return dispatch(searchUsersAssociated(data));
    },
    newUserAssociated: function (data, callback) {
      return dispatch(newUserAssociated(data, callback));
    },
    editUserAssociated: function (data, callback) {
      return dispatch(editUserAssociated(data, callback));
    },
    blockUserAssociated: function (data, callback) {
      return dispatch(blockUserAssociated(data, callback));
    },
    unblockUserAssociated: function (data, callback) {
      return dispatch(unblockUserAssociated(data, callback));
    },
    getRolesAssociatedList: function () {
      return dispatch(getRolesAssociatedList());
    },
    getFilterAssociatedUsers: function () {
      return dispatch(getFilterAssociatedUsers());
    },
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(React.memo(RegisterAndQuery));
