import React from "react";
import { NotFound } from "../features";

function NotFoundPage() {
  return (
    <>
      <NotFound />
    </>
  );
}

export default NotFoundPage;
