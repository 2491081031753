import React from "react";
import { connect } from "react-redux";
import { Styles } from "../styles";

import { CustomText, InputAutoComplete, InputDate } from "../../../components";
import { Fonts, Spacing, Themes } from "../../../config";
import { Grid } from "@material-ui/core";
import {
  Delete,
  KeyboardArrowDown,
  KeyboardArrowUp,
  Search,
} from "@material-ui/icons";
import { FormHolder } from "../../../FormConfig";

import fonts from "../../../config/fonts";
const now = new Date();
const year = now.getFullYear();
const month = now.getMonth() + 1; // Os meses em JavaScript são indexados de 0 a 11

const begin = new Date(year, month - 1, 1);
const end = now;
function Filters({ isFetching, listInputs, onSubmit, onClear }) {
  const [show, setShow] = React.useState(true);
  const [ref, setRef] = React.useState(null);
  const [initDate, setInitDate] = React.useState(begin);

  return (
    <Styles.Content>
      <Styles.HeaderCard>
        <CustomText fontFamily={Fonts.bold} fontSize={1.75}>
          PESQUISAR CADASTROS
        </CustomText>
        <Styles.Hide onClick={() => setShow(!show)}>
          {show ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
        </Styles.Hide>
      </Styles.HeaderCard>
      <Styles.Line></Styles.Line>
      {show && (
        <>
          <FormHolder formRef={setRef} onSubmit={onSubmit}>
            <Grid container spacing={1} style={{ alignItems: "flex-end" }}>
              <Grid item xs={6} sm={6} md={6} lg={4}>
                <InputAutoComplete
                  name="companyName"
                  inputLabel="NOME DA INSTITUIÇÃO ASSOCIADA"
                  options={
                    listInputs?.companies ? [...listInputs?.companies] : []
                  }
                />
              </Grid>
              <Grid item xs={6} sm={6} md={6} lg={3}>
                <InputAutoComplete
                  name="companyCnpj"
                  inputLabel="CNPJ"
                  options={listInputs?.cnpjs ? [...listInputs?.cnpjs] : []}
                />
              </Grid>
              <Grid item xs={6} sm={12} md={6} lg={5}>
                <InputAutoComplete
                  name="requester"
                  inputLabel="GESTOR"
                  options={listInputs?.names ? [...listInputs?.names] : []}
                />
              </Grid>

              <Grid item xs={6} sm={6} md={6} lg={3}>
                <InputAutoComplete
                  name="cpf"
                  inputLabel="CPF"
                  options={listInputs?.cpfs ? [...listInputs?.cpfs] : []}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={3}>
                <InputAutoComplete
                  name="email"
                  inputLabel="E-MAIL"
                  options={listInputs?.emails ? [...listInputs?.emails] : []}
                />
              </Grid>
              <Grid container item spacing={1} xs={12} sm={12} md={6} lg={6}>
                <Grid
                  container
                  item
                  xs={12}
                  style={{ marginBottom: Spacing(-1) }}
                >
                  <CustomText
                    textColor={Themes.Light.palette.text.secondary}
                    fontSize={1.75}
                    fontFamily={fonts.semibold}
                  >
                    PERÍODO DA SOLICITAÇÃO
                  </CustomText>
                </Grid>

                <Grid item xs={5}>
                  <InputDate
                    defaultValue={begin}
                    name="begin"
                    nextInput="end"
                    placeholder="dd/mm/yyyy"
                    onChange={(data, value) => {
                      if (
                        ref?.getFieldValue("end") &&
                        ref?.getFieldValue("end") < value
                      ) {
                        setInitDate(value);
                        var novaData = new Date(value);
                        novaData.setDate(value.getDate() + 1);

                        ref?.setValue("end", novaData);
                      } else {
                        setInitDate(value);
                      }
                    }}
                  />
                </Grid>
                <Grid
                  item
                  xs={2}
                  style={{ justifyContent: "center", display: "flex" }}
                >
                  <CustomText
                    styles={{ paddingTop: Spacing(1.75) }}
                    fontSize={1.75}
                    fontFamily={fonts.medium}
                  >
                    até
                  </CustomText>
                </Grid>
                <Grid item xs={5}>
                  <InputDate
                    defaultValue={end}
                    minDate={initDate}
                    name="end"
                    placeholder="dd/mm/yyyy"
                  />
                </Grid>
              </Grid>
            </Grid>

            <Styles.FormButtons>
              <Styles.FormButtonOutlined
                startIcon={<Delete />}
                fullWidth={false}
                type="clear"
                onClick={() => {
                  ref?.clearAllValues();
                  onClear();
                }}
              >
                Limpar
              </Styles.FormButtonOutlined>
              <Styles.FormButton
                startIcon={<Search />}
                fullWidth={false}
                type="submit"
              >
                Pesquisar
              </Styles.FormButton>
            </Styles.FormButtons>
          </FormHolder>
        </>
      )}
    </Styles.Content>
  );
}

function mapStateToProps(redux) {
  const { isFetching } = redux.registerAndQuery;
  return { isFetching };
}

function mapDispatchToProps(dispatch) {
  return {};
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(React.memo(Filters));
