import React from "react";
import {
  FormControl,
  TextField,
  FormHelperText,
  Popper,
} from "@material-ui/core";
import styled from "styled-components";
import { withTheme } from "@material-ui/styles";
import { makeStyles } from "@material-ui/core";
import { formConnector } from "../../FormConfig";
import { Autocomplete, createFilterOptions } from "@material-ui/lab";
import { FontStyles } from "../index";
import Text from "../strings/Text";
const StyledInput = styled(TextField)(
  ({
    theme,
    error,
    disabled,
    $inputStyle,
    $error,
    $small,
    $smallBottom,
    $alternativecolors,
    $margin,
    $height,
    $color,
  }) => {
    const { spacing, palette: colors } = theme;
    return {
      paddingBlock: spacing(1),
      paddingTop: $smallBottom === 2 ? spacing(1) : null,
      paddingInline: spacing(1),
      multiline: true,
      textOverflow: "ellipsis",
      backgroundColor: $alternativecolors
        ? colors.text.secondary
        : colors.background.input,
      borderRadius: spacing(0.625),
      height: $height === 2 ? spacing(6) : null,
      boxShadow: $error
        ? `inset 0 0 0 2px ${colors.text.tertiary}`
        : `inset 0 0 0 ${colors.error.main}`,

      "& .MuiInput-input": {
        color: $alternativecolors ? colors.text.secondary : colors.text.primary,
        marginLeft: spacing(1),
        "&::placeholder": {
          opacity: 0.8,
        },
      },
      "& .MuiIconButton-root": {
        color:
          $color === 2
            ? colors.text.primary
            : $color === 1
            ? colors.orange
            : colors.text.secondary,
      },

      ...$inputStyle,
    };
  }
);
const Label = styled(Text)(
  ({ theme, labelsmall, $witherror, disabled, $color }) => {
    const { palette: colors } = theme;

    return {
      ...FontStyles.semibold14,

      textTransform: "uppercase",
      position: "relative",
      top: 0,
      left: 0,
      color: $witherror
        ? colors.error.main
        : $color === 2
        ? colors.text.primary
        : $color === 1
        ? colors.orange
        : colors.text.secondary,
      transition: ".2s",
      pointerEvents: "none",
      opacity: disabled && 0.6,
    };
  }
);
const ErrorMessage = styled(FormHelperText)(({ theme }) => {
  const { palette: colors } = theme;
  return {
    color: colors.text.tertiary,
  };
});

const PopperSelect = styled(Popper)(({ theme, $color }) => {
  const { palette: colors } = theme;
  return {
    "& .MuiAutocomplete-listbox": {
      color:
        $color === 2
          ? colors.text.primary
          : $color === 1
          ? colors.orange
          : colors.text.secondary,
      "& li:hover": { backgroundColor: colors.text.tertiary + 20 },
      '& [aria-selected="true"]': {
        backgroundColor: colors.text.tertiary + 40,
        borderColor: "transparent",
      },
      "::-webkit-scrollbar-thumb": {
        background:
          $color === 2
            ? colors.text.primary
            : $color === 1
            ? colors.orange
            : colors.text.secondary,
        borderRadius: "15px",
        height: "2px",
      },
    },
  };
});
const useStyles = makeStyles((theme) => ({
  root: {},
  inputRoot: {
    '&.MuiAutocomplete-inputRoot[class*="MuiInput-root"]': {
      "&::before": { display: "none" },
      "&::after": { display: "none" },
    },
  },
}));
function InputComponent(props) {
  const { value, error, onBlur, setConfigs, formHandler, usedProps, ref } =
    formConnector.useStringValue(props);
  const {
    inputLabel,
    hideVisualError,
    small,
    smallBottom,
    alternativeColors,
    marginInput,
    heightInput,
    options,
    inputStyle,
    disabled,
    required,
    labelsmall,
    noOptionsText,
    readOnly,
    placeholder = "Todos",
    color,
    justNumbers = false,
  } = usedProps;
  const classes = useStyles();
  let focus = false;
  React.useEffect(() => {
    if (ref && value === "") {
      const ele = ref.current.getElementsByClassName(
        "MuiAutocomplete-clearIndicator"
      )[0];
      if (ele) {
        ele.click();
      }
    }
  }, [value, ref]);

  const filterOptions = createFilterOptions({
    stringify: (option) => option.label?.replace(/[.,/\\-]/g, ""),
  });

  const filterOptionsDefault = createFilterOptions({
    stringify: (option) => option.label,
  });
  return (
    <FormControl fullWidth error={hideVisualError ? null : Boolean(error)}>
      <Autocomplete
        filterOptions={justNumbers ? filterOptions : filterOptionsDefault}
        ref={ref}
        key={inputLabel}
        noOptionsText={noOptionsText}
        classes={classes}
        componentsProps={{ disableUnderline: true }}
        options={options ? [{ label: "Todos", value: -1 }, ...options] : null}
        PopperComponent={(props) => {
          return <PopperSelect $color={color} {...props} />;
        }}
        clearOnBlur={false}
        clearOnEscape={false}
        disabled={readOnly || disabled}
        disableUnderline={true}
        getOptionLabel={(option) => option.label || ""}
        onChange={(event, value) => {
          if (value === null || value === -1) {
            setConfigs(null, event);
          } else {
            setConfigs(value.value, event);
          }
          setTimeout(() => {
            onBlur();
            formHandler.testInputError(props.name);
          }, 10);
        }}
        renderInput={(params) => (
          <>
            <Label
              labelsmall={labelsmall}
              disabled={disabled}
              $withValue={Boolean(value) || value === 0 || focus}
              $witherror={Boolean(error) ? true : undefined}
              $color={color}
            >
              {inputLabel}
            </Label>
            <StyledInput
              placeholder={placeholder}
              readOnly={readOnly}
              InputProps={{
                disableUnderline: true,
                underline: "none",
              }}
              value={value}
              onFocus={() => (focus = true)}
              disableUnderline={true}
              required={required}
              $inputStyle={inputStyle}
              $alternativecolors={alternativeColors}
              $margin={marginInput}
              $height={heightInput}
              $small={small}
              $smallBottom={smallBottom}
              $error={Boolean(error)}
              error={Boolean(error)}
              $color={color}
              {...params}
            />
          </>
        )}
      />

      <ErrorMessage error={Boolean(error)}>
        {Boolean(error) ? error : " "}
      </ErrorMessage>
    </FormControl>
  );
}

export default withTheme(InputComponent);
