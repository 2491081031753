import React from "react";

export default function useVersion() {
  const [version, setVersion] = React.useState("");
  const didMount = React.useCallback(() => {
    setVersion(process.env.REACT_APP_VERSION);
  }, [setVersion]);
  React.useEffect(didMount, [didMount]);

  return version;
}
