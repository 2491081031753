import React from "react";
import {
  FormControl,
  TextField,
  FormHelperText,
  Popper,
} from "@material-ui/core";
import styled from "styled-components";
import { withTheme } from "@material-ui/styles";
import { formConnector } from "../../FormConfig";
import { Autocomplete } from "@material-ui/lab";
import { FontStyles, Tag, Tolltip } from "../index";
import Text from "../strings/Text";
import { makeStyles } from "@material-ui/core";
const StyledInput = styled(TextField)(
  ({
    theme,
    error,
    disabled,
    $inputStyle,
    $error,
    $small,
    $smallBottom,
    $alternativecolors,
    $margin,
    $height,
    $color,
  }) => {
    const { spacing, palette: colors } = theme;
    return {
      minHeight: spacing(6),
      padding: $small ? spacing(0, 3) : spacing(0.625),
      borderRadius: theme.spacing(0.625),
      multiline: true,
      textOverflow: "ellipsis",
      backgroundColor: $alternativecolors
        ? colors.text.secondary
        : colors.background.input,
      height: $height === 2 ? spacing(6) : null,
      boxShadow: $error
        ? `inset 0 0 0 2px ${colors.text.tertiary}`
        : `inset 0 0 0 ${colors.error.main}`,
      opacity: disabled ? 0.3 : 1,
      "& .MuiInput-input": {
        color: $alternativecolors ? colors.text.secondary : colors.text.primary,
        "&::before": { display: "none" },
        "&::after": { display: "none" },
        "&::placeholder": {
          opacity: 0.8,
        },
      },
      "& .MuiIconButton-root": {
        color:
          $color === 2
            ? colors.text.primary
            : $color === 1
            ? colors.orange
            : colors.text.secondary,
      },
      "&::before": { display: "none" },
      "&::after": { display: "none" },
      paddingTop: spacing(1),
      paddingLeft: spacing(1),
      ...$inputStyle,
    };
  }
);
const Label = styled(Text)(({ theme, $witherror, $color }) => {
  const { palette: colors } = theme;

  return {
    ...FontStyles.semibold14,
    textTransform: "uppercase",
    position: "relative",
    top: 0,
    left: 0,
    color: $witherror
      ? colors.error.main
      : $color === 2
      ? colors.text.primary
      : $color === 1
      ? colors.orange
      : colors.text.secondary,
    transition: ".2s",
    pointerEvents: "none",
  };
});
const ErrorMessage = styled(FormHelperText)(({ theme }) => {
  const { palette: colors } = theme;
  return {
    color: colors.text.tertiary,
  };
});

const PopperSelect = styled(Popper)(({ theme, $color }) => {
  const { palette: colors } = theme;
  return {
    "& .MuiAutocomplete-listbox": {
      color:
        $color === 2
          ? colors.text.primary
          : $color === 1
          ? colors.orange
          : colors.text.secondary,
      "& li": { backgroundColor: colors.background.input },
      "& li:hover": { backgroundColor: colors.primary.paper + 20 },
      '& [aria-selected="true"]': {
        backgroundColor: colors.text.tertiary + 40,
        borderColor: "transparent",
      },
      "::-webkit-scrollbar-thumb": {
        background:
          $color === 2
            ? colors.text.primary
            : $color === 1
            ? colors.orange
            : colors.text.secondary,
        borderRadius: "15px",
        height: "2px",
      },
    },
  };
});
const LabelView = styled.div(() => {
  return {
    display: "flex",
    alignContent: "center",
    justifyContent: "flex-start",
  };
});

const ShowMoreButton = styled.a(({ theme, $color }) => {
  const { palette: colors, spacing } = theme;
  return {
    ...FontStyles.bold12,
    color:
      $color === 2
        ? colors.text.primary
        : $color === 1
        ? colors.orange
        : colors.text.secondary,
    marginLeft: spacing(1),
    cursor: "pointer",
    borderBottom: `1px solid ${
      $color === 2
        ? colors.text.primary
        : $color === 1
        ? colors.orange
        : colors.text.secondary
    }`,
  };
});

const useStyles = makeStyles((theme) => ({
  root: {},
  inputRoot: {
    '&.MuiAutocomplete-inputRoot[class*="MuiInput-root"]': {
      "&::before": { display: "none" },
      "&::after": { display: "none" },
    },
  },
}));

function InputTags(props) {
  const { value, error, onBlur, setConfigs, formHandler, usedProps } =
    formConnector.useStringValue(props);
  const {
    inputLabel,
    hideVisualError,
    small,
    smallBottom,
    alternativeColors,
    marginInput,
    heightInput,
    options,
    inputStyle,
    disabled,
    required,
    noOptionsText,
    tooltip,
    disableError,
    readOnly,
    color,
    placeholder = "Todos",
  } = usedProps;
  let focus = false;
  const classes = useStyles();

  const [show, setShow] = React.useState(false);

  const RemoveDuplicates = (list) => {
    var valueArr = list.map(function (item) {
      return item.value;
    });
    var isDuplicate = valueArr.some(function (item, idx) {
      return valueArr.indexOf(item) !== idx;
    });

    if (isDuplicate) {
      var toRemove = list
        .map((e) => e["value"])
        .map((e, i, final) => final.indexOf(e) !== i && i)
        .filter((obj) => list[obj])
        .map((e) => list[e]["value"]);

      const newList = list.filter(function (el) {
        return el.value !== toRemove[0];
      });
      return newList;
    } else {
      return list;
    }
  };

  return (
    <FormControl fullWidth error={hideVisualError ? null : Boolean(error)}>
      <Autocomplete
        readOnly={readOnly}
        noOptionsText={noOptionsText}
        classes={classes}
        defaultValue={value?.length > 0 ? value : []}
        value={value?.length > 0 ? value : []}
        required={required}
        multiple
        forcePopupIcon={true}
        id="tags-filled"
        options={options ? options : []}
        disabled={disabled}
        disableClearable
        PopperComponent={(props) => {
          return <PopperSelect {...props} $color={color} />;
        }}
        getOptionLabel={(option) => option?.label || ""}
        onChange={(event, value) => {
          if (value === null) {
            setConfigs(-1, event);
          } else {
            setConfigs(RemoveDuplicates(value), event);
          }
          setTimeout(() => {
            onBlur();
            formHandler.testInputError(props.name);
          }, 10);
        }}
        renderInput={(params) => (
          <>
            <LabelView>
              <Label
                $withValue={Boolean(value) || value === 0 || focus}
                $witherror={Boolean(error) ? true : undefined}
                $color={color}
              >
                {inputLabel}
              </Label>
              {tooltip && (
                <Tolltip
                  title={tooltip?.title}
                  tip={tooltip?.content}
                  disableFocusListener
                />
              )}
            </LabelView>

            <StyledInput
              placeholder={value?.length > 0 ? "" : placeholder}
              InputProps={{
                disableUnderline: true,
                underline: "none",
              }}
              onFocus={() => (focus = true)}
              required={required}
              $inputStyle={inputStyle}
              $alternativecolors={alternativeColors}
              $margin={marginInput}
              $height={heightInput}
              $small={small}
              $smallBottom={smallBottom}
              $error={Boolean(error)}
              $color={color}
              error={Boolean(error)}
              {...params}
            />
          </>
        )}
        renderTags={(values, getTagProps) => {
          const selected = [];
          const clearValues = RemoveDuplicates(values);

          if (clearValues.length > 0) {
            if (!show) {
              for (let i = 0; i < 3; i++) {
                if (Boolean(clearValues[i])) {
                  selected.push(
                    <Tag
                      tabindex={clearValues[i].value}
                      label={clearValues[i].label}
                      {...getTagProps({ index: i })}
                    />
                  );
                }
              }
              if (clearValues?.length > 3) {
                selected.push(
                  <>
                    <ShowMoreButton
                      onClick={() => setShow(true)}
                      $color={color}
                    >
                      {`mais ${clearValues?.length - selected?.length}...`}
                    </ShowMoreButton>
                  </>
                );
              }
            } else {
              for (let i = 0; i < clearValues.length; i++) {
                selected.push(
                  <Tag
                    tabindex={clearValues[i].value}
                    label={clearValues[i].label}
                    {...getTagProps({ index: i })}
                  />
                );
              }
              selected.push(
                <>
                  <ShowMoreButton $color={color} onClick={() => setShow(false)}>
                    mostrar menos
                  </ShowMoreButton>
                </>
              );
            }
          } else {
            return null;
          }

          return selected.map((option) => option);
        }}
      />
      {disableError ? null : (
        <ErrorMessage error={Boolean(error)}>
          {Boolean(error) ? error : " "}
        </ErrorMessage>
      )}
    </FormControl>
  );
}

export default withTheme(InputTags);
