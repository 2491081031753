import React from "react";
import { connect } from "react-redux";
import { UserInfosStyles as Styles } from "../styles";

import { GetRoleByName } from "../../../services";
function UserInfos({ isFetching, userInfos }) {
  return (
    <>
      <Styles.PersonInfo>
        <Styles.InfoRow>
          <Styles.Name
            loading={isFetching}
            loadingSizes={[250, 24]}
            $color={GetRoleByName(userInfos?.role?.name)}
          >
            {userInfos?.firstName + " " + userInfos?.lastName}
          </Styles.Name>
          <Styles.Email loading={isFetching} loadingSizes={[250, 24]}>
            {userInfos?.email}
          </Styles.Email>
        </Styles.InfoRow>
      </Styles.PersonInfo>
    </>
  );
}

function mapStateToProps({ global }) {
  const { isFetchingUserInfos: isFetching } = global;
  return { isFetching };
}

function mapDispatchToProps(dispatch) {
  return {};
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(React.memo(UserInfos));
