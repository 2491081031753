//CHAMA A API sendRecoveryPasswordRequest
import { call, put } from "redux-saga/effects";
import { Creators } from ".";
import { api } from "../../../services";

export default function* resetPasswordRequest({ email, callback }) {
  try {
    yield put(Creators.resetPasswordClearDataStart());

    const response = yield call(api.sendRecoveryPasswordRequest, email);
    yield put(Creators.resetPasswordRequestSuccess(response));
    if (callback !== undefined && response.ok) {
      callback();
    }
  } catch (response) {
    yield put(Creators.resetPasswordRequestSuccess());
    callback();
    yield put(Creators.resetPasswordClearData());
  }
}
