import {
  createBarScalesX,
  createFixedDatalabels,
  createLayoutWithDatalabels,
} from "../helpers/CommonChartConfigs";
import ChartDataLabels from "chartjs-plugin-datalabels";
import Chart from "chart.js";
import { Filters } from "../../lib";
import colors from "../../config/colors";
function gerarCoresParaItens(listaItens) {
  const listaCores = [
    "#FFCC29",
    "#5D3EDF",
    "#33AE3C",
    "#BB3A72",
    "#28AFAF",
    "#FF7729",
    "#6A5CA5",
    "#913C1C",
    "#18739B",
    "#683DAA",
    "#629232",
    "#771212",
    "#0E31A5",
  ];
  const arrayCores = [];

  for (let i = 0; i < listaItens.length; i++) {
    const cor = listaCores[i % listaCores.length];
    arrayCores.push(cor);
  }

  return arrayCores;
}

function getAverage(array) {
  var total = 0;
  var count = 0;

  array.forEach(function (item, index) {
    if (item.hasOwnProperty("quantity")) {
      total += item?.quantity;
    } else if (item.hasOwnProperty("value")) {
      total += item?.value;
    }

    count++;
  });

  return total / count;
}

function valoresMaioresQueZero(array) {
  // Filtra o array usando a função de callback
  const resultado = array.filter((valor) => valor > 0);
  return resultado;
}

function filtrarLabelComValorMaiorQueZero(arrayDeObjetos) {
  const resultado = [];

  arrayDeObjetos.forEach((objeto) => {
    if (objeto.hasOwnProperty("quantity") && objeto.quantity > 0) {
      resultado.push(objeto.name);
    } else if (objeto.hasOwnProperty("value") && objeto.value > 0) {
      resultado.push(objeto.name);
    }
  });

  return resultado;
}

export default function getBarChart(response, noScales, isMoney = false) {
  const iMax = 4;
  const data = valoresMaioresQueZero(
    response?.map((item) => {
      if ("quantity" in item) {
        return item?.quantity;
      } else {
        return item?.value;
      }
    })
  );

  const labels = filtrarLabelComValorMaiorQueZero(response);

  const lineAverage = getAverage(response);

  const coresBorda = gerarCoresParaItens(data);
  const cores = coresBorda.map((item) => {
    return item + "4F";
  });

  const chartData = {
    type: "bar",
    data: {
      labels: labels,
      datasets: [
        {
          label: "Teste2",
          type: "bar",
          responsive: true,
          maintainAspectRatio: false,
          maxBarThickness: 27,
          backgroundColor: cores,
          borderColor: coresBorda,
          borderWidth: 2,
          fill: true,
          data: data,
        },
      ],
    },
    plugins: [
      ChartDataLabels,
      {
        beforeDraw: function (chart) {
          if (typeof chart.config.options.lineAverage !== "undefined") {
            var lineAverage = chart.config.options.lineAverage;
            var ctxPlugin = chart.chart.ctx;
            var xAxe = chart.scales[chart.config.options.scales.xAxes[0].id];
            var yAxe = chart.scales[chart.config.options.scales.yAxes[0].id];
            if (yAxe.min !== 0) return;

            ctxPlugin.strokeStyle = colors.orange;
            ctxPlugin.lineWidth = 2;
            ctxPlugin.beginPath();
            lineAverage = (lineAverage - yAxe.min) * (100 / yAxe.max);
            lineAverage = ((100 - lineAverage) / 100) * yAxe.height + yAxe.top;
            ctxPlugin.moveTo(xAxe.left, lineAverage);
            ctxPlugin.lineTo(xAxe.right, lineAverage);
            ctxPlugin.setLineDash([0]);
            ctxPlugin.stroke();
          }

          chart.chart.ctx.setLineDash([0]);
        },
      },
    ],
    options: {
      lineAverage: lineAverage,
      interaction: {
        mode: "dataset",
      },
      responsive: true,
      maintainAspectRatio: false,
      labels: {
        display: false,
      },
      legend: {
        display: false,
      },
      tooltips: {
        enabled: false,
      },
      plugins: {
        datalabels: createFixedDatalabels(
          "white",
          "#00000012",
          "#464646",
          (value, context) => {
            if (value === null) {
              return;
            }
            const valueData = Filters.convertNumberInputMask(
              Number(value).toFixed(2),
              isMoney,
              true
            );
            return labels[context?.dataIndex] + ":\n" + valueData;
          },
          iMax > 32
        ),
      },
      onHover: function (event, elements) {
        var chart = this;
        var expando = chart.$_user_ || (chart.$_user_ = {});

        if (!Chart.helpers.arrayEquals(expando.hovered, elements)) {
          expando.hovered = elements;
          chart.update();
        }
      },
      layout: createLayoutWithDatalabels(),

      scales: noScales
        ? {
            yAxes: [{ ticks: { display: false } }],
            xAxes: [
              { ticks: { display: false }, gridLines: { display: false } },
            ],
          }
        : createBarScalesX("#46464628", "#464646", false, undefined, 8, null),
    },
  };

  return {
    data: {
      chartData,
      lineAverage,
      type: "bar",
    },
  };
}
