import { call, put } from "redux-saga/effects";
import { Creators } from ".";
import { api, ResponseError } from "../../services";

export default function* getUserInfos() {
  try {
    const response = yield call(api.getCurrentUser);

    yield put(Creators.getUserInfosSuccess(response?.data));
  } catch (response) {
    const errorResponse = new ResponseError(response);
    errorResponse.alertMessage();
    yield put(Creators.getUserInfosFailure());
  }
}
