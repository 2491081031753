import createReducers from "../../../store/helpers/createPageReducer";

import searchDashboardInstitutions from "./searchDashboardInstitutions";

const { Creators, reducers, sagas } = createReducers(
  [
    {
      name: "searchDashboardInstitutions",
      params: ["data"],
      function: (state, { data }) => ({
        ...state,
        isFetching: true,
        dashLastFilter: data,
      }),
      sagaFunction: searchDashboardInstitutions,
    },
    {
      name: "searchDashboardInstitutionsSuccess",
      params: ["dashboardData"],

      function: (state, { dashboardData }) => ({
        ...state,
        isFetching: false,
        dashboardData,
      }),
    },
    {
      name: "searchDashboardInstitutionsFailure",
      function: (state) => ({
        ...state,
        isFetching: false,
        dashboardData: null,
      }),
    },
  ],
  {
    isFetching: false,
    dashboardData: null,
    dashdashLastFilter: null,
  }
);

export { Creators, reducers, sagas };
