import { sagas as globalSagas } from "./globalReduxSagas";
import { sagas as loginSagas } from "./login";

import { sagas as recoveryPasswordSagas } from "./forgotPassword";
import { sagas as registerAndQuerySagas } from "./registerAndQuery";
import { sagas as profileSagas } from "./profile";
import { sagas as accessRequestsSagas } from "./accessRequests";
import { sagas as dashboardAmcredSagas } from "./dashboardAmcred";
import { sagas as generalReportSagas } from "./generalReport";
import { sagas as detailReportSagas } from "./detailReport";

import { sagas as dashboardInstitutionsSagas } from "./dashboardInstitutions";

import { sagas as registerAndQueryAssociatedSagas } from "./registerAndQueryAssociated";

import { sagas as creditAnalysisSagas } from "./creditAnalysis";
import { sagas as queriesHistorySagas } from "./queriesHistory";
import { sagas as registerRequestSagas } from "./registerRequest";
import { sagas as registerAndQueryInstitutionSagas } from "./registerAndQueryInstitution";
const sagas = [
  ...globalSagas,
  ...loginSagas,

  ...recoveryPasswordSagas,
  ...registerAndQuerySagas,
  ...profileSagas,
  ...accessRequestsSagas,
  ...dashboardAmcredSagas,
  ...dashboardInstitutionsSagas,
  ...registerAndQueryAssociatedSagas,
  ...creditAnalysisSagas,
  ...registerRequestSagas,
  ...queriesHistorySagas,
  ...registerAndQueryInstitutionSagas,
  ...generalReportSagas,
  ...detailReportSagas,
];

export default sagas;
