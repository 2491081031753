import { call, put } from "redux-saga/effects";
import { Creators } from ".";
import { api } from "../../../services";
import { Alerts } from "../../../lib";

export default function* resetPasswordCodeRequest({ data }) {
  try {
    const dataRequest = {
      email: data?.email,
      code: data?.code?.toUpperCase(),
    };

    const response = yield call(api.recoveryPasswordCode, dataRequest);

    yield put(
      Creators.resetPasswordCodeRequestSuccess(response?.data?.response?.token)
    );
  } catch (response) {
    Alerts.alertError(
      response?.data?.log
        ? response?.data?.log
        : "Código de autenticação incorreto"
    );
    yield put(Creators.resetPasswordCodeFailure());
  }
}
