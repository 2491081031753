import Logo from "../assets/images/logo-amcred.png";
import Background from "../assets/images/turbilhao-de-dados.png";
import BackgroundRed from "../assets/images/grafismo-background.png";

const images = {
  Logo,
  Background,
  BackgroundRed,
};

export default images;
