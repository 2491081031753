export default function getDetailReport(response) {
  const data = response?.data?.response?.elements?.map((item) => {
    return {
      companyCnpj: item.companyCnpj,
      companyName: item.companyName,
      userName: item.userName,
      createAt: item.createAt,
    };
  });

  return { ...response?.data?.response, list: data };
}
