import { call, put } from "redux-saga/effects";
import { api } from "../../../services";
import { Creators } from "./index";

import { Alerts } from "../../../lib";

export default function* searchDashboardAmcred({ data }) {
  try {
    let filters = data;
    if ("companyIds" in filters) {
      filters.companyIds = filters?.companyIds?.map((item) => item?.value);
    }
    const response = yield call(api.searchDashboardAmcred, filters);

    yield put(Creators.searchDashboardAmcredSuccess(response?.data));
  } catch (response) {
    Alerts.alertError(
      response?.data?.log
        ? response?.data?.log
        : "Erro carregar dados do dashboard"
    );

    yield put(Creators.searchDashboardAmcredFailure());
  }
}
