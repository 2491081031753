import React from "react";
import { CreditAnalysis } from "../features";

function CreditAnalysisPage() {
  return (
    <>
      <CreditAnalysis />
    </>
  );
}

export default CreditAnalysisPage;
