import React from "react";
import { connect } from "react-redux";
import { Styles } from "../styles";

import { ButtonText, CustomText, Input } from "../../../components";
import { Fonts } from "../../../config";
import { Grid } from "@material-ui/core";

import { FormHolder } from "../../../FormConfig";

import { customModal } from "../../modals/utils";

function ModalRegister({ isFetching, onSubmit, user }) {
  const fullName = user?.requester.split(" ");
  const name = fullName[0];
  fullName.shift();
  const lastName = fullName.join(" ");

  return (
    <Styles.ModalContent>
      <Styles.HeaderModal>
        <CustomText fontFamily={Fonts.medium} fontSize={1.75}>
          Informe abaixo os dados da Instituição Associada a ser editada:aa
        </CustomText>
      </Styles.HeaderModal>

      <FormHolder onSubmit={onSubmit}>
        <Styles.UserInfo>
          <Grid container spacing={1}>
            <Grid item xs={6}>
              <Styles.UserInfoCard>
                <CustomText fontFamily={Fonts.bold} fontSize={1.75}>
                  NOME DA INSTITUIÇÃO ASSOCIADA
                </CustomText>
                <CustomText fontFamily={Fonts.medium} fontSize={2}>
                  {user?.requester}
                </CustomText>
              </Styles.UserInfoCard>
            </Grid>
            <Grid item xs={6}>
              <Styles.UserInfoCard>
                <CustomText fontFamily={Fonts.bold} fontSize={1.75}>
                  CNPJ
                </CustomText>
                <CustomText fontFamily={Fonts.medium} fontSize={2}>
                  {user?.companyCnpj}
                </CustomText>
              </Styles.UserInfoCard>
            </Grid>

            <Grid item xs={6}>
              <Styles.UserInfoCard>
                <CustomText fontFamily={Fonts.bold} fontSize={1.75}>
                  CPF
                </CustomText>
                <CustomText fontFamily={Fonts.medium} fontSize={2}>
                  {user?.cpf}
                </CustomText>
              </Styles.UserInfoCard>
            </Grid>

            <Grid item xs={6}>
              <Styles.UserInfoCard>
                <CustomText fontFamily={Fonts.bold} fontSize={1.75}>
                  E-MAIL
                </CustomText>
                <CustomText fontFamily={Fonts.medium} fontSize={2}>
                  {user?.email}
                </CustomText>
              </Styles.UserInfoCard>
            </Grid>
          </Grid>
        </Styles.UserInfo>
        <Grid container spacing={1}>
          <Grid item xs={6}>
            <Input
              required="*Campo Obrigatório"
              name="firstName"
              inputLabel="NOME GESTOR"
              defaultValue={name}
            />
          </Grid>
          <Grid item xs={6}>
            <Input
              required="*Campo Obrigatório"
              name="lastName"
              inputLabel="SOBRENOME GESTOR"
              defaultValue={lastName}
            />
          </Grid>
        </Grid>
        <Styles.FormButtons>
          <ButtonText
            fullWidth={false}
            onClick={() => {
              customModal.close();
            }}
          >
            Voltar
          </ButtonText>
          <Styles.FormButton fullWidth={false} type="submit">
            CONFIRMAR EDIÇÃO
          </Styles.FormButton>
        </Styles.FormButtons>
      </FormHolder>
    </Styles.ModalContent>
  );
}

function mapStateToProps(redux) {
  const { isFetching } = redux.registerAndQuery;
  return { isFetching };
}

function mapDispatchToProps(dispatch) {
  return {};
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(React.memo(ModalRegister));
