import { paths } from "../navigation/navigate";

import texts from "./texts";

const Titles = texts["pt-BR"];

const pagesConfig = {
  notLogged: [
    {
      navigationId: 0,
      path: paths.login,
      name: "Login",
    },
    {
      navigationId: 1,
      path: paths.forgotPassword,
      name: "ForgotPasswordPage",
    },
    {
      navigationId: 1,
      path: paths.registerRequest,
      name: "RegisterRequest",
    },
    {
      navigationId: 0,
      path: "*",
      name: "NotFound",
      working: true,
    },
  ],
  logged: [
    {
      dontShow: true,
      pages: [
        {
          navigationId: 0,
          path: paths.home, // TODO Mudar path,
          name: "Home",
          working: true,
        },
        {
          navigationId: 0,
          path: paths.dashboardAmcred, // TODO Mudar path,
          name: "DashboardAmcred",
          working: true,
        },
        {
          navigationId: 0,
          path: paths.dashboardInstitutions, // TODO Mudar path,
          name: "DashboardInstitutions",
          working: true,
        },
        {
          navigationId: 2,
          path: paths.profile,
          name: "MyPerfil",
          title: Titles.Profile.title,

          showOnHeader: true,
          working: true,
        },
        {
          navigationId: 0,
          path: paths.registerAndQueryAmcred,
          name: "RegisterAndQuery",
          title: Titles.RegisterAndQuery.title,

          showOnHeader: true,
          working: true,
        },
        {
          navigationId: 0,
          path: paths.accessRequests,
          name: "AccessRequests",
          title: Titles.RegisterAndQuery.title,

          showOnHeader: true,
          working: true,
        },
        {
          navigationId: 0,
          path: paths.generalReport,
          name: "GeneralReport",
          title: Titles.GeneralReport.title,

          showOnHeader: true,
          working: true,
        },
        {
          navigationId: 0,
          path: paths.detailReport,
          name: "DetailReport",
          title: Titles.DetailReport.title,

          showOnHeader: true,
          working: true,
        },
        {
          navigationId: 0,
          path: paths.registerAndQueryAssociated,
          name: "RegisterAndQueryAssociated",
          title: Titles.RegisterAndQuery.title,

          showOnHeader: true,
          working: true,
        },

        {
          navigationId: 0,
          path: paths.creditAnalysis,
          name: "CreditAnalysis",
          title: Titles.RegisterAndQuery.title,
          tooltip:
            "Ao preencher os campos e realizar a análise de crédito, os mesmos serão liberados novamente somente após o envio da sinalização de crédito.",
          showOnHeader: true,
          working: true,
        },
        {
          navigationId: 0,
          path: paths.queriesHistory,
          name: "QueriesHistory",
          title: Titles.RegisterAndQuery.title,

          showOnHeader: true,
          working: true,
        },

        {
          navigationId: 0,
          path: paths.registerAndQueryInstitution,
          name: "RegisterAndQueryInstitution",
          title: Titles.RegisterAndQuery.title,

          showOnHeader: true,
          working: true,
        },

        // DEIXAR SEMPRE POR ULTIMO ********************
        // DEIXAR SEMPRE POR ULTIMO ********************
        // DEIXAR SEMPRE POR ULTIMO ********************
        // DEIXAR SEMPRE POR ULTIMO ********************
        {
          navigationId: 0,
          path: "*",
          name: "NotFound",
          working: true,
        },
      ],
    },

    {
      groupTitle: Titles.Menu.system,
      dontShow: false,
      pages: [
        {
          navigationId: 1,
          path: "",
          title: "Meu Perfil",
          working: true,
          dontShow: false,
          showOnHeader: true,
        },
        {
          navigationId: 3,
          path: "/sign_out",
          title: "Sair",
          working: true,
          dontShow: false,
          showOnHeader: true,
        },
      ],
    },
  ],
};
export default pagesConfig;
