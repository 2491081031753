import { createReducers } from "../../store/helpers";
import logoutFunction from "./logoutFunction";

import getGlobalStatus from "./getGlobalStatus";

import getUserInfos from "./getUserInfos";
import getRolesList from "./getRolesList";
import getRolesAssociatedList from "./getRolesAssociatedList";
import getFilterManagerUsers from "./getFilterManagerUsers";
import getCompanies from "./getCompanies";
import getFilterAssociatedUsers from "./getFilterAssociatedUsers";
import getCpfsCnpj from "./getCpfsCnpj";
import getAllUserFullname from "./getAllUserFullname";
import getUsernameAndRoles from "./getUsernameAndRoles";
import getFilterAmcred from "./getFilterAmcred";

const { Creators, reducers, sagas } = createReducers(
  [
    {
      name: "globalLogout",
      function: (state) => ({
        ...state,
        userInfos: {},
      }),
      sagaFunction: logoutFunction,
    },

    {
      name: "getGlobalStatus",
      params: ["userData"],
      function: (state) => ({
        ...state,
      }),
      sagaFunction: getGlobalStatus,
    },

    {
      name: "setIsLoggedIn",
      params: ["isLoggedIn"],
      function: (state, { isLoggedIn }) => ({
        ...state,
        isLoggedIn: isLoggedIn,
      }),
    },

    {
      name: "getUserInfos",
      function: (state) => ({
        ...state,
        isFetchingUserInfos: true,
      }),
      sagaFunction: getUserInfos,
    },
    {
      name: "getUserInfosSuccess",
      params: ["userInfos"],
      function: (state, { userInfos }) => ({
        ...state,
        userInfos,
        isFetchingUserInfos: false,
      }),
    },

    {
      name: "getUserInfosFailure",
      function: (state) => ({
        ...state,
        isFetchingUserInfos: false,
        userInfos: {},
      }),
    },

    {
      name: "getRolesList",
      function: (state) => ({
        ...state,
        isFetching: true,
      }),
      sagaFunction: getRolesList,
    },
    {
      name: "getRolesListSuccess",
      params: ["rolesList"],
      function: (state, { rolesList }) => ({
        ...state,
        rolesList,
        isFetching: false,
      }),
    },

    {
      name: "getRolesListFailure",
      function: (state) => ({
        ...state,
        isFetching: false,
      }),
    },

    {
      name: "getFilterManagerUsers",
      function: (state) => ({
        ...state,
        isFetching: true,
      }),
      sagaFunction: getFilterManagerUsers,
    },
    {
      name: "getFilterManagerUsersSuccess",
      params: ["filterManagerUsers"],
      function: (state, { filterManagerUsers }) => ({
        ...state,
        filterManagerUsers,
        isFetching: false,
      }),
    },

    {
      name: "getFilterManagerUsersFailure",
      function: (state) => ({
        ...state,
        isFetching: false,
        filterManagerUsers: null,
      }),
    },

    {
      name: "getRolesAssociatedList",
      function: (state) => ({
        ...state,
        isFetching: true,
      }),
      sagaFunction: getRolesAssociatedList,
    },
    {
      name: "getRolesAssociatedListSuccess",
      params: ["rolesAssociatedList"],
      function: (state, { rolesAssociatedList }) => ({
        ...state,
        rolesAssociatedList,
        isFetching: false,
      }),
    },

    {
      name: "getRolesAssociatedListFailure",
      function: (state) => ({
        ...state,
        isFetching: false,
      }),
    },

    {
      name: "getFilterAssociatedUsers",
      function: (state) => ({
        ...state,
        isFetching: true,
      }),
      sagaFunction: getFilterAssociatedUsers,
    },
    {
      name: "getFilterAssociatedUsersSuccess",
      params: ["filterAssociatedUsers"],
      function: (state, { filterAssociatedUsers }) => ({
        ...state,
        filterAssociatedUsers,
        isFetching: false,
      }),
    },

    {
      name: "getFilterAssociatedUsersFailure",
      function: (state) => ({
        ...state,
        isFetching: false,
        filterAssociatedUsers: null,
      }),
    },

    {
      name: "getCompanies",
      function: (state) => ({
        ...state,
        isFetching: true,
      }),
      sagaFunction: getCompanies,
    },
    {
      name: "getCompaniesSuccess",
      params: ["companiesList"],
      function: (state, { companiesList }) => ({
        ...state,
        companiesList,
        isFetching: false,
      }),
    },

    {
      name: "getCompaniesFailure",
      function: (state) => ({
        ...state,
        isFetching: true,
        companiesList: null,
      }),
    },

    {
      name: "getCpfsCnpj",
      function: (state) => ({
        ...state,
        isFetching: true,
      }),
      sagaFunction: getCpfsCnpj,
    },
    {
      name: "getCpfsCnpjSuccess",
      params: ["cpfsCnpj"],
      function: (state, { cpfsCnpj }) => ({
        ...state,
        cpfsCnpj,
        isFetching: false,
      }),
    },

    {
      name: "getCpfsCnpjFailure",
      function: (state) => ({
        ...state,
        isFetching: false,
        cpfsCnpj: null,
      }),
    },

    {
      name: "getAllUserFullname",
      function: (state) => ({
        ...state,
        isFetching: true,
      }),
      sagaFunction: getAllUserFullname,
    },
    {
      name: "getAllUserFullnameSuccess",
      params: ["allUserFullnameList"],
      function: (state, { allUserFullnameList }) => ({
        ...state,
        allUserFullnameList,
        isFetching: false,
      }),
    },
    {
      name: "getAllUserFullnameFailure",
      function: (state) => ({
        ...state,
        isFetching: true,
        allUserFullnameList: null,
      }),
    },

    {
      name: "getUsernameAndRoles",
      function: (state) => ({
        ...state,
        isFetching: true,
      }),
      sagaFunction: getUsernameAndRoles,
    },
    {
      name: "getUsernameAndRolesSuccess",
      params: ["usernameAndRolesList"],
      function: (state, { usernameAndRolesList }) => ({
        ...state,
        usernameAndRolesList,
        isFetching: false,
      }),
    },
    {
      name: "getUsernameAndRolesFailure",
      function: (state) => ({
        ...state,
        isFetching: true,
        usernameAndRolesList: null,
      }),
    },

    {
      name: "getFilterAmcred",
      function: (state) => ({
        ...state,
        isFetching: true,
      }),
      sagaFunction: getFilterAmcred,
    },
    {
      name: "getFilterAmcredSuccess",
      params: ["filterAmcred"],
      function: (state, { filterAmcred }) => ({
        ...state,
        filterAmcred,
        isFetching: false,
      }),
    },
    {
      name: "getFilterAmcredFailure",
      function: (state) => ({
        ...state,
        isFetching: true,
        filterAmcred: null,
      }),
    },
  ],
  {
    isLoggedIn: false,
    isFetchingUserInfos: false,
    isFetching: false,
    userInfos: null,
    rolesList: null,
    filterManagerUsers: null,
    allUserFullnameList: null,
    companiesList: null,
    rolesAssociatedList: null,
    filterAssociatedUsers: null,
    cpfsCnpj: null,
    usernameAndRolesList: null,
    filterAmcred: null,
  }
);

export { Creators, reducers, sagas };
