import React from "react";
import { connect } from "react-redux";
import { Styles } from "../styles";

import { CustomText } from "../../../components";
import { Fonts, SVG } from "../../../config";

function HeaderRegister({ isFetching, onClickRegister }) {
  return (
    <Styles.Content>
      <Styles.HeaderCard>
        <CustomText fontFamily={Fonts.bold} fontSize={1.75}>
          CADASTRAR NOVO USUÁRIO
        </CustomText>
        <Styles.NewUser
          loading={isFetching}
          disabled={isFetching}
          startIcon={<SVG.AddDocument />}
          fullWidth={false}
          onClick={onClickRegister}
        >
          Novo Cadastro
        </Styles.NewUser>
      </Styles.HeaderCard>
    </Styles.Content>
  );
}

function mapStateToProps(redux) {
  const { isFetching } = redux.registerAndQuery;
  return { isFetching };
}

function mapDispatchToProps(dispatch) {
  return {};
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(React.memo(HeaderRegister));
