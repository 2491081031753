import React from "react";
import { RegisterAndQuery } from "../features";

function RegisterAndQueryPage() {
  return (
    <>
      <RegisterAndQuery />
    </>
  );
}

export default RegisterAndQueryPage;
