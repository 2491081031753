import { Backdrop, Modal, Paper } from "@material-ui/core";
import styled from "styled-components";
import { MediaQueries } from "../../../config";
import CloseIcon from "@material-ui/icons/Close";

const StyledBackdrop = styled(Backdrop)({
  backdropFilter: "blur(5px)",
  WebkitBackdropFilter: "blur(5px)",
  backgroundColor: "#00000020",
});

const StyledModal = styled(Modal)({
  outlineWidth: 0,
  borderWidth: 0,
  ":focus": {},
});

const Container = styled(Paper)(({ theme, alternative, $noTitle }) => {
  const { spacing, palette: colors } = theme;
  return {
    position: "absolute",
    width: 400,
    maxWidth: "90vw",
    maxHeight: "95vh",
    minWidth: spacing(50),
    paddingBlock: !alternative && spacing(2),

    display: alternative && "flex",
    gap: alternative && spacing(1),

    top: "50%",
    left: "50%",
    transform: `translate(-50%, -50%)`,
    outline: "none",
    backgroundColor: colors.white,

    [MediaQueries.smUp]: {
      width: "fit-content",
    },
    [MediaQueries.mdDown]: {
      flexDirection: alternative && "column-reverse",
      overflow: alternative && "auto",
      width: "max-content",
      minWidth: "none",
    },
    boxShadow: alternative && "none",
    paddingTop: $noTitle === null && 0,
  };
});

const Card = styled.div(({ theme, alternative }) => {
  const { spacing, palette: colors } = theme;
  return {
    paddingBlock: spacing(2),
    maxHeight: `calc(95vh - ${spacing(2)}px)`,
    backgroundColor: colors.background.paper,
    borderRadius: spacing(0.6),
    height: "fit-content",
    [MediaQueries.mdDown]: {
      maxHeight: alternative && "none",
    },
  };
});

const ContentContainer = styled.div(({ theme, alternative }) => {
  const { spacing } = theme;
  return {
    overflow: "overlay",
    maxHeight: `calc(95vh - ${spacing(9)}px)`,
    [MediaQueries.mdDown]: {
      maxHeight: alternative && "none",
    },
  };
});

const HeaderContainer = styled.div(({ theme, noExit, $noTitle }) => {
  const { spacing } = theme;
  return {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    paddingInline: spacing(2.5),
    marginTop: $noTitle ? spacing(0.5) : noExit ? spacing(1.5) : spacing(0.5),
  };
});

const Close = styled(CloseIcon)(({ theme }) => {
  const { palette: colors, spacing } = theme;
  return {
    color: colors.carbon,
    width: spacing(3),
    height: spacing(3),
  };
});

const TextContainer = styled.div(({ theme }) => {
  const { spacing } = theme;
  return {
    color: "black",
    padding: spacing(2, 0, 2, 0),
    paddingInline: spacing(3.5),
  };
});
const PaddingModal = styled.div(({ theme }) => {
  const { spacing } = theme;
  return {
    marginTop: spacing(1),
    paddingInline: spacing(3.5),
    display: "flex",
    justifyContent: "flex-end",
  };
});
const Styles = {
  StyledBackdrop,
  StyledModal,
  Container,
  ContentContainer,
  HeaderContainer,
  Close,
  TextContainer,
  PaddingModal,
  Card,
};

export default Styles;
