import createReducers from "../../../store/helpers/createPageReducer";
import fullReport from "./fullReport";
import reportGeneralList from "./reportGeneralList";
import reportGeneralListInstitution from "./reportGeneralListInstitution";

const { Creators, reducers, sagas } = createReducers(
  [
    {
      name: "reportGeneralListRequest",
      params: ["data"],
      function: (state, { data }) => {
        return {
          ...state,
          isFetching: true,
          lastFilter: data,
        };
      },
      sagaFunction: reportGeneralList,
    },
    {
      name: "reportGeneralListSuccess",
      params: ["generalReportList"],
      function: (state, { generalReportList }) => ({
        ...state,
        isFetching: false,
        generalReportList,
      }),
    },
    {
      name: "reportGeneralListFailure",
      function: (state) => ({ ...state, isFetching: false }),
    },

    {
      name: "reportGeneralListInstitutionRequest",
      params: ["data"],
      function: (state, { data }) => {
        return {
          ...state,
          isFetching: true,
          lastFilter: data,
        };
      },
      sagaFunction: reportGeneralListInstitution,
    },
    {
      name: "reportGeneralListInstitutionSuccess",
      params: ["generalReportList"],
      function: (state, { generalReportList }) => ({
        ...state,
        isFetching: false,
        generalReportList,
      }),
    },
    {
      name: "reportGeneralListInstitutionFailure",
      function: (state) => ({ ...state, isFetching: false }),
    },

    {
      name: "fullReport",
      params: ["data", "id", "callback"],
      function: (state, { data, id, callback }) => {
        return {
          ...state,
          isFetching: true,
          lastFilter: data,
        };
      },
      sagaFunction: fullReport,
    },
    {
      name: "fullReportSuccess",
      params: ["generalReportList"],
      function: (state, { generalReportList }) => ({
        ...state,
        isFetching: false,
      }),
    },
    {
      name: "fullReportFailure",
      function: (state) => ({ ...state, isFetching: false }),
    },
  ],
  {
    isFetching: false,
    lastFilter: null,
    generalReportList: null,
  }
);

export { Creators, reducers, sagas };
