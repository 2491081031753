import createReducers from "../../../store/helpers/createPageReducer";

import registerRequest from "./registerRequest";
import validCpf from "./validCpf";
import validEmail from "./validEmail";

const { Creators, reducers, sagas } = createReducers(
  [
    {
      name: "registerRequest",
      params: ["data", "callback"],
      function: (state) => ({
        ...state,
        isFetching: true,
      }),
      sagaFunction: registerRequest,
    },
    {
      name: "registerRequestSuccess",
      function: (state) => ({ ...state, isFetching: false }),
    },
    {
      name: "registerRequestFailure",
      function: (state) => ({ ...state, isFetching: false }),
    },

    {
      name: "validEmail",
      params: ["data", "callback"],
      function: (state) => ({
        ...state,
        isFetching: true,
      }),
      sagaFunction: validEmail,
    },
    {
      name: "validEmailSuccess",
      function: (state) => ({
        ...state,
        isFetching: false,
      }),
    },
    {
      name: "validEmailFailure",
      function: (state) => ({ ...state, isFetching: false }),
    },

    {
      name: "validCpf",
      params: ["data", "callback"],
      function: (state) => ({
        ...state,
        isFetching: true,
      }),
      sagaFunction: validCpf,
    },
    {
      name: "validCpfSuccess",
      function: (state) => ({
        ...state,
        isFetching: false,
      }),
    },
    {
      name: "validCpfFailure",
      function: (state) => ({ ...state, isFetching: false }),
    },
  ],
  {
    isFetching: false,
  }
);

export { Creators, reducers, sagas };
