import React from "react";
import { connect } from "react-redux";
import { Styles } from "../styles";

import { ButtonText, CustomText } from "../../../components";
import { Fonts } from "../../../config";
import { Grid } from "@material-ui/core";

import { customModal } from "../../modals/utils";

function ModalBlock({
  isFetching,
  rolesList,
  onSubmit,
  user,
  viewOnly,
  block,
}) {
  const fullName = user?.fullName.split(" ");
  const name = fullName[0];
  fullName.shift();
  const lastName = fullName.join(" ");

  return (
    <Styles.ModalContent>
      <Styles.HeaderModal>
        <CustomText fontFamily={Fonts.medium} fontSize={1.75}>
          {viewOnly
            ? "Cadastro de usuário AMCRED realizado com sucesso!"
            : block
            ? "Confirmar o bloqueio de acesso deste usuário AMCRED?"
            : "Confirmar o desbloqueio de acesso deste usuário AMCRED?"}
        </CustomText>
      </Styles.HeaderModal>

      <Styles.UserInfo>
        <Grid container spacing={1}>
          <Grid item xs={6}>
            <Styles.UserInfoCard>
              <CustomText fontFamily={Fonts.bold} fontSize={1.75}>
                NOME USUÁRIO AMCRED
              </CustomText>
              <CustomText fontFamily={Fonts.medium} fontSize={2}>
                {name}
              </CustomText>
            </Styles.UserInfoCard>
          </Grid>
          <Grid item xs={6}>
            <Styles.UserInfoCard>
              <CustomText fontFamily={Fonts.bold} fontSize={1.75}>
                SOBRENOME USUÁRIO AMCRED
              </CustomText>
              <CustomText fontFamily={Fonts.medium} fontSize={2}>
                {lastName}
              </CustomText>
            </Styles.UserInfoCard>
          </Grid>
          <Grid item xs={6}>
            <Styles.UserInfoCard>
              <CustomText fontFamily={Fonts.bold} fontSize={1.75}>
                CPF
              </CustomText>
              <CustomText fontFamily={Fonts.medium} fontSize={2}>
                {user?.cpf}
              </CustomText>
            </Styles.UserInfoCard>
          </Grid>
          <Grid item xs={6}>
            <Styles.UserInfoCard>
              <CustomText fontFamily={Fonts.bold} fontSize={1.75}>
                CARGO
              </CustomText>
              <CustomText fontFamily={Fonts.medium} fontSize={2}>
                {!user?.officeId
                  ? user?.officeName
                  : rolesList.find(
                      (item) => item?.value === parseInt(user?.officeId)
                    )?.label}
              </CustomText>
            </Styles.UserInfoCard>
          </Grid>
          <Grid item xs={12}>
            <Styles.UserInfoCard>
              <CustomText fontFamily={Fonts.bold} fontSize={1.75}>
                E-MAIL
              </CustomText>
              <CustomText fontFamily={Fonts.medium} fontSize={2}>
                {user?.email}
              </CustomText>
            </Styles.UserInfoCard>
          </Grid>
        </Grid>
      </Styles.UserInfo>
      <Styles.FormButtons>
        {!viewOnly && (
          <ButtonText
            fullWidth={false}
            onClick={() => {
              customModal.close();
            }}
          >
            Voltar
          </ButtonText>
        )}
        <Styles.FormButton fullWidth={false} onClick={() => onSubmit()}>
          {viewOnly ? "OK" : block ? "SIM, BLOQUEAR" : "SIM, DESBLOQUEAR"}
        </Styles.FormButton>
      </Styles.FormButtons>
    </Styles.ModalContent>
  );
}

function mapStateToProps(redux) {
  const { isFetching } = redux.registerAndQuery;
  return { isFetching };
}

function mapDispatchToProps(dispatch) {
  return {};
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(React.memo(ModalBlock));
