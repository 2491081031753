import React from "react";
import {
  FormControl,
  FormHelperText,
  Chip,
  withTheme,
} from "@material-ui/core";
import { formConnector } from "../../FormConfig";
import styled from "styled-components";
import FontStyle from "../styles/fontsStyles";
import Text from "../strings/Text";
import { FontStyles } from "..";
import { Check } from "@material-ui/icons";
import { Spacing } from "../../config";

const ChipSelect = styled(Chip)(({ theme, selected, color }) => {
  const { spacing } = theme;
  return {
    "& .MuiChip-label": {
      display: "flex",
      padding: spacing(0.5),
    },
    minWidth: spacing(5),
    paddingInline: spacing(1.25),

    marginRight: spacing(0.6),
    marginBottom: spacing(0.6),
    backgroundColor: color
      ? selected
        ? color + "20"
        : color
      : selected
      ? "#E6E6E6"
      : "#E2F4FF",
    color: color ? color : selected ? "#00000054" : "#3BA1E5",
    border: color
      ? `1px solid ${color}`
      : selected
      ? `1px solid #393939`
      : `1px solid #0099FF`,
    ...FontStyle.semibold12,
    ":focus": {
      backgroundColor: color
        ? selected
          ? color + "20"
          : color
        : selected
        ? "#E6E6E6"
        : "#E2F4FF",
    },
    opacity: 1,
  };
});

const ListContainer = styled.div(({ theme, selected }) => {
  const { spacing } = theme;
  return {
    width: "100%",
    display: "flex",
    flexWrap: "wrap",
    marginTop: spacing(1.2),
  };
});

const Label = styled(Text)(({ theme, $withValue, $witherror }) => {
  const { palette: colors } = theme;

  return {
    ...FontStyles.semibold14,
    textTransform: "uppercase",
    position: "relative",
    top: 0,
    left: 0,
    color: $withValue
      ? $witherror
        ? colors.error.main
        : colors.text.primary
      : colors.text.primary,
    transition: ".2s",
    pointerEvents: "none",
    alignItems: "center",
    display: "flex",
    overflow: "hidden",
  };
});

const Description = styled(Text)(({ theme, $withValue, $witherror }) => {
  const { palette: colors, spacing } = theme;

  return {
    ...FontStyle.regular14,
    marginBlock: spacing(0.6),
    color: colors.text.secondary,
    opacity: 0.55,
  };
});

function ChipList(props) {
  const {
    inputLabel,
    description,
    required,
    disableError,
    options,
    labelsmall,
  } = props;

  const { value, error, setConfigs, ref } = formConnector.useStringValue(props);

  // const handleSelect = (item) => {
  //   if ([...value].find((value) => value === -1) && item.value !== -1) {
  //     return null;
  //   }
  //   let newList = [...value];
  //   const index = newList.indexOf(item.value);
  //   if (item.value === -1) {
  //     if (index > -1) {
  //       newList = [];
  //     } else {
  //       newList = [item.value];
  //     }
  //   } else {
  //     if (index > -1) {
  //       newList.splice(index, 1);
  //     } else {
  //       newList.push(item.value);
  //     }
  //   }

  //   setConfigs(newList);
  // };

  const handleSelect = (item) => {
    if (value === item.value) {
      setConfigs(null);
    } else {
      setConfigs(item.value);
    }
  };
  return (
    <FormControl required={required} error={error}>
      <>
        <Label
          $withValue={Boolean(value) || value === 0}
          $witherror={Boolean(error) ? true : undefined}
          labelsmall={labelsmall}
        >
          {inputLabel}
        </Label>
        <Description> {description}</Description>
        <ListContainer>
          {[{ value: -1, label: "Todos" }, ...options]?.map((item, index) => {
            let propsInput;

            //if (value && value?.find((value) => value === item.value)) {
            if (value === item.value) {
              propsInput = { selected: false };
            } else {
              propsInput = { selected: true };
            }

            return (
              <ChipSelect
                color={item.color}
                first={index === 0}
                inputRef={ref}
                label={
                  item.color ? (
                    !propsInput.selected ? (
                      <Check
                        style={{
                          fill: "white",
                          width: Spacing(2.15),
                          height: "auto",
                          margin: 0,
                          padding: 0,
                        }}
                      />
                    ) : (
                      ""
                    )
                  ) : (
                    item.label
                  )
                }
                onClick={() => handleSelect(item)}
                {...propsInput}
              />
            );
          })}
        </ListContainer>
      </>
      {disableError ? null : (
        <FormHelperText>{error ? error : " "}</FormHelperText>
      )}
    </FormControl>
  );
}

export default withTheme(ChipList);
