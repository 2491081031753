import createReducers from "../../../store/helpers/createPageReducer";
import editMultipleQueries from "./editMultipleQueries";
import editQueries from "./editQueries";
import fullQueriesHistory from "./fullQueriesHistory";
import getAllUndefinedQueries from "./getAllUndefinedQueries";
import getQueriesHistory from "./getQueriesHistory";

const { Creators, reducers, sagas } = createReducers(
  [
    {
      name: "getQueriesHistory",
      params: ["data"],
      function: (state, { data }) => ({
        ...state,
        isFetching: true,
        lastFilter: data,
      }),
      sagaFunction: getQueriesHistory,
    },
    {
      name: "getQueriesHistorySuccess",
      params: ["historyList"],

      function: (state, { historyList }) => ({
        ...state,
        isFetching: false,
        historyList,
      }),
    },
    {
      name: "getQueriesHistoryFailure",
      function: (state) => ({ ...state, isFetching: false }),
    },

    {
      name: "fullQueriesHistory",
      params: ["data", "callback"],
      function: (state, { data }) => ({
        ...state,
        isFetching: true,
        lastFilter: data,
      }),
      sagaFunction: fullQueriesHistory,
    },
    {
      name: "fullQueriesHistorySuccess",
      params: ["historyList"],

      function: (state) => ({
        ...state,
        isFetching: false,
      }),
    },
    {
      name: "fullQueriesHistoryFailure",
      function: (state) => ({ ...state, isFetching: false }),
    },

    {
      name: "editQueries",
      params: ["data", "callback"],
      function: (state) => ({
        ...state,
        isFetching: true,
      }),
      sagaFunction: editQueries,
    },
    {
      name: "editQueriesSuccess",
      function: (state) => ({
        ...state,
        isFetching: false,
      }),
    },
    {
      name: "editQueriesFailure",
      function: (state) => ({ ...state, isFetching: false }),
    },

    {
      name: "editMultipleQueries",
      params: ["data", "callback"],
      function: (state) => ({
        ...state,
        isFetching: true,
      }),
      sagaFunction: editMultipleQueries,
    },
    {
      name: "editMultipleQueriesSuccess",
      function: (state) => ({
        ...state,
        isFetching: false,
      }),
    },
    {
      name: "editMultipleQueriesFailure",
      function: (state) => ({ ...state, isFetching: false }),
    },

    {
      name: "getAllUndefinedQueries",
      params: ["data", "callback"],
      function: (state, { data }) => ({
        ...state,
        isFetching: true,
        lastFilter: data,
      }),
      sagaFunction: getAllUndefinedQueries,
    },
    {
      name: "getAllUndefinedQueriesSuccess",

      function: (state) => ({
        ...state,
        isFetching: false,
      }),
    },
    {
      name: "getAllUndefinedQueriesFailure",
      function: (state) => ({ ...state, isFetching: false }),
    },
  ],
  {
    isFetching: false,
    historyList: null,
    lastFilter: null,
  }
);

export { Creators, reducers, sagas };
