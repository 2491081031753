import models from ".";

export default function getDashboard(response) {
  const values = response.data.response;
  const data = {};

  data["accessNumber"] = models.getIntBarChart(values?.accessNumber);
  data["accessValue"] = models.getBarChart(values?.accessValue, false, true);
  data["total"] = values?.total;
  data["totalAccess"] = values?.totalAccess;
  data["totalReceivable"] = values?.totalReceivable;

  return { ...response, data: { ...response?.data, data } };
}
