import React from "react";
import { connect } from "react-redux";
import { Styles } from "../styles";

import {
  ButtonText,
  CustomText,
  Input,
  InputEmail,
  Select,
} from "../../../components";
import { Fonts, Texts } from "../../../config";
import { Grid } from "@material-ui/core";

import { FormHolder } from "../../../FormConfig";
import { Validations } from "../../../lib";
import filters from "../../../lib/Filters";
import { customModal } from "../../modals/utils";

function ModalRegister({ isFetching, roleList, onSubmit }) {
  const texts = Texts["pt-BR"].registry;
  return (
    <Styles.ModalContent>
      <Styles.HeaderModal>
        <CustomText fontFamily={Fonts.medium} fontSize={1.75}>
          Informe abaixo os dados do usuário AMCRED a ser cadastrado:
        </CustomText>
      </Styles.HeaderModal>

      <FormHolder onSubmit={onSubmit}>
        <Grid container spacing={1}>
          <Grid item xs={6}>
            <Input
              required={texts.userMessage}
              name="firstName"
              inputLabel="NOME USUÁRIO AMCRED"
            />
          </Grid>
          <Grid item xs={6}>
            <Input
              required={texts.userMessage}
              name="lastName"
              inputLabel="SOBRENOME USUÁRIO AMCRED"
            />
          </Grid>
          <Grid item xs={6}>
            <Input
              required={texts.userMessage}
              name="cpf"
              inputLabel="CPF"
              validation={Validations.isCPF}
              mask={filters.cpfMask}
            />
          </Grid>
          <Grid item xs={6}>
            <Select
              required={texts.userMessage}
              name="officeId"
              inputLabel="Cargo"
              options={roleList}
            />
          </Grid>
          <Grid item xs={12}>
            <InputEmail
              name="email"
              required={texts.userMessage}
              inputLabel="E-mail"
            />
          </Grid>
        </Grid>
        <Styles.FormButtons>
          <ButtonText
            fullWidth={false}
            onClick={() => {
              customModal.close();
            }}
          >
            Voltar
          </ButtonText>
          <Styles.FormButton fullWidth={false} type="submit">
            CONFIRMAR CADASTRO
          </Styles.FormButton>
        </Styles.FormButtons>
      </FormHolder>
    </Styles.ModalContent>
  );
}

function mapStateToProps(redux) {
  const { isFetching } = redux.registerAndQuery;
  return { isFetching };
}

function mapDispatchToProps(dispatch) {
  return {};
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(React.memo(ModalRegister));
