import React from "react";
import { connect } from "react-redux";
import { Styles } from "../styles";

import { ButtonText, CustomText, Select } from "../../../components";
import { Fonts } from "../../../config";
import { Grid } from "@material-ui/core";

import { FormHolder } from "../../../FormConfig";

import { customModal } from "../../modals/utils";
const formatDate = (date) => {
  var data = new Date(date);

  var dia = data.getDate();
  var mes = data.getMonth() + 1;
  var ano = data.getFullYear();
  var horas = data.getHours();
  var minutos = data.getMinutes();

  dia = dia < 10 ? "0" + dia : dia;
  mes = mes < 10 ? "0" + mes : mes;
  horas = horas < 10 ? "0" + horas : horas;
  minutos = minutos < 10 ? "0" + minutos : minutos;

  var dataString = dia + "/" + mes + "/" + ano + " - " + horas + ":" + minutos;
  return dataString;
};
function EditStatus({ onSubmit, user }) {
  const options = [
    { value: 1, label: "Sim" },
    { value: 0, label: "Não" },
    { value: 2, label: "Indefinida" },
  ];

  return (
    <Styles.ModalContent noPadding>
      <Styles.HeaderModal withPadding>
        <CustomText fontFamily={Fonts.medium} fontSize={1.75}>
          Dados adicionais da consulta
        </CustomText>
      </Styles.HeaderModal>
      <Styles.CpfEditModal>
        <Grid container spacing={1}>
          <Grid item xs={6}>
            <CustomText fontFamily={Fonts.bold} fontSize={1.75}>
              CLIENTE:
            </CustomText>
            <CustomText fontFamily={Fonts.medium} fontSize={2}>
              {user?.cpfOrCnpj}
            </CustomText>
          </Grid>
          <Grid item xs={6}>
            <CustomText fontFamily={Fonts.bold} fontSize={1.75}>
              SCORE:
            </CustomText>
            <CustomText fontFamily={Fonts.medium} fontSize={2}>
              {user?.score
                ? user?.score?.toFixed(2)?.replace(".", ",") + "%"
                : "-"}
            </CustomText>
          </Grid>
          <Grid item xs={6}>
            <CustomText fontFamily={Fonts.bold} fontSize={1.75}>
              DATA HORA:
            </CustomText>
            <CustomText fontFamily={Fonts.medium} fontSize={2}>
              {formatDate(user?.createdAt)}
            </CustomText>
          </Grid>
        </Grid>
      </Styles.CpfEditModal>
      <Styles.InLinePaddingModal>
        <FormHolder onSubmit={onSubmit}>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Select
                color={1}
                required={"*Campo Obrigatório"}
                name="signalCreditReleaseId"
                inputLabel="POSSIBILIDADE DE LIBERAÇÃO DE CRÉDITO?"
                options={options}
                defaultValue={
                  user?.signalCreditRelease
                    ? options.find(
                        (item) => item.label === user?.signalCreditRelease
                      )?.value
                    : null
                }
              />
            </Grid>
          </Grid>
          <Styles.FormButtons>
            <ButtonText
              color={1}
              fullWidth={false}
              onClick={() => {
                customModal.close();
              }}
            >
              Voltar
            </ButtonText>
            <Styles.FormButton fullWidth={false} type="submit">
              SALVAR
            </Styles.FormButton>
          </Styles.FormButtons>
        </FormHolder>
      </Styles.InLinePaddingModal>
    </Styles.ModalContent>
  );
}

export default connect(null, null)(React.memo(EditStatus));
