import { call, put } from "redux-saga/effects";
import { Creators } from ".";
import { api, ResponseError } from "../../services";

export default function* getCompanies() {
  try {
    const response = yield call(api.getCompanies);

    yield put(Creators.getCompaniesSuccess(response));
  } catch (response) {
    const errorResponse = new ResponseError(response);
    errorResponse.alertMessage();
    yield put(Creators.getCompaniesFailure());
  }
}
