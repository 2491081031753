import { CircularProgress, Grid } from "@material-ui/core";
import React, { useCallback, useEffect, useState } from "react";
import { connect } from "react-redux";
import { customModal } from "../../modals/utils";

import { Filters, TableUsers, ModalBlock } from "../components";

import { Creators } from "../reduxSagas";
import Styles from "../styles/Styles";
import { Creators as GlobalCreators } from "../../globalReduxSagas";
import { IntegratedComponentHolder } from "../../../components";

const pageSize = 8;
function AccessRequests({
  accessList,
  getAccessRequestAmcred,
  isFetching,
  getFilterManagerUsers,
  filterManagerUsers,
  lastFilter,
  refuseAccessAmcred,
  acceptAccessAmcred,
  userInfos,
}) {
  const rowsWithSort = [];
  const [isMounted, setIsMounted] = useState(false);
  const [page, setPage] = useState(0);
  const [minH, setHeight] = useState("100vh");
  const [sortKey, setSortKey] = useState("dateRequest");
  const [sortDirection, setSortDirection] = useState(true);
  const Mount = useCallback(() => {
    setIsMounted(true);
    getFilterManagerUsers();
    const now = new Date();
    const year = now.getFullYear();
    const month = now.getMonth() + 1; // Os meses em JavaScript são indexados de 0 a 11

    const begin = new Date(year, month - 1, 1);
    const end = now;
    getAccessRequestAmcred({
      searchParameters: {
        order: "dateRequest",
        typeOrder: "ASC",
        begin: begin,
        end: end,
      },
      page: { size: pageSize, page: 0 },
    });
  }, [getAccessRequestAmcred, getFilterManagerUsers]);

  useEffect(Mount, [Mount]);

  useEffect(() => {
    if (isMounted) {
      const newH = document.getElementById("header-menu");
      setHeight(newH?.offsetHeight);
    }
  }, [isMounted]);

  const onAccept = (user) => {
    customModal.setInfos(
      "APROVAR SOLICITAÇÃO DE ACESSO",
      [],
      null,
      null,
      <ModalBlock
        onSubmit={() => {
          acceptAccessAmcred(user?.id, () => {
            customModal.close();
            customModal.setInfos(
              "ACESSO APROVADO",
              ["A solicitação de acesso foi aprovada com sucesso!"],
              {
                onClick: () => {
                  customModal.close();
                },
                label: "Ok",
              }
            );
            searchUsers(lastFilter?.searchParameters, true);
          });
        }}
        user={user}
      />
    );
  };

  const onRefuse = (user) => {
    customModal.setInfos(
      "REPROVAR SOLICITAÇÃO DE ACESSO",
      [],
      null,
      null,
      <ModalBlock
        reprove
        onSubmit={() => {
          refuseAccessAmcred(user?.id, () => {
            customModal.close();
            customModal.setInfos(
              "ACESSO REPROVADO",
              ["A solicitação de acesso foi reprovada com sucesso!"],
              {
                onClick: () => {
                  customModal.close();
                },
                label: "Ok",
              }
            );
            searchUsers(lastFilter?.searchParameters, true);
          });
        }}
        user={user}
      />
    );
  };

  const searchUsers = (filters, reset) => {
    if (reset) {
      setPage(0);
    }
    getAccessRequestAmcred({
      searchParameters: {
        ...filters,
        order: sortKey,
        typeOrder: sortDirection === true ? "ASC" : "DESC",
      },
      page: { size: pageSize, page: reset ? 0 : page },
    });
  };
  const openViewModal = (user) => {
    customModal.setInfos(
      "VER MAIS DETALHES",
      [],
      null,
      null,
      <>
        <ModalBlock
          viewOnly
          user={user}
          onSubmit={() => {
            customModal.close();
          }}
        />
      </>
    );
  };
  const clearFilter = () => {
    setSortKey("dateRequest");
    setSortDirection(true);
  };

  const sortContent = (filter, user, order, type) => {
    getAccessRequestAmcred({
      searchParameters: {
        ...filter,
        order: order,
        typeOrder: type,
      },
      page: {
        size: pageSize,
        page:
          user?.pageTotal > 0 &&
          accessList?.pageTotal <= page + 1 &&
          (accessList === null || accessList === undefined)
            ? accessList?.pageTotal - 1
            : page,
      },
    });
  };

  return (
    <Styles.Container minH={minH}>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Filters
            listInputs={filterManagerUsers}
            onSubmit={(data) => searchUsers(data, true)}
            onClear={clearFilter}
            userInfos={userInfos}
          />
        </Grid>
        <Grid item xs={12}>
          <IntegratedComponentHolder
            showReload={!isFetching && !Array.isArray(accessList?.elements)}
            reloadMessage={"Nenhum dado encontrado"}
            showEmpty={!isFetching && accessList?.elements?.length < 1}
            emptyMessage={"Lista Vazia"}
            maxSize={"62rem"}
          >
            {isFetching ? (
              <div
                style={{
                  width: "100%",
                  height: "40vh",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <CircularProgress color="primary" />
              </div>
            ) : (
              <TableUsers
                onView={openViewModal}
                list={accessList?.elements}
                onAccept={onAccept}
                onRefuse={onRefuse}
                page={page + 1}
                lastFilter={lastFilter?.searchParameters}
                user={accessList}
                sort={sortContent}
                setPage={(a) => {
                  setPage(a - 1);
                  getAccessRequestAmcred({
                    searchParameters: lastFilter?.searchParameters,
                    page: { size: pageSize, page: a - 1 },
                  });
                }}
                setSortKey={setSortKey}
                setSortDirection={setSortDirection}
                sortKey={sortKey}
                sortDirection={sortDirection}
                sortList={rowsWithSort}
                totalPages={accessList?.pageTotal}
              />
            )}
          </IntegratedComponentHolder>
        </Grid>
      </Grid>
    </Styles.Container>
  );
}
function mapStateToProps(redux) {
  const {
    isFetching: isFetchingPage,
    accessList,
    lastFilter,
  } = redux.accessRequests;
  const {
    isFetching: isFetchingGlobal,
    filterManagerUsers,
    userInfos,
  } = redux.global;

  return {
    isFetching: isFetchingPage || isFetchingGlobal,
    accessList,
    lastFilter,
    filterManagerUsers,
    userInfos,
  };
}

function mapDispatchToProps(dispatch) {
  const { getAccessRequestAmcred, refuseAccessAmcred, acceptAccessAmcred } =
    Creators;
  const { getFilterManagerUsers } = GlobalCreators;

  return {
    getAccessRequestAmcred: function (data) {
      return dispatch(getAccessRequestAmcred(data));
    },

    getFilterManagerUsers: function () {
      return dispatch(getFilterManagerUsers());
    },
    refuseAccessAmcred: function (data, callback) {
      return dispatch(refuseAccessAmcred(data, callback));
    },
    acceptAccessAmcred: function (data, callback) {
      return dispatch(acceptAccessAmcred(data, callback));
    },
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(React.memo(AccessRequests));
