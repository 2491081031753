import { call, put } from "redux-saga/effects";
import { api } from "../../../services";
import { Creators } from "./index";

import { Alerts } from "../../../lib";

export default function* acceptAccessAmcred({ data, callback }) {
  try {
    const response = yield call(api.postAcceptAccessAmcred, data);

    yield put(Creators.acceptAccessAmcredSuccess(response?.data));
    callback();
  } catch (response) {
    Alerts.alertError(
      response?.data?.log
        ? response?.data?.log
        : "Não foi possível encontrar as solicitações de acesso"
    );

    yield put(Creators.acceptAccessAmcredFailure());
  }
}
