import { call, put } from "redux-saga/effects";
import { api } from "../../../services";
import { Creators } from "./index";
import { Creators as globalCreators } from "../../globalReduxSagas";
import { SessionStorage, Alerts } from "../../../lib";
import { navigateTo } from "../../../navigation/navigate";

export default function* loginRequest({ data, captcha }) {
  try {
    const response = yield call(api.getToken, data, captcha);

    yield put(Creators.loginSuccess());
    SessionStorage.setItem("isLoggedIn", true);

    SessionStorage.setItem("token", response?.data?.response?.token);
    SessionStorage.setItem("tokenType", response?.data?.response?.type);
    yield put(globalCreators.getGlobalStatus());

    if (response?.data?.response?.role === "ROLE_AMCRED_GESTOR") {
      navigateTo.MainPanel();
    } else if (
      response?.data?.response?.role === "ROLE_INSTITUICAO_ASSOCIADA_GESTOR"
    ) {
      navigateTo.DashboardInstitutions();
    } else {
      navigateTo.CreditAnalysis();
    }
  } catch (response) {
    Alerts.alertError(
      response?.data?.log
        ? response?.data?.log
        : "E-mail e/ou senha informados estão incorretos. Tente novamente ou clique em 'Esqueci minha senha'. "
    );

    yield put(Creators.loginFailure());
  }
}
