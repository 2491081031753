import React from "react";
import {
  FormControlLabel,
  FormControl,
  FormHelperText,
  withTheme,
  RadioGroup,
  Radio,
} from "@material-ui/core";
import { formConnector } from "../../FormConfig";
import styled from "styled-components";
import { FontStyles } from "..";

const StyledRadioGroup = styled(RadioGroup)(({ theme, $witherror }) => {
  return {
    flexWrap: "nowrap",
    flexDirection: "row",
    justifyContent: "flex-start",
  };
});

const StyledChips = styled(FormControlLabel)(({ theme, $witherror }) => {
  return {
    ...FontStyles.medium14,
    color: theme.palette.text.primary,
    "& .MuiRadio-root": {
      color: "#00000054",
    },
    "& .MuiRadio-colorSecondary.Mui-checked": {
      color: "#F58634",
    },
    "& .MuiTypography-root": {
      color: theme.palette.text.primary,
    },
  };
});

function RadioList(props) {
  const { required, disableError, name } = props;

  const { value, error, setConfigs } = formConnector.useStringValue(props);
  const options = [
    { value: 1, label: "Sim" },
    { value: 0, label: "Não" },
    { value: 2, label: "Indefinida" },
  ];
  return (
    <FormControl required={Boolean(required)} error={Boolean(error)}>
      <StyledRadioGroup
        name={name}
        value={parseInt(value)}
        onChange={(data, a) => {
          setConfigs(a);
        }}
      >
        {options?.map((item, imdex) => (
          <StyledChips
            key={imdex + "_radio"}
            value={item?.value}
            control={<Radio />}
            label={item?.label}
          />
        ))}
      </StyledRadioGroup>

      {disableError ? null : (
        <FormHelperText>{error ? error : " "}</FormHelperText>
      )}
    </FormControl>
  );
}

export default withTheme(RadioList);
