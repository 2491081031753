import React from "react";
import { connect } from "react-redux";
import { Styles } from "../styles";

import { ButtonText, CustomText } from "../../../components";
import { Fonts } from "../../../config";
import { Grid } from "@material-ui/core";

import { customModal } from "../../modals/utils";
import { Filters } from "../../../lib";

function ModalBlock({
  isFetching,
  rolesList,
  onSubmit,
  user,
  viewOnly,
  unblock,
}) {
  return (
    <Styles.ModalContent>
      <Styles.HeaderModal>
        <CustomText fontFamily={Fonts.medium} fontSize={1.75}>
          {viewOnly === 2
            ? "Dados adicionais do cadastro:"
            : viewOnly
            ? "Cadastro de usuário AMCRED realizado com sucesso!"
            : `Confirmar ${
                unblock ? "desbloqueio" : "bloqueio"
              } de acesso da Instituição Associada?`}
        </CustomText>
      </Styles.HeaderModal>

      <Styles.UserInfo>
        <Grid container spacing={1}>
          <Grid item xs={6}>
            <Styles.UserInfoCard>
              <CustomText fontFamily={Fonts.bold} fontSize={1.75}>
                NOME DA INSTITUIÇÃO ASSOCIADA
              </CustomText>
              <CustomText fontFamily={Fonts.medium} fontSize={2}>
                {user?.requester}
              </CustomText>
            </Styles.UserInfoCard>
          </Grid>
          <Grid item xs={6}>
            <Styles.UserInfoCard>
              <CustomText fontFamily={Fonts.bold} fontSize={1.75}>
                CNPJ
              </CustomText>
              <CustomText fontFamily={Fonts.medium} fontSize={2}>
                {user?.companyCnpj}
              </CustomText>
            </Styles.UserInfoCard>
          </Grid>
          <Grid item xs={6}>
            <Styles.UserInfoCard>
              <CustomText fontFamily={Fonts.bold} fontSize={1.75}>
                NOME GESTOR
              </CustomText>
              <CustomText fontFamily={Fonts.medium} fontSize={2}>
                {user?.requester}
              </CustomText>
            </Styles.UserInfoCard>
          </Grid>
          {viewOnly === 2 && (
            <Grid item xs={6}>
              <Styles.UserInfoCard>
                <CustomText fontFamily={Fonts.bold} fontSize={1.75}>
                  DATA CADASTRO
                </CustomText>
                <CustomText fontFamily={Fonts.medium} fontSize={2}>
                  {Filters.formatDate(user.dateRequest)}
                </CustomText>
              </Styles.UserInfoCard>
            </Grid>
          )}
          <Grid item xs={6}>
            <Styles.UserInfoCard>
              <CustomText fontFamily={Fonts.bold} fontSize={1.75}>
                PERFIL
              </CustomText>
              <CustomText fontFamily={Fonts.medium} fontSize={2}>
                {user?.officeName
                  ? user?.officeName
                  : rolesList.find((item) => item?.value === user?.officeId)
                      ?.label}
              </CustomText>
            </Styles.UserInfoCard>
          </Grid>
          <Grid item xs={6}>
            <Styles.UserInfoCard>
              <CustomText fontFamily={Fonts.bold} fontSize={1.75}>
                CPF
              </CustomText>
              <CustomText fontFamily={Fonts.medium} fontSize={2}>
                {user?.cpf}
              </CustomText>
            </Styles.UserInfoCard>
          </Grid>

          <Grid item xs={6}>
            <Styles.UserInfoCard>
              <CustomText fontFamily={Fonts.bold} fontSize={1.75}>
                E-MAIL
              </CustomText>
              <CustomText fontFamily={Fonts.medium} fontSize={2}>
                {user?.email}
              </CustomText>
            </Styles.UserInfoCard>
          </Grid>
        </Grid>
      </Styles.UserInfo>
      <Styles.FormButtons>
        {!viewOnly && (
          <ButtonText
            fullWidth={false}
            onClick={() => {
              customModal.close();
            }}
          >
            Voltar
          </ButtonText>
        )}
        <Styles.FormButton fullWidth={false} onClick={() => onSubmit()}>
          {viewOnly === 2
            ? "Voltar"
            : viewOnly
            ? "OK"
            : `SIM, ${unblock ? "DESBLOQUEAR" : "BLOQUEAR"}`}
        </Styles.FormButton>
      </Styles.FormButtons>
    </Styles.ModalContent>
  );
}

function mapStateToProps(redux) {
  const { isFetching } = redux.registerAndQuery;
  return { isFetching };
}

function mapDispatchToProps(dispatch) {
  return {};
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(React.memo(ModalBlock));
