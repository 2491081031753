import createReducers from "../../../store/helpers/createPageReducer";

import searchDashboardAmcred from "./searchDashboardAmcred";

const { Creators, reducers, sagas } = createReducers(
  [
    {
      name: "searchDashboardAmcred",
      params: ["data"],
      function: (state, { data }) => ({
        ...state,
        isFetching: true,
        dashLastFilter: data,
      }),
      sagaFunction: searchDashboardAmcred,
    },
    {
      name: "searchDashboardAmcredSuccess",
      params: ["dashboardData"],

      function: (state, { dashboardData }) => ({
        ...state,
        isFetching: false,
        dashboardData,
      }),
    },
    {
      name: "searchDashboardAmcredFailure",
      function: (state) => ({
        ...state,
        isFetching: false,
        dashboardData: null,
      }),
    },
  ],
  {
    isFetching: false,
    dashboardData: null,
    dashdashLastFilter: null,
  }
);

export { Creators, reducers, sagas };
