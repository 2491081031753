import React, { useState } from "react";
import { connect } from "react-redux";
import { Styles } from "../styles";

import {
  ButtonText,
  CustomText,
  IntegratedComponentHolder,
  Select,
  Table,
} from "../../../components";
import { Fonts, Spacing } from "../../../config";
import { Checkbox, Grid, makeStyles } from "@material-ui/core";

import { FormHolder } from "../../../FormConfig";

import { customModal } from "../../modals/utils";
import colors from "../../../config/colors";

const useStyles = makeStyles((theme) => {
  const { spacing, palette } = theme;
  return {
    icon: {
      width: spacing(3),
      height: spacing(3),
    },
    border: {
      fill: "transparent",
      strokeWidth: spacing(0.2),
      x: `calc(50% - ${spacing(2) / 2}px)`,
      y: `calc(50% - ${spacing(2) / 2}px)`,
      stroke: palette.orange,
      width: spacing(2),
      height: spacing(2),
      rx: spacing(0.2),
    },
    iconCenter: {
      fill: palette.orange,
      width: spacing(1),
      height: spacing(1),
      rx: spacing(0.2),
      x: `calc(50% - ${spacing(1) / 2}px)`,
      y: `calc(50% - ${spacing(1) / 2}px)`,
    },
  };
});

const formatDate = (date) => {
  var data = new Date(date);

  var dia = data.getDate();
  var mes = data.getMonth() + 1;
  var ano = data.getFullYear();
  var horas = data.getHours();
  var minutos = data.getMinutes();

  dia = dia < 10 ? "0" + dia : dia;
  mes = mes < 10 ? "0" + mes : mes;
  horas = horas < 10 ? "0" + horas : horas;
  minutos = minutos < 10 ? "0" + minutos : minutos;

  var dataString = dia + "/" + mes + "/" + ano + " - " + horas + ":" + minutos;
  return dataString;
};

function EditStatusAll({ onSubmit, data, isFetching }) {
  const maxStatus = data?.length < 50 ? data?.length : 50;
  const [formRef, setRef] = useState(null);
  const [selected, setSelected] = useState([]);

  const classes = useStyles();
  const options = [
    { value: 1, label: "Sim" },
    { value: 0, label: "Não" },
  ];

  function selectMaxItens() {
    let listFull = [...data];
    let newList = [...selected];
    const maxItens = Math.min(maxStatus - newList.length, listFull.length);
    let ii = 0;

    for (let i = 0; i < listFull.length; i++) {
      if (ii === maxItens) {
        break;
      }
      if (!newList.includes(listFull[i].id)) {
        ii += 1;
        newList.push(listFull[i].id);
      }
    }
    setSelected(newList);
    return maxItens;
  }

  function clearSelect() {
    setSelected([]);
  }

  return (
    <Styles.ModalContent noPadding>
      <Styles.HeaderModal withPadding>
        <CustomText fontFamily={Fonts.medium} fontSize={1.75}>
          Listagem Total
        </CustomText>
      </Styles.HeaderModal>

      <div
        style={{
          display: "flex",
          flexDirection: "row",
          gap: Spacing(1),
          paddingInline: Spacing(2.5),
          alignItems: "center",
          justifyContent: "space-between",
          width: "100%",
        }}
      >
        {maxStatus <= 0 ? null : selected?.length >= maxStatus ? (
          <Styles.SelectAll alternative onClick={clearSelect} fullWidth={false}>
            Limpar Seleção
          </Styles.SelectAll>
        ) : (
          <Styles.SelectAll onClick={selectMaxItens} fullWidth={false}>
            Selecionar {maxStatus}
          </Styles.SelectAll>
        )}

        <CustomText
          fontSize={1.25}
          fontFamily={Fonts.italic}
          styles={{ paddingBottom: Spacing(0.7) }}
        >
          Consultas selecionadas: {selected?.length}/{maxStatus}
        </CustomText>
      </div>

      <div
        style={{
          margin: "15px",
          padding: "5px",
          borderRadius: "5px",
          backgroundColor: "#F6F6F6",
          overflowX: "auto",
          maxHeight: "34vh",
          height: "auto",
          marginTop: 0,
          paddingTop: 0,
        }}
      >
        <IntegratedComponentHolder
          showReload={!isFetching && !Array.isArray(data)}
          reloadMessage={"Nenhum dado encontrado"}
          showEmpty={!isFetching && data?.length < 1}
          emptyMessage={"Lista Vazia"}
          maxSize={"62rem"}
        >
          <Table
            headerBackground={true}
            id={"teste"}
            color={1}
            headers={{
              table: [
                "SELECIONAR",
                "CLIENTE",
                "SCORE",
                "DATA / HORA",
                "LIBERAÇÃO DE CRÉDITO",
              ],
            }}
            data={data}
            renderItemColumns={(item, indexItem) => [
              <div
                style={{
                  gap: Spacing(1),
                  display: "flex",

                  justifyContent: "flex-start",
                }}
              >
                <Checkbox
                  onChange={(event, value) => {
                    if (value === true && selected?.length < maxStatus) {
                      setSelected([...selected, item.id]);
                    } else if (
                      selected?.find((data) => data === item.id) !== undefined
                    ) {
                      setSelected(selected.filter((id) => id !== item.id));
                    }
                  }}
                  checked={
                    selected?.find((data) => data === item.id) !== undefined
                      ? true
                      : false
                  }
                  color="primary"
                  icon={
                    <svg className={classes.icon}>
                      <rect className={classes.border} />
                    </svg>
                  }
                  checkedIcon={
                    <svg className={classes.icon}>
                      <rect className={classes.border} />
                      <rect className={classes.iconCenter} />
                    </svg>
                  }
                  style={{ margin: 0, padding: 0 }}
                />
              </div>,
              item?.cpfOrCnpj,
              item?.score
                ? item?.score?.toFixed(2)?.replace(".", ",") + "%"
                : "-",
              formatDate(item?.createdAt),
              item?.signalCreditRelease,
            ]}
          />
        </IntegratedComponentHolder>
      </div>

      <Styles.InLinePaddingModal>
        <CustomText
          fontFamily={Fonts.bold}
          fontSize={1.75}
          textColor={colors.orange}
        >
          VALOR A ALTERAR
        </CustomText>
        <CustomText
          styles={{ paddingBottom: Spacing(1.75) }}
          fontFamily={Fonts.medium}
          fontSize={1.75}
        >
          Insira o novo valor a alterar para os itens selecionados:
        </CustomText>
        <FormHolder formRef={setRef}>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Select
                disableError
                color={1}
                defaultValue={true}
                name="status"
                inputLabel="POSSIBILIDADE DE LIBERAÇÃO DE CRÉDITO?"
                options={options}
              />
            </Grid>
          </Grid>
          <Styles.FormButtons>
            <ButtonText
              color={1}
              fullWidth={false}
              onClick={() => {
                customModal.close();
              }}
            >
              Voltar
            </ButtonText>
            <Styles.FormButton
              color={1}
              fullWidth={false}
              disabled={selected?.length < 1}
              onClick={() => {
                if (formRef) {
                  let status = formRef.getFieldValue("status");
                  onSubmit(status, selected);
                }
              }}
            >
              SALVAR
            </Styles.FormButton>
          </Styles.FormButtons>
        </FormHolder>
      </Styles.InLinePaddingModal>
    </Styles.ModalContent>
  );
}
function mapStateToProps(redux) {
  const { isFetching: isFetchingPage } = redux.queriesHistory;
  const { isFetching: isFetchingGlobal } = redux.global;

  return {
    isFetching: isFetchingPage || isFetchingGlobal,
  };
}
export default connect(mapStateToProps, null)(React.memo(EditStatusAll));
