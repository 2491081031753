import React from "react";
import styled from "styled-components";
import ButtonContainedComponent from "./ButtonContained";

const StyledButton = styled(ButtonContainedComponent)(({ theme }) => ({
  padding: theme.spacing(0.85),
  minWidth: 0,
  marginRight: theme.spacing(0.75),
  borderRadius: theme.spacing(10),
  "& .MuiButton-startIcon": {
    margin: 0,
    width: "auto",
  },
}));

function ButtonIcon({icon, ...props}) {
  return (
    <StyledButton
      fullWidth={false}
      startIcon={icon}
      {...props}
    />
  );
}

export default ButtonIcon;
