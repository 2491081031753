import { call, put } from "redux-saga/effects";
import { api } from "../../../services";
import { Creators } from "./index";

import { Alerts } from "../../../lib";

export default function* validCpf({ data, callback }) {
  try {
    const response = yield call(api.validCpf, data);

    yield put(Creators.validCpfSuccess(response));
    if (!response?.data?.response) {
      callback("Esse CPF ja esta em uso");
    }
  } catch (response) {
    Alerts.alertError(
      response?.data?.log ? response?.data?.log : "Erro a validar CPF "
    );

    yield put(Creators.validCpfFailure());
  }
}
