import React from "react";

import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import Text from "../strings/Text";

import dateFns from "@date-io/date-fns";
import { formConnector } from "../../FormConfig";
import styled from "styled-components";
import FontStyles from "../styles/fontsStyles";
import { FormControl, ThemeProvider, withTheme } from "@material-ui/core";
import { Validations } from "../../lib";
import brLocate from "date-fns/locale/pt-BR";
import { Colors, Fonts } from "../../config";

const amcredTheme = withTheme({
  overrides: {
    MuiFilledInput: {
      root: {
        backgroundColor: Colors.softGray,
        borderRadius: 5,
        height: 50,
        fontFamily: Fonts.medium,
        fontSize: Fonts.sizes.regular,
      },
      input: {
        paddingTop: 10,
      },
    },
    MuiIconButton: {
      root: {
        color: Colors.red,
      },
    },
    MuiPickersToolbar: {
      toolbar: {
        backgroundColor: Colors.red,
      },
    },
    MuiPickersCalendarHeader: {
      iconButton: {
        color: Colors.carbon,
      },
    },
    MuiTypography: {
      alignCenter: {
        color: Colors.carbon,
      },
    },
    MuiPickersDay: {
      day: {
        color: Colors.carbon,
      },
      daySelected: {
        backgroundColor: Colors.red,
      },
      dayDisabled: {
        opacity: 0.5,
      },
    },
    MuiButton: {
      label: {
        color: Colors.red,
      },
    },
  },
});

const amcredErrorTheme = withTheme({
  overrides: {
    MuiFilledInput: {
      root: {
        backgroundColor: Colors.softGray,
        borderRadius: 5,
        height: 50,
        fontFamily: Fonts.medium,
        fontSize: Fonts.sizes.regular,
        border: `1px solid ${Colors.redError}`,
      },
      input: {
        paddingTop: 10,
      },
    },
    MuiIconButton: {
      root: {
        color: Colors.red,
      },
    },
    MuiPickersToolbar: {
      toolbar: {
        backgroundColor: Colors.red,
      },
    },
    MuiPickersCalendarHeader: {
      iconButton: {
        color: Colors.carbon,
      },
    },
    MuiTypography: {
      alignCenter: {
        color: Colors.carbon,
      },
    },
    MuiPickersDay: {
      day: {
        color: Colors.carbon,
      },
      daySelected: {
        backgroundColor: Colors.red,
      },
      dayDisabled: {
        opacity: 0.5,
      },
    },
    MuiPickersMonth: {
      monthDisabled: {
        opacity: 0.5,
      },
    },
    MuiButton: {
      label: {
        color: Colors.red,
      },
    },
  },
});

const gestorTheme = withTheme({
  overrides: {
    MuiFilledInput: {
      root: {
        backgroundColor: Colors.softGray,
        borderRadius: 5,
        height: 50,
        fontFamily: Fonts.medium,
        fontSize: Fonts.sizes.regular,
      },
      input: {
        paddingTop: 10,
      },
    },
    MuiIconButton: {
      root: {
        color: Colors.orange,
      },
    },
    MuiPickersToolbar: {
      toolbar: {
        backgroundColor: Colors.orange,
      },
    },
    MuiPickersCalendarHeader: {
      iconButton: {
        color: Colors.carbon,
      },
    },
    MuiTypography: {
      alignCenter: {
        color: Colors.carbon,
      },
    },
    MuiPickersDay: {
      day: {
        color: Colors.carbon,
      },
      daySelected: {
        backgroundColor: Colors.orange,
      },
      dayDisabled: {
        opacity: 0.5,
      },
    },
    MuiPickersMonth: {
      monthDisabled: {
        opacity: 0.5,
      },
    },
    MuiButton: {
      label: {
        color: Colors.orange,
      },
    },
  },
});

const gestorErrorTheme = withTheme({
  overrides: {
    MuiFilledInput: {
      root: {
        backgroundColor: Colors.softGray,
        borderRadius: 5,
        height: 50,
        fontFamily: Fonts.medium,
        fontSize: Fonts.sizes.regular,
        border: `1px solid ${Colors.redError}`,
      },
      input: {
        paddingTop: 10,
      },
    },
    MuiIconButton: {
      root: {
        color: Colors.orange,
      },
    },
    MuiPickersToolbar: {
      toolbar: {
        backgroundColor: Colors.orange,
      },
    },
    MuiPickersCalendarHeader: {
      iconButton: {
        color: Colors.carbon,
      },
    },
    MuiTypography: {
      alignCenter: {
        color: Colors.carbon,
      },
    },
    MuiPickersDay: {
      day: {
        color: Colors.carbon,
      },
      daySelected: {
        backgroundColor: Colors.orange,
      },
      dayDisabled: {
        opacity: 0.5,
      },
    },
    MuiPickersMonth: {
      monthDisabled: {
        opacity: 0.5,
      },
    },
    MuiButton: {
      label: {
        color: Colors.orange,
      },
    },
  },
});

const Label = styled(Text)(({ theme, $witherror, $color }) => {
  const { palette: colors } = theme;
  return {
    ...FontStyles.semibold14,
    textTransform: "uppercase",
    color: $witherror
      ? colors.error.main
      : $color === 2
      ? colors.text.primary
      : $color === 1
      ? colors.orange
      : colors.text.secondary,
    transition: ".2s",
    pointerEvents: "none",
  };
});

function InputDateComponent(props) {
  const {
    value,
    error,
    handleKeyPress,
    handleKeyDown,
    onBlur,
    setConfigs,
    ref,
    formHandler,
    usedProps,
  } = formConnector.useStringValue({
    ...props,
    validation: (data) =>
      Validations.inputDate(data, usedProps.maxDate, usedProps.minDate),
  });

  const {
    inputLabel,
    icon,
    theme,
    required,
    color,
    disableError,
    borderRadius,
    variant = "outlined",
    inputStyle,
    views = ["year", "month", "date"],
    autoOk = true,
    format,
    readOnly,
    minDate,
    maxDate,
    alternativeColors,
    autoComplete,
    end,
    ...restProps
  } = usedProps;

  return (
    <FormControl fullWidth error={Boolean(error)}>
      <MuiPickersUtilsProvider locale={brLocate} utils={dateFns}>
        <Label $witherror={Boolean(error) ? true : undefined} $color={color}>
          {inputLabel}
          {Boolean(required) && "*"}
        </Label>
        <ThemeProvider
          theme={
            Boolean(error)
              ? color === 1
                ? gestorErrorTheme
                : amcredErrorTheme
              : color === 1
              ? gestorTheme
              : amcredTheme
          }
        >
          <KeyboardDatePicker
            {...restProps}
            $alternativecolors={alternativeColors}
            {...(readOnly ? { open: !readOnly } : {})}
            autoComplete={autoComplete === false ? "new-password" : null}
            inputVariant="filled"
            format={format ? format : "dd/MM/yyyy"}
            minDate={
              typeof minDate !== "string" && minDate !== null
                ? minDate
                : undefined
            }
            maxDate={
              typeof maxDate !== "string" && maxDate !== null
                ? maxDate
                : undefined
            }
            InputProps={{
              disableUnderline: true,
              readOnly: readOnly,
            }}
            value={value ? value : null}
            withError={Boolean(error) ? true : undefined}
            required={Boolean(required)}
            variant={variant}
            fullWidth
            onKeyPress={handleKeyPress}
            onKeyDown={handleKeyDown}
            views={views}
            inputRef={ref}
            autoOk={autoOk}
            onChange={(date) => {
              setConfigs(date);
            }}
            onBlur={(event) => {
              setTimeout(() => {
                onBlur(event);
                formHandler.testInputError(props.name);
              }, 10);
            }}
            error={Boolean(error)}
            helperText={disableError ? null : error ? error : " "}
          />
        </ThemeProvider>
      </MuiPickersUtilsProvider>
    </FormControl>
  );
}

export default InputDateComponent;
