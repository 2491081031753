import React from "react";
import { CustomText, RadioList } from "../../../components";
import Styles from "../styles/Styles";

import { Spacing, Texts } from "../../../config";

import { Info } from "@material-ui/icons";
import fonts from "../../../config/fonts";
function SubmitScore({ analysisScore, isFetching }) {
  const texts = Texts["pt-BR"].CreditAnalysis;

  return (
    <Styles.CardContainer fullHeight>
      <Styles.CardHeader>
        <Styles.CardTitle>{texts.creditForm.send}</Styles.CardTitle>
      </Styles.CardHeader>
      <Styles.Line />
      <div
        style={{
          height: `calc(100% - ${Spacing(4)}px)`,
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
        }}
      >
        <div>
          <Styles.ScoreFormContainer>
            <Info
              style={{
                height: Spacing(1.75),
                width: Spacing(1.75),
                marginTop: Spacing(0.5),
                marginRight: Spacing(0.5),
                opacity: 0.66,
              }}
            />

            <CustomText
              fontSize={1.75}
              fontFamily={fonts.medium}
              styles={{ opacity: 0.66 }}
            >
              A partir da geração do score para este usuário, indique se
              futuramente este usuário possui alguma chance de liberação de
              crédito ou não.
            </CustomText>
          </Styles.ScoreFormContainer>
          <Styles.FormSendContainer>
            <RadioList
              required={"*Selecione um valor"}
              name="signalCreditRelease"
            />
          </Styles.FormSendContainer>
        </div>

        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          <Styles.ButtonSubmit
            disabled={!Boolean(analysisScore) || isFetching}
            loading={isFetching}
            fullWidth={false}
            color={1}
            type="submit"
          >
            Confirmar
          </Styles.ButtonSubmit>
        </div>
      </div>
    </Styles.CardContainer>
  );
}

export default React.memo(SubmitScore);
