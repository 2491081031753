import React from "react";
import { RegisterAndQueryAssociated } from "../features";

function RegisterAndQueryAssociatedPage() {
  return (
    <>
      <RegisterAndQueryAssociated />
    </>
  );
}

export default RegisterAndQueryAssociatedPage;
