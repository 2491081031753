export default function getCities(response) {
  const data = response.data.response.map((item) => {
    return {
      value: item.id,
      label: item.name,
      uf: item?.uf?.id,
    };
  });

  return data;
}
