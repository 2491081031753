import styled from "styled-components";
import { ButtonContained, FontStyles } from "../../../components";

import IconButton from "@material-ui/core/IconButton";
import { Button } from "@material-ui/core/";

const Container = styled.div(({ theme, minH }) => {
  const { spacing } = theme;
  return {
    display: "flex",
    overflow: "auto",
    height: `calc(100vh - ${minH}px)`,
    width: "100%",
    justifyContent: "center",
    alignItems: "flex-start",
    padding: spacing(3.75, 3.125),
  };
});

const Content = styled.div(({ theme }) => {
  const { spacing } = theme;
  return {
    backgroundColor: "white",
    borderRadius: spacing(0.625),
    width: "100%",
    padding: spacing(2.625, 1.875),
    overflow: "auto",
  };
});

const Line = styled.div(({ theme }) => {
  const { spacing, palette: colors } = theme;
  return {
    backgroundColor: colors.carbon,
    width: "100%",
    height: 1,
    marginBottom: spacing(2),
    opacity: 0.1,
  };
});

const CardContainer = styled.div(({ theme, fullHeight }) => {
  const { spacing, palette: colors } = theme;
  return {
    backgroundColor: colors.white,
    width: "100%",
    height: fullHeight ? "100%" : "min-content",
    padding: spacing(2, 2, 3, 2),
    borderRadius: spacing(2),
  };
});

const CardHeader = styled.div(({ theme }) => {
  return {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  };
});

const CardTitle = styled.p(({ theme }) => {
  const { palette: colors } = theme;
  return {
    ...FontStyles.semibold14,
    color: colors.text.primary,
    textTransform: "uppercase",
    margin: 0,
  };
});
const ButtonContainer = styled.div(({ theme }) => {
  return {
    display: "flex",
    alignItems: "flex-end",
    justifyContent: "flex-end",
    width: "100%",
    margin: 0,
    padding: 0,
  };
});

const Dots = styled(IconButton)(({ theme }) => {
  const { spacing, palette: colors } = theme;
  return {
    width: spacing(2),
    height: "auto",
    color: colors.carbon,
  };
});

const ButtonsNavigation = styled(Button)(({ theme, Second }) => {
  const { palette: colors } = theme;
  return {
    width: "100%",
    backgroundColor: Second
      ? colors.action.disabledBackground
      : colors.primary.main,
    borderRadius: 0,
    borderBottom: Second
      ? `4px solid ${colors.carbon}`
      : `4px solid ${colors.background.defaultHover}`,
    color: Second ? colors.carbon : colors.text.primary,
    "&:hover": {
      backgroundColor: colors.background.defaultHover,
    },
  };
});

const ButtonSubmit = styled(ButtonContained)(({ theme }) => {
  return {
    ...FontStyles.medium14,
    textTransform: "capitalize",
    height: "fit-content",
  };
});

const ScoreContainer = styled.div(({ theme }) => {
  return {
    padding: theme.spacing(4),
    flex: 1,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: theme.spacing(0.65),
    backgroundColor: theme.palette.background.paper,
    marginTop: theme.spacing(2),
  };
});

const ScoreFormContainer = styled.div(({ theme }) => {
  return {
    display: "flex",
  };
});

const FormSendContainer = styled.div(({ theme }) => {
  return {
    marginTop: theme.spacing(2),
    display: "flex",
  };
});

const Styles = {
  Container,
  Line,
  CardContainer,
  CardHeader,
  CardTitle,
  Dots,
  ButtonContainer,
  ButtonsNavigation,
  ButtonSubmit,
  Content,
  ScoreContainer,
  ScoreFormContainer,
  FormSendContainer,
};

export default Styles;
