import { call, put } from "redux-saga/effects";
import { api } from "../../../services";
import { Creators } from "./index";

import { Alerts } from "../../../lib";

export default function* editUserAmcred({ data, callback }) {
  try {
    let sendData = {
      firstName: data?.firstName ? data?.firstName : null,
      lastName: data?.lastName ? data?.lastName : null,
      roleID: data?.officeId ? data?.officeId : null,
      email: data?.email ? data?.email : null,
    };
    yield call(api.editUserAmcred, { data: sendData, id: data?.id });

    yield put(Creators.editUserAmcredSuccess());

    callback();
  } catch (response) {
    Alerts.alertError(
      response?.data?.log
        ? response?.data?.log
        : "Erro ao cadastrar novo usuário"
    );

    yield put(Creators.editUserAmcredFailure());
  }
}
