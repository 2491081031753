import React from "react";
import Styles from "../styles/Styles";
import SearchReports from "../components/SearchReports";
import { CircularProgress, Grid } from "@material-ui/core";
import ReportList from "../components/ReportList";
import { connect } from "react-redux";
import { Creators } from "../reduxSagas";
import { Creators as GlobalCreators } from "../../globalReduxSagas";

import { IntegratedComponentHolder } from "../../../components";
import { GetRoleByName } from "../../../services";
import { ToCSV } from "../../../lib";

const pageSize = 10;
const formatTime = (date) => {
  var data = new Date(date);

  var dia = data.getDate();
  var mes = data.getMonth() + 1;
  var ano = data.getFullYear();
  var horas = data.getHours();
  var minutos = data.getMinutes();

  dia = dia < 10 ? "0" + dia : dia;
  mes = mes < 10 ? "0" + mes : mes;
  horas = horas < 10 ? "0" + horas : horas;
  minutos = minutos < 10 ? "0" + minutos : minutos;

  var dataString = dia + "/" + mes + "/" + ano + " - " + horas + ":" + minutos;
  return dataString;
};
function DetailReportPage({
  isFetching,
  userInfos,
  reportDetailListRequest,
  detailReportList,
  getCompanies,
  companiesList,
  lastFilter,
  filterAmcred,
  getFilterAmcred,
  reportDetailListInstitution,
  getUsernameAndRoles,
  usernameAndRolesList,
  fullReportDetail,
  getValuesDatail,
  getValuesInstitutionDatail,
  totalValues,
}) {
  const rowsWithSort = [];
  const [page, setPage] = React.useState(0);
  const [sortKey, setSortKey] = React.useState("createAt");
  const [sortDirection, setSortDirection] = React.useState(true);

  React.useEffect(() => {
    const roleId = GetRoleByName(userInfos?.role?.name);
    const now = new Date();
    const year = now.getFullYear();
    const month = now.getMonth() + 1; // Os meses em JavaScript são indexados de 0 a 11

    const begin = new Date(year, month - 1, 1);
    const end = now;
    if (roleId === 1) {
      getValuesInstitutionDatail(
        {
          searchParameters: { begin: begin, end: end },
          page: { size: pageSize, page: 0 },
        },
        roleId
      );
      getUsernameAndRoles();
      reportDetailListInstitution({
        searchParameters: {
          order: "createAt",
          typeOrder: "ASC",
          begin: begin,
          end: end,
        },
        page: { size: pageSize, page: 0 },
      });
    } else {
      getValuesDatail({
        searchParameters: { begin: begin, end: end },
        page: { size: pageSize, page: 0 },
      });
      reportDetailListRequest({
        searchParameters: {
          order: "createAt",
          typeOrder: "ASC",
          begin: begin,
          end: end,
        },
        page: { size: pageSize, page: 0 },
      });

      getFilterAmcred();
    }
  }, [
    reportDetailListRequest,
    getFilterAmcred,
    getCompanies,
    reportDetailListInstitution,
    getUsernameAndRoles,
    getCompanies,
    getValuesDatail,
    getValuesInstitutionDatail,
    userInfos,
  ]);

  const formatFilter = (obj) => {
    function formatDate(date) {
      const day = String(date.getDate()).padStart(2, "0");
      const month = String(date.getMonth() + 1).padStart(2, "0");
      const year = String(date.getFullYear());
      return day + "/" + month + "/" + year;
    }
    const result = [];
    if (obj.companies) {
      const companiesNames = obj.companies?.map((i) => {
        return i?.label;
      });
      companiesNames.join(", ");
      const companyIdsStr = "Instituições: " + companiesNames;
      result.push(companyIdsStr);
      result.push(";");
    }

    if (obj.cnpj) {
      const companyIdsStr = "CNPJ: " + obj.cnpj;
      result.push(companyIdsStr);
      result.push(";");
    }

    if (obj.userIds) {
      const companiesNames = obj.userIds?.map((i) => {
        return i?.label;
      });
      companiesNames.join(", ");
      const companyIdsStr = "Usuários: " + companiesNames;
      result.push(companyIdsStr);
      result.push(";");
    }

    if (obj.roleId) {
      const findName = usernameAndRolesList?.roleNames?.find(
        (i) => parseInt(i?.value) === parseInt(obj.roleId)
      );

      const companyIdsStr = "Perfis: " + findName?.label;
      result.push(companyIdsStr);
      result.push(";");
    }

    if (obj.begin && obj.begin instanceof Date) {
      result.push("Período: ");
      const formattedBegin = formatDate(obj.begin);

      if (obj.end && obj.end instanceof Date) {
        const formattedEnd = formatDate(obj.end);
        result.push(formattedBegin + " até " + formattedEnd);
      } else {
        result.push(formattedBegin);
      }
    }

    return result.join("");
  };

  const submit = (filters, reset) => {
    const roleId = GetRoleByName(userInfos?.role?.name);
    setPage(0);

    if (roleId === 1) {
      reportDetailListInstitution({
        searchParameters: {
          ...filters,
          order: sortKey,
          typeOrder: sortDirection === true ? "ASC" : "DESC",
        },
        page: { size: pageSize, page: reset ? 0 : page },
      });
      getValuesInstitutionDatail(
        {
          searchParameters: filters,
          page: { size: pageSize, page: reset ? 0 : page },
        },
        roleId
      );
    } else {
      reportDetailListRequest({
        searchParameters: {
          ...filters,
          order: sortKey,
          typeOrder: sortDirection === true ? "ASC" : "DESC",
        },
        page: { size: pageSize, page: reset ? 0 : page },
      });
      getValuesDatail({
        searchParameters: filters,
        page: { size: pageSize, page: reset ? 0 : page },
      });
    }
  };

  const sortContent = (filter, user, order, type) => {
    const roleId = GetRoleByName(userInfos?.role?.name);
    if (roleId === 1) {
      reportDetailListInstitution({
        searchParameters: { ...filter, order: order, typeOrder: type },
        page: {
          size: pageSize,
          page:
            user?.pageTotal > 0 &&
            detailReportList?.pageTotal <= page + 1 &&
            (detailReportList === null || detailReportList === undefined)
              ? detailReportList?.pageTotal - 1
              : page,
        },
      });
    } else {
      reportDetailListRequest({
        searchParameters: { ...filter, order: order, typeOrder: type },
        page: {
          size: pageSize,
          page:
            user?.pageTotal > 0 &&
            detailReportList?.pageTotal <= page + 1 &&
            (detailReportList === null || detailReportList === undefined)
              ? detailReportList?.pageTotal - 1
              : page,
        },
      });
    }
  };

  const nextPageReport = (filters, pageN) => {
    const roleId = GetRoleByName(userInfos?.role?.name);

    if (roleId === 1) {
      reportDetailListInstitution({
        searchParameters: filters,
        page: { size: pageSize, page: pageN },
      });
      getValuesInstitutionDatail(
        {
          searchParameters: filters,
          page: { size: pageSize, page: pageN },
        },
        roleId
      );
    } else {
      reportDetailListRequest({
        searchParameters: filters,
        page: { size: pageSize, page: pageN },
      });
      getValuesDatail({
        searchParameters: filters,
        page: { size: pageSize, page: pageN },
      });
    }
  };

  const exportData = (list) => {
    let filters = formatFilter(lastFilter?.searchParameters);

    let headers =
      GetRoleByName(userInfos?.role?.name) === 1
        ? [
            "NOME DO USUÁRIO",
            "PERFIL",
            "CLIENTE CONSULTADO",
            "LIBERAÇÃO DE CRÉDITO",
            "DATA E HORA",
          ]
        : [
            "NOME DA INSTITUIÇÃO ASSOCIADA",
            "CNPJ",
            "USUÁRIO",
            "DATA E HORA",
            "VALOR DA CONSULTA (R$)",
          ];

    let rows =
      GetRoleByName(userInfos?.role?.name) === 1
        ? ["fullName", "roleName", "cpfCnpj", "released", "createAt"]
        : ["companyName", "companyCnpj", "userName", "createAt", "value"];

    let data =
      GetRoleByName(userInfos?.role?.name) === 1
        ? Array.from(list)
        : Array.from(list?.details);

    data?.map((i) => {
      let a = i;
      a.createAt = formatTime(i?.createAt);

      if (a.value !== undefined) {
        a.value = i?.value?.toString()?.replace(".", ",");
      }

      return a;
    });

    let extra = "";

    if (GetRoleByName(userInfos?.role?.name) !== 1) {
      const keysAndValues = [];
      if (
        list.totalPayableFromCompanies &&
        typeof list.totalPayableFromCompanies === "object"
      ) {
        for (const key in list.totalPayableFromCompanies) {
          if (
            typeof list.totalPayableFromCompanies[key] === "number" &&
            !isNaN(list.totalPayableFromCompanies[key])
          ) {
            keysAndValues.push(
              `${key.toUpperCase()} (R$): ; ${list.totalPayableFromCompanies[
                key
              ]
                ?.toString()
                ?.replace(".", ",")}`
            );
          }
        }
        extra = keysAndValues.join("\n");
      }
      if (keysAndValues.length > 0) {
        extra += "\n ";
      }
      extra += `TOTAL A PAGAR (R$): ; ${list.totalAllCompanies
        ?.toString()
        ?.replace(".", ",")}`;
    } else if (totalValues) {
      extra += `TOTAL A PAGAR (R$): ; ${totalValues.totalAllCompanies
        ?.toString()
        ?.replace(".", ",")}`;
    }

    ToCSV.report(
      data,
      headers,
      rows,
      `Relatório Detalhado`,
      `Relatório_Detalhado`,
      filters,
      extra
    );
  };

  const getFulData = () => {
    const roleId = GetRoleByName(userInfos?.role?.name);
    fullReportDetail(
      {
        searchParameters: lastFilter?.searchParameters,
      },
      roleId,
      (data) => {
        exportData(data);
      }
    );
  };

  const onClear = () => {
    setSortKey("createAt");
    setSortDirection(true);
  };

  return (
    <Styles.Container>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <SearchReports
            onSubmit={(data) => submit(data)}
            userInfos={userInfos}
            filterAmcred={filterAmcred}
            companiesList={companiesList}
            usernameAndRolesList={usernameAndRolesList}
            isFetching={isFetching}
            onClear={onClear}
          />
        </Grid>
        <Grid item xs={12}>
          <IntegratedComponentHolder
            showReload={!isFetching && !Boolean(detailReportList?.elements)}
            reloadMessage={"Não encontrado"}
            showEmpty={!isFetching && detailReportList?.elements?.length < 1}
            emptyMessage={"Lista Vazia"}
            maxSize={"62rem"}
          >
            {isFetching ? (
              <div
                style={{
                  width: "100%",
                  height: "40vh",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <CircularProgress color="primary" />
              </div>
            ) : (
              <ReportList
                setSortKey={setSortKey}
                setSortDirection={setSortDirection}
                sortKey={sortKey}
                sortDirection={sortDirection}
                exportData={() => getFulData()}
                page={page + 1}
                setPage={(a) => {
                  setPage(a - 1);
                  nextPageReport(lastFilter?.searchParameters, a - 1);
                }}
                totalPages={detailReportList?.pageTotal}
                userInfos={userInfos}
                isFetching={isFetching}
                detailReportList={detailReportList}
                lastFilter={lastFilter?.searchParameters}
                user={detailReportList}
                sort={sortContent}
                totalValues={totalValues}
                sortList={rowsWithSort}
                roleId={GetRoleByName(userInfos?.role?.name)}
              />
            )}
          </IntegratedComponentHolder>
        </Grid>
      </Grid>
    </Styles.Container>
  );
}

function mapStateToProps(redux) {
  const {
    isFetching: isFetchingPage,
    detailReportList,
    lastFilter,
    totalValues,
    isFetchingFullValue,
  } = redux.detailReport;
  const {
    isFetching: isFetchingGlobal,
    userInfos,
    companiesList,
    filterAmcred,
    usernameAndRolesList,
  } = redux.global;

  return {
    isFetching: isFetchingPage || isFetchingGlobal || isFetchingFullValue,
    companiesList,
    userInfos,
    detailReportList,
    lastFilter,
    filterAmcred,
    usernameAndRolesList,
    totalValues,
  };
}

function mapDispatchToProps(dispatch) {
  const {
    reportDetailListRequest,
    reportDetailListInstitution,
    fullReportDetail,
    getValuesDatail,
    getValuesInstitutionDatail,
  } = Creators;
  const { getCompanies, getFilterAmcred, getUsernameAndRoles } = GlobalCreators;
  return {
    reportDetailListRequest: function (data) {
      return dispatch(reportDetailListRequest(data));
    },
    getValuesDatail: function (data) {
      return dispatch(getValuesDatail(data));
    },
    getValuesInstitutionDatail: function (data, type) {
      return dispatch(getValuesInstitutionDatail(data, type));
    },
    reportDetailListInstitution: function (data) {
      return dispatch(reportDetailListInstitution(data));
    },
    getCompanies: function () {
      return dispatch(getCompanies());
    },
    getFilterAmcred: function () {
      return dispatch(getFilterAmcred());
    },
    getUsernameAndRoles: function () {
      return dispatch(getUsernameAndRoles());
    },
    fullReportDetail: function (data, id, callback) {
      return dispatch(fullReportDetail(data, id, callback));
    },
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(React.memo(DetailReportPage));
