import React from "react";
import styled from "styled-components";
import FontStyles from "../styles/fontsStyles";
import { IconButton, Button, Select, MenuItem } from "@material-ui/core";
import Text from "../strings/Text";
import { ExpandMoreOutlined } from "@material-ui/icons";
import KeyboardArrowLeftIcon from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@material-ui/icons/KeyboardArrowRight";
import { makeStyles } from "@material-ui/core";
import { Colors } from "../../config";
const BottomNav = styled.div(({ theme }) => {
  const { spacing } = theme;
  return {
    display: "flex",
    justifyContent: "flex-end",
    margin: spacing(1.875, 0),
  };
});

const PageNav = styled(IconButton)(({ theme, $color }) => {
  const { palette: colors } = theme;
  return {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "1.75rem",
    height: "1.75rem",

    minHeight: "1.75rem",
    minWidth: "1.75rem",

    backgroundColor: "transparent",
    borderWidth: "0",
    color:
      $color === 2
        ? colors.text.primary
        : $color === 1
        ? colors.orange
        : colors.text.secondary,
  };
});

const PageList = styled.div(({ theme }) => {
  return {
    display: "flex",
  };
});

const PageListButton = styled(Button)(({ theme, $show, $color }) => {
  const { spacing, palette: colors } = theme;
  return {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",

    width: "1.75rem",
    height: "1.75rem",
    minHeight: "1.75rem",
    minWidth: "1.75rem",

    margin: spacing(0, 0.125),
    backgroundColor: "transparent",
    color: $show
      ? $color === 2
        ? colors.text.primary
        : $color === 1
        ? colors.orange
        : colors.text.secondary
      : undefined,
    border: $show
      ? `0.125rem solid ${
          $color === 2
            ? colors.text.primary
            : $color === 1
            ? colors.orange
            : colors.text.secondary
        }`
      : "none",
  };
});

const PageSelectText = styled(Text)(({ theme }) => {
  const { spacing } = theme;
  return {
    alignSelf: "center",
    ...FontStyles.medium14,
    margin: spacing(0, 0.625),
  };
});

const PageSelect = styled(Select)(({ theme, $color }) => {
  const { spacing, palette: colors } = theme;
  return {
    width: "5.25rem",
    height: "1.75rem",
    padding: spacing(0.75, 1.875),
    //marginRight: spacing(1.875),
    color: colors.primary.contrastText,
    backgroundColor:
      $color === 2
        ? colors.text.primary
        : $color === 1
        ? colors.orange
        : colors.text.secondary,
    ".MuiOutlinedInput-input": {
      "$hover $notchedOutline": { borderColor: "orange" },
      padding: 0,
      ...FontStyles.roman14,
      color: colors.primary.contrastText,
    },
    ".MuiSelect-icon": {
      alignSelf: "center",
      width: spacing(2.315),
      height: spacing(2.315),
      filter: "brightness(0) invert(1)",
      color: colors.primary.contrastText,
    },
  };
});

const ItemMenu = styled(MenuItem)(({ theme, $color }) => {
  const { spacing, palette: colors } = theme;
  return {
    width: "5.25rem",
    height: "1.75rem",
    padding: spacing(0.75, 1.875),
    "&:hover": {
      backgroundColor:
        $color === 2
          ? colors.text.primary
          : $color === 1
          ? "#fac49e"
          : "#dda2a4",
      color: "white",
    },
  };
});

const SelectPageIcon = styled(ExpandMoreOutlined)(({ theme }) => {
  const { spacing } = theme;
  return {
    margin: spacing(0.4),
  };
});

const amcredStyles = makeStyles((theme) => ({
  root: {
    width: "300px",
  },
  selected: {
    backgroundColor: `${Colors.red} !important`,
    color: "white",
    fontWeight: 600,
  },
}));

const gestorStyles = makeStyles((theme) => ({
  root: {
    width: "300px",
    color: "orange",
  },
  selected: {
    backgroundColor: `${Colors.orange} !important`,
    color: "white",
    fontWeight: 600,
  },
}));

function PaginationComponent({ page, setPage, totalPages, color }) {
  const amcredClasses = amcredStyles();
  const gestorClasses = gestorStyles();
  return (
    <BottomNav>
      <PageNav
        $color={color}
        onClick={() => {
          backPage(page, setPage);
        }}
      >
        <KeyboardArrowLeftIcon />
      </PageNav>
      <PageList>
        {getPaginationContent(setPage, totalPages, page, color)}
      </PageList>
      <PageNav
        $color={color}
        onClick={() => {
          nextPage(page, totalPages, setPage);
        }}
      >
        <KeyboardArrowRightIcon />
      </PageNav>
      <PageSelectText> Ir à página: </PageSelectText>
      <PageSelect
        variant="outlined"
        labelId="Seleção-da-pagina"
        id="Seleção-da-pagina"
        value={page}
        onChange={(value) => {
          setPage(value.target.value);
        }}
        IconComponent={SelectPageIcon}
        label="Pagina"
        $color={color}
      >
        {checkTotalPages(totalPages, page, amcredClasses, gestorClasses, color)}
      </PageSelect>
    </BottomNav>
  );
}

export default React.memo(PaginationComponent);

const checkTotalPages = (
  totalPages,
  page,
  amcredClasses,
  gestorClasses,
  color
) => {
  let components = [];
  for (let i = 0; i < totalPages; i++) {
    components.push(
      <ItemMenu
        key={"key_" + (i + 1)}
        selected={i === page}
        value={i + 1}
        classes={
          color === 2
            ? amcredClasses
            : color === 1
            ? gestorClasses
            : amcredClasses
        }
        $color={color}
      >
        {i + 1}
      </ItemMenu>
    );
  }

  return components;
};
const getPaginationContent = (setPage, totalPages, page, color) => {
  let content = [];
  const changePageTo = (toPage) => {
    setPage(toPage);
  };
  for (let i = 0; i < totalPages; i++) {
    if (page > 3 && page < totalPages - 4) {
      if (i >= page - 3 && i <= page + 1) {
        content.push(
          <PageListButton
            key={"keyPage_" + (i + 1)}
            $show={i + 1 === page ? true : false}
            $color={color}
            onClick={() => changePageTo(i + 1, setPage)}
          >
            {" "}
            {i + 1}{" "}
          </PageListButton>
        );
      }
      if (i === page + 3) {
        content.push(
          <PageListButton
            key={"keyPage_" + (i + 1)}
            $show={i + 1 === page ? true : false}
            $color={color}
            onClick={() => changePageTo(totalPages, setPage)}
          >
            {"..."}
          </PageListButton>
        );
      }
    } else if (page < 4) {
      if (i <= 5) {
        content.push(
          <PageListButton
            key={"keyPage_" + (i + 1)}
            $show={i + 1 === page ? true : false}
            $color={color}
            onClick={() => changePageTo(i + 1, setPage)}
          >
            {" "}
            {i + 1}{" "}
          </PageListButton>
        );
      }
    } else if (i >= totalPages - 6) {
      if (i === 14) {
        content.push(
          <PageListButton
            key={"keyPage_" + (i + 1)}
            $show={i + 1 === page ? true : false}
            $color={color}
            onClick={() => changePageTo(1, setPage)}
          >
            {"..."}
          </PageListButton>
        );
      }

      content.push(
        <PageListButton
          key={"keyPage_" + (i + 1)}
          $show={i + 1 === page ? true : false}
          $color={color}
          onClick={() => changePageTo(i + 1, setPage)}
        >
          {" "}
          {i + 1}{" "}
        </PageListButton>
      );
    }
  }
  return content;
};

function nextPage(page, totalPages, setPage) {
  if (page !== totalPages) {
    setPage(page + 1);
  }
}

function backPage(page, setPage) {
  if (page > 1) {
    setPage(page - 1);
  }
}
