export default function getCurrentUser(response) {
  const data = {
    role: {
      name: response?.data?.response?.role.name,
      sessions: response?.data?.response?.role?.sessions?.sort((a, b) =>
        a.position > b.position ? 1 : -1
      ),
    },
  };

  return { ...response, userData: data };
}
