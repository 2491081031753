import React from "react";
import { DashboardAmcred } from "../features";

function DashboardAmcredPage() {
  return (
    <>
      <DashboardAmcred />
    </>
  );
}

export default DashboardAmcredPage;
