export default function getAllUserFullname(response) {
  const data = response.data.response.map((item) => {
    return {
      value: item.id,
      label: item.fullName,
    };
  });

  return data;
}
