export default function getFullDetailReport(response) {
  const data = response?.data?.response?.map((item) => {
    return {
      companyCnpj: item?.companyCnpj,
      companyName: item?.companyName,
      userName: item?.userName,
      createAt: item?.createAt,
    };
  });

  return { ...response?.data, list: data };
}
