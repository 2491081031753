import { Container as DefaultContainer } from "@material-ui/core";
import styled from "styled-components";
import { MediaQueries } from "../../config";

const InitialBackground = styled.div(({ $didMount }) => {
  return {
    opacity: $didMount ? 1 : 0,
    transition: "1s",
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "row",
  };
});

const LoggedContainer = styled.div(({ theme, $Image }) => {
  const { palette: colors } = theme;
  return {
    backgroundColor: colors.background.paper,
    display: "flex",
    flexDirection: "column",
    flexWrap: "nowrap",
    flex: 1,
    padding: 0,
    maxWidth: "80vw",
    backgroundImage: $Image,
    backgroundSize: "cover",
    backgroundPosition: "bottom",
    [MediaQueries.smDown]: {
      maxWidth: "100vw",
    },
  };
});

const Container = styled(DefaultContainer)(({ theme, $maxWidth }) => {
  const { breakpoints } = theme;
  return {
    maxWidth: breakpoints.values[$maxWidth],
    flexGrow: 1,
    display: "flex",
    flexDirection: "column",
    padding: 0,
  };
});

const Styles = {
  Container,
  LoggedContainer,
  InitialBackground,
};

export default Styles;
