import styled from "styled-components";
import {
  FontStyles,
  Text,
  ButtonContained,
  ButtonOutlined,
} from "../../../components";
import { Link } from "@material-ui/core";
import { MediaQueries } from "../../../config";

const Container = styled.div(({ theme }) => {
  const { palette: colors } = theme;
  return {
    display: "flex",
    width: "100%",
    height: "100%",
    maxHeight: "100vh",
    backgroundColor: colors.background.secondaryPaper,
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    overflow: "auto",
  };
});

const BackgroundImage = styled.img(() => {
  return {
    display: "flex",
    width: "60%",
    height: "100%",
    alignSelf: "end",
    opacity: 0.1,
    overflow: "hidden",

    [MediaQueries.lgDown]: {
      width: "100%",
    },
  };
});

const Content = styled.div(({ theme }) => {
  const { spacing } = theme;
  return {
    display: "flex",
    position: "absolute",
    flexDirection: "column",
    width: "51.25rem",
    maxWidth: `calc(100% - ${spacing(2)}px)`,
    maxHeight: `calc(100% - ${spacing(3)}px)`,
    backgroundColor: "#FFFFFF",
    borderRadius: 5,
    marginInline: "auto",
    overflowY: "auto",
    padding: spacing(4),
  };
});

const LoginSubtitle = styled(Text)(({ theme }) => {
  const { palette: colors } = theme;
  return {
    color: colors.text.primary,
    ...FontStyles.medium14,
  };
});

const LogoContainer = styled.div(({ theme }) => {
  const { spacing } = theme;
  return {
    width: "100%",
    height: "fit-content",
    alignSelf: "center",
    marginBottom: "23px",
    maxWidth: spacing(19.25),
  };
});

const Header = styled.div(() => {
  return {
    width: "100%",
    height: "fit-content",
    display: "flex",
    alignSelf: "center",
    justifyContent: "center",
  };
});

const SideFooter = styled.div(() => {
  return {
    width: "100%",
    display: "flex",
    alignSelf: "center",
    justifyContent: "space-between",
    flexDirection: "row",
  };
});

const AmcredLogo = styled.img(({ theme }) => {
  const { spacing } = theme;
  return {
    width: spacing(17),
    height: "auto",
  };
});

const Subtitle = styled.p(({ theme }) => {
  const { palette: colors } = theme;
  return {
    color: colors.text.primary,
    ...FontStyles.roman14,
    opacity: 0.88,
    margin: 0,
  };
});

const Version = styled.p(({ theme }) => {
  const { palette: colors, spacing } = theme;
  return {
    alignSelf: "center",
    marginTop: spacing(4),
    color: colors.text.secondary,
    ...FontStyles.bold12,
  };
});

const ImageBG = styled.div(() => {
  return {
    width: "100%",
    height: "100vh",
  };
});

const DivBG = styled.div(({ theme }) => {
  const { spacing } = theme;
  return {
    width: "100%",
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: spacing(9.5),
    flexDirection: "column",
  };
});

const TextBG = styled.p(({ theme }) => {
  const { spacing } = theme;
  return {
    ...FontStyles.bold22,
    fontSize: spacing(6.75),
    margin: 0,
    textAlign: "end",
    color: "white",
    [MediaQueries.mdDown]: {
      fontSize: spacing(4.25),
    },
  };
});

const TextCopyright = styled.p(() => {
  return {
    ...FontStyles.medium12,
    marginTop: -20,
    textAlign: "end",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    color: "white",
  };
});

const TextSelect = styled.p(({ theme }) => {
  const { palette: colors } = theme;
  return {
    ...FontStyles.medium14,
    color: colors.text.secondary,
    textTransform: "uppercase",
    padding: 0,
    margin: 0,
  };
});

const SelectBox = styled.div(() => {
  return {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  };
});

const ConfirmButton = styled(ButtonContained)(({ theme }) => {
  const { spacing } = theme;
  return {
    marginBlock: spacing(2, 1),
  };
});

const AskAccessButton = styled(ButtonOutlined)(({ theme }) => {
  const { spacing } = theme;
  return {
    margin: spacing(1, 0, 3, 0),
  };
});

const HeaderLogo = styled.div(() => {
  return {
    width: "100%",
    display: "flex",
    justifyContent: "center",
  };
});

const Footer = styled.div(() => {
  return {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
  };
});

const HyperLink = styled(Link)(({ theme, $underline }) => {
  const { palette: colors } = theme;
  return {
    ...FontStyles.medium14,
    alignSelf: "flex-end",
    cursor: "pointer",
    textDecoration: $underline ? "underline" : "none",
    color: $underline ? colors.orange : colors.text.primary,
  };
});
const Dot = styled.p(({ theme }) => {
  const { spacing } = theme;
  return {
    ...FontStyles.medium14,
    fontSize: spacing(2.1),
    color: "#D5D5D5",
    padding: 0,
    margin: 0,
    marginInline: spacing(2.5),
  };
});
const LoginType = styled(ButtonContained)(({ theme, isSelected }) => {
  const { spacing } = theme;
  return {
    width: "100%",
    borderRadius: 0,
    borderBottom: isSelected ? "5px solid #00406B" : "5px solid #00000054",
    backgroundColor: !isSelected && "#E8E8E8",
    textTransform: "uppercase",
    ...FontStyles.semibold14,
    color: !isSelected && "#00000054",
    marginBottom: spacing(3),
    boxShadow: "none",
  };
});

const AmcredLogoWhite = styled.div(({ theme }) => {
  const { spacing } = theme;
  return {
    color: "#D5D5D5",
    padding: 0,
    marginBlock: spacing(1),
  };
});

const Line = styled.div(({ theme }) => {
  const { spacing } = theme;
  return {
    display: "block",
    borderBottom: "1px solid #FFCC29",
    marginBlock: spacing(2),
  };
});

const Styles = {
  LoginType,
  HeaderLogo,
  Container,
  Content,
  LogoContainer,
  Version,
  Subtitle,
  Link,
  ImageBG,
  TextBG,
  DivBG,
  TextCopyright,

  Header,
  AmcredLogo,
  LoginSubtitle,
  ConfirmButton,
  AskAccessButton,
  TextSelect,
  SelectBox,
  HyperLink,
  Footer,
  Dot,
  SideFooter,
  AmcredLogoWhite,
  Line,

  BackgroundImage,
};

export default Styles;
