import React from "react";
import { connect } from "react-redux";
import { Styles } from "../styles";

import { CustomText, Pagination, Table } from "../../../components";
import { Fonts, Spacing } from "../../../config";

import {
  Edit,
  Visibility,
  ArrowDropDown,
  LockOpen,
  Lock,
} from "@material-ui/icons";
import { Filters } from "../../../lib";
import ButtonMenuComponent from "../../../components/buttons/ButtonMenu";

function TableUsers({
  isFetching,
  list,
  page,
  setPage,
  totalPages,
  onBlock,
  onUnblock,
  onEdit,
  onView,
  sort,
  lastFilter,
  user,
  setSortKey,
  setSortDirection,
  sortKey,
  sortDirection,
}) {
  const sortContent = (key, orderType) => {
    setSortKey(key);
    setSortDirection(orderType);
    const type = orderType ? "ASC" : "DESC";

    sort(lastFilter, user, key, type);
  };

  return (
    <Styles.Content>
      <Styles.HeaderCard>
        <CustomText fontFamily={Fonts.bold} fontSize={1.75}>
          CADASTROS DO SISTEMA
        </CustomText>
      </Styles.HeaderCard>
      <Styles.Line></Styles.Line>
      <Table
        currentSortKey={sortKey}
        sortDirection={sortDirection}
        sortLast={false}
        headers={{
          table: [
            "NOME DA INSTITUIÇÃO ASSOCIADA",
            "CNPJ",
            "NOME GESTOR",
            "DATA CADASTRO",
            "Ação",
          ],
          keys: ["companyName", "companyCnpj", "requester", "dateRequest"],
        }}
        data={list}
        sortContent={sortContent}
        renderItemColumns={(item) => [
          item.companyName,
          item.companyCnpj,
          item.requester,
          Filters.formatDate(item.dateRequest),

          <div
            style={{
              gap: Spacing(1),
              display: "flex",

              justifyContent: "flex-start",
            }}
          >
            <ButtonMenuComponent
              endIcon={<ArrowDropDown />}
              buttons={
                item?.active
                  ? [
                      {
                        label: "Editar",
                        icon: Edit,
                        onClick: () => onEdit(item),
                      },
                      {
                        label: "Bloquear",
                        icon: Lock,
                        onClick: () => onBlock(item),
                      },

                      {
                        label: "Ver Mais",
                        icon: Visibility,
                        onClick: () => onView(item),
                      },
                    ]
                  : [
                      {
                        label: "Editar",
                        icon: Edit,
                        onClick: () => onEdit(item),
                      },

                      {
                        label: "Desbloquear",
                        icon: LockOpen,
                        onClick: () => onUnblock(item),
                      },
                      {
                        label: "Ver Mais",
                        icon: Visibility,
                        onClick: () => onView(item),
                      },
                    ]
              }
            >
              Opções
            </ButtonMenuComponent>
          </div>,
        ]}
      />
      <Pagination page={page} setPage={setPage} totalPages={totalPages} />
    </Styles.Content>
  );
}

function mapStateToProps(redux) {
  const { isFetching } = redux.registerAndQuery;
  return { isFetching };
}

function mapDispatchToProps(dispatch) {
  return {};
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(React.memo(TableUsers));
