import React from "react";
import { connect } from "react-redux";
import { Styles } from "../styles";

import {
  ButtonText,
  CustomText,
  Input,
  InputEmail,
  Select,
} from "../../../components";
import { Fonts } from "../../../config";
import { Grid } from "@material-ui/core";

import { FormHolder } from "../../../FormConfig";

import { customModal } from "../../modals/utils";
import { GetRoleByName } from "../../../services";

function ModalEdit({ isFetching, roleList, onSubmit, userInfos, user }) {
  const allNames = user?.fullName?.split(" ");
  const firstName = allNames[0];
  allNames.shift();

  return (
    <Styles.ModalContent noPadding>
      <Styles.HeaderModal withPadding>
        <CustomText fontFamily={Fonts.medium} fontSize={1.75}>
          Edite abaixo os dados do usuário selecionado:
        </CustomText>
      </Styles.HeaderModal>
      <Styles.CpfEditModal>
        <CustomText fontFamily={Fonts.bold} fontSize={1.75}>
          CPF:
        </CustomText>
        <CustomText fontFamily={Fonts.medium} fontSize={2}>
          {user?.cpf}
        </CustomText>
      </Styles.CpfEditModal>
      <Styles.InLinePaddingModal>
        <FormHolder onSubmit={onSubmit}>
          <Grid container spacing={1}>
            <Grid item xs={6}>
              <Input
                color={1}
                required={"*Campo Obrigatório"}
                name="firstName"
                inputLabel="NOME "
                defaultValue={firstName}
              />
            </Grid>
            <Grid item xs={6}>
              <Input
                color={1}
                required={"*Campo Obrigatório"}
                name="lastName"
                defaultValue={allNames?.join(" ")}
                inputLabel="SOBRENOME"
              />
            </Grid>

            <Grid item xs={6}>
              <Select
                color={1}
                required={"*Campo Obrigatório"}
                name="roleId"
                defaultValue={user?.role?.id}
                inputLabel="Perfil"
                options={roleList}
              />
            </Grid>
            <Grid item xs={6}>
              <InputEmail
                color={1}
                name="email"
                required={"*Campo Obrigatório"}
                defaultValue={user?.email}
                inputLabel="E-mail"
              />
            </Grid>
          </Grid>
          <Styles.FormButtons>
            <ButtonText
              loading={isFetching}
              fullWidth={false}
              onClick={() => {
                customModal.close();
              }}
              color={GetRoleByName(userInfos?.role?.name)}
            >
              Voltar
            </ButtonText>
            <Styles.FormButton
              loading={isFetching}
              fullWidth={false}
              type="submit"
            >
              SALVAR
            </Styles.FormButton>
          </Styles.FormButtons>
        </FormHolder>
      </Styles.InLinePaddingModal>
    </Styles.ModalContent>
  );
}

function mapStateToProps(redux) {
  const { isFetching } = redux.registerAndQuery;
  return { isFetching };
}

function mapDispatchToProps(dispatch) {
  return {};
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(React.memo(ModalEdit));
