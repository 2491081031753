import React from "react";
import { AccessRequests } from "../features";

function AccessRequestsPage() {
  return (
    <>
      <AccessRequests />
    </>
  );
}

export default AccessRequestsPage;
