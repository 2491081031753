import { call, put } from "redux-saga/effects";
import { Creators } from ".";
import { Alerts } from "../../../lib";

import { api } from "../../../services";

export default function* resetNewPasswordRequest({ data, callback, token }) {
  try {
    const dataRequest = {
      email: data?.email,
      password: data?.password,
    };

    yield call(
      api.resetPasswordRequest,
      { data: dataRequest, token: token },
      token
    );
    if (callback !== undefined) {
      callback();
    }
    yield put(Creators.resetNewPasswordFinished());
    yield put(Creators.resetPasswordClearData());
  } catch (response) {
    Alerts.alertError(
      response?.data?.log
        ? response?.data?.log
        : "Não foi possível alterar suas senha."
    );
    yield put(Creators.resetNewPasswordFailure());
  }
}
