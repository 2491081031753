import { call, put } from "redux-saga/effects";
import { api } from "../../../services";
import { Creators } from "./index";

import { Alerts } from "../../../lib";

export default function* editQueries({ data, callback }) {
  try {
    const requestData = {
      id: data?.id,
      cpfOrCnpj: data?.cpfOrCnpj,
      signalCreditRelease:
        data?.signalCreditReleaseId !== null
          ? parseInt(data?.signalCreditReleaseId)
          : 2,
    };
    yield call(api.sendAnalysisResponse, requestData);

    yield put(Creators.editQueriesSuccess());
    callback();
  } catch (response) {
    Alerts.alertError(
      response?.data?.log
        ? response?.data?.log
        : "Não foi possível modificar a liberação"
    );

    yield put(Creators.editQueriesFailure());
  }
}
