import { call, put } from "redux-saga/effects";
import { api } from "../../../services";
import { Creators } from "./index";
import { Alerts } from "../../../lib";

export default function* getValuesInstitutionDatail({ data, reportType }) {
  try {
    const companiesIds = data?.searchParameters?.companies?.map((item) => {
      return item.value;
    });
    let newData = {};
    if (reportType === 0) {
      newData = {
        begin: !data?.searchParameters?.begin
          ? null
          : data?.searchParameters?.begin,
        end: !data?.searchParameters?.end ? null : data?.searchParameters?.end,
        cnpj:
          !data?.searchParameters?.cnpj || data?.searchParameters?.cnpj === "-1"
            ? null
            : data?.searchParameters?.cnpj,
        companies: companiesIds?.length > 0 ? companiesIds : null,
      };
    } else {
      newData = {
        begin: !data?.searchParameters?.begin
          ? null
          : data?.searchParameters?.begin,
        end: !data?.searchParameters?.end ? null : data?.searchParameters?.end,

        userIds:
          data?.searchParameters?.userIds?.length > 0
            ? data?.searchParameters?.userIds?.map((item) => item.value)
            : null,
        roleId: !data?.searchParameters?.roleId
          ? null
          : data?.searchParameters?.roleId,
      };
    }

    const response = yield call(api.getValuesInstitutionDatail, newData);

    yield put(
      Creators.getValuesInstitutionDatailSuccess(response?.data?.response)
    );
  } catch (response) {
    Alerts.alertError(
      response?.data?.log
        ? response?.data?.log
        : "Não foi possível encontrar as solicitações de pesquisa de relatório"
    );
    yield put(Creators.getValuesInstitutionDatailFailure());
  }
}
