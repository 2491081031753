import Home from "./Home";
import Login from "./Login";
import MyPerfil from "./Profile";

import ForgotPasswordPage from "./ForgotPassword";
import RegisterAndQuery from "./RegisterAndQuery";
import AccessRequests from "./AccessRequests";

import RegisterAndQueryInstitution from "./RegisterAndQueryInstitution";

import DashboardAmcred from "./DashboardAmcred";
import DashboardInstitutions from "./DashboardInstitutions";
import GeneralReport from "./GeneralReport";
import DetailReport from "./DetailReport";

import RegisterAndQueryAssociated from "./RegisterAndQueryAssociated";
import CreditAnalysis from "./CreditAnalysis";
import QueriesHistory from "./QueriesHistory";

import RegisterRequest from "./RegisterRequest";
import NotFound from "./NotFound";
const pages = {
  Home,
  Login,
  ForgotPasswordPage,
  RegisterAndQuery,
  MyPerfil,
  AccessRequests,
  DashboardAmcred,
  DashboardInstitutions,
  RegisterAndQueryAssociated,
  GeneralReport,
  DetailReport,
  CreditAnalysis,
  QueriesHistory,
  RegisterRequest,
  RegisterAndQueryInstitution,
  NotFound,
};
export default pages;
