import styled from "styled-components";
import { FontStyles, Text, ButtonContained, Input } from "../../../components";
import CopyrightIcon from "@material-ui/icons/Copyright";
import { Link } from "@material-ui/core";
import { MediaQueries } from "../../../config";
import { Info } from "@material-ui/icons";
import fonts from "../../../config/fonts";
const Container = styled.div(({ theme }) => {
  const { palette: colors, spacing } = theme;
  return {
    display: "flex",
    width: "100%",
    height: "100%",
    maxHeight: "100vh",
    backgroundColor: colors.background.paper,
    flexDirection: "column",
    padding: spacing(2, 3, 2, 3),
    justifyContent: "space-evenly",
    overflow: "auto",
  };
});

const Content = styled.div(({ theme }) => {
  const { spacing, palette: colors } = theme;
  return {
    display: "flex",
    position: "relative",
    justifyContent: "center",
    flexDirection: "column",
    width: "100%",

    maxWidth: spacing(87.5),
    maxHeight: spacing(81.125),
    backgroundColor: colors.background.default,
    borderRadius: 5,
    marginInline: "auto",
    overflowY: "auto",
    padding: spacing(4),
  };
});

const LoginSubtitle = styled(Text)(({ theme }) => {
  const { spacing, palette: colors } = theme;
  return {
    marginBottom: spacing(5),
    color: colors.text.primary,
    ...FontStyles.medium14,
  };
});

const LogoContainer = styled.div(({ theme }) => {
  return {
    width: "100%",
    height: "fit-content",
    alignSelf: "center",
    marginBottom: "23px",
  };
});
const LabelContainer = styled.div(({ theme }) => {
  const { spacing } = theme;
  return {
    marginTop: spacing(0),
    marginBottom: spacing(1),
    marginLeft: spacing(4),
    width: "100%",
    display: "flex",
  };
});
const InputCodeLabel = styled(Text)(({ theme }) => {
  const { spacing, palette: colors } = theme;
  return {
    ...FontStyles.bold16,
    color: colors.background.secondary,
    fontSize: spacing(2),
  };
});

const InputCode = styled(Input)(({ theme }) => {
  return {
    width: "100%",
    height: "80%",
    color: "#464646",
    "& .MuiInput-input": {
      fontSize: 30,
      marginLeft: 10,
      textTransform: "uppercase",
    },
  };
});

const AmcredLogo = styled.img(({ theme }) => {
  const { spacing } = theme;
  return {
    width: spacing(17),
    height: "auto",
  };
});
const SubLogo = styled.p(({ theme }) => {
  const { spacing } = theme;
  return {
    ...FontStyles.bold16,
    marginInline: spacing(0),

    letterSpacing: 9,
    fontSize: "0.8rem",
    color: "#ACCA13",
    marginTop: spacing(-2),
  };
});

const Subtitle = styled.p(({ theme }) => {
  const { palette: colors } = theme;
  return {
    color: colors.text.primary,
    ...FontStyles.roman14,
    opacity: 0.88,
    margin: 0,
  };
});

const ImageBG = styled.div(({ theme, $Image }) => {
  return {
    width: "100%",
    height: "100vh",
    backgroundImage: "linear-gradient(45deg, #57EB64, #0088FF)",
  };
});

const ContainerTextBG = styled.div(({ theme }) => {
  const { spacing } = theme;
  return {
    width: "100%",
    height: "100%",
    display: "flex",
    alignItems: "flex-end",
    justifyContent: "flex-end",
    padding: spacing(9.5),
    flexDirection: "column",
    color: "white",
  };
});
const Header = styled.div(() => {
  return {
    width: "100%",
    height: "fit-content",
    display: "flex",
    alignSelf: "center",
    justifyContent: "center",
  };
});
const TextBG = styled.p(({ theme }) => {
  const { spacing } = theme;
  return {
    ...FontStyles.bold22,
    fontSize: spacing(6.75),
    margin: 0,
    textAlign: "end",
    color: "white",
    [MediaQueries.mdDown]: {
      fontSize: spacing(4.25),
    },
  };
});
const Copyright = styled(CopyrightIcon)(({ theme }) => {
  const { palette: colors, spacing } = theme;
  return {
    color: colors.text.tertiary,
    width: spacing(2),
    marginLeft: spacing(1.5),
  };
});
const ConfirmButton = styled(ButtonContained)(({ theme }) => {
  const { spacing } = theme;
  return {
    marginBlock: spacing(5, 1),
  };
});

const TextCopyright = styled.p(({ theme }) => {
  return {
    ...FontStyles.medium14,
    margin: 0,
    textAlign: "end",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    color: "white",
  };
});

const HeaderLogo = styled.div(({ theme }) => {
  return {
    width: "100%",
    display: "flex",
    justifyContent: "center",
  };
});

const Footer = styled.div(({ theme }) => {
  return {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
  };
});

const HyperLink = styled(Link)(({ theme, $underline }) => {
  const { palette: colors } = theme;
  return {
    ...FontStyles.medium14,
    alignSelf: "flex-end",
    cursor: "pointer",
    textDecoration: $underline ? "underline" : "none",
    color: $underline ? colors.text.primary : colors.text.secondary,
  };
});
const Dot = styled.p(({ theme }) => {
  const { spacing } = theme;
  return {
    ...FontStyles.medium14,
    fontSize: spacing(2.1),
    color: "#D5D5D5",
    padding: 0,
    margin: 0,
    marginInline: spacing(2.5),
  };
});

const InfoIcon = styled(Info)(({ theme }) => {
  const { spacing } = theme;
  return {
    width: spacing(1.75),
    height: spacing(1.75),
    padding: 0,
    marginRight: spacing(0.5),
  };
});
const MarginBottom = styled.div(({ theme, center }) => {
  const { spacing } = theme;
  return {
    display: "flex",
    width: "100%",
    justifyContent: center ? "center" : "flex-start",
    alignItems: center ? "center" : "flex-start",
    marginBottom: spacing(3.5),
  };
});
const InputBottom = styled.div(({ theme, center }) => {
  return {
    display: "flex",
    flexDirection: "column",
    width: "max-content",
    justifyContent: "center",
    alignItems: "flex-start",
  };
});
const LineBottom = styled.div(({ theme, center }) => {
  const { spacing, palette: colors } = theme;
  return {
    display: "flex",
    width: "100%",
    justifyContent: center ? "center" : "flex-start",
    alignItems: center ? "center" : "flex-start",
    marginBottom: spacing(3.5),
    borderTop: `1px solid ${colors?.background?.tertiary}`,
    marginTop: spacing(1),
  };
});

const Countdown = styled.p(({ theme }) => {
  const { palette: colors } = theme;
  return {
    width: "100%",

    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: colors.text.secondary,
    ...FontStyles.medium12,
    fontFamily: fonts.regular,
  };
});

const ReSend = styled(Link)(({ theme, disabled }) => {
  const { palette: colors } = theme;
  return {
    width: "100%",
    textAlign: "center",
    cursor: !disabled ? "pointer" : "default",
    color: colors.text.primary,
    opacity: disabled ? 0.6 : 1,

    textDecoration: "underline",
    ...FontStyles.medium14,
  };
});

const Styles = {
  ReSend,
  MarginBottom,
  InfoIcon,
  Dot,
  HyperLink,
  HeaderLogo,
  Footer,
  Copyright,
  TextCopyright,
  Container,
  Content,
  LogoContainer,
  InputCodeLabel,
  Subtitle,
  ImageBG,
  TextBG,
  ContainerTextBG,
  AmcredLogo,
  SubLogo,
  LoginSubtitle,
  ConfirmButton,
  InputCode,
  LabelContainer,
  Countdown,
  LineBottom,
  InputBottom,
  Header,
};

export default Styles;
