import createReducers from "../../../store/helpers/createPageReducer";
import getAccessRequestAmcred from "./getAccessRequestAmcred";
import refuseAccessAmcred from "./refuseAccessAmcred";
import acceptAccessAmcred from "./acceptAccessAmcred";

const { Creators, reducers, sagas } = createReducers(
  [
    {
      name: "getAccessRequestAmcred",
      params: ["data"],
      function: (state, { data }) => ({
        ...state,
        isFetching: true,
        lastFilter: data,
      }),
      sagaFunction: getAccessRequestAmcred,
    },
    {
      name: "getAccessRequestAmcredSuccess",
      params: ["accessList"],

      function: (state, { accessList }) => ({
        ...state,
        isFetching: false,
        accessList,
      }),
    },
    {
      name: "getAccessRequestAmcredFailure",
      function: (state) => ({ ...state, isFetching: false }),
    },

    {
      name: "refuseAccessAmcred",
      params: ["data", "callback"],
      function: (state, { data }) => ({
        ...state,
        isFetching: true,
        lastFilter: data,
      }),
      sagaFunction: refuseAccessAmcred,
    },
    {
      name: "refuseAccessAmcredSuccess",

      function: (state, { accessList }) => ({
        ...state,
        isFetching: false,
      }),
    },
    {
      name: "refuseAccessAmcredFailure",
      function: (state) => ({ ...state, isFetching: false }),
    },

    {
      name: "acceptAccessAmcred",
      params: ["data", "callback"],
      function: (state, { data }) => ({
        ...state,
        isFetching: true,
        lastFilter: data,
      }),
      sagaFunction: acceptAccessAmcred,
    },
    {
      name: "acceptAccessAmcredSuccess",

      function: (state, { accessList }) => ({
        ...state,
        isFetching: false,
      }),
    },
    {
      name: "acceptAccessAmcredFailure",
      function: (state) => ({ ...state, isFetching: false }),
    },
  ],
  {
    isFetching: false,
    accessList: null,
    lastFilter: null,
  }
);

export { Creators, reducers, sagas };
