import React from "react";
import { navigate } from "../../../navigation/navigate";
import Styles from "../styles/Styles";
import { customModal } from "../../modals/utils";

import { PagesConfig, Texts } from "../../../config";
import { connect } from "react-redux";
import { GetRoleByName } from "../../../services";

function MenuGroupWithPagesComponent({
  item,
  firstItem,
  headerName,
  globalLogout,
  userInfos,

  pathname,
  user,
}) {
  const classes = Styles.useStyles();

  const texts = Texts["pt-BR"];

  function handleLogout() {
    customModal.setInfos(
      texts.leave.logoutModal.exitTitle,
      [texts.leave.logoutModal.exitConfirmationText],
      {
        label: texts.leave.logoutModal.exitConfirmation,
        onClick: () => {
          customModal.close();

          globalLogout();
        },
      },
      {
        label: texts.leave.logoutModal.back,
        onClick: () => {
          customModal.close();
        },
      },
      null,
      null,
      null,
      GetRoleByName(userInfos?.role?.name)
    );
  }

  const goto = (path, title) => {
    let link;
    if (!isDisabled(path) === false) {
      if (title === "Sair") {
        handleLogout();
      } else {
        navigate(path);
      }
    } else {
      link = null;
    }
    return link;
  };

  function isDisabled(path) {
    let a = false;
    PagesConfig.logged.map((item) => {
      return item.pages.map((element) => {
        if (path === element.path) {
          if (element.working !== (undefined || null)) {
            a = element.working;
          } else {
            a = false;
          }
        }
        return element;
      });
    });
    return a;
  }

  return (
    <Styles.Group $firstOne={firstItem}>
      <Styles.GroupTitleContainer>
        <Styles.GroupNameContainer>
          <Styles.GroupTitle>{headerName}</Styles.GroupTitle>
        </Styles.GroupNameContainer>
      </Styles.GroupTitleContainer>
      <Styles.SubGroup>
        {item.map((page, pageIndex) => {
          return (
            <Styles.PageLine
              key={pageIndex + page?.name}
              $currentPage={page.path === pathname}
            >
              <Styles.Page
                $buttonContained={false}
                $currentPage={page.path === pathname}
                $disabled={!isDisabled(page.path)}
                key={page.name + pageIndex}
                onClick={() => goto(page.path, page.title)}
              >
                <Styles.PageContent $currentPage={page.path === pathname}>
                  {page.icon ? (
                    <img alt="" src={page.icon} className={classes.icon} />
                  ) : null}
                  {page.title}
                </Styles.PageContent>
              </Styles.Page>
              {/*    {page?.subPages &&
                page?.subPages.map((subPag, subKey) => {
                  return (
                    <Styles.Page
                      $buttonContained={false}
                      $currentPage={page.path === pathname && subKey === 0}
                      $disabled={!isDisabled(page.path) || subKey > 0}
                      key={page.name + pageIndex}
                      onClick={() => goto(page.path, page.title)}
                    >
                      <div style={{ paddingLeft: Spacing(2) }}>
                        <CustomText
                          styles={{
                            paddingBlock: Spacing(0.9),
                            borderBottom:
                              page.path === pathname &&
                              subKey === 0 &&
                              "2px solid #ffffff",
                          }}
                          fontFamily={fonts.medium}
                          fontSize={1.5}
                          textColor={Themes.AMCRED.palette.text.primary}
                        >
                          {subPag.name}
                        </CustomText>
                      </div>
                    </Styles.Page>
                  );
                })} */}
            </Styles.PageLine>
          );
        })}
      </Styles.SubGroup>
    </Styles.Group>
  );
}

function mapStateToProps({ global }) {
  const { userInfos } = global;
  return {
    userInfos,
  };
}

export default connect(mapStateToProps)(
  React.memo(MenuGroupWithPagesComponent)
);
