import { reducers as globalReducers } from "./globalReduxSagas";
import { reducers as loginReducers } from "./login";

import { reducers as recoveryPasswordReducers } from "./forgotPassword";
import { reducers as registerAndQueryReducers } from "./registerAndQuery";
import { reducers as profileReducers } from "./profile";
import { reducers as accessRequestsReducers } from "./accessRequests";
import { reducers as dashboardAmcredReducers } from "./dashboardAmcred";

import { reducers as dashboardInstitutionsReducers } from "./dashboardInstitutions";

import { reducers as registerAndQueryAssociatedReducers } from "./registerAndQueryAssociated";
import { reducers as creditAnalysisReducers } from "./creditAnalysis";
import { reducers as queriesHistoryReducers } from "./queriesHistory";

import { reducers as registerRequestReducers } from "./registerRequest";

import { reducers as registerAndQueryInstitutionReducers } from "./registerAndQueryInstitution";

import { reducers as generalReportReducers } from "./generalReport";
import { reducers as detailReportReducers } from "./detailReport";
const reducers = {
  global: globalReducers,
  login: loginReducers,
  recoveryPassword: recoveryPasswordReducers,
  registerAndQuery: registerAndQueryReducers,
  registerAndQueryInstitution: registerAndQueryInstitutionReducers,
  profile: profileReducers,
  accessRequests: accessRequestsReducers,
  dashboardAmcred: dashboardAmcredReducers,
  dashboardInstitutions: dashboardInstitutionsReducers,
  registerAndQueryAssociated: registerAndQueryAssociatedReducers,
  creditAnalysis: creditAnalysisReducers,
  registerRequest: registerRequestReducers,
  queriesHistory: queriesHistoryReducers,
  generalReport: generalReportReducers,
  detailReport: detailReportReducers,
};

export default reducers;
