import React from "react";
import { connect } from "react-redux";
import { Styles } from "../styles";

import { CustomText, InputAutoComplete } from "../../../components";
import { Fonts } from "../../../config";
import { Grid } from "@material-ui/core";
import {
  Delete,
  KeyboardArrowDown,
  KeyboardArrowUp,
  Search,
} from "@material-ui/icons";
import { FormHolder } from "../../../FormConfig";
import { GetRoleByName } from "../../../services";

function Filters({
  isFetching,
  roleList,
  onSubmit,
  onClear,
  filterAssociatedUsers,
  userInfos,
}) {
  const [show, setShow] = React.useState(true);
  const [ref, setRef] = React.useState(null);

  return (
    <Styles.Content>
      <Styles.HeaderCard>
        <CustomText fontFamily={Fonts.bold} fontSize={1.75}>
          PESQUISAR CADASTROS
        </CustomText>
        <Styles.Hide onClick={() => setShow(!show)}>
          {show ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
        </Styles.Hide>
      </Styles.HeaderCard>
      <Styles.Line></Styles.Line>
      {show && (
        <>
          <FormHolder formRef={setRef} onSubmit={onSubmit}>
            <Grid container spacing={1}>
              <Grid item xs={6} sm={6} md={3}>
                <InputAutoComplete
                  options={
                    filterAssociatedUsers?.names
                      ? filterAssociatedUsers?.names
                      : []
                  }
                  name="fullName"
                  inputLabel="Nome Completo"
                  color={GetRoleByName(userInfos?.role?.name)}
                />
              </Grid>
              <Grid item xs={6} sm={6} md={3}>
                <InputAutoComplete
                  options={
                    filterAssociatedUsers?.cpfs
                      ? filterAssociatedUsers?.cpfs
                      : []
                  }
                  name="cpf"
                  inputLabel="CPF"
                  color={GetRoleByName(userInfos?.role?.name)}
                />
              </Grid>
              <Grid item xs={6} sm={6} md={3}>
                <InputAutoComplete
                  name="officeId"
                  inputLabel="Perfil"
                  options={
                    filterAssociatedUsers?.roleNames
                      ? filterAssociatedUsers?.roleNames
                      : []
                  }
                  color={GetRoleByName(userInfos?.role?.name)}
                />
              </Grid>
              <Grid item xs={6} sm={6} md={3}>
                <InputAutoComplete
                  options={
                    filterAssociatedUsers?.emails
                      ? filterAssociatedUsers?.emails
                      : []
                  }
                  name="email"
                  inputLabel="E-mail"
                  color={GetRoleByName(userInfos?.role?.name)}
                />
              </Grid>
            </Grid>
            <Styles.FormButtons>
              <Styles.FormButtonOutlined
                startIcon={<Delete />}
                fullWidth={false}
                type="clear"
                onClick={() => {
                  ref?.clearAllValues(true);
                  onClear();
                }}
              >
                Limpar
              </Styles.FormButtonOutlined>
              <Styles.FormButton
                startIcon={<Search />}
                fullWidth={false}
                type="submit"
              >
                Pesquisar
              </Styles.FormButton>
            </Styles.FormButtons>
          </FormHolder>
        </>
      )}
    </Styles.Content>
  );
}

function mapStateToProps(redux) {
  const { isFetching } = redux.registerAndQuery;
  return { isFetching };
}

function mapDispatchToProps(dispatch) {
  return {};
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(React.memo(Filters));
