const ELEMENTS = {
  IDS: {
    reactContainer: "root",
    applicationDefaultScroll: "scroll-container",
    headerFilterModal: "header-filters-modal",
  },
  CLASSES: {
    headerFilterModalVisible: "show-header-filters",
  },
};

const HML = true; // true = Prod , False = Dev

const Constants = {
  ELEMENTS,
  HML,
};

export default Constants;
