import { call, put } from "redux-saga/effects";
import { api } from "../../../services";
import { Creators } from "./index";

import { Alerts } from "../../../lib";

export default function* blockUserAssociated({ data, callback }) {
  try {
    yield call(api.blockUserAmcred, data);

    yield put(Creators.newUserAssociatedSuccess());

    callback();
  } catch (response) {
    Alerts.alertError(
      response?.data?.log
        ? response?.data?.log
        : "Não foi possível bloquear o usuário",
      1
    );

    yield put(Creators.newUserAssociatedFailure());
  }
}
