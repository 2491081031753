import {
  Button,
  CircularProgress,
  Fade,
  ListItemIcon,
  makeStyles,
  Menu,
  MenuItem,
} from "@material-ui/core";
import React from "react";
import styled from "styled-components";
import { Colors, Spacing } from "../../config";

import FontStyle from "../styles/fontsStyles";

const StyledButton = styled(Button)(({ theme, $color }) => {
  const { spacing, palette: colors } = theme;

  return {
    display: "flex",
    height: "fit-content",
    alignItems: "center",
    justifyContent: "center",
    padding: spacing(0.9, 2),
    flexShrink: 0,
    lineHeight: spacing(2) + "px",
    overflow: "hidden",
    textTransform: "uppercase",
    backgroundColor:
      $color === 2 ? Colors.carbon : $color === 1 ? Colors.orange : Colors.red,
    color: "white",
    ...FontStyle.semibold12,
    ".MuiButton-startIcon": {
      fill: colors.primary.contrastText,
      width: 17,
    },
    "&:disabled": {
      backgroundColor: colors.lightGray,
    },
    ":hover": {
      backgroundColor:
        $color === 2
          ? Colors.carbon
          : $color === 1
          ? Colors.carbon
          : Colors.carbon,
    },
  };
});
const StyledButtonList = styled(MenuItem)(({ theme, $alternative, $color }) => {
  const { spacing, palette: colors } = theme;
  return {
    display: "flex",
    height: "fit-content",
    alignItems: "center",
    justifyContent: " flex-end",
    padding: spacing(0.9, 2),
    flexShrink: 0,
    lineHeight: spacing(2) + "px",
    overflow: "hidden",
    textTransform: "uppercase",
    color: $color === 1 ? colors.orange : colors.red,
    ...FontStyle.semibold12,

    "&.Mui-focusVisible": {
      backgroundColor: "#efeded",
    },
    "&:hover": {
      backgroundColor: "#efeded",
    },
  };
});

const StyledCircularProgress = styled(CircularProgress)(({ theme, $show }) => {
  const { palette: colors } = theme;
  return {
    position: "absolute",
    color: colors.primary.main,
    transition: ".5s",
    opacity: $show ? 1 : 0,
  };
});

const ChildrenContainer = styled.div(({ $show }) => {
  return {
    transition: ".5s",
    opacity: $show ? 1 : 0.5,
  };
});
const Icon = styled(ListItemIcon)(({ theme, $color }) => {
  const { palette: colors, spacing } = theme;
  return {
    minWidth: 0,
    paddingLeft: spacing(1),
    color: $color === 1 ? colors.orange : colors.red,
  };
});

const useStyles = makeStyles((theme) => {
  const { palette: colors } = theme;
  return {
    menuPaper: {
      backgroundColor: colors.background.default,
      boxShadow: "0px 0px 10px #00000010",
    },
  };
});
function ButtonMenuComponent({
  fullWidth = false,
  loading,
  children,
  buttons,
  disabled,
  name,
  color,

  endIcon,
  ...props
}) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const classes = useStyles();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  function ordenarPorLabel(lista, chave) {
    return lista.sort((a, b) => {
      const labelA = a[chave].toUpperCase();
      const labelB = b[chave].toUpperCase();

      if (labelA < labelB) {
        return -1;
      }
      if (labelA > labelB) {
        return 1;
      }

      return 0;
    });
  }
  return (
    <div>
      <StyledButton
        endIcon={endIcon}
        variant="contained"
        type={null}
        fullWidth={fullWidth}
        disabled={disabled}
        {...props}
        onClick={handleClick}
        $color={color}
      >
        <StyledCircularProgress size={Spacing(3)} $show={loading} />
        <ChildrenContainer $show={!loading}>{children}</ChildrenContainer>
      </StyledButton>
      <Menu
        classes={{ paper: classes.menuPaper }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        TransitionComponent={Fade}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        elevation={0}
        getContentAnchorEl={null}
      >
        {buttons?.length > 0 ? (
          ordenarPorLabel(buttons, "label")?.map((item) => (
            <StyledButtonList
              $color={color}
              style={{
                paddingInline: Spacing(1),
              }}
              onClick={() => {
                item?.onClick();
              }}
            >
              {item?.label}
              {item?.icon && (
                <Icon $color={color}>
                  <item.icon fontSize="small" />
                </Icon>
              )}
            </StyledButtonList>
          ))
        ) : (
          <StyledButtonList
            $color={color}
            style={{
              paddingInline: Spacing(1),
            }}
            onClick={() => {}}
          >
            Sem opções
          </StyledButtonList>
        )}
      </Menu>
    </div>
  );
}

export default ButtonMenuComponent;
