import { Tooltip } from "@material-ui/core";
import { Info } from "@material-ui/icons";
import { makeStyles } from "@material-ui/styles";
import React from "react";
import { FontStyles } from "../../../components";
import { PagesConfig, Spacing } from "../../../config";
import colors from "../../../config/colors";
import { GetRoleByName } from "../../../services";

import { NavigationButtonsStyles as Styles } from "../styles";

function NavigationInfos({ pathname, userInfos }) {
  const color = GetRoleByName(userInfos?.role?.name);
  const useStylesBootstrap = makeStyles((theme) => ({
    tooltip: {
      backgroundColor:
        color === 1 ? "#FFF4EC" : theme.palette.text.secondary + "30",
      boxShadow: " 0px 3px 6px #00000029",
      borderRadius: "5px",
      color: theme.palette.text.primary,
      ...FontStyles.medium14,
      maxWidth: theme.spacing(75),
      padding: theme.spacing(1.5),
    },
  }));
  const classes = useStylesBootstrap();

  const getTooltip = (page) => {
    const iPage = PagesConfig.logged[0]?.pages?.find(
      (i) => i?.path === page?.path
    );
    if (iPage?.tooltip) {
      return (
        <Tooltip
          classes={classes}
          style={{
            backgroundColor: "red",
            "& .MuiTooltip-tooltip": {
              backgroundColor: "blue",
            },
          }}
          placement="bottom-start"
          title={iPage?.tooltip}
        >
          <Info
            style={{
              marginLeft: Spacing(1),
              color:
                color === 0
                  ? colors?.red
                  : color === 1
                  ? colors?.orange
                  : colors.carbon,
            }}
          />
        </Tooltip>
      );
    } else {
      return null;
    }
  };
  return (
    <Styles.Container>
      {userInfos?.role?.sessions?.map((item, index) => {
        return item?.pages?.map((page, index) => {
          const initial = pathname.split("/");

          return page?.path === "/" + initial[1] ? (
            <Styles.Content key={"nav_" + index + page?.name}>
              {page?.icon ? <Styles.ImageIcon alt="" src={page?.icon} /> : null}
              {page?.title}
              {page?.subPages?.length > 0 && " - " + page?.subPages[0]?.name}
              {getTooltip(page)}
            </Styles.Content>
          ) : null;
        });
      })}
    </Styles.Container>
  );
}

export default NavigationInfos;
