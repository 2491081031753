import createReducers from "../../../store/helpers/createPageReducer";
import blockUserAmcred from "./blockUserAmcred";
import editUserInstitution from "./editUserInstitution";
import searchUsersAmcred from "./searchUsersAmcred";
import unblockUserAmcred from "./unblockUserAmcred";

const { Creators, reducers, sagas } = createReducers(
  [
    {
      name: "searchUsersInstitution",
      params: ["data"],
      function: (state, { data }) => ({
        ...state,
        isFetching: true,
        lastFilter: data,
      }),
      sagaFunction: searchUsersAmcred,
    },
    {
      name: "searchUsersInstitutionSuccess",
      params: ["institutionList"],

      function: (state, { institutionList }) => ({
        ...state,
        isFetching: false,
        institutionList,
      }),
    },
    {
      name: "searchUsersInstitutionFailure",
      function: (state) => ({ ...state, isFetching: false }),
    },

    {
      name: "editUserInstitution",
      params: ["data", "callback"],
      function: (state) => ({
        ...state,
        isFetching: true,
      }),
      sagaFunction: editUserInstitution,
    },
    {
      name: "editUserInstitutionSuccess",
      function: (state) => ({
        ...state,
        isFetching: false,
      }),
    },
    {
      name: "editUserInstitutionFailure",
      function: (state) => ({ ...state, isFetching: false }),
    },

    {
      name: "blockUserInstitution",
      params: ["data", "callback"],
      function: (state) => ({ ...state, isFetching: false }),
      sagaFunction: blockUserAmcred,
    },
    {
      name: "unblockUserInstitution",
      params: ["data", "callback"],
      function: (state) => ({ ...state, isFetching: false }),
      sagaFunction: unblockUserAmcred,
    },
    {
      name: "blockUserInstitutionSuccess",
      function: (state) => ({
        ...state,
        isFetching: false,
      }),
    },
    {
      name: "blockUserInstitutionFailure",
      function: (state) => ({ ...state, isFetching: false }),
    },
  ],
  {
    isFetching: false,
    institutionList: null,
    lastFilter: null,
  }
);

export { Creators, reducers, sagas };
