import { call, put } from "redux-saga/effects";
import { api } from "../../../services";
import { Creators } from "./index";

import { Alerts } from "../../../lib";

export default function* editUserInstitution({ data, callback }) {
  try {
    yield call(api.editUserInstitution, data);

    yield put(Creators.editUserInstitutionSuccess());

    callback();
  } catch (response) {
    Alerts.alertError(
      response?.data?.log
        ? response?.data?.log
        : "Erro ao cadastrar novo usuário"
    );

    yield put(Creators.editUserInstitutionFailure());
  }
}
