export default function getProfessionals(response) {
  const data = response?.data?.response?.elements?.map((item) => {
    return {
      id: item.id,
      name: item?.user?.firstName,
      lastName: item?.user?.lastName,
      cpf: item?.user?.cpf,
      registry: item?.professionalRecord,
      unity: item?.unity,
      phone: item?.user?.telephone,
      susCard: item?.susCardNumber,
      others: {
        ...item?.user,
      },
      address: item?.address,
      profession: item?.profession,
    };
  });

  return { ...response, list: data };
}
