import { call, put } from "redux-saga/effects";
import { api } from "../../../services";
import { Creators } from "./index";
import { Alerts } from "../../../lib";

export default function* getLastAnalysis() {
  try {
    const response = yield call(api.getLastAnalysis);

    yield put(Creators.getLastAnalysisSuccess(response?.data?.response));
    if (Boolean(response?.data?.response)) {
      Alerts.alertWarning("Existe uma analise pendente!");
    }
  } catch (response) {
    Alerts.alertError("Erro ao recuperar ultima analise no sistema. ");
    yield put(Creators.getLastAnalysisFailure());
  }
}
