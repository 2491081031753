import { call, put } from "redux-saga/effects";
import { api } from "../../../services";
import { Creators } from "./index";

import { Alerts } from "../../../lib";

export default function* searchUsersAssociated({ data }) {
  try {
    let filters = data;
    if (filters?.searchParameters) {
      filters.searchParameters = {
        fullName:
          data?.searchParameters?.fullName === "-1"
            ? null
            : data?.searchParameters?.fullName,
        cpf:
          data?.searchParameters?.cpf === "-1"
            ? null
            : data?.searchParameters?.cpf,
        roleId:
          data?.searchParameters?.officeId === "-1"
            ? null
            : parseInt(data?.searchParameters?.officeId),
        email:
          data?.searchParameters?.email === "-1"
            ? null
            : data?.searchParameters?.email,
      };
    }

    const response = yield call(api.searchUsersAssociated, filters);

    yield put(Creators.searchUsersAssociatedSuccess(response?.data));
  } catch (response) {
    Alerts.alertError(
      response?.data?.log
        ? response?.data?.log
        : "Erro ao encontrar lista de usuários",
      1
    );

    yield put(Creators.searchUsersAssociatedFailure());
  }
}
