import React from "react";
import { connect } from "react-redux";
import { Styles } from "../styles";

import { ButtonText, CustomText } from "../../../components";
import { Fonts } from "../../../config";
import { Grid } from "@material-ui/core";

import { customModal } from "../../modals/utils";

function ModalBlock({
  isFetching,
  rolesList,
  onSubmit,
  user,
  viewOnly,
  block,
  rolesAssociatedList,
}) {
  const fullName = user?.fullName.split(" ");
  const name = fullName[0];
  fullName.shift();
  const lastName = fullName.join(" ");

  return (
    <Styles.ModalContent>
      <Styles.HeaderModal>
        <CustomText fontFamily={Fonts.medium} fontSize={1.75}>
          {viewOnly
            ? "Cadastro de usuário realizado com sucesso!"
            : block
            ? "Confirmar o bloqueio de acesso deste usuário?"
            : "Confirmar o desbloqueio de acesso deste usuário?"}
        </CustomText>
      </Styles.HeaderModal>

      <Styles.UserInfo>
        <Grid container spacing={1}>
          {viewOnly ? (
            <>
              <Grid item xs={6}>
                <Styles.UserInfoCard>
                  <CustomText fontFamily={Fonts.bold} fontSize={1.75}>
                    NOME USUÁRIO
                  </CustomText>
                  <CustomText fontFamily={Fonts.medium} fontSize={2}>
                    {`${name}`}
                  </CustomText>
                </Styles.UserInfoCard>
              </Grid>
              <Grid item xs={6}>
                <Styles.UserInfoCard>
                  <CustomText fontFamily={Fonts.bold} fontSize={1.75}>
                    SOBRENOME USUÁRIO
                  </CustomText>
                  <CustomText fontFamily={Fonts.medium} fontSize={2}>
                    {`${lastName}`}
                  </CustomText>
                </Styles.UserInfoCard>
              </Grid>
            </>
          ) : (
            <Grid item xs={6}>
              <Styles.UserInfoCard>
                <CustomText fontFamily={Fonts.bold} fontSize={1.75}>
                  NOME COMPLETO
                </CustomText>
                <CustomText fontFamily={Fonts.medium} fontSize={2}>
                  {`${name} ${lastName}`}
                </CustomText>
              </Styles.UserInfoCard>
            </Grid>
          )}

          <Grid item xs={6}>
            <Styles.UserInfoCard>
              <CustomText fontFamily={Fonts.bold} fontSize={1.75}>
                CPF
              </CustomText>
              <CustomText fontFamily={Fonts.medium} fontSize={2}>
                {user?.cpf}
              </CustomText>
            </Styles.UserInfoCard>
          </Grid>
          <Grid item xs={6}>
            <Styles.UserInfoCard>
              <CustomText fontFamily={Fonts.bold} fontSize={1.75}>
                PERFIL
              </CustomText>
              <CustomText fontFamily={Fonts.medium} fontSize={2}>
                {!user?.role
                  ? rolesAssociatedList.find(
                      (item) => item?.value === parseInt(user?.roleId)
                    )?.label
                  : user?.role?.name}
              </CustomText>
            </Styles.UserInfoCard>
          </Grid>
          <Grid item xs={6}>
            <Styles.UserInfoCard>
              <CustomText fontFamily={Fonts.bold} fontSize={1.75}>
                E-MAIL
              </CustomText>
              <CustomText fontFamily={Fonts.medium} fontSize={2}>
                {user?.email}
              </CustomText>
            </Styles.UserInfoCard>
          </Grid>
        </Grid>
      </Styles.UserInfo>
      <Styles.FormButtons>
        {!viewOnly && (
          <ButtonText
            fullWidth={false}
            onClick={() => {
              customModal.close();
            }}
            color={1}
          >
            Voltar
          </ButtonText>
        )}
        <Styles.FormButton fullWidth={false} onClick={() => onSubmit()}>
          {viewOnly ? "OK" : block ? "SIM, BLOQUEAR" : "SIM, DESBLOQUEAR"}
        </Styles.FormButton>
      </Styles.FormButtons>
    </Styles.ModalContent>
  );
}

function mapStateToProps(redux) {
  const { isFetching } = redux.registerAndQuery;
  const { rolesAssociatedList } = redux.global;
  return { isFetching, rolesAssociatedList };
}

function mapDispatchToProps(dispatch) {
  return {};
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(React.memo(ModalBlock));
