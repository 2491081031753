import React from "react";
import { QueriesHistory } from "../features";

function QueriesHistoryPage() {
  return (
    <>
      <QueriesHistory />
    </>
  );
}

export default QueriesHistoryPage;
