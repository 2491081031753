import React, { useEffect, useState } from "react";

import { Styles } from "../styles";
const TIME_IN_MILISECONDS_TO_COUNTDOWN = 90 * 1000;
const INTERVAL_IN_MILISECONDS = 1000;
function Countdown(props) {
  const [timer, setTimer] = useState(TIME_IN_MILISECONDS_TO_COUNTDOWN);
  const [referenceTime, setReferenceTime] = useState(Date.now());

  useEffect(() => {
    const countDownUntilZero = () => {
      setTimer((prevTime) => {
        if (prevTime <= 0) return 0;

        const now = Date.now();
        const interval = now - referenceTime;

        setReferenceTime(now);
        return prevTime - interval;
      });
    };

    if (timer > 0) {
      setTimeout(countDownUntilZero, INTERVAL_IN_MILISECONDS);
    }
  }, [timer, referenceTime]);

  const ReSendCode = async () => {
    props.setReSend();
    setTimer(90 * 1000);
    setReferenceTime(Date.now());
  };

  return (
    <>
      <Styles.Countdown>
        <Styles.InfoIcon /> {`Tempo limite para validação do Token⠀`}
        <u>
          {`${
            Math.floor(timer / 60000) < 0
              ? "00"
              : String("0" + parseInt(Math.floor(timer / 60000)))
          }` +
            ":" +
            `${
              parseInt((timer % 60000) / 1000) < 0
                ? "00"
                : String(
                    `${(timer % 60000) / 1000 < 10 ? "0" : ""}` +
                      parseInt((timer % 60000) / 1000)
                  )
            }`}
        </u>
      </Styles.Countdown>
      <Styles.ReSend
        disabled={timer <= 0 ? false : true}
        onClick={() => timer <= 0 && ReSendCode()}
      >
        Reenviar Código
      </Styles.ReSend>
    </>
  );
}

export default Countdown;
