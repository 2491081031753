import { call, put } from "redux-saga/effects";
import { api } from "../../../services";
import { Creators } from "./index";

import { Alerts } from "../../../lib";

export default function* searchUsersAmcred({ data }) {
  try {
    let filters = { ...data };

    if (filters?.searchParameters) {
      filters.searchParameters = {
        companyName:
          data?.searchParameters?.companyName !== "-1"
            ? data?.searchParameters?.companyName
            : null,
        companyCnpj:
          data?.searchParameters?.companyCnpj !== "-1"
            ? data?.searchParameters?.companyCnpj
            : null,
        requester:
          data?.searchParameters?.requester !== "-1"
            ? data?.searchParameters?.requester
            : null,
        cpf:
          data?.searchParameters?.cpf !== "-1"
            ? data?.searchParameters?.cpf
            : null,
        email:
          data?.searchParameters?.email !== "-1"
            ? data?.searchParameters?.email
            : null,
        officeId:
          data?.searchParameters?.officeId !== "-1"
            ? parseInt(data?.searchParameters?.officeId)
            : null,
        end: data?.searchParameters?.end,
        begin: data?.searchParameters?.begin,
        order: data?.searchParameters?.order,
        typeOrder: data?.searchParameters?.typeOrder,
      };
    }

    const response = yield call(api.searchUsersInstitution, filters);

    yield put(Creators.searchUsersInstitutionSuccess(response?.data));
  } catch (response) {
    Alerts.alertError(
      response?.data?.log
        ? response?.data?.log
        : "Erro ao encontrar lista de usuários"
    );

    yield put(Creators.searchUsersInstitutionFailure());
  }
}
