import { call, put } from "redux-saga/effects";
import { api } from "../../../services";
import { Creators } from "./index";

import { Alerts } from "../../../lib";

export default function* searchDashboardInstitutions({ data }) {
  try {
    let filters = data;
    if ("companyIds" in filters) {
      filters.userIds = filters?.companyIds?.map((item) => item?.value);
      delete data?.companyIds;
    }
    const response = yield call(api.searchDashboardInstitutions, data);

    yield put(Creators.searchDashboardInstitutionsSuccess(response?.data));
  } catch (response) {
    Alerts.alertError(
      response?.data?.log
        ? response?.data?.log
        : "Erro carregar dados do dashboard"
    );

    yield put(Creators.searchDashboardInstitutionsFailure());
  }
}
