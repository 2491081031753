import { call, put } from "redux-saga/effects";
import { api } from "../../../services";
import { Creators } from "./index";
import { Alerts } from "../../../lib";

export default function* reportDetailListInstitution({ data }) {
  try {
    const namesIds = data?.searchParameters?.userIds?.map((item) => {
      return item.value;
    });

    const newData = {
      begin: !data?.searchParameters?.begin
        ? null
        : data?.searchParameters?.begin,
      end: !data?.searchParameters?.end ? null : data?.searchParameters?.end,
      roleId:
        !data?.searchParameters?.roleId ||
        data?.searchParameters?.roleId === "-1"
          ? null
          : parseInt(data?.searchParameters?.roleId),
      userIds: namesIds?.length > 0 ? namesIds : null,
      order: data?.searchParameters?.order,
      typeOrder: data?.searchParameters?.typeOrder,
    };

    const response = yield call(api.reportDetailListInstitution, {
      searchParameters: newData,
      page: data.page,
    });

    yield put(Creators.reportDetailListInstitutionSuccess(response));
  } catch (response) {
    Alerts.alertError(
      response?.data?.log
        ? response?.data?.log
        : "Não foi possível encontrar as solicitações de pesquisa de relatório"
    );
    yield put(Creators.reportDetailListInstitutionFailure());
  }
}
