import React from "react";
import styled from "styled-components";

import FontStyle from "../styles/fontsStyles";

const Text = styled.p(
  ({ $fontFamily, $fontSize, $textColor, $styles, theme }) => {
    const { palette: colors, spacing } = theme;
    return {
      padding: 0,
      margin: 0,
      fontFamily: $fontFamily ? $fontFamily : FontStyle.medium12.fontFamily,
      fontSize: $fontSize ? spacing($fontSize) : FontStyle.medium12.fontSize,
      color: $textColor ? $textColor : colors.text.primary,
      display: "flex",
      justifyContent: "flex-start",
      alignContent: "center",
      letterSpacing: 0,
      ...$styles,
    };
  }
);

function CustomText({ children, styles, fontFamily, fontSize, textColor }) {
  return (
    <Text
      $styles={styles}
      $fontFamily={fontFamily}
      $fontSize={fontSize}
      $textColor={textColor}>
      {children}
    </Text>
  );
}

export default CustomText;
