import React from "react";
import {
  Select,
  MenuItem,
  FormHelperText,
  FormControl,
  Input,
} from "@material-ui/core";
import { formConnector } from "../../FormConfig";
import styled from "styled-components";
import { FontStyles, Tolltip } from "../index";
import Text from "../strings/Text";

import FontStyle from "../styles/fontsStyles";
const StyledInput = styled(Input)(
  ({
    theme,
    error,
    disabled,
    $inputStyle,
    $error,
    $small,
    $alternativecolors,
    ...rest
  }) => {
    const { spacing, palette: colors } = theme;
    return {
      padding: $small ? spacing(0, 1) : spacing(1),
      multiline: true,
      textOverflow: "ellipsis",
      backgroundColor: $alternativecolors
        ? colors.text.secondary
        : colors.text.primary,
      borderRadius: spacing(1),
      boxShadow: $error
        ? `inset 0 0 0 2px ${colors.background.secondary}`
        : `inset 0 0 0 ${colors.error.main}`,
      opacity: disabled ? 0.3 : 1,
      "& .MuiInput-input": {
        backgroundColor: "red",
        color: $alternativecolors ? colors.text.primary : colors.text.primary,

        "&::placeholder": {
          opacity: 0.8,
        },
      },

      ...$inputStyle,
    };
  }
);
const StyledFormControl = styled(FormControl)(({ theme }) => {
  return {};
});

const StyledMenuItem = styled(MenuItem)(({ theme }) => {
  const { palette: colors } = theme;
  return {
    ...FontStyle.medium14,
    color: colors.text.primary,
    backgroundColor: colors.background.input,
    "&.MuiListItem-button:hover": {
      backgroundColor: colors.text.tertiary + 20,
    },
    "&.Mui-focusVisible": {
      backgroundColor: "transparent",
    },
    "&.Mui-selected": {
      backgroundColor: colors.text.primary + 50,
    },

    "& .MuiPaper-root": {
      backgroundColor: "red",
    },
  };
});

const StyledSelect = styled(Select)(
  ({ theme, $witherror, $maxHeight, $color, disabled }) => {
    const { palette: colors, spacing } = theme;
    return {
      ...FontStyle.medium16,
      color: colors.text.primary,
      padding: spacing(1),
      backgroundColor: colors.background.input,
      borderRadius: spacing(0.7),

      border: $witherror ? `1px solid ${colors.error.main}` : "0px solid red",
      opacity: disabled ? 0.3 : 1,
      "& .MuiSelect-select.MuiSelect-select": {
        paddingLeft: spacing(1),
      },
      ".MuiSelect-icon": {
        color:
          $color === 2
            ? colors.text.primary
            : $color === 1
            ? colors.orange
            : colors.text.secondary,
      },
    };
  }
);

const Label = styled(Text)(
  ({ theme, $withValue, $witherror, $color, $disabled }) => {
    const { palette: colors } = theme;

    return {
      ...FontStyles.semibold14,
      textTransform: "uppercase",
      position: "relative",
      top: 0,
      left: 0,
      color: $witherror
        ? colors.error.main
        : $color === 2
        ? colors.text.primary
        : $color === 1
        ? colors.orange
        : colors.text.secondary,
      transition: ".2s",
      pointerEvents: "none",
      alignItems: "center",
      display: "flex",
      overflow: "hidden",
      opacity: $disabled ? 0.4 : 1,
    };
  }
);
function SelectComponent(props) {
  const { value, error, setConfigs, onBlur, ref, formHandler, usedProps } =
    formConnector.useStringValue(props);
  const { onChange, name } = props;
  const onSelectValue = React.useCallback(
    (event) => {
      setConfigs(event.target.value, event);
      try {
        onChange(event.target.value);
      } catch (err) {}
      setTimeout(() => {
        onBlur();
        formHandler.testInputError(name);
      }, 10);
    },
    [setConfigs, onBlur, formHandler, name, onChange]
  );
  const ErrorMessage = styled(FormHelperText)(({ theme }) => {
    const { palette: colors } = theme;
    return {
      ...FontStyles.medium12,
      color: colors.text.tertiary,
    };
  });
  const {
    containerStyles,
    required,
    inputLabel,
    options,
    disableError,
    customInput,
    $maxHeight,
    alternativeColors = false,
    tooltip,
    readOnly,
    color,
    disabled,
    placeholder,
    forceError,
  } = usedProps;

  return (
    <StyledFormControl
      style={containerStyles}
      fullWidth
      error={Boolean(error)}
      required={Boolean(required)}
      $maxHeight={$maxHeight}
      onMouseUp={(event) => event.stopPropagation()}
    >
      <Label
        $disabled={disabled}
        $withValue={Boolean(value) || value === 0}
        $witherror={Boolean(error) ? true : undefined}
        $color={color}
        $maxHeight={$maxHeight}
      >
        {inputLabel}
        {Boolean(required) && "*"}
        {tooltip && (
          <Tolltip
            title={tooltip?.title}
            tip={tooltip?.content}
            disableFocusListener
          />
        )}
      </Label>
      <StyledSelect
        disabled={disabled}
        readOnly={readOnly}
        $witherror={Boolean(error) ? true : undefined}
        $alternativecolors={alternativeColors}
        disableUnderline
        ref={ref}
        value={value}
        $maxHeight={$maxHeight}
        onChange={onSelectValue}
        onMouseUp={(event) => event.stopPropagation()}
        $color={color}
        input={
          customInput ? (
            <StyledInput
              placeholder={placeholder}
              readOnly={readOnly}
              $maxHeight={$maxHeight}
            />
          ) : null
        }
      >
        {options?.map((option) => (
          <StyledMenuItem
            key={`${name}-${option.value}`}
            value={option.value}
            disabled={option.disabled}
          >
            {option.label}
          </StyledMenuItem>
        ))}
      </StyledSelect>
      {disableError ? null : (
        <ErrorMessage error={forceError || (Boolean(error) && !readOnly)}>
          {forceError ? forceError : Boolean(error) && !readOnly ? error : " "}
        </ErrorMessage>
      )}
    </StyledFormControl>
  );
}

export default SelectComponent;
