import React from "react";
import { customModal } from "../utils";

import { Fade, IconButton } from "@material-ui/core";
import {
  ButtonText,
  FontStyles,
  ThemeProvider,
  CustomText,
  ButtonContained,
} from "../../../components";
import { Styles } from "../styles";
import { Colors, Spacing, Themes } from "../../../config";
import fonts from "../../../config/fonts";

function ModalContainer() {
  const [infos, setInfos] = React.useState({});
  const [open, setOpen] = React.useState(false);
  const {
    texts = [],
    title,
    confirmButton,
    exitButton,
    extraField,
    noExit,
    color,
  } = infos;

  const handleModal = React.useCallback(
    (config) => {
      if (config.open) {
        setInfos(config.infos);
      }
      setOpen(config.open);
    },
    [setInfos, setOpen]
  );

  customModal.setCallback(handleModal);
  return (
    <ThemeProvider theme={Themes.Light}>
      <Styles.StyledModal
        open={open}
        onClose={(event, reason) =>
          reason !== "backdropClick" && setOpen(false)
        }
        BackdropComponent={Styles.StyledBackdrop}
        aria-labelledby="Título do modal"
        aria-describedby="Descrição do modal"
      >
        <Fade in={open}>
          <Styles.Container $noTitle={title}>
            <Styles.ContentContainer>
              <Styles.HeaderContainer $noTitle={title} noExit={noExit}>
                <CustomText
                  fontSize={2}
                  fontFamily={fonts.bold}
                  textColor={
                    color === 2
                      ? Colors.carbon
                      : color === 1
                      ? Colors.orange
                      : Colors.red
                  }
                >
                  {title}
                </CustomText>
                {noExit ? null : (
                  <IconButton onClick={customModal.close}>
                    <Styles.Close />
                  </IconButton>
                )}
              </Styles.HeaderContainer>
              {texts.length >= 1 ? (
                <Styles.TextContainer>
                  {texts.map((text) => (
                    <CustomText
                      fontSize={1.75}
                      fontFamily={fonts.medium}
                      key={text}
                    >
                      {text}
                    </CustomText>
                  ))}
                </Styles.TextContainer>
              ) : null}

              {extraField ? extraField : null}
            </Styles.ContentContainer>

            <Styles.PaddingModal>
              {exitButton ? (
                <ButtonText
                  {...exitButton}
                  fullWidth={false}
                  style={{
                    textTransform: "capitalize",
                    ...FontStyles.semibold14,
                  }}
                  color={color}
                >
                  {exitButton.label}
                </ButtonText>
              ) : null}
              {confirmButton ? (
                <ButtonContained
                  fullWidth={false}
                  color={color}
                  {...confirmButton}
                  style={{
                    textTransform: "uppercase",
                    ...FontStyles.semibold14,
                    paddingInline: Spacing(2.75),
                  }}
                >
                  {confirmButton.label}
                </ButtonContained>
              ) : null}
            </Styles.PaddingModal>
          </Styles.Container>
        </Fade>
      </Styles.StyledModal>
    </ThemeProvider>
  );
}

export default React.memo(ModalContainer);
