import { call, put } from "redux-saga/effects";
import { api } from "../../../services";
import { Creators } from "./index";

import { Alerts } from "../../../lib";

export default function* fullQueriesHistory({ data, callback }) {
  try {
    const dataRequest = {
      cpfOrCnpj:
        data?.searchParameters?.cpfOrCnpj === "-1"
          ? null
          : data?.searchParameters?.cpfOrCnpj,
      begin: data?.searchParameters?.begin
        ? data?.searchParameters?.begin
        : null,
      end: data?.searchParameters?.end ? data?.searchParameters?.end : null,
      creditRelease:
        data?.searchParameters?.creditRelease === "-1" ||
        data?.searchParameters?.creditRelease === null
          ? null
          : parseInt(data?.searchParameters?.creditRelease),
    };

    data.searchParameters = dataRequest;

    const response = yield call(api.fullQueriesHistory, data);

    yield put(Creators.fullQueriesHistorySuccess());
    callback(response?.data);
  } catch (response) {
    Alerts.alertError(
      response?.data?.log
        ? response?.data?.log
        : "Não foi possível exportar o histórico"
    );

    yield put(Creators.fullQueriesHistoryFailure());
  }
}
