import React from "react";
import Styles from "../styles/Styles";
import { Pagination, Table } from "../../../components";
import HeaderExportList from "./HeaderExportList";
import { GetRoleByName } from "../../../services";
import { Filters } from "../../../lib";

function ReportList({
  page,
  setPage,
  totalPages,
  userInfos,
  isFetching,
  generalReportList,
  exportData,
  sort,
  lastFilter,
  user,
  roleId,
  setSortKey,
  setSortDirection,
  sortKey,
  sortDirection,
  totalValues,
}) {
  const sortContent = (key, orderType) => {
    setSortKey(key);
    setSortDirection(orderType);
    const type = orderType ? "ASC" : "DESC";

    sort(lastFilter, user, key, type);
  };

  return (
    <Styles.CardContainer>
      <HeaderExportList
        isFetching={isFetching}
        exportData={exportData}
        generalReportList={generalReportList}
        role={GetRoleByName(userInfos?.role?.name)}
        totalValues={totalValues}
      />
      <Styles.Line />
      <Table
        currentSortKey={sortKey}
        sortDirection={sortDirection}
        sortLast={true}
        id="reportTable"
        headers={{
          table:
            GetRoleByName(userInfos?.role?.name) === 2
              ? [
                  "NOME DA INSTITUIÇÃO ASSOCIADA",
                  "CNPJ",
                  "Nº CONSULTAS",
                  "VALOR",
                ]
              : GetRoleByName(userInfos?.role?.name) === 1
              ? ["NOME DO USUÁRIO", "PERFIL", "Nº CONSULTAS", "VALOR"]
              : [
                  "NOME DA INSTITUIÇÃO ASSOCIADA",
                  "CNPJ",
                  "Nº CONSULTAS",
                  "VALOR",
                ],
          keys:
            GetRoleByName(userInfos?.role?.name) === 1
              ? ["fullName", "roleName", "queryQuantity", "totalValue"]
              : ["companyName", "companyCnpj", "queryQuantity", "totalValue"],
        }}
        data={generalReportList}
        sortContent={sortContent}
        renderItemColumns={(item) =>
          GetRoleByName(userInfos?.role?.name) === 2
            ? [
                item.companyName,
                item.companyCnpj,
                item.queryQuantity,
                Filters.convertMoneyTextMask(item.totalValue),
              ]
            : GetRoleByName(userInfos?.role?.name) === 1
            ? [
                item.fullName,
                item.roleName,
                item.queryQuantity,
                Filters.convertMoneyTextMask(item.totalValue),
              ]
            : [
                item.companyName,
                item.companyCnpj,
                item.queryQuantity,
                Filters.convertMoneyTextMask(item.totalValue),
              ]
        }
        color={GetRoleByName(userInfos?.role?.name)}
      />
      <Pagination
        page={page}
        setPage={setPage}
        totalPages={totalPages}
        color={GetRoleByName(userInfos?.role?.name)}
      />
    </Styles.CardContainer>
  );
}

export default ReportList;
