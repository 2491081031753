import React from "react";
import Styles from "../styles/Styles";
import { FormHolder } from "../../../FormConfig";
import {
  CustomText,
  InputAutoComplete,
  InputDate,
  InputTags,
} from "../../../components";
import { Grid } from "@material-ui/core";
import {
  Delete,
  KeyboardArrowDown,
  KeyboardArrowUp,
  Search,
} from "@material-ui/icons";

import { Colors, Fonts, Spacing, Texts, Themes } from "../../../config";
import fonts from "../../../config/fonts";
import { GetRoleByName } from "../../../services";
const now = new Date();
const year = now.getFullYear();
const month = now.getMonth() + 1; // Os meses em JavaScript são indexados de 0 a 11

const begin = new Date(year, month - 1, 1);
const end = now;
function SearchReports({
  onSubmit,
  userInfos,
  companiesList,
  isFetching,
  filterAmcred,
  usernameAndRolesList,
  onClear,
}) {
  const texts = Texts["pt-BR"].DetailReport.searchReport;
  const [show, setShow] = React.useState(true);
  const [ref, setRef] = React.useState(null);
  const [initDate, setInitDate] = React.useState(begin);
  return (
    <Styles.CardContainer>
      <Styles.CardHeader>
        <CustomText fontFamily={Fonts.bold} fontSize={1.75}>
          {texts.title}
        </CustomText>
        <Styles.Hide onClick={() => setShow(!show)}>
          {show ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
        </Styles.Hide>
      </Styles.CardHeader>
      <Styles.Line></Styles.Line>
      {show && (
        <>
          <FormHolder
            formRef={setRef}
            onSubmit={onSubmit}
            clearOnSubmit={false}
          >
            <Grid container spacing={1}>
              <Grid item xs={12} sm={12} md={6} lg={4}>
                <InputTags
                  inputLabel={
                    GetRoleByName(userInfos?.role?.name) === 2
                      ? texts.institution
                      : GetRoleByName(userInfos?.role?.name) === 1
                      ? texts.username
                      : texts.institution
                  }
                  name={
                    GetRoleByName(userInfos?.role?.name) === 2
                      ? "companies"
                      : GetRoleByName(userInfos?.role?.name) === 1
                      ? "userIds"
                      : "companies"
                  }
                  autoComplete="off"
                  options={
                    GetRoleByName(userInfos?.role?.name) === 2
                      ? companiesList
                      : GetRoleByName(userInfos?.role?.name) === 1
                      ? usernameAndRolesList?.names
                        ? usernameAndRolesList?.names
                        : []
                      : filterAmcred?.companies
                      ? filterAmcred?.companies
                      : []
                  }
                  color={GetRoleByName(userInfos?.role?.name)}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={3}>
                {GetRoleByName(userInfos?.role?.name) === 1 ? (
                  <InputAutoComplete
                    name="roleId"
                    inputLabel={texts.role}
                    options={
                      usernameAndRolesList?.roleNames
                        ? [...usernameAndRolesList?.roleNames]
                        : []
                    }
                    color={GetRoleByName(userInfos?.role?.name)}
                    placeholder="Todos"
                  />
                ) : (
                  <InputAutoComplete
                    options={
                      filterAmcred?.cnpjs ? [...filterAmcred?.cnpjs] : []
                    }
                    inputLabel={texts.cnpj}
                    name="cnpj"
                    placeholder="Todos"
                    color={GetRoleByName(userInfos?.role?.name)}
                  />
                )}
              </Grid>
              <Grid
                style={{ display: "flex", alignContent: "flex-start" }}
                container
                item
                spacing={1}
                xs={12}
                sm={12}
                md={12}
                lg={5}
              >
                <Grid
                  container
                  item
                  xs={12}
                  style={{ marginBottom: Spacing(-1) }}
                >
                  <CustomText
                    textColor={
                      GetRoleByName(userInfos?.role?.name) === 2
                        ? Themes.Light.palette.text.primary
                        : GetRoleByName(userInfos?.role?.name) === 1
                        ? Colors.orange
                        : Themes.Light.palette.text.secondary
                    }
                    fontSize={1.75}
                    fontFamily={fonts.semibold}
                  >
                    {texts.period}
                  </CustomText>
                </Grid>
                <Grid item xs={5}>
                  <InputDate
                    defaultValue={begin}
                    color={GetRoleByName(userInfos?.role?.name)}
                    name="begin"
                    nextInput="end"
                    placeholder="dd/mm/yyyy"
                    onChange={(data, value) => {
                      if (
                        ref?.getFieldValue("end") &&
                        ref?.getFieldValue("end") < value
                      ) {
                        setInitDate(value);
                        var novaData = new Date(value);
                        novaData.setDate(value.getDate() + 1);

                        ref?.setValue("end", novaData);
                      } else {
                        setInitDate(value);
                      }
                    }}
                  />
                </Grid>
                <Grid
                  item
                  xs={2}
                  style={{ justifyContent: "center", display: "flex" }}
                >
                  <CustomText
                    styles={{ paddingTop: Spacing(1.75) }}
                    fontSize={1.75}
                    fontFamily={fonts.medium}
                  >
                    até
                  </CustomText>
                </Grid>
                <Grid item xs={5}>
                  <InputDate
                    defaultValue={end}
                    color={GetRoleByName(userInfos?.role?.name)}
                    name="end"
                    minDate={initDate}
                    placeholder="dd/mm/yyyy"
                  />
                </Grid>
              </Grid>
            </Grid>
            <Styles.FormButtons>
              <Styles.FormButtonOutlined
                $color={GetRoleByName(userInfos?.role?.name)}
                startIcon={<Delete />}
                fullWidth={false}
                loading={isFetching}
                disabled={isFetching}
                type="clear"
                onClick={() => {
                  ref?.clearAllValues();
                  onClear();
                }}
              >
                Limpar
              </Styles.FormButtonOutlined>
              <Styles.FormButton
                $color={GetRoleByName(userInfos?.role?.name)}
                startIcon={<Search />}
                loading={isFetching}
                disabled={isFetching}
                fullWidth={false}
                type="submit"
              >
                Pesquisar
              </Styles.FormButton>
            </Styles.FormButtons>
          </FormHolder>
        </>
      )}
    </Styles.CardContainer>
  );
}

export default SearchReports;
