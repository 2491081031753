import React from "react";
import { customDoubleModal } from "../utils";

import { Fade } from "@material-ui/core";
import { CustomText, ThemeProvider } from "../../../components";
import { Styles } from "../styles";
import { Colors, Themes } from "../../../config";
import fonts from "../../../config/fonts";
function ModalDouble() {
  const [infos, setInfos] = React.useState({});
  const [open, setOpen] = React.useState(false);

  const { modalOne, modalTwo, titleOne, titleTwo } = infos;

  const handleModal = React.useCallback(
    (config) => {
      if (config.open) {
        setInfos(config.infos);
      }
      setOpen(config.open);
    },
    [setInfos, setOpen]
  );

  customDoubleModal.setCallback(handleModal);

  return (
    <ThemeProvider theme={Themes.Light}>
      <Styles.StyledModal
        open={open}
        onClose={(event, reason) =>
          reason !== "backdropClick" && setOpen(false)
        }
        BackdropComponent={Styles.StyledBackdrop}
        aria-labelledby="Título do modal"
        aria-describedby="Descrição do modal"
      >
        <Fade in={open}>
          <Styles.Container alternative>
            <Styles.Card alternative>
              <Styles.HeaderContainer>
                <CustomText
                  fontSize={2}
                  fontFamily={fonts.bold}
                  textColor={Colors.blue}
                >
                  {titleOne}
                </CustomText>
              </Styles.HeaderContainer>
              <Styles.ContentContainer alternative>
                {modalOne}
              </Styles.ContentContainer>
            </Styles.Card>
            <Styles.Card alternative>
              <Styles.HeaderContainer>
                <CustomText
                  fontSize={2}
                  fontFamily={fonts.bold}
                  textColor={Colors.blue}
                >
                  {titleTwo}
                </CustomText>
              </Styles.HeaderContainer>
              <Styles.ContentContainer alternative>
                {modalTwo}
              </Styles.ContentContainer>
            </Styles.Card>
          </Styles.Container>
        </Fade>
      </Styles.StyledModal>
    </ThemeProvider>
  );
}

export default React.memo(ModalDouble);
