import React from "react";
import { Homepage } from "../features";

function Home() {
  return (
    <>
      <Homepage />
    </>
  );
}

export default Home;
