import React from "react";
import { HeaderStyles as Styles } from "../styles";
import { NavigationInfos, UserInfos } from "../components";
import { connect } from "react-redux";
import MenuIcon from "@material-ui/icons/Menu";
import { GetRoleByName } from "../../../services";
function Header({ pathname, userInfos, openMenu }) {
  return (
    <Styles.Container id="header-menu">
      <Styles.FirstContentGroup>
        <Styles.Rectangle onClick={() => openMenu(true)}>
          <MenuIcon />
        </Styles.Rectangle>
        <Styles.LineBody>
          <Styles.SubLine></Styles.SubLine>
        </Styles.LineBody>
        {userInfos && (
          <NavigationInfos pathname={pathname} userInfos={userInfos} />
        )}
      </Styles.FirstContentGroup>
      <Styles.UserContentGroup $color={GetRoleByName(userInfos?.role?.name)}>
        <UserInfos userInfos={userInfos} />
      </Styles.UserContentGroup>
    </Styles.Container>
  );
}

function mapStateToProps({ router, global }) {
  const { userInfos } = global;
  return {
    pathname: router.location.pathname,
    userInfos,
  };
}
export default connect(mapStateToProps)(React.memo(Header));
