import React from "react";
import { ProfilePage } from "../features";

function Profile() {
  return (
    <>
      <ProfilePage />
    </>
  );
}

export default Profile;
