import React from "react";
import { connect } from "react-redux";
import { Styles } from "../styles";

import { CustomText, Pagination, Table } from "../../../components";
import { Fonts } from "../../../config";

import { ArrowDropDown, Edit, Lock, LockOpen } from "@material-ui/icons";
import ButtonMenuComponent from "../../../components/buttons/ButtonMenu";

function TableUsers({
  isFetching,
  list,
  page,
  setPage,
  totalPages,
  onBlock,
  onUnblock,
  onEdit,
}) {
  return (
    <Styles.Content>
      <Styles.HeaderCard>
        <CustomText fontFamily={Fonts.bold} fontSize={1.75}>
          CADASTROS DO SISTEMA
        </CustomText>
      </Styles.HeaderCard>
      <Styles.Line></Styles.Line>
      <Table
        headers={{ table: ["Nome Completo", "CPF", "Cargo", "Email", "Ação"] }}
        data={list}
        renderItemColumns={(item) => [
          item.fullName,
          item.cpf,
          item.officeName,
          item.email,

          <ButtonMenuComponent
            endIcon={<ArrowDropDown />}
            buttons={
              item?.active
                ? [
                    {
                      label: "Editar",
                      icon: Edit,
                      onClick: () => onEdit(item),
                    },
                    {
                      label: "Bloquear",
                      icon: Lock,
                      onClick: () => onBlock(item),
                    },
                  ]
                : [
                    {
                      label: "Editar",
                      icon: Edit,
                      onClick: () => onEdit(item),
                    },
                    {
                      label: "Desbloquear",
                      icon: LockOpen,
                      onClick: () => onUnblock(item),
                    },
                  ]
            }
          >
            Opções
          </ButtonMenuComponent>,
        ]}
      />
      <Pagination page={page} setPage={setPage} totalPages={totalPages} />
    </Styles.Content>
  );
}

function mapStateToProps(redux) {
  const { isFetching } = redux.registerAndQuery;
  return { isFetching };
}

function mapDispatchToProps(dispatch) {
  return {};
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(React.memo(TableUsers));
