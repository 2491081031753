import { call, put } from "redux-saga/effects";
import { api } from "../../../services";
import { Creators } from "./index";

import { Alerts } from "../../../lib";

export default function* validEmail({ data, callback }) {
  try {
    const response = yield call(api.validEmail, data);

    yield put(Creators.validEmailSuccess());
    if (!response?.data?.response) {
      callback("Esse Email ja esta em uso");
    }
  } catch (response) {
    Alerts.alertError(
      response?.data?.log ? response?.data?.log : "Erro ao validar email"
    );

    yield put(Creators.validEmailFailure());
  }
}
