import { call, put } from "redux-saga/effects";
import { api } from "../../../services";
import { Creators } from "./index";

import { Alerts } from "../../../lib";

export default function* unblockUserAmcred({ data, callback }) {
  try {
    yield call(api.unblockUserInstitution, data);

    yield put(Creators.blockUserInstitutionSuccess());

    callback();
  } catch (response) {
    Alerts.alertError(
      response?.data?.log
        ? response?.data?.log
        : "Não foi possível desbloquear o usuário"
    );

    yield put(Creators.blockUserInstitutionFailure());
  }
}
