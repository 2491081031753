import { call, put } from "redux-saga/effects";
import { api } from "../../../services";
import { Creators } from "./index";

import { Alerts } from "../../../lib";

export default function* searchUsersAmcred({ data }) {
  try {
    let filters = data;
    if (
      !filters?.searchParameters?.officeId ||
      filters?.searchParameters?.officeId === "-1"
    ) {
      filters.searchParameters.officeId = null;
    }

    const response = yield call(api.searchUsersAmcred, filters);

    yield put(Creators.searchUsersAmcredSuccess(response?.data));
  } catch (response) {
    Alerts.alertError(
      response?.data?.log
        ? response?.data?.log
        : "Erro ao encontrar lista de usuários"
    );

    yield put(Creators.searchUsersAmcredFailure());
  }
}
