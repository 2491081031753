import { Grid } from "@material-ui/core";
import React from "react";
import { connect } from "react-redux";
import filters from "../../../lib/Filters";

import BarChart from "./BarChart";

import ValueCard from "./ValueCard";
function DataCards({ isFetching, data, small }) {
  function formatNumber(numero) {
    if (numero && numero % 1 !== 0) {
      // Verifica se o número tem casas decimais
      return numero.toFixed(2).replace(".", ",");
    } else {
      return numero;
    }
  }
  return (
    <>
      <Grid container spacing={1}>
        <Grid item xs={6} sm={4}>
          <ValueCard
            label={"TOTAL DE INSTITUIÇÕES ASSOCIADAS"}
            value={formatNumber(data?.total)}
          />
        </Grid>
        <Grid item xs={6} sm={4}>
          <ValueCard
            label={"TOTAL DE CONSULTAS"}
            value={formatNumber(data?.totalAccess)}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <ValueCard
            label={"TOTAL A RECEBER"}
            value={filters.convertMoneyTextMask(data?.totalReceivable)}
          />
        </Grid>

        <Grid item xs={12}>
          <BarChart
            label={"NÚMERO DE CONSULTAS POR INSTITUIÇÃO"}
            data={data?.accessNumber?.data}
          />
        </Grid>
        <Grid item xs={12}>
          <BarChart
            label={"VALOR TOTAL DE CONSULTAS POR INSTITUIÇÃO"}
            data={data?.accessValue?.data}
            type="value"
          />
        </Grid>
      </Grid>
    </>
  );
}

function mapStateToProps(redux) {
  const { isFetching: isFetchingPage } = redux.dashboardAmcred;
  const { isFetching: isFetchingGlobal } = redux.global;
  return { isFetching: isFetchingGlobal || isFetchingPage };
}

function mapDispatchToProps(dispatch) {
  return {};
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(React.memo(DataCards));
