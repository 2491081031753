import React from "react";
import { Input } from "../../../components";
import Styles from "../styles/Styles";
import Grid from "@material-ui/core/Grid";
import { FormHolder } from "../../../FormConfig";
import { Texts } from "../../../config";
function BasicData({ userInfos, color }) {
  const texts = Texts["pt-BR"].Profile;

  return (
    <Styles.CardContainer>
      <Styles.CardHeader>
        <Styles.CardTitle>{texts.basicData.myBasicData}</Styles.CardTitle>
      </Styles.CardHeader>
      <Styles.Line />
      <FormHolder>
        <Grid container spacing={1}>
          <Grid item xs={12} sm={12} md={12} lg={6} xl={3}>
            <Input
              color={color}
              variant="standard"
              name="companyName"
              readOnly
              inputLabel={texts.basicData.companyName}
              defaultValue={userInfos?.company.name}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={6} xl={3}>
            <Input
              color={color}
              variant="standard"
              name="cpf"
              readOnly
              inputLabel={texts.basicData.cpf}
              defaultValue={userInfos?.cpf}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={6} xl={3}>
            <Input
              color={color}
              variant="standard"
              name="fullName"
              readOnly
              inputLabel={texts.basicData.fullName}
              defaultValue={`${userInfos?.firstName} ${userInfos?.lastName}`}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={6} xl={3}>
            <Input
              color={color}
              variant="standard"
              name="email"
              readOnly
              inputLabel={texts.basicData.email}
              defaultValue={userInfos?.email}
            />
          </Grid>
        </Grid>
      </FormHolder>
    </Styles.CardContainer>
  );
}

export default React.memo(BasicData);
