import { Divider } from "@material-ui/core";
import React from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import { FontStyles, ButtonContained } from "..";
import { MediaQueries } from "../../config";
import fonts from "../../config/fonts";
import { FormHolder } from "../../FormConfig";
import CustomText from "../strings/CustomText";

const Container = styled.div(({ theme, open }) => {
  const { spacing } = theme;
  return {
    width: "100%",
    maxWidth: `calc(80% - ${spacing(7.25)}px)`,
    maxHeight: `calc(100vh - ${spacing(18.5)}px)`,
    backgroundColor: "#F8F8F8",
    borderRadius: 5,
    position: "absolute",
    marginTop: theme.spacing(-2),
    zIndex: 3,
    display: open ? "default" : "none",
    padding: spacing(2.5),
    overflow: "auto",
    [MediaQueries.smDown]: {
      maxWidth: `calc(100% - ${spacing(7.25)}px)`,
    },
  };
});
const BlurContainer = styled.div(({ theme, open }) => {
  return {
    width: "110vw",
    height: "100vh",
    top: 0,
    left: 0,
    backgroundColor: "#00000026",
    position: "fixed",
    display: open ? "flex" : "none",
    zIndex: 2,
  };
});

const CardHeader = styled.div(({ theme }) => {
  return {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  };
});
const SmallButton = styled(ButtonContained)(({ theme }) => ({
  ...FontStyles.semibold12,

  padding: theme.spacing(0.85, 2.5),
  backgroundColor: theme.palette.text.primary,

  whiteSpace: "nowrap",
  overflow: "hidden",
  textVerflow: "ellipsis",
}));
const DividerBar = styled(Divider)(({ theme }) => ({
  marginBlock: theme.spacing(1, 2),
}));
function FilterContainer({
  inputs,
  onSubmit,
  open,
  setOpen,
  title,
  children,
  isFetching,
}) {
  return (
    <FormHolder onSubmit={onSubmit}>
      {inputs !== null && (
        <Container open={open}>
          <CardHeader>
            <CustomText fontSize={2} fontFamily={fonts.bold}>
              {title}
            </CustomText>
            <SmallButton
              loading={isFetching}
              disabled={isFetching}
              iconOnly
              fullWidth={false}
              onClick={() => setOpen(false)}
            >
              Filtros
            </SmallButton>
          </CardHeader>
          <DividerBar />
          {children}
        </Container>
      )}
      <BlurContainer open={open} onClick={() => setOpen(false)}></BlurContainer>
    </FormHolder>
  );
}

export default connect()(React.memo(FilterContainer));
