function tableToCSV(tableId, fileName, title, filter) {
  const filters = filter?.map((item) => item)?.join(" ") + "\n";
  //   Select rows from table_id
  var rows = document.querySelectorAll("table#" + tableId + " tr");
  // Construct csv
  var csv = [];
  for (var i = 0; i < rows.length; i++) {
    var row = [],
      cols = rows[i].querySelectorAll("td, th");
    for (var j = 0; j < cols.length; j++) {
      // Clean innertext to remove multiple spaces and jumpline (break csv)
      var data = cols[j].innerText
        .replace(/(\r\n|\n|\r)/gm, "")
        .replace(/(\s\s)/gm, " ");
      // Escape double-quote with double-double-quote (see https://stackoverflow.com/questions/17808511/properly-escape-a-double-quote-in-csv)
      data = data.replace(/"/g, '""');
      // Push escaped string
      row.push(`="${data}"`);
    }
    csv.push(row.join(";"));
  }
  var body = csv.join("\n");
  const csv_string = `${title}\n${filters}\n${body}`;

  // Download it
  var filename = fileName + "_" + new Date().toLocaleDateString() + ".csv";
  var link = document.createElement("a");
  link.style.display = "none";
  link.setAttribute("target", "_blank");
  link.setAttribute(
    "href",
    "data:attachment/csv;charset=UTF-8," + escape(csv_string)
  );
  link.setAttribute("download", filename);
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}

function forEachItem(list, callback) {
  let i = 0;
  const iMax = list.length;
  const result = [];
  for (; i < iMax; i++) {
    result.push(callback(list[i], i));
  }

  return result;
}
function forEachBody(list, list2) {
  let i = 0;
  const iMax = list.length;
  const result = [];
  for (; i < iMax; i++) {
    result.push(list[i] + ";" + list2[i].join(";"));
  }
  return result;
}

function dataToCSVAlternative(data, headers, rows, fileName) {
  const cdText = `Indicadores Financeiros e gerenciais`;

  const header = forEachItem(headers, (item) => `"${item}"`).join(";");

  const body = forEachBody(rows, data).join("\n");

  const csv_string = `${cdText}\n${header}\n${body}`;

  var filename = fileName + "_" + new Date().toLocaleDateString() + ".csv";
  var link = document.createElement("a");
  link.style.display = "none";
  link.setAttribute("target", "_blank");
  link.setAttribute(
    "href",
    "data:attachment/csv;charset=UTF-8," + escape(csv_string)
  );
  link.setAttribute("download", filename);
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);

  // //   Select rows from table_id
  // var rows = document.querySelectorAll("table#" + tableId + " tr");
  // // Construct csv
  // var csv = [];
  // for (var i = 0; i < rows.length; i++) {
  //   var row = [],
  //     cols = rows[i].querySelectorAll("td, th");
  //   for (var j = 0; j < cols.length; j++) {
  //     // Clean innertext to remove multiple spaces and jumpline (break csv)
  //     var data = cols[j].innerText
  //       .replace(/(\r\n|\n|\r)/gm, "")
  //       .replace(/(\s\s)/gm, " ");
  //     // Escape double-quote with double-double-quote (see https://stackoverflow.com/questions/17808511/properly-escape-a-double-quote-in-csv)
  //     data = data.replace(/"/g, '""');
  //     // Push escaped string
  //     row.push(`="${data}"`);
  //   }
  //   csv.push(row.join(";"));
  // }
  // var csv_string = csv.join("\n");
  // // Download it
  // var filename = fileName + "_" + new Date().toLocaleDateString() + ".csv";
  // var link = document.createElement("a");
  // link.style.display = "none";
  // link.setAttribute("target", "_blank");
  // link.setAttribute(
  //   "href",
  //   "data:attachment/csv;charset=UTF-8," + escape(csv_string)
  // );
  // link.setAttribute("download", filename);
  // document.body.appendChild(link);
  // link.click();
  // document.body.removeChild(link);
}

function dataToCSVx2(data, headers, rows, fileName, title) {
  const header = forEachItem(
    headers,
    (item, index) => item + ";" + data[rows[index]]
  ).join("\n");

  const csv_string = `${title}\n${header}\n`;

  var filename = fileName + "_" + new Date().toLocaleDateString() + ".csv";
  var link = document.createElement("a");
  link.style.display = "none";
  link.setAttribute("target", "_blank");
  link.setAttribute(
    "href",
    "data:attachment/csv;charset=UTF-8," + escape(csv_string)
  );
  link.setAttribute("download", filename);
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}

function dashboardCSV(data, title, fileName, tableHeader, filter) {
  const filters = filter?.map((item) => item).join("\n");

  let body = "";

  // Extrai as chaves do objeto
  const keys = Object.keys(data);

  // Filtra as chaves que têm valores numéricos
  const numericKeys = keys.filter(
    (key) => typeof data[key] === "number" || typeof data[key] === "string"
  );
  const otherKeys = keys.filter(
    (key) => typeof data[key] !== "number" || typeof data[key] !== "string"
  );
  // Cria a linha de cabeçalho com as chaves numéricas e seus nomes

  const headerRow =
    numericKeys.map((key) => tableHeader[key])?.join(";") + "\n";
  const tableRow = numericKeys.map((key) => data[key]).join(";") + "\n\n";

  body += headerRow;
  body += tableRow;

  // Verifica se as chaves numéricas têm arrays correspondentes
  otherKeys.forEach((key) => {
    if (Array.isArray(data[key])) {
      body += tableHeader[key][2] + "\n";
      const rowHeader = `${tableHeader[key][0]};${tableHeader[key][1]} \n`;
      body += rowHeader;
      data[key].forEach((item) => {
        const row = Object.values(item).join(";") + "\n";
        body += row;
      });
      body += "\n";
    }
  });

  const csv_string = `${title}\n${filters}\n\n${body}`;

  var filename = fileName + "_" + new Date().toLocaleDateString() + ".csv";
  var link = document.createElement("a");
  link.style.display = "none";
  link.setAttribute("target", "_blank");
  link.setAttribute(
    "href",
    "data:attachment/csv;charset=UTF-8," + escape(csv_string)
  );
  link.setAttribute("download", filename);
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}

function reportDataCSV(data, headers, keys, title, fileName, filter, extra) {
  let body = "";
  body += headers.join("; ") + ";\n";
  for (let i = 0; i < data.length; i++) {
    for (let j = 0; j < keys.length; j++) {
      body += data[i][keys[j]] + "; ";
    }
    body += "\n";
  }
  const csv_string = `${title}\n${filter?.length > 0 ? filter + "\n" : ""}\n${
    Boolean(extra) ? extra + "\n\n" : ""
  }${body}`;

  var filename = fileName + "_" + new Date().toLocaleDateString() + ".csv";
  var link = document.createElement("a");
  link.style.display = "none";
  link.setAttribute("target", "_blank");
  link.setAttribute(
    "href",
    "data:attachment/csv;charset=UTF-8," + escape(csv_string)
  );
  link.setAttribute("download", filename);
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}
const toCSV = {
  data: dataToCSVx2,
  csv: dataToCSVAlternative,
  table: tableToCSV,
  dash: dashboardCSV,
  report: reportDataCSV,
};

export default toCSV;
