import { call, put } from "redux-saga/effects";
import { api } from "../../../services";
import { Creators } from "./index";
import { Alerts } from "../../../lib";

export default function* reportGeneralListInstitutionRequest({ data }) {
  const idUsers = data?.searchParameters?.userIds?.map((item) => {
    return item.value;
  });

  const newData = {
    begin: !data?.searchParameters?.begin
      ? null
      : data?.searchParameters?.begin,
    end: !data?.searchParameters?.end ? null : data?.searchParameters?.end,
    roleId:
      data?.searchParameters?.roleId === "-1"
        ? null
        : data?.searchParameters?.roleId,
    userIds: idUsers,
    order: data?.searchParameters?.order,
    typeOrder: data?.searchParameters?.typeOrder,
  };

  try {
    const response = yield call(api.filterReportGeneralInstitution, {
      searchParameters: newData,
      page: data.page,
    });

    yield put(Creators.reportGeneralListInstitutionSuccess(response));
  } catch (response) {
    Alerts.alertError(
      response?.data?.log
        ? response?.data?.log
        : "Não foi possível encontrar as solicitações de pesquisa de relatório"
    );
    yield put(Creators.reportGeneralListInstitutionFailure());
  }
}
