import React from "react";
import { ButtonContained, InputPassword } from "../../../components";
import Styles from "../styles/Styles";
import Grid from "@material-ui/core/Grid";
import { FormHolder } from "../../../FormConfig";
import { FormHelperText } from "@material-ui/core";
import Validations from "../../../lib/Validations";
import { Texts } from "../../../config";
function ResetPassword({ onSubmit, isFetching, setForm, color }) {
  const texts = Texts["pt-BR"].Profile;
  const [error, setError] = React.useState(false);

  const passwordCheck = (data) => {
    if (data.newPassword === data.confirmPassword) {
      onSubmit(data);
    } else {
      setError(true);
    }
  };

  return (
    <Styles.CardContainer>
      <Styles.CardHeader>
        <Styles.CardTitle>{texts.newPassword.passwordChange}</Styles.CardTitle>
      </Styles.CardHeader>
      <Styles.Line />
      <FormHolder onSubmit={passwordCheck} formRef={setForm}>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <InputPassword
              color={color}
              variant="standard"
              name="password"
              autoComplete="new-password"
              nextInput="newPassword"
              required={texts.validationField}
              inputLabel={texts.newPassword.password}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <InputPassword
              color={color}
              variant="standard"
              name="newPassword"
              nextInput="confirmPassword"
              required={texts.validationField}
              inputLabel={texts.newPassword.newPassword}
              validation={(data) => Validations.validPassword(data)}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <InputPassword
              color={color}
              variant="standard"
              name="confirmPassword"
              nextInput="password"
              required={texts.validationField}
              inputLabel={texts.newPassword.confirmPassword}
              validation={(data) => Validations.validPassword(data)}
            />
          </Grid>
        </Grid>
        <FormHelperText error={error}>
          {Boolean(error) ? texts.newPassword.passwordMatch : " "}
        </FormHelperText>

        <Styles.ButtonContainer>
          <ButtonContained
            loading={isFetching}
            disabled={isFetching}
            type="submit"
            name="button"
            fullWidth={false}
            color={color}
          >
            {texts.confirm}
          </ButtonContained>
        </Styles.ButtonContainer>
      </FormHolder>
    </Styles.CardContainer>
  );
}

export default React.memo(ResetPassword);
