import React from "react";
import { connect } from "react-redux";
import { Styles } from "../styles";

import { CustomText, InputDate, InputTags } from "../../../components";
import { Fonts, Spacing, Themes } from "../../../config";
import { Grid } from "@material-ui/core";
import {
  Delete,
  KeyboardArrowDown,
  KeyboardArrowUp,
  Search,
} from "@material-ui/icons";
import { FormHolder } from "../../../FormConfig";

import fonts from "../../../config/fonts";
const now = new Date();
const year = now.getFullYear();
const month = now.getMonth() + 1; // Os meses em JavaScript são indexados de 0 a 11

const begin = new Date(year, month - 1, 1);
const end = now;
function Filters({ isFetching, companiesList, onSubmit, onClear }) {
  const [show, setShow] = React.useState(true);
  const [ref, setRef] = React.useState(null);
  const [initDate, setInitDate] = React.useState(begin);

  return (
    <Styles.Content>
      <Styles.HeaderCard>
        <CustomText fontFamily={Fonts.bold} fontSize={1.75}>
          FILTROS DO PAINEL
        </CustomText>
        <Styles.Hide onClick={() => setShow(!show)}>
          {show ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
        </Styles.Hide>
      </Styles.HeaderCard>
      <Styles.Line></Styles.Line>
      {show && (
        <>
          <FormHolder formRef={setRef} onSubmit={onSubmit}>
            <Grid
              style={{ display: "flex", alignItems: "flex-start" }}
              container
              spacing={1}
            >
              <Grid item xs={12} sm={12} md={5}>
                <InputTags
                  name="companyIds"
                  defaultValue={[]}
                  inputLabel="NOME DA INSTITUIÇÃO ASSOCIADA"
                  options={companiesList ? companiesList : []}
                />
              </Grid>
              <Grid
                container
                style={{
                  display: "flex",
                  alignContent: "flex-start",
                  alignItems: "flex-start",
                }}
                item
                spacing={1}
                xs={12}
                sm={12}
                md={7}
              >
                <Grid
                  container
                  item
                  xs={12}
                  style={{
                    marginBottom: Spacing(-1),
                  }}
                >
                  <CustomText
                    textColor={Themes.Light.palette.text.secondary}
                    fontSize={1.75}
                    fontFamily={fonts.semibold}
                  >
                    PERÍODO
                  </CustomText>
                </Grid>

                <Grid item xs={5}>
                  <InputDate
                    name="begin"
                    nextInput="end"
                    placeholder="dd/mm/yyyy"
                    defaultValue={begin}
                    onChange={(data, value) => {
                      if (
                        ref?.getFieldValue("end") &&
                        ref?.getFieldValue("end") < value
                      ) {
                        setInitDate(value);
                        var novaData = new Date(value);
                        novaData.setDate(value.getDate() + 1);

                        ref?.setValue("end", novaData);
                      } else {
                        setInitDate(value);
                      }
                    }}
                  />
                </Grid>
                <Grid
                  item
                  xs={2}
                  style={{ justifyContent: "center", display: "flex" }}
                >
                  <CustomText
                    styles={{ paddingTop: Spacing(1.75) }}
                    fontSize={1.75}
                    fontFamily={fonts.medium}
                  >
                    até
                  </CustomText>
                </Grid>
                <Grid item xs={5}>
                  <InputDate
                    defaultValue={end}
                    minDate={initDate}
                    name="end"
                    placeholder="dd/mm/yyyy"
                  />
                </Grid>
              </Grid>
              <Grid item xs={12} sm={12}>
                <Styles.FormButtons>
                  <Styles.FormButtonOutlined
                    startIcon={<Delete />}
                    fullWidth={false}
                    type="clear"
                    loading={isFetching}
                    disabled={isFetching}
                    onClick={() => {
                      ref?.clearAllValues(false);

                      onClear();
                    }}
                  >
                    Limpar
                  </Styles.FormButtonOutlined>
                  <Styles.FormButton
                    startIcon={<Search />}
                    fullWidth={false}
                    type="submit"
                    loading={isFetching}
                    disabled={isFetching}
                  >
                    Pesquisar
                  </Styles.FormButton>
                </Styles.FormButtons>
              </Grid>
            </Grid>
          </FormHolder>
        </>
      )}
    </Styles.Content>
  );
}

function mapStateToProps(redux) {
  const { isFetching: isFetchingPage } = redux.dashboardAmcred;
  const { isFetching: isFetchingGlobal } = redux.global;
  return { isFetching: isFetchingGlobal || isFetchingPage };
}
function mapDispatchToProps(dispatch) {
  return {};
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(React.memo(Filters));
