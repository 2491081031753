import React from "react";
import { connect } from "react-redux";
import { Styles } from "../styles";

import { ButtonText, CustomText } from "../../../components";
import { Fonts } from "../../../config";
import { Grid } from "@material-ui/core";

import { customModal } from "../../modals/utils";
import { Filters } from "../../../lib";

function ModalBlock({ isFetching, onSubmit, user, reprove, viewOnly }) {
  return (
    <Styles.ModalContent>
      <Styles.HeaderModal>
        <CustomText fontFamily={Fonts.medium} fontSize={1.75}>
          {viewOnly
            ? "Dados adicionais da solicitação"
            : reprove
            ? "Confirma a reprovação desta solicitação de acesso?"
            : "Confirma a aprovação desta solicitação de acesso?"}
        </CustomText>
      </Styles.HeaderModal>

      <Styles.UserInfo>
        <Grid container spacing={1}>
          <Grid item xs={6}>
            <Styles.UserInfoCard>
              <CustomText fontFamily={Fonts.bold} fontSize={1.75}>
                NOME DA INSTITUIÇÃO ASSOCIADA
              </CustomText>
              <CustomText fontFamily={Fonts.medium} fontSize={2}>
                {user?.companyName}
              </CustomText>
            </Styles.UserInfoCard>
          </Grid>
          <Grid item xs={6}>
            <Styles.UserInfoCard>
              <CustomText fontFamily={Fonts.bold} fontSize={1.75}>
                CNPJ
              </CustomText>
              <CustomText fontFamily={Fonts.medium} fontSize={2}>
                {user?.companyCnpj}
              </CustomText>
            </Styles.UserInfoCard>
          </Grid>
          <Grid item xs={6}>
            <Styles.UserInfoCard>
              <CustomText fontFamily={Fonts.bold} fontSize={1.75}>
                NOME DO SOLICITANTE
              </CustomText>
              <CustomText fontFamily={Fonts.medium} fontSize={2}>
                {user?.requester}
              </CustomText>
            </Styles.UserInfoCard>
          </Grid>
          {viewOnly && (
            <Grid item xs={6}>
              <Styles.UserInfoCard>
                <CustomText fontFamily={Fonts.bold} fontSize={1.75}>
                  DATA SOLICITAÇÃO
                </CustomText>
                <CustomText fontFamily={Fonts.medium} fontSize={2}>
                  {Filters.formatDate(user.dateRequest)}
                </CustomText>
              </Styles.UserInfoCard>
            </Grid>
          )}

          <Grid item xs={6}>
            <Styles.UserInfoCard>
              <CustomText fontFamily={Fonts.bold} fontSize={1.75}>
                PERFIL
              </CustomText>
              <CustomText fontFamily={Fonts.medium} fontSize={2}>
                {user?.officeName}
              </CustomText>
            </Styles.UserInfoCard>
          </Grid>
          <Grid item xs={6}>
            <Styles.UserInfoCard>
              <CustomText fontFamily={Fonts.bold} fontSize={1.75}>
                CPF
              </CustomText>
              <CustomText fontFamily={Fonts.medium} fontSize={2}>
                {user?.cpf}
              </CustomText>
            </Styles.UserInfoCard>
          </Grid>
          <Grid item xs={6}>
            <Styles.UserInfoCard>
              <CustomText fontFamily={Fonts.bold} fontSize={1.75}>
                E-MAIL
              </CustomText>
              <CustomText fontFamily={Fonts.medium} fontSize={2}>
                {user?.email}
              </CustomText>
            </Styles.UserInfoCard>
          </Grid>
        </Grid>
      </Styles.UserInfo>
      <Styles.FormButtons>
        {!viewOnly && (
          <ButtonText
            disabled={isFetching}
            loading={isFetching}
            fullWidth={false}
            onClick={() => {
              customModal.close();
            }}
          >
            Voltar
          </ButtonText>
        )}

        <Styles.FormButton
          disabled={isFetching}
          loading={isFetching}
          fullWidth={false}
          onClick={() => onSubmit()}
        >
          {viewOnly
            ? "VOLTAR"
            : reprove
            ? "REPROVAR SOLICITAÇÃO"
            : "SIM, APROVAR"}
        </Styles.FormButton>
      </Styles.FormButtons>
    </Styles.ModalContent>
  );
}

function mapStateToProps(redux) {
  const { isFetching } = redux.accessRequests;
  return { isFetching };
}

function mapDispatchToProps(dispatch) {
  return {};
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(React.memo(ModalBlock));
