import { create } from "apisauce";
import caller from "./helpers/caller";
import jwt_decode from "jwt-decode";

import { SessionStorage } from "../lib";
import Models from "./models";

import models from "./models";

const apiUrl = process.env.REACT_APP_API;

const api = create({
  baseURL: apiUrl,
  timeout: 500000,
});

api.addRequestTransform((request) => {
  const token = SessionStorage.getItem("token");
  const tokenType = SessionStorage.getItem("tokenType");

  if (token && tokenType) {
    request.headers.authorization = tokenType + " " + token;
  }
});

api.addMonitor((response) => {
  const token = response.headers.authorization;

  if (token) {
    SessionStorage.setItem("token", token);
  }
});

async function getToken(loginData) {
  return caller(api.post, "/auth/v1/sign_in", Models.getToken, loginData);
}

async function getUserInfos() {
  const decodedToken = jwt_decode(SessionStorage.getItem("token"));
  return caller(
    api.get,
    `/user/get_by_id?id=${decodedToken.user_id}`,
    Models.getUserInfos
  );
}

async function getCurrentUser() {
  return caller(api.get, `/user/v1/current`, null);
}

async function recoveryPasswordCode(data) {
  return caller(api.post, `/access/v1/password_reset_code`, null, data);
}

async function sendRecoveryPasswordRequest(data) {
  return caller(
    api.post,
    `/access/v1/password_reset_request?email=${data.email}`,
    null
  );
}

async function resetPasswordRequest(data) {
  return caller(
    api.post,
    `/access/v1/password_reset_confirmation`,
    null,
    data?.data,
    {
      headers: { authorization: "Bearer " + data?.token },
    }
  );
}

async function updatePassword(data) {
  return caller(api.patch, `/user/update_password`, null, data);
}

async function newUserPassword(data) {
  return caller(api.post, "/access/v1/redefinition", null, data);
}

async function searchUsersAmcred(data) {
  return caller(api.post, `/user/v1/search_amcred_users`, null, data);
}

async function getRolesList() {
  return caller(api.get, `/office/v1/find_all`, models.getRoles);
}

async function newUserAmcred(data) {
  return caller(api.post, `/user/v1/register_amcred_user`, null, data);
}

async function editUserAmcred(values) {
  return caller(
    api.post,
    `/user/v1/update_users/${values?.id}`,
    null,
    values?.data
  );
}

async function blockUserAmcred(data) {
  return caller(api.patch, `/user/v1/block/${data}`, null, null);
}
async function unblockUserAmcred(data) {
  return caller(api.patch, `/user/v1/unlock/${data}`, null, null);
}
async function getAccessRequestAmcred(data) {
  return caller(api.post, `/user/v1/access_request`, null, data);
}

async function getFilterManagerUsers() {
  return caller(
    api.get,
    `/filter/associated_institution_manager_users`,
    models.getFilterList
  );
}

async function postAcceptAccessAmcred(data) {
  return caller(api.patch, `/user/v1/approve/${data}`, null, null);
}

async function postRefuseAccessAmcred(data) {
  return caller(api.patch, `/user/v1/reprove/${data}`, null, null);
}

async function searchDashboardAmcred(data) {
  return caller(api.post, `/dashboard/v1/amcred`, models.getDashboard, data);
}

async function searchDashboardInstitutions(data) {
  return caller(
    api.post,
    `/dashboard/v1/associeted_institutions`,
    models.getDashboard,
    data
  );
}

async function getCompanies() {
  return caller(api.get, `/company/v1/find_all`, models.getRoles);
}

async function getAllUserFullname() {
  return caller(
    api.get,
    `/user/v1/find_all_user_fullname`,
    models.getAllUserFullname
  );
}

async function searchUsersAssociated(data) {
  return caller(
    api.post,
    `/user/v1/search_associated_institutions_users`,
    null,
    data
  );
}
async function newUserAssociated(data) {
  return caller(
    api.post,
    `/user/v1/register_associated_institution`,
    null,
    data
  );
}

async function editUserAssociated(data) {
  return caller(
    api.post,
    `/user/v1/update_users/${data?.id}`,
    null,
    data?.data
  );
}
async function getRolesAssociatedList() {
  return caller(api.get, `/v1/role/find_all_institution`, models.getRoles);
}
async function getFilterAssociatedUsers() {
  return caller(
    api.get,
    `/filter/associated_institution_users`,
    models.getFilterList
  );
}

async function creditAnalysisInputs() {
  return caller(api.get, `/credit_analysis/values`, models.getFilterList);
}

async function getCreditAnalysis(data) {
  return caller(api.post, `/credit_analysis`, null, data);
}

async function getLastAnalysis() {
  return caller(api.get, `/has_signal_last`, null);
}
async function sendAnalysisResponse(data) {
  return caller(api.post, `/credit_analysis/signal_credit_release`, null, data);
}
async function sendMultipleAnalysisResponse(data) {
  return caller(
    api.post,
    `/credit_analysis/signal_credit_release_array`,
    null,
    data
  );
}
//-----------------------------TO-DO:  TROCAR ROTAS QUANDO A TAREFA FOR LIBERADA NOVAMENTE  ---------------------------------------------------------//
async function searchUsersInstitution(data) {
  return caller(api.post, `/user/v1/access_released`, null, data);
}

async function editUserInstitution(data) {
  return caller(api.post, `/user/v1/update`, null, data);
}
async function blockUserInstitution(data) {
  return caller(api.patch, `/user/v1/block/${data}`, null, null);
}
async function unblockUserInstitution(data) {
  return caller(api.patch, `/user/v1/unlock/${data}`, null, null);
}

//--------------------------------------------------------------------------------------//

async function getQueriesHistory(data) {
  return caller(api.post, `/query_history/search`, null, data);
}

async function getCpfsCnpj() {
  return caller(api.get, "/query_history/cpf_or_cnpj", null);
}

async function registerRequest(data) {
  return caller(api.post, `/auth/v1/request_access`, null, data);
}

async function validEmail(data) {
  return caller(api.get, `/user/v1/has_email_available?email=${data}`, null);
}

async function validCpf(data) {
  return caller(api.get, `/user/v1/has_cpf_available?cpf=${data}`, null);
}

async function filterReportGeneralAmcred(data) {
  return caller(
    api.post,
    `/report/v1/amcred/general`,
    models.getGeneralReport,
    data
  );
}

async function filterReportDetailAmcred(data) {
  return caller(
    api.post,
    `/report/v1/amcred/detail`,
    models.getDetailReport,
    data
  );
}

async function filterReportGeneralInstitution(data) {
  return caller(
    api.post,
    `/report/v1/associated_insitution/general`,
    models.getGeneralReportInstitution,
    data
  );
}

async function getUsernameAndRoles() {
  return caller(
    api.get,
    `/filter/associated_institution_report`,
    models.getUsernameAndRoles
  );
}

// async function filterReportDetailAmcred(data) {
//   return caller(
//     api.post,
//     `/report/v1/amcred/detail`,
//     models.getDetailReport,
//     data
//   );
// }

async function getFilterAmcred() {
  return caller(api.get, `/filter/amcred_report`, models.getFilterList);
}

async function reportDetailListInstitution(data) {
  return caller(
    api.post,
    `/report/v1/associated_insitution/detail`,
    models.getDetailReport,
    data
  );
}

async function reportGeneralListInstitution(data) {
  return caller(
    api.post,
    `./report/v1/associated_insitution/general`,
    null,
    data
  );
}

async function fullReportDetailListInstitution(data) {
  return caller(
    api.post,
    `/report/v1/associated_insitution/detail/download`,
    null,
    data
  );
}

async function fullReportDetailListAmcred(data) {
  return caller(api.post, `/report/v1/amcred/detail/download`, null, data);
}

async function fullReportGeneralListInstitution(data) {
  return caller(
    api.post,
    `/report/v1/associated_insitution/general/download`,
    models.getFullGeneralReport,
    data
  );
}

async function fullReportGeneralListAmcred(data) {
  return caller(
    api.post,
    `/report/v1/amcred/general/download`,
    models.getFullGeneralReport,
    data
  );
}

async function getValuesDatail(data) {
  return caller(api.post, `/report/v1/amcred/total`, null, data);
}
async function getValuesInstitutionDatail(data) {
  return caller(api.post, `/report/v1/associated_insitution/total`, null, data);
}
async function fullQueriesHistory(data) {
  return caller(api.post, `/query_history/download`, null, data);
}

async function getAllUndefinedQueries(data) {
  return caller(api.post, `/query_history/search_all_undefined`, null, data);
}

const apiServices = {
  getToken,
  validEmail,
  validCpf,
  registerRequest,
  getUserInfos,
  getCurrentUser,

  recoveryPasswordCode,
  sendRecoveryPasswordRequest,
  resetPasswordRequest,
  updatePassword,
  searchUsersAmcred,
  getRolesList,
  newUserAmcred,
  blockUserAmcred,
  unblockUserAmcred,
  newUserPassword,
  getAccessRequestAmcred,
  getFilterManagerUsers,
  postAcceptAccessAmcred,
  postRefuseAccessAmcred,
  searchUsersInstitution,
  editUserInstitution,
  blockUserInstitution,
  unblockUserInstitution,
  searchDashboardAmcred,
  searchDashboardInstitutions,
  getCompanies,
  getAllUserFullname,
  searchUsersAssociated,
  newUserAssociated,
  getRolesAssociatedList,
  getFilterAssociatedUsers,
  getCpfsCnpj,
  getQueriesHistory,
  creditAnalysisInputs,
  getCreditAnalysis,
  getLastAnalysis,
  sendAnalysisResponse,
  filterReportGeneralAmcred,
  filterReportDetailAmcred,
  filterReportGeneralInstitution,
  getUsernameAndRoles,
  getFilterAmcred,
  reportDetailListInstitution,
  reportGeneralListInstitution,
  fullReportDetailListInstitution,
  fullReportDetailListAmcred,
  fullReportGeneralListInstitution,
  fullReportGeneralListAmcred,
  getValuesDatail,
  getValuesInstitutionDatail,
  fullQueriesHistory,
  editUserAssociated,
  editUserAmcred,
  sendMultipleAnalysisResponse,
  getAllUndefinedQueries,
};

export default apiServices;
