import React from "react";
import styled from "styled-components";
import FontStyles from "../styles/fontsStyles";
import SkeletonLoader from "./SkeletonLoader";
import { Button, Tooltip } from "@material-ui/core";
import { ArrowDropDown, ArrowDropUp, FilterList } from "@material-ui/icons";
import CustomText from "../strings/CustomText";

const TableWrapper = styled.div(() => ({
  overflow: "auto",
}));

const Table = styled.table(({ theme, $headerBackground }) => ({
  borderSpacing: $headerBackground ? 0 : theme.spacing(0, 0.5),

  width: "100%",
}));

const HeaderRow = styled.tr(({ theme, $headerBackground }) => {
  return {
    ...FontStyles.bold12,
    backgroundColor: !$headerBackground
      ? theme.palette.background.primary
      : theme.palette.background.paper,

    textTransform: "uppercase",
    color: theme.palette.text.tertiary,
    padding: theme.spacing(2),
    textAlign: "left",
    position: "sticky",
    top: 0,
    zIndex: 10,
  };
});

const GridData = styled.tr(({ theme, $isOdd }) => ({
  backgroundColor: theme.palette.background.paper,
}));

const HeaderItem = styled.th(({ theme, $first, $last, $headerBackground }) => ({
  ...FontStyles.bold12,

  color: theme.palette.text.secondary,
  padding: $first ? theme.spacing(1, 1, 1, 2) : theme.spacing(1),

  textAlign: $last ? "end" : "start",

  whiteSpace: "normal",
  wordWrap: "break-word",
  overflowWrap: "break-word",
  borderBottom: $headerBackground && "2px solid #C5C5C5",
}));

const ColumnItem = styled.td(
  ({ theme, $first, $last, $small, $headerBackground }) => ({
    ...($small ? FontStyles.medium12 : FontStyles.medium14),
    borderBottom: $headerBackground && "2px solid #C5C5C5",
    color: theme.palette.text.primary,
    padding: $first
      ? theme.spacing(1, 1, 1, 2)
      : $last
      ? theme.spacing(1, 2, 1, 1)
      : theme.spacing(1),
    marginBlock: theme.spacing(2),
  })
);

const ItemContainer = styled.div(({ $last }) => ({
  display: "flex",
  flexDirection: "row",
  width: "100%",
  justifyContent: $last ? "flex-end" : "flex-start",
}));

const ButtonT = styled(Button)(({ theme, $color }) => {
  const { palette: colors } = theme;
  return {
    ...FontStyles.bold12,
    color:
      $color === 2
        ? colors.text.primary
        : $color === 1
        ? colors.orange
        : colors.text.secondary,
    justifyContent: "space-between",
    padding: "0",

    lineHeight: "normal",
    textAlign: "end",
    width: "auto",
    height: "auto",
  };
});

function TableComponent({
  data,
  placeholderSize = 0,
  columnSize = 0,
  renderItemColumns,
  headers,
  loading,
  id,
  withGroup,
  firstDivision,
  secondDivision,
  sortContent = null,
  sortDirection = false,
  currentSortKey = null,
  sortLast = true,
  color,
  headerBackground = false,
}) {
  const placeholderList = createArray(placeholderSize);
  const columnList = createArray(columnSize);

  return (
    <TableWrapper>
      <Table id={id} $headerBackground={headerBackground}>
        <thead style={{ position: "sticky", top: 0, zIndex: 1 }}>
          {withGroup ? (
            <HeaderRow $headerBackground={headerBackground}>
              {headers.table.map((text, key) => {
                if (typeof text === "object") {
                  return text.data.map((t, k) => {
                    if (k === 1) {
                      return text.name;
                    } else {
                      return (
                        <HeaderItem
                          $first={key === 0 ? true : false}
                          $last={key === headers.table.length - 1}
                          key={key + k}
                        >
                          {""}
                        </HeaderItem>
                      );
                    }
                  });
                } else {
                  return (
                    <HeaderItem
                      $first={key === 0 ? true : false}
                      $last={key === headers.table.length - 1}
                      key={key}
                    >
                      {" "}
                      {key === 10 ? headers.FirstRow : ""}
                    </HeaderItem>
                  );
                }
              })}
            </HeaderRow>
          ) : null}
          <HeaderRow $headerBackground={headerBackground}>
            {headers.table.map((text, key) => {
              if (typeof text === "object" && withGroup) {
                return (
                  <>
                    {text.data.map((t, k) => {
                      return (
                        <HeaderItem
                          $headerBackground={headerBackground}
                          $first={key === 0 ? true : false}
                          $loc={k !== 0 ? true : false}
                          $last={key === headers.table.length - 1}
                          key={k + key}
                        >
                          {k !== 0 ? (
                            <ButtonT
                              $color={color}
                              $small={headers.table.length > 7 ? true : false}
                              onClick={() => {
                                sortContent !== null &&
                                  sortContent(
                                    key < 7
                                      ? k + firstDivision - 1
                                      : k + secondDivision - 1,
                                    sortDirection
                                  );
                              }}
                              endIcon={
                                !sortDirection ? (
                                  <ArrowDropUp />
                                ) : (
                                  <ArrowDropDown />
                                )
                              }
                            >
                              {t}
                            </ButtonT>
                          ) : null}
                        </HeaderItem>
                      );
                    })}
                  </>
                );
              } else {
                if (key !== firstDivision || key !== secondDivision) {
                  return (
                    <HeaderItem
                      $headerBackground={headerBackground}
                      $first={key === 0 ? true : false}
                      key={key}
                      $last={key === headers.table.length - 1}
                    >
                      <ButtonT
                        $color={color}
                        $small={headers.table.length > 7 ? true : false}
                        onClick={() => {
                          if (sortLast) {
                            if (sortContent !== null) {
                              sortContent(headers.keys[key], !sortDirection);
                            }
                          } else if (key < headers.table.length - 1) {
                            if (sortContent !== null) {
                              sortContent(headers.keys[key], !sortDirection);
                            }
                          }
                        }}
                        endIcon={
                          !sortContent ||
                          (key >= headers.table.length - 1 &&
                            !sortLast) ? undefined : currentSortKey ===
                            headers?.keys[key] ? (
                            !sortDirection ? (
                              <Tooltip
                                title={
                                  <CustomText
                                    textColor={"white"}
                                    fontSize={1.6}
                                  >
                                    Ordem decrescente
                                  </CustomText>
                                }
                                aria-label="Decrescente"
                              >
                                <ArrowDropUp />
                              </Tooltip>
                            ) : (
                              <Tooltip
                                title={
                                  <CustomText
                                    textColor={"white"}
                                    fontSize={1.6}
                                  >
                                    Ordem crescente
                                  </CustomText>
                                }
                                aria-label="Crescente"
                              >
                                <ArrowDropDown />
                              </Tooltip>
                            )
                          ) : (
                            <Tooltip
                              title={
                                <CustomText textColor={"white"} fontSize={1.6}>
                                  {!sortDirection
                                    ? "Ordem decrescente"
                                    : "Ordem crescente"}
                                </CustomText>
                              }
                              aria-label={
                                !sortDirection ? "Decrescente" : "Crescente"
                              }
                            >
                              <FilterList />
                            </Tooltip>
                          )
                        }
                      >
                        {text}
                      </ButtonT>
                    </HeaderItem>
                  );
                } else {
                  return (
                    <>
                      <td
                        style={{
                          width: "10px",
                          padding: 0,
                          margin: 0,
                        }}
                      ></td>
                      <HeaderItem
                        $headerBackground={headerBackground}
                        $first={key === 0 ? true : false}
                        key={key}
                        $last={key === headers.table.length - 1}
                      >
                        {text}
                      </HeaderItem>
                    </>
                  );
                }
              }
            })}
          </HeaderRow>
        </thead>
        <tbody>
          {data && !loading
            ? data.map((item, index) => (
                <GridData $isOdd={(index + 1) % 2 === 1} key={index}>
                  {renderItemColumns(item, index).map((itemToRender, key) => (
                    <ColumnItem
                      $headerBackground={headerBackground}
                      $small={headers.table.length > 7 ? true : false}
                      key={key}
                      $first={key === 0 ? true : false}
                      $last={
                        key === renderItemColumns(item).length - 1
                          ? true
                          : false
                      }
                    >
                      <ItemContainer
                        $last={
                          key === renderItemColumns(item).length - 1
                            ? true
                            : false
                        }
                      >
                        {itemToRender}
                      </ItemContainer>
                    </ColumnItem>
                  ))}
                </GridData>
              ))
            : placeholderList.map((keyI) => (
                <GridData $isOdd={(keyI + 1) % 2 === 1} key={keyI}>
                  {columnList.map((KeyJ) => (
                    <ColumnItem
                      $headerBackground={headerBackground}
                      $small={headers.table.length > 7 ? true : false}
                      key={KeyJ}
                      $first={KeyJ === 0 ? true : false}
                      $last={KeyJ === columnList.length - 1 ? true : false}
                    >
                      <ItemContainer>
                        <SkeletonLoader width="100%">&nbsp;</SkeletonLoader>
                      </ItemContainer>
                    </ColumnItem>
                  ))}
                </GridData>
              ))}
        </tbody>
      </Table>
    </TableWrapper>
  );
}

export default React.memo(TableComponent);

function createArray(size) {
  const newArray = new Array(size);
  let i = 0;
  const iMax = size;
  for (; i < iMax; i++) {
    newArray[i] = i;
  }
  return newArray;
}
