import React, { Component } from "react";

import Input from "./Input";

export default class InputNumber extends Component {
  render() {
    const { ...rest } = this.props;

    return (
      <Input
        {...rest}
        type="number"
        step={rest.customStep ? rest.customStep : "0.01"}
      />
    );
  }
}
