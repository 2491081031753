export default function getUsernameAndRoles(response) {
  function transformarObjeto(objeto) {
    const novoObjeto = {};

    for (const chave in objeto) {
      if (objeto.hasOwnProperty(chave)) {
        const arrayDeString = objeto[chave];
        const novoArray = [];

        for (const string of arrayDeString) {
          if (typeof string === "string") {
            novoArray.push({ label: string, value: string });
          } else if (typeof string === "object") {
            novoArray.push({
              label: string?.name ? string?.name : string?.fullName,
              value: string?.id,
            });
          }
        }

        novoObjeto[chave] = novoArray;
      }
    }

    return novoObjeto;
  }

  const data = transformarObjeto(response.data.response);

  return data;
}
