import { CircularProgress, Grid } from "@material-ui/core";
import React, { useCallback, useEffect, useState } from "react";
import { connect } from "react-redux";
import { IntegratedComponentHolder } from "../../../components";
import { customModal } from "../../modals/utils";

import { Filters, TableUsers } from "../components";
import ModalBlock from "../components/ModalBlock";
import ModalRegister from "../components/ModalRegister";
import { Creators } from "../reduxSagas";

import { Creators as GlobalCreators } from "../../globalReduxSagas";

import Styles from "../styles/Styles";
const pageSize = 8;
function RegisterAndQueryInstitution({
  searchUsersInstitution,
  institutionList,
  lastFilter,
  isFetching,
  getFilterManagerUsers,
  filterManagerUsers,
  editUserInstitution,
  unblockUserInstitution,
  blockUserInstitution,
  rolesList,
  getRolesList,
}) {
  const rowsWithSort = [];
  const [isMounted, setIsMounted] = useState(false);
  const [page, setPage] = useState(0);
  const [minH, setHeight] = useState("100vh");
  const [sortKey, setSortKey] = useState("dateRequest");
  const [sortDirection, setSortDirection] = useState(true);

  const searchUsers = (filters, user) => {
    if (user === null || user === undefined) {
      setPage(0);
    }

    searchUsersInstitution({
      searchParameters: {
        ...filters,
        order: sortKey,
        typeOrder: sortDirection === true ? "ASC" : "DESC",
      },
      page: {
        size: pageSize,
        page: user ? page : 0,
      },
    });
  };

  const sortContent = (filter, user, order, type) => {
    searchUsersInstitution({
      searchParameters: { ...filter, order: order, typeOrder: type },
      page: {
        size: pageSize,
        page:
          user?.pageTotal > 0 &&
          institutionList?.pageTotal <= page + 1 &&
          (institutionList === null || institutionList === undefined)
            ? institutionList?.pageTotal - 1
            : page,
      },
    });
  };

  const Mount = useCallback(() => {
    setIsMounted(true);
    getRolesList();
    getFilterManagerUsers();
    const now = new Date();
    const year = now.getFullYear();
    const month = now.getMonth() + 1; // Os meses em JavaScript são indexados de 0 a 11

    const begin = new Date(year, month - 1, 1);
    const end = now;

    searchUsersInstitution({
      searchParameters: {
        order: "dateRequest",
        typeOrder: "ASC",
        begin: begin,
        end: end,
      },
      page: { size: pageSize, page: 0 },
    });
  }, [getFilterManagerUsers, getRolesList, searchUsersInstitution]);

  useEffect(Mount, [Mount]);

  useEffect(() => {
    if (isMounted) {
      const newH = document.getElementById("header-menu");
      setHeight(newH?.offsetHeight);
    }
  }, [isMounted]);

  const openNewUserModal = (user) => {
    customModal.setInfos(
      "EDITAR CADASTRO DE INSTITUIÇÃO ASSOCIADA",
      [],
      null,
      null,
      <>
        <ModalRegister
          user={user}
          roleList={rolesList}
          onSubmit={(data) => {
            customModal.close();
            editUserInstitution({ ...data, id: user?.id }, () => {
              customModal.setInfos(
                "EDIÇÃO REALIZADO",
                [],
                null,
                null,
                <ModalBlock
                  rolesList={rolesList}
                  user={{
                    ...user,
                    requester: data?.firstName + " " + data?.lastName,
                  }}
                  viewOnly
                  onSubmit={() => customModal.close()}
                />
              );
              searchUsers(lastFilter?.searchParameters, institutionList);
            });
          }}
        />
      </>
    );
  };

  const openBlockModal = (user) => {
    customModal.setInfos(
      "BLOQUEAR ACESSO",
      [],
      null,
      null,
      <>
        <ModalBlock
          rolesList={rolesList}
          user={user}
          onSubmit={() => {
            blockUserInstitution(user?.id, () => {
              customModal.close();
              customModal.setInfos(
                "Sucesso!",
                ["Usuário bloqueado com sucesso!"],
                {
                  onClick: () => {
                    customModal.close();
                  },
                  label: "Ok",
                }
              );
              searchUsers(lastFilter?.searchParameters, institutionList);
            });
          }}
        />
      </>
    );
  };

  const openUnblock = (user) => {
    customModal.setInfos(
      "DESBLOQUEAR ACESSO",
      [],
      null,
      null,
      <>
        <ModalBlock
          unblock
          user={user}
          rolesList={rolesList}
          onSubmit={() => {
            unblockUserInstitution(user?.id, () => {
              customModal.close();
              customModal.setInfos(
                "Sucesso!",
                ["Usuário Desbloqueado com sucesso!"],
                {
                  onClick: () => {
                    customModal.close();
                  },
                  label: "Ok",
                }
              );
              searchUsers(lastFilter?.searchParameters, institutionList);
            });
          }}
        />
      </>
    );
  };

  const openViewModal = (user) => {
    customModal.setInfos(
      "VER MAIS DETALHES",
      [],
      null,
      null,
      <>
        <ModalBlock
          viewOnly={2}
          rolesList={rolesList}
          user={user}
          onSubmit={() => {
            customModal.close();
          }}
        />
      </>
    );
  };
  const clearFilter = () => {
    setSortKey("dateRequest");
    setSortDirection(true);
  };

  return (
    <Styles.Container minH={minH}>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Filters
            listInputs={filterManagerUsers}
            onSubmit={(data) => searchUsers(data, institutionList)}
            onClear={clearFilter}
          />
        </Grid>
        <Grid item xs={12}>
          <IntegratedComponentHolder
            showReload={!isFetching && institutionList?.elements?.length < 1}
            reloadMessage={"Não encontrado"}
            showEmpty={!isFetching && institutionList?.elements?.length < 1}
            emptyMessage={"Lista Vazia"}
            maxSize={"62rem"}
          >
            {isFetching ? (
              <div
                style={{
                  width: "100%",
                  height: "40vh",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <CircularProgress color="primary" />
              </div>
            ) : (
              <TableUsers
                setSortKey={setSortKey}
                setSortDirection={setSortDirection}
                sortKey={sortKey}
                sortDirection={sortDirection}
                list={institutionList?.elements}
                onBlock={openBlockModal}
                onUnblock={openUnblock}
                onEdit={openNewUserModal}
                onView={openViewModal}
                lastFilter={lastFilter?.searchParameters}
                user={institutionList}
                sort={sortContent}
                page={page + 1}
                setPage={(a) => {
                  setPage(a - 1);

                  searchUsersInstitution(
                    {
                      searchParameters: lastFilter?.searchParameters,
                      page: { size: pageSize, page: a - 1 },
                    },
                    institutionList
                  );
                }}
                sortList={rowsWithSort}
                totalPages={institutionList?.pageTotal}
              />
            )}
          </IntegratedComponentHolder>
        </Grid>
      </Grid>
    </Styles.Container>
  );
}

function mapStateToProps(redux) {
  const {
    isFetching: isFetchingPage,
    institutionList,
    lastFilter,
  } = redux.registerAndQueryInstitution;
  const {
    isFetching: isFetchingGlobal,
    rolesList,
    filterManagerUsers,
  } = redux.global;

  return {
    isFetching: isFetchingPage || isFetchingGlobal,
    institutionList,
    lastFilter,
    rolesList,
    filterManagerUsers,
  };
}

function mapDispatchToProps(dispatch) {
  const {
    searchUsersInstitution,
    editUserInstitution,
    unblockUserInstitution,
    blockUserInstitution,
  } = Creators;
  const { getRolesList, getFilterManagerUsers } = GlobalCreators;

  return {
    getFilterManagerUsers: function () {
      return dispatch(getFilterManagerUsers());
    },
    searchUsersInstitution: function (data) {
      return dispatch(searchUsersInstitution(data));
    },
    editUserInstitution: function (data, callback) {
      return dispatch(editUserInstitution(data, callback));
    },
    blockUserInstitution: function (data, callback) {
      return dispatch(blockUserInstitution(data, callback));
    },
    unblockUserInstitution: function (data, callback) {
      return dispatch(unblockUserInstitution(data, callback));
    },
    getRolesList: function () {
      return dispatch(getRolesList());
    },
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(React.memo(RegisterAndQueryInstitution));
