import { call, put } from "redux-saga/effects";
import { api } from "../../../services";
import { Creators } from "./index";

import { Alerts } from "../../../lib";

export default function* getAllUndefinedQueries({ data, callback }) {
  try {
    const dataRequest = {
      cpfOrCnpj:
        data?.searchParameters?.cpfOrCnpj === "-1"
          ? null
          : data?.searchParameters?.cpfOrCnpj,
      begin: data?.searchParameters?.begin
        ? data?.searchParameters?.begin
        : null,
      end: data?.searchParameters?.end ? data?.searchParameters?.end : null,
    };

    const response = yield call(api.getAllUndefinedQueries, {
      searchParameters: dataRequest,
    });

    yield put(Creators.getAllUndefinedQueriesSuccess());

    if (response?.data) {
      callback(response?.data);
    }
  } catch (response) {
    Alerts.alertError(
      response?.data?.log
        ? response?.data?.log
        : "Não foi possível encontrar o histórico"
    );

    yield put(Creators.getAllUndefinedQueriesFailure());
  }
}
