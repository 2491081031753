import styled from "styled-components";
import { makeStyles } from "@material-ui/core";
import { FontStyles } from "../../../components";
import { MediaQueries } from "../../../config";
function hexToRGB(hex, alpha) {
  var r = parseInt(hex.slice(1, 3), 16),
    g = parseInt(hex.slice(3, 5), 16),
    b = parseInt(hex.slice(5, 7), 16);

  if (alpha) {
    return "rgba(" + r + ", " + g + ", " + b + ", " + alpha + ")";
  } else {
    return "rgb(" + r + ", " + g + ", " + b + ")";
  }
}
function hexToRGBDarker(hex, alpha = 100) {
  let r = parseInt(hex.slice(1, 3), 16),
    g = parseInt(hex.slice(3, 5), 16),
    b = parseInt(hex.slice(5, 7), 16);
  r = parseInt(r * 0.94);
  g = parseInt(g * 0.94);
  b = parseInt(b * 0.94);
  if (alpha) {
    return "rgba(" + r + ", " + g + ", " + b + ", " + alpha + ")";
  } else {
    return "rgb(" + r + ", " + g + ", " + b + ")";
  }
}
const useStyles = makeStyles((theme, $color) => {
  const { palette: colors, spacing } = theme;
  return {
    icon: {
      paddingRight: spacing(0.625),
      width: spacing(3),
      height: spacing(3),
      marginRight: spacing(0.5),

      fill:
        $color === 2
          ? colors.text.primary
          : $color === 1
          ? colors.background.tertiary
          : colors.white,
      filter: "invert(1)",
      minWidth: 24,
    },
    subicon: {
      paddingRight: spacing(0.625),
      width: "auto",
      height: "0.8rem",
      marginRight: spacing(0.5),
    },
    iconFill: {
      paddingRight: spacing(0.625),
      width: "1.5rem",
      height: "1.5rem",
      marginRight: spacing(0.5),
      fill: colors.background.secondary,
    },
  };
});

const Container = styled.div(({ theme, $color }) => {
  const { palette: colors } = theme;
  return {
    display: "flex",
    flexDirection: "column",
    overflow: "hidden",
    height: "100vh",
    width: "min-content",
    justifyContent: "space-between",
    background:
      $color === 2
        ? colors.text.primary
        : $color === 1
        ? `linear-gradient(0deg, ${hexToRGBDarker(
            colors.background.associated,
            100
          )} 0%, ${hexToRGB(colors.background.associated, 100)} 100%)`
        : `linear-gradient(0deg, ${hexToRGBDarker(
            colors.background.secondary,
            100
          )} 0%, ${hexToRGB(colors.background.secondary, 100)} 100%)`,
    position: "relative",
    boxShadow: "0px 0px 4px #00000014",
    maxWidth: "20vw",
    minWidth: "20vw",
    zIndex: 90,
    [MediaQueries.smDown]: {
      maxWidth: "100vw",
      minWidth: "20vw",
    },
  };
});

const ShowQuery = styled.div(({ theme }) => {
  const { palette: colors } = theme;
  return {
    display: "flex",
    flexDirection: "column",
    overflow: "hidden",
    height: "100vh",
    width: "min-content",
    backgroundColor: colors.background.secondary,
    position: "relative",
    boxShadow: "0px 0px 4px #00000014",
    maxWidth: "20vw",
    minWidth: "20vw",
    zIndex: 90,
    [MediaQueries.smDown]: {
      width: 0,
      maxWidth: 0,
      minWidth: 0,
    },
  };
});

const Background = styled.div((props) => {
  return {
    marginLeft: "auto",
    height: "100%",
  };
});

const SubLogo = styled.p(({ theme }) => {
  const { spacing, palette: colors } = theme;
  return {
    ...FontStyles.bold16,
    marginInline: spacing(3),
    letterSpacing: 1,
    fontWeight: "600",
    color: colors.text.primary,
    marginTop: spacing(3),
  };
});

const Line = styled.div(({ theme }) => {
  const { palette: colors } = theme;
  return {
    backgroundColor: colors.white,
    width: "100%",
    height: 1,
  };
});

const ScrollContainer = styled.div(({ theme }) => {
  const { spacing } = theme;
  return {
    overflow: "auto",
    width: "100%",
    marginRight: spacing(3.75),
  };
});

const Group = styled.div(({ theme, $withoutPadding }) => {
  const { spacing } = theme;
  return {
    display: "flex",
    flexDirection: "column",
    paddingBottom: $withoutPadding ? 0 : spacing(1),
    marginTop: spacing(2),
    [MediaQueries.mdDown]: {
      padding: spacing(0, 2, $withoutPadding ? 0 : 1, 2),
    },
    [MediaQueries.smDown]: {},
  };
});

const GroupTitle = styled.p((props) => ({
  margin: 0,
  color: props.theme.palette.text.tertiary,
  ...FontStyles.semibold12,
}));

const GroupNameContainer = styled.div({
  display: "flex",
  alignItems: "center",
});

const GroupTitleContainer = styled.div(({ theme }) => {
  const { spacing } = theme;
  return {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: spacing(0, 5),
    marginBottom: spacing(1),
    [MediaQueries.mdDown]: {
      padding: spacing(0, 2),
    },
  };
});

const Page = styled.a(
  ({
    theme,
    $disabled,
    $buttonContained,
    $currentPage,
    alternative,
    subPages,
  }) => {
    const { spacing, palette: colors } = theme;
    return {
      justifyContent: " space-between",
      color: $buttonContained
        ? colors.background.secondary
        : colors.text.secondary,
      cursor: $disabled || subPages ? "default" : "pointer",
      display: "flex",
      alignItems: "center",
      opacity: $disabled ? 0.55 : 1,
      margin: $buttonContained ? "auto" : null,
      marginLeft: spacing(5),
      ...FontStyles.medium12,

      "&:hover": {
        backgroundColor:
          $disabled || alternative ? null : colors.text.primary + 30,
      },
      borderRight: $currentPage ? `${spacing(0.5)}px solid white` : null,
      [MediaQueries.mdDown]: {
        padding: spacing(0, 1),
        marginLeft: 0,
      },
    };
  }
);

const PageLine = styled.div(
  ({
    theme,

    $currentPage,
  }) => {
    const { spacing } = theme;
    return {
      borderRight: $currentPage ? `${spacing(1)}px solid white` : null,
      paddingRight: spacing(0.5),
    };
  }
);

const PageContent = styled.div(
  ({ theme, alternative, $color, $currentPage }) => {
    const { spacing, palette: colors } = theme;
    return {
      color: !alternative
        ? colors.text.tertiary
        : $color === 2
        ? colors.text.primary
        : $color === 1
        ? colors.background.tertiary
        : colors.background.secondary,
      ...FontStyles.medium14,
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-start",
      marginRight: spacing(3),
      width: "max-content",
      marginBlock: spacing(0.25),
      backgroundColor: alternative && "white",
      paddingInline: alternative && spacing(2),
      paddingBlock: alternative && spacing(0.4),
      borderRadius: alternative && spacing(20),
    };
  }
);
const Premium = styled.div(({ theme }) => {
  const { spacing, palette: colors } = theme;
  return {
    background: "white",
    color: colors.text.tertiary,
    borderRadius: spacing(3),
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: spacing(0, 2),
  };
});
const SubGroup = styled.div(({ props }) => ({
  transition: "1s",
  overflow: "hidden",
  height: "unset",
}));

const ContactContent = styled.div(({ theme }) => {
  const { spacing, palette: colors } = theme;
  return {
    width: "100%",
    padding: spacing(3.5),
    backgroundColor: colors.background.input,
  };
});

const TitleModal = styled.p(({ theme }) => {
  const { palette: colors } = theme;
  return {
    ...FontStyles.bold14,
    textTransform: "uppercase",
    color: colors.text.secondary,
  };
});

const InfoModal = styled.p(({ theme }) => {
  const { palette: colors } = theme;
  return {
    ...FontStyles.medium16,
    color: colors.text.secondary,
  };
});
const ModalPadding = styled.div(({ theme }) => {
  const { spacing } = theme;
  return {
    paddingInline: spacing(3.5),
    overflow: "auto",
    height: "100%",
  };
});
const Notification = styled.p(({ theme }) => {
  const { palette: colors, spacing } = theme;
  return {
    ...FontStyles.bold10,
    color: colors.text.tertiary,
    backgroundColor: colors.white,
    borderRadius: spacing(1.25),
    letterSpacing: spacing(0.041),
    padding: spacing(0.25, 1.5),

    marginRight: spacing(1.25),
  };
});

const BlueBullet = styled.li(({ theme }) => {
  const { palette: colors } = theme;
  return {
    color: colors.blue,
    fontSize: 20,
  };
});
const Header = styled.div(({ theme }) => {
  const { spacing } = theme;
  return {
    filter: "brightness(0) invert(1)",
    padding: spacing(3.5, 2.2),
    paddingLeft: spacing(5),
    paddingBottom: spacing(2),
  };
});

const Icon = styled.img(({ theme, $color }) => {
  const { spacing } = theme;
  return {
    paddingRight: spacing(0.625),
    width: spacing(3),
    height: spacing(3),
    marginRight: spacing(0.5),

    filter:
      $color === 2
        ? "invert(15%) sepia(0%) saturate(2790%) hue-rotate(256deg) brightness(106%) contrast(81%)"
        : $color === 1
        ? "invert(49%) sepia(94%) saturate(313%) hue-rotate(75deg) brightness(98%) contrast(93%)"
        : "invert(1)",

    minWidth: 24,
  };
});

const Styles = {
  Header,
  Container,
  Background,
  SubLogo,
  Line,
  GroupTitle,
  Group,
  GroupNameContainer,
  useStyles,
  Page,
  GroupTitleContainer,
  SubGroup,
  ScrollContainer,

  Premium,
  ContactContent,
  InfoModal,
  TitleModal,
  ModalPadding,
  Notification,
  PageContent,
  BlueBullet,
  ShowQuery,
  PageLine,
  Icon,
};

export default Styles;
