import React from "react";
import { connect } from "react-redux";
import Title from "../../../components/strings/Title";
import { Texts } from "../../../config";
import { InputEmail, Select, Input } from "../../../components";
import { FormHolder } from "../../../FormConfig";
import { Styles } from "../styles";
import { Creators } from "../reduxSagas";
import { navigateTo } from "../../../navigation/navigate";
import { Images } from "../../../config";
import { Filters, Validations } from "../../../lib";
import { Grid } from "@material-ui/core";
import { customModal } from "../../modals/utils";

function RegisterRequest({
  registerRequest,
  isFetching,
  validCpf,
  validEmail,
}) {
  const texts = Texts["pt-BR"].registry;

  const onSubmit = (data) => {
    registerRequest(data, () => {
      customModal.setInfos(
        "SOLICITAÇÃO DE ACESSO ENVIADA",
        [
          "Sua solicitação de acesso foi realizada com sucesso! Assim que o seu acesso for avaliado pela AMCRED avisaremos você por e-mail.",
        ],
        {
          onClick: () => {
            navigateTo.Landing();
            customModal.close();
          },
          label: "OK, ENTENDI",
        },
        null,
        null,
        true
      );
    });
  };

  return (
    <>
      <Styles.Container>
        <Styles.BackgroundImage src={Images.Background} />
        <Styles.Content>
          <Styles.Header>
            <Styles.AmcredLogo src={Images.Logo} />
          </Styles.Header>
          <Title>{texts.title}</Title>
          <Styles.LoginSubtitle>{texts.subtitle}</Styles.LoginSubtitle>
          <Styles.Line />
          <FormHolder onSubmit={onSubmit}>
            <Grid
              style={{ display: "flex", alignItems: "flex-end" }}
              container
              spacing={1}
            >
              <Grid item xs={12} md={6}>
                <Input
                  placeholder="Nome da Instituição Associada"
                  name="companyName"
                  required={texts.userMessage}
                  inputLabel={texts.companyName}
                  requiredField
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <Input
                  placeholder="00.000.000/0000-00"
                  name="cnpj"
                  required={texts.userMessage}
                  inputLabel={texts.cnpj}
                  mask={Filters.cnpjMask}
                  validation={Validations.isCNPJ}
                  requiredField
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <Input
                  placeholder="Nome"
                  name="name"
                  required={texts.userMessage}
                  inputLabel={texts.name}
                  requiredField
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <Input
                  placeholder="Sobrenome"
                  name="lastName"
                  required={texts.userMessage}
                  inputLabel={texts.lastName}
                  requiredField
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <Input
                  placeholder="000.000.000-00"
                  name="cpf"
                  required={texts.userMessage}
                  inputLabel={texts.stepTwo?.cpf}
                  mask={Filters.cpfMask}
                  validation={Validations.isCPF}
                  requiredField
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <Select
                  readOnly
                  placeholder="Selecione"
                  name="profile"
                  defaultValue={1}
                  options={[{ label: "Gestor", value: 1 }]}
                  required={texts.userMessage}
                  inputLabel={texts.profile}
                  requiredField
                />
              </Grid>
              <Grid item xs={12} md={12}>
                <InputEmail
                  placeholder="E-mail"
                  name="email"
                  nextInput="password"
                  required={texts.userMessage}
                  inputLabel={texts.email}
                  requiredField
                />
              </Grid>
            </Grid>

            <Styles.ConfirmButton
              loading={isFetching}
              type="submit"
              name="enter"
            >
              {texts.enter}
            </Styles.ConfirmButton>
            <Styles.AskAccessButton
              onClick={navigateTo.Landing}
              loading={isFetching}
            >
              {texts.firstAccess}
            </Styles.AskAccessButton>
          </FormHolder>
        </Styles.Content>
      </Styles.Container>
    </>
  );
}

function mapStateToProps(state) {
  const { isFetching } = state.registerRequest;
  return {
    isFetching,
  };
}

function mapDispatchToProps(dispatch) {
  const { registerRequest, validCpf, validEmail } = Creators;
  return {
    registerRequest: function (data, callback) {
      return dispatch(registerRequest(data, callback));
    },
    validCpf: function (data, callback) {
      return dispatch(validCpf(data, callback));
    },
    validEmail: function (data, callback) {
      return dispatch(validEmail(data, callback));
    },
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(React.memo(RegisterRequest));
