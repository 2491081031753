import { call, put } from "redux-saga/effects";
import { api } from "../../../services";
import { Creators } from "./index";
import { Alerts, Filters } from "../../../lib";

export default function* fullReport({ data, id, callback }) {
  try {
    let route = api.fullReportGeneralListAmcred;
    let newData = {};
    if (id === 1) {
      const namesIds = data?.searchParameters?.userIds?.map((item) => {
        return item.value;
      });

      newData = {
        begin: !data?.searchParameters?.begin
          ? null
          : data?.searchParameters?.begin,
        end: !data?.searchParameters?.end ? null : data?.searchParameters?.end,
        roleId:
          !data?.searchParameters?.roleId ||
          data?.searchParameters?.roleId === "-1"
            ? null
            : parseInt(data?.searchParameters?.roleId),
        userIds: namesIds?.length > 0 ? namesIds : null,
      };
      route = api.fullReportGeneralListInstitution;
    } else {
      const companiesIds = data?.searchParameters?.companies?.map((item) => {
        return item.value;
      });
      newData = {
        begin: !data?.searchParameters?.begin
          ? null
          : data?.searchParameters?.begin,
        end: !data?.searchParameters?.end ? null : data?.searchParameters?.end,
        cnpj:
          !data?.searchParameters?.cnpj || data?.searchParameters?.cnpj === "-1"
            ? null
            : data?.searchParameters?.cnpj,
        companies: companiesIds?.length > 0 ? companiesIds : null,
      };
      route = api.fullReportGeneralListAmcred;
    }

    const response = yield call(route, newData);
    yield put(Creators.fullReportSuccess());

    let newResponse = [];
    response?.response?.map((item) => {
      return newResponse.push({
        companyCnpj: item?.companyCnpj,
        companyName: item?.companyName,
        createAt: item?.createAt,
        queryQuantity: item?.queryQuantity,
        totalValue: item?.totalValue,
        fullName: item?.fullName,
        roleName: item?.roleName,
        monetary: `R$ ${Filters.convertMoneyTextMask(item?.totalValue)}`,
        exportMonetary: item?.totalValue?.toString()?.replace(".", ","),
      });
    });
    callback(newResponse);
  } catch (response) {
    Alerts.alertError(
      response?.data?.log
        ? response?.data?.log
        : "Falha ao recuperar data para exportar o relatório"
    );
    yield put(Creators.fullReportFailure());
  }
}
