import { IconButton } from "@material-ui/core";
import styled from "styled-components";
import {
  FontStyles,
  Text,
  ButtonContained,
  ButtonOutlined,
} from "../../../components";
const Container = styled.div(({ theme, minH }) => {
  const { spacing } = theme;
  return {
    display: "flex",
    overflow: "auto",
    height: `calc(100vh - ${minH}px)`,
    width: "100%",
    justifyContent: "center",
    alignItems: "flex-start",
    padding: spacing(3.75, 3.125),
  };
});

const Content = styled.div(({ theme }) => {
  const { spacing } = theme;
  return {
    backgroundColor: "white",
    borderRadius: spacing(0.625),
    width: "100%",
    padding: spacing(2.625, 1.875),
    overflow: "auto",
  };
});

const HeaderCard = styled.div(({ theme }) => {
  return {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  };
});

const Line = styled.div(({ theme }) => {
  const { spacing, palette: colors } = theme;
  return {
    width: "100%",
    height: spacing(0.1),
    marginBottom: spacing(1),
    backgroundColor: colors.carbon,
    opacity: 0.7,
  };
});

const TitleCard = styled(Text)(({ theme }) => {
  return {
    ...FontStyles.semibold14,
  };
});

const NewUser = styled(ButtonContained)(({ theme, $alternative }) => {
  const { spacing, palette: colors } = theme;
  return {
    ...FontStyles.semibold12,
    padding: spacing(0.9, 2),
    lineHeight: "8px",
    backgroundColor: $alternative ? colors.text.secondary : colors.text.primary,
    textTransform: "capitalize",
    "& .MuiSvgIcon-root": {
      maxHeight: spacing(1.875),
    },
  };
});
const FormButtons = styled.div(({ theme }) => {
  return {
    flexDirection: "row",
    display: "flex",
    gap: 8,
    justifyContent: "flex-end",
  };
});
const FormButton = styled(ButtonContained)(({ theme }) => {
  return {
    ...FontStyles.medium14,
    textTransform: "capitalize",
  };
});
const FormButtonOutlined = styled(ButtonOutlined)(({ theme }) => {
  return {
    ...FontStyles.medium14,
    textTransform: "capitalize",
  };
});
const Hide = styled(IconButton)(({ theme }) => {
  const { spacing, palette: colors } = theme;
  return {
    padding: spacing(0.5),

    backgroundColor: "transparent",
    color: colors.text.primary,
    "& .MuiSvgIcon-root": {
      width: spacing(1.875),
      height: spacing(1.875),
    },
  };
});

const DeleteUser = styled(ButtonContained)(({ theme, $secondColor, off }) => {
  const { spacing, palette: colors } = theme;
  return {
    ...FontStyles.semibold12,
    minWidth: $secondColor ? 0 : null,
    backgroundColor: $secondColor ? colors.carbon : !off ? "gray" : null,
    padding: $secondColor ? spacing(0.975, 1.5) : spacing(0.9, 2),
    margin: 0,
    "& .MuiButton-startIcon": {
      marginRight: $secondColor ? 0 : 0,
    },
    ":hover": {
      backgroundColor: $secondColor ? colors.carbon + "CC" : null,
    },
  };
});

const ManagerButton = styled(ButtonOutlined)(({ theme }) => {
  const { spacing } = theme;
  return {
    ...FontStyles.semibold12,
    padding: spacing(0.6, 2),
  };
});
const ModalPadding = styled.div(({ theme }) => {
  const { spacing } = theme;
  return {
    paddingInline: spacing(3.5),
  };
});
const ModalInfo = styled.div(({ theme }) => {
  const { spacing, palette: colors } = theme;
  return {
    backgroundColor: colors.background.input,
    padding: spacing(3.75),
  };
});
const ModalUserTitle = styled(Text)(({ theme }) => {
  const { spacing, palette: colors } = theme;
  return {
    ...FontStyles.bold14,
    color: colors.text.secondary,
    marginBottom: spacing(1),
  };
});
const ModalUserInfo = styled(Text)(({ theme }) => {
  const { palette: colors } = theme;
  return {
    ...FontStyles.medium16,
    color: colors.text.secondary,
  };
});

const ModalContent = styled.div(({ theme, noPadding }) => {
  const { spacing } = theme;
  return {
    backgroundColor: "white",
    borderRadius: spacing(0.625),
    width: "100%",
    padding: noPadding ? 0 : spacing(2.5),
    overflow: "auto",
    paddingTop: spacing(1),
    paddingBottom: 0,
  };
});
const HeaderModal = styled.div(({ theme }) => {
  const { spacing } = theme;
  return {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    paddingBottom: spacing(1.5),
  };
});

const UserInfo = styled.div(({ theme }) => {
  const { spacing, palette: colors } = theme;
  return {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: spacing(2.5),
    backgroundColor: colors.background.paper,
    marginInline: spacing(-2.5),
    marginBottom: spacing(1.5),
  };
});
const UserInfoCard = styled.div(({ theme }) => {
  const { spacing } = theme;
  return {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",

    gap: spacing(1),
  };
});
const Styles = {
  Container,
  Content,
  HeaderCard,
  TitleCard,
  NewUser,
  DeleteUser,
  Line,
  ModalPadding,
  ManagerButton,
  ModalInfo,
  ModalUserInfo,
  ModalUserTitle,
  Hide,
  FormButton,
  FormButtonOutlined,
  FormButtons,
  ModalContent,
  HeaderModal,
  UserInfo,
  UserInfoCard,
};

export default Styles;
