import { call, put } from "redux-saga/effects";
import { api } from "../../../services";
import { Creators } from "./index";
import { Alerts } from "../../../lib";

export default function* getInputValues() {
  try {
    const response = yield call(api.creditAnalysisInputs);

    yield put(Creators.getInputValuesSuccess(response));
  } catch (response) {
    Alerts.alertError("Erro ao recuperar opções dos inputs. ");
    yield put(Creators.getInputValuesFailure());
  }
}
