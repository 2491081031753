import getToken from "./getToken";
import getUserInfos from "./getUserInfos";
import getCurrentUser from "./getCurrentUser";
import getSelectData from "./getSelectData";
import getCities from "./getCities";
import getRoles from "./getRoles";
import getAllUserFullname from "./getAllUserFullname";
import getCompanies from "./getCompanies";

import getProfessionals from "./getProfessionals";
import getFilterList from "./getFilterList";
import getBarChart from "./getBarChart";
import getDashboard from "./getDashboard";

import getGeneralReport from "./getGeneralReport";
import getDetailReport from "./getDetailReport";
import getGeneralReportInstitution from "./getGeneralReportInstitution";
import getUsernameAndRoles from "./getUsernameAndRoles";
import getFullDetailReport from "./getFullDetailReport";
import getFullGeneralReport from "./getFullGeneralReport";
import getIntBarChart from "./getIntBarChart";
const models = {
  getToken,
  getUserInfos,
  getCurrentUser,
  getSelectData,
  getCities,
  getProfessionals,
  getRoles,
  getAllUserFullname,
  getFilterList,
  getBarChart,
  getDashboard,
  getCompanies,
  getGeneralReport,
  getDetailReport,
  getGeneralReportInstitution,
  getUsernameAndRoles,
  getFullDetailReport,
  getFullGeneralReport,
  getIntBarChart,
};

export default models;
