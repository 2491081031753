import React from "react";
import Styles from "../styles/Styles";
import { Spacing, SVG, Texts } from "../../../config";
import { CustomText, SkeletonLoader } from "../../../components";
import { Filters } from "../../../lib";
import fonts from "../../../config/fonts";
import colors from "../../../config/colors";

function HeaderExportList({
  isFetching,
  exportData,
  detailReportList,
  role,
  totalValues,
}) {
  const texts = Texts["pt-BR"].DetailReport.header;

  return (
    <Styles.Content>
      <Styles.CardHeader>
        <Styles.CardTitle>{texts.title}</Styles.CardTitle>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            flexDirection: "row",
          }}
        >
          {totalValues && (
            <div
              style={{
                padding: Spacing(0.75, 1.25),
                backgroundColor: role !== 1 ? "#FFEBEB" : "#fff8eb",
                borderRadius: "5px",
                display: "flex",
                alignItems: "center",
                flexDirection: "row",
                marginRight: Spacing(1),
              }}
            >
              <CustomText
                fontFamily={fonts.bold}
                textColor={role !== 1 ? colors.red : colors.orange}
                fontSize={1.75}
                styles={{ marginRight: Spacing(1.25) }}
              >
                {texts.total}
              </CustomText>
              {isFetching ? (
                <SkeletonLoader></SkeletonLoader>
              ) : (
                <CustomText
                  fontFamily={fonts.semibold}
                  textColor={colors.carbon}
                  fontSize={1.75}
                >
                  {Filters.convertMoneyTextMask(totalValues?.totalAllCompanies)}
                </CustomText>
              )}
            </div>
          )}

          <Styles.Export
            fullWidth={false}
            startIcon={<SVG.Export />}
            onClick={() => exportData()}
            disabled={!Boolean(detailReportList?.elements) || isFetching}
            loading={isFetching}
          >
            {texts.button}
          </Styles.Export>
        </div>
      </Styles.CardHeader>
    </Styles.Content>
  );
}

export default HeaderExportList;
