import { CircularProgress, Grid } from "@material-ui/core";
import React, { useCallback, useEffect, useState } from "react";
import { connect } from "react-redux";

import { EditStatus, EditStatusAll, Filters, TableUsers } from "../components";

import { Creators } from "../reduxSagas";
import Styles from "../styles/Styles";
import { Creators as GlobalCreators } from "../../globalReduxSagas";
import { IntegratedComponentHolder } from "../../../components";
import { ToCSV } from "../../../lib";
import { customModal } from "../../modals/utils";

const pageSize = 8;
const formatDateTime = (date) => {
  var data = new Date(date);

  var dia = data.getDate();
  var mes = data.getMonth() + 1;
  var ano = data.getFullYear();
  var horas = data.getHours();
  var minutos = data.getMinutes();

  dia = dia < 10 ? "0" + dia : dia;
  mes = mes < 10 ? "0" + mes : mes;
  horas = horas < 10 ? "0" + horas : horas;
  minutos = minutos < 10 ? "0" + minutos : minutos;

  var dataString = dia + "/" + mes + "/" + ano + " - " + horas + ":" + minutos;
  return dataString;
};

function QueriesHistory({
  historyList,
  isFetching,
  getQueriesHistory,
  lastFilter,
  getCpfsCnpj,
  cpfsCnpj,
  fullQueriesHistory,
  editQueries,
  editMultipleQueries,
  getAllUndefinedQueries,
}) {
  const [isMounted, setIsMounted] = useState(false);
  const [page, setPage] = useState(0);
  const [minH, setHeight] = useState("100vh");

  const Mount = useCallback(() => {
    setIsMounted(true);
    getCpfsCnpj();
    const now = new Date();
    const year = now.getFullYear();
    const month = now.getMonth() + 1; // Os meses em JavaScript são indexados de 0 a 11

    const begin = new Date(year, month - 1, 1);
    const end = now;
    getQueriesHistory({
      searchParameters: { begin: begin, end: end },
      page: { size: pageSize, page: 0 },
    });
  }, [getQueriesHistory, getCpfsCnpj]);

  useEffect(Mount, [Mount]);

  useEffect(() => {
    if (isMounted) {
      const newH = document.getElementById("header-menu");
      setHeight(newH?.offsetHeight);
    }
  }, [isMounted]);

  const formatFilter = (obj) => {
    function formatDate(date) {
      const day = String(date.getDate()).padStart(2, "0");
      const month = String(date.getMonth() + 1).padStart(2, "0");
      const year = String(date.getFullYear());
      return day + "/" + month + "/" + year;
    }
    const result = [];

    if (obj.cpfOrCnpj && obj.cpfOrCnpj !== -1) {
      const companyIdsStr = "CPF/CNPJ: " + obj.cpfOrCnpj;
      result.push(companyIdsStr);
      result.push(";");
    }

    if (obj.begin && obj.begin instanceof Date) {
      result.push("Período: ");
      const formattedBegin = formatDate(obj.begin);

      if (obj.end && obj.end instanceof Date) {
        const formattedEnd = formatDate(obj.end);
        result.push(formattedBegin + " até " + formattedEnd);
      } else {
        result.push(formattedBegin);
      }
    }

    return result.join("");
  };

  const searchUsers = (filters, reset) => {
    if (reset) {
      setPage(0);
    }
    getQueriesHistory({
      searchParameters: filters,
      page: { size: pageSize, page: reset ? 0 : page },
    });
  };

  const clearFilter = () => {};

  const onExport = (data) => {
    let filters = formatFilter(lastFilter?.searchParameters);

    const dataExport = data.map((item) => {
      let a = { ...item };

      a.createdAt = formatDateTime(a.createdAt);
      a.score = item?.score ? item?.score?.toFixed(2)?.replace(".", ",") : "";

      return a;
    });

    ToCSV.report(
      dataExport,
      ["CLIENTE", "SCORE", "DATA / HORA", "LIBERAÇÃO DE CRÉDITO"],
      ["cpfOrCnpj", "score", "createdAt", "signalCreditRelease"],
      `Histórico de consultas`,
      `Histórico_de_consultas`,
      filters
    );
  };

  const onEdit = (item) => {
    customModal.setInfos(
      "EDITAR CONSULTA",
      [],
      null,
      null,
      <>
        <EditStatus
          user={item}
          onSubmit={(data) => {
            customModal.close();
            editQueries({ ...item, ...data }, () => {
              customModal.setInfos(
                "EDIÇÃO REALIZADA",
                [`Consulta editada com sucesso!`],
                {
                  label: "Ok",
                  onClick: () => {
                    customModal.close();
                  },
                },
                null,
                null,
                null,
                null,
                1
              );
              searchUsers(lastFilter?.searchParameters);
            });
          }}
        />
      </>,
      null,
      null,
      1
    );
  };

  const onEditAll = () => {
    getAllUndefinedQueries(lastFilter, (list) => {
      customModal.setInfos(
        "EDITAR LOTE DE HISTÓRICO DE CONSULTAS",
        [],
        null,
        null,
        <>
          <EditStatusAll
            data={list}
            onSubmit={(status, list) => {
              editMultipleQueries(
                { list: list, signalCreditReleaseId: status },
                () => {
                  customModal.setInfos(
                    "EDIÇÃO REALIZADA",
                    [`Consulta editada com sucesso!`],
                    {
                      label: "Ok",
                      onClick: () => {
                        customModal.close();
                      },
                    },
                    null,
                    null,
                    null,
                    null,
                    1
                  );
                  searchUsers(lastFilter?.searchParameters);
                }
              );

              customModal.close();
            }}
          />
        </>,
        null,
        null,
        1
      );
    });
  };

  const getFulData = () => {
    fullQueriesHistory(
      {
        searchParameters: lastFilter?.searchParameters,
      },
      (data) => {
        onExport(data);
      }
    );
  };

  return (
    <Styles.Container minH={minH}>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Filters
            listInputs={cpfsCnpj}
            onSubmit={(data) => searchUsers(data, true)}
            onClear={clearFilter}
          />
        </Grid>
        <Grid item xs={12}>
          <IntegratedComponentHolder
            showReload={!isFetching && !Array.isArray(historyList?.elements)}
            reloadMessage={"Nenhum dado encontrado"}
            showEmpty={!isFetching && historyList?.elements?.length < 1}
            emptyMessage={"Lista Vazia"}
            maxSize={"62rem"}
          >
            {isFetching ? (
              <div
                style={{
                  width: "100%",
                  height: "40vh",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <CircularProgress color="primary" />
              </div>
            ) : (
              <TableUsers
                onEditAll={onEditAll}
                onEdit={onEdit}
                onExport={getFulData}
                list={historyList?.elements}
                page={page + 1}
                setPage={(a) => {
                  setPage(a - 1);
                  getQueriesHistory({
                    searchParameters: lastFilter?.searchParameters,
                    page: { size: pageSize, page: a - 1 },
                  });
                }}
                totalPages={historyList?.pageTotal}
              />
            )}
          </IntegratedComponentHolder>
        </Grid>
      </Grid>
    </Styles.Container>
  );
}
function mapStateToProps(redux) {
  const {
    isFetching: isFetchingPage,
    historyList,
    lastFilter,
  } = redux.queriesHistory;
  const { isFetching: isFetchingGlobal, cpfsCnpj } = redux.global;

  return {
    isFetching: isFetchingPage || isFetchingGlobal,
    historyList,
    cpfsCnpj,
    lastFilter,
  };
}

function mapDispatchToProps(dispatch) {
  const {
    getQueriesHistory,
    fullQueriesHistory,
    editQueries,
    editMultipleQueries,
    getAllUndefinedQueries,
  } = Creators;
  const { getCpfsCnpj } = GlobalCreators;

  return {
    getQueriesHistory: function (data, callback) {
      return dispatch(getQueriesHistory(data, callback));
    },
    getCpfsCnpj: function () {
      return dispatch(getCpfsCnpj());
    },
    fullQueriesHistory: function (data, callback) {
      return dispatch(fullQueriesHistory(data, callback));
    },
    editQueries: function (data, callback) {
      return dispatch(editQueries(data, callback));
    },
    editMultipleQueries: function (data, callback) {
      return dispatch(editMultipleQueries(data, callback));
    },
    getAllUndefinedQueries: function (data, callback) {
      return dispatch(getAllUndefinedQueries(data, callback));
    },
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(React.memo(QueriesHistory));
