import { call, put } from "redux-saga/effects";
import { api } from "../../../services";
import { Creators } from "./index";

import { Alerts } from "../../../lib";

export default function* getAccessRequestAmcred({ data, captcha }) {
  try {
    const dataRequest = {
      companyName:
        data?.searchParameters?.companyName === "-1" ||
        data?.searchParameters?.companyName?.value
          ? null
          : data?.searchParameters?.companyName,
      companyCnpj:
        data?.searchParameters?.companyCnpj === "-1" ||
        data?.searchParameters?.companyCnpj?.value
          ? null
          : data?.searchParameters?.companyCnpj,
      requester:
        data?.searchParameters?.requester === "-1" ||
        data?.searchParameters?.requester?.value
          ? null
          : data?.searchParameters?.requester,
      cpf:
        data?.searchParameters?.cpf === "-1" ||
        data?.searchParameters?.cpf?.value
          ? null
          : data?.searchParameters?.cpf,
      email:
        data?.searchParameters?.email === "-1" ||
        data?.searchParameters?.email?.value
          ? null
          : data?.searchParameters?.email,
      begin: data?.searchParameters?.begin
        ? data?.searchParameters?.begin
        : null,
      end: data?.searchParameters?.end ? data?.searchParameters?.end : null,
      order: data?.searchParameters?.order,
      typeOrder: data?.searchParameters?.typeOrder,
    };
    data.searchParameters = dataRequest;

    const response = yield call(api.getAccessRequestAmcred, data, captcha);

    yield put(Creators.getAccessRequestAmcredSuccess(response?.data));
  } catch (response) {
    Alerts.alertError(
      response?.data?.log
        ? response?.data?.log
        : "Não foi possível encontrar as solicitações de acesso"
    );

    yield put(Creators.getAccessRequestAmcredFailure());
  }
}
