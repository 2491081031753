import { createReducers } from "../../../store/helpers";
import resetPasswordRequest from "./resetPasswordRequest";
import resetPasswordCodeRequest from "./resetPasswordCodeRequest";
import resetNewPasswordRequest from "./resetNewPassword";

const { Creators, reducers, sagas } = createReducers(
  [
    {
      name: "resetPasswordRequest", //resetPasswordRequest
      params: ["email", "callback"],
      function: (state) => ({
        ...state,
        sucess: false,
        isFetching: true,
      }),
      sagaFunction: resetPasswordRequest,
    },
    {
      name: "resetPasswordRequestSuccess",
      params: ["email"],
      function: (state) => ({
        ...state,
        isFetching: false,
      }),
    },
    {
      name: "resetPasswordRequestFailure", //resetPasswordRequest
      function: (state) => ({
        ...state,
        isFetching: false,
      }),
    },
    {
      name: "resetPasswordCodeRequest", //resetPasswordCodeRequest
      params: ["data"],
      function: (state) => ({
        ...state,
        isFetching: true,
        success: false,
      }),
      sagaFunction: resetPasswordCodeRequest,
    },
    {
      name: "resetPasswordCodeRequestSuccess", //resetPasswordCodeRequest
      params: ["data"],
      function: (state, { data }) => ({
        ...state,
        isFetching: false,
        validCode: true,
        token: data,
      }),
    },
    {
      name: "resetPasswordCodeFailure", //resetPasswordCodeRequest
      function: (state) => ({
        ...state,
        isFetching: false,
        validCode: false,
      }),
    },
    {
      name: "resetNewPasswordRequest", //resetPasswordRequest
      params: ["data", "callback", "token"],
      function: (state) => ({
        ...state,
        isFetching: true,
        success: false,
      }),
      sagaFunction: resetNewPasswordRequest,
    },
    {
      name: "resetNewPasswordFinished", //resetPasswordCodeRequest
      function: (state) => ({
        ...state,
        isFetching: false,
      }),
    },
    {
      name: "resetNewPasswordFailure", //resetPasswordCodeRequest
      function: (state) => ({
        ...state,
        isFetching: false,
      }),
    },
    {
      name: "resetPasswordClearData", //resetPasswordRequest, resetNewPasswordRequest,
      function: (state) => ({
        ...state,
        sucess: false,
        isFetching: false,
        email: null,
        token: null,
      }),
    },
    {
      name: "resetPasswordClearDataStart", //resetPasswordRequest,
      function: (state) => ({
        ...state,
        sucess: false,
        email: null,
        token: null,
      }),
    },
    {
      name: "goBackCode", //resetPasswordRequest,
      function: (state) => ({
        ...state,
        validCode: false,
      }),
    },
  ],
  {
    email: null,
    token: null,
    isFetching: false,
    success: false,
    validCode: false,
  }
);

export { Creators, reducers, sagas };
