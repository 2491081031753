import { call, put } from "redux-saga/effects";
import { api } from "../../../services";
import { Creators } from "./index";

import { Alerts } from "../../../lib";

export default function* getQueriesHistory({ data, captcha }) {
  try {
    const dataRequest = {
      cpfOrCnpj:
        data?.searchParameters?.cpfOrCnpj === "-1"
          ? null
          : data?.searchParameters?.cpfOrCnpj,
      begin: data?.searchParameters?.begin
        ? data?.searchParameters?.begin
        : null,
      end: data?.searchParameters?.end ? data?.searchParameters?.end : null,
      creditRelease:
        data?.searchParameters?.signalCreditRelease === "-1" ||
        data?.searchParameters?.signalCreditRelease === null
          ? null
          : parseInt(data?.searchParameters?.signalCreditRelease),
    };

    data.searchParameters = dataRequest;

    const response = yield call(api.getQueriesHistory, data, captcha);

    yield put(Creators.getQueriesHistorySuccess(response?.data));
  } catch (response) {
    Alerts.alertError(
      response?.data?.log
        ? response?.data?.log
        : "Não foi possível encontrar o histórico"
    );

    yield put(Creators.getQueriesHistoryFailure());
  }
}
