import { Grid } from "@material-ui/core";
import React from "react";
import { connect } from "react-redux";
import filters from "../../../lib/Filters";

import BarChart from "./BarChart";
import ValueCard from "./ValueCard";
function DataCards({ isFetching, data, small, userInfos }) {
  return (
    <>
      <Grid container spacing={1}>
        <Grid item xs={6} sm={4}>
          <ValueCard
            label={"TOTAL DE ANALISTAS"}
            value={data?.total}
            userInfos={userInfos}
          />
        </Grid>
        <Grid item xs={6} sm={4}>
          <ValueCard
            label={"TOTAL DE CONSULTAS"}
            value={data?.totalAccess}
            userInfos={userInfos}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <ValueCard
            label={"TOTAL A PAGAR"}
            value={filters.convertMoneyTextMask(data?.totalReceivable)}
            userInfos={userInfos}
          />
        </Grid>

        <Grid item xs={12}>
          <BarChart
            label={"NÚMERO DE CONSULTAS POR ANALISTAS"}
            data={data?.accessNumber?.data}
          />
        </Grid>
        <Grid item xs={12}>
          <BarChart
            label={"VALOR TOTAL DE CONSULTAS POR ANALISTA"}
            data={data?.accessValue?.data}
            type="value"
          />
        </Grid>
      </Grid>
    </>
  );
}

function mapStateToProps(redux) {
  const { isFetching: isFetchingPage } = redux.dashboardAmcred;
  const { isFetching: isFetchingGlobal } = redux.global;
  return { isFetching: isFetchingGlobal || isFetchingPage };
}

function mapDispatchToProps(dispatch) {
  return {};
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(React.memo(DataCards));
