import React, { useEffect } from "react";
import { Images, Texts, Themes } from "../../../config";
import { FormHolder } from "../../../FormConfig";
import { Styles } from "../styles";
import { Grid } from "@material-ui/core";
import { ButtonOutlined } from "../../../components";
import Title from "../../../components/strings/Title";

import ReactCodeInput from "react-code-input";
import fonts from "../../../config/fonts";
import Countdown from "./Countdown";
function StepTwo({ goBack, onSubmit, loading, resendToken, title, text }) {
  const texts = Texts["pt-BR"].forgotPassword.tokenConfirmatiom;

  const inputRef = React.useRef();
  const [value, setValue] = React.useState("");
  const [disabled, setDisabled] = React.useState(true);
  useEffect(() => {
    if (value.length > 4 && disabled) {
      setDisabled(false);
    } else if (!disabled && value.length < 5) {
      setDisabled(true);
    }
  }, [value, disabled]);
  const sendTokenData = () => {
    const code = inputRef.current.state.value;

    onSubmit(code);
  };

  return (
    <Styles.Content>
      <Styles.Header>
        <Styles.AmcredLogo src={Images.Logo} />
      </Styles.Header>
      <Title>{title}</Title>
      <Styles.Subtitle>{text}</Styles.Subtitle>
      <Styles.LineBottom></Styles.LineBottom>
      <div>
        <FormHolder onSubmit={sendTokenData} clearOnSubmit>
          <Grid container spacing={0}>
            <Styles.MarginBottom center>
              <Styles.InputBottom center>
                <Styles.InputCodeLabel>
                  {texts.instertToken}
                </Styles.InputCodeLabel>
                <ReactCodeInput
                  inputStyle={{
                    fontFamily: fonts.regular,
                    margin: "4px",
                    MozAppearance: "textfield",
                    width: "66px",
                    borderRadius: "5px",
                    height: "64px",
                    textAlign: "center",
                    fontSize: "24px",
                    color: "#6A6A6A",
                    border: "0px solid lightskyblue",

                    backgroundColor: Themes.Light.palette.background.paper,
                  }}
                  onChange={(a) => {
                    setValue(a);
                  }}
                  value={value}
                  ref={inputRef}
                  forceUppercase
                  type="text"
                  fields={5}
                />
              </Styles.InputBottom>
            </Styles.MarginBottom>

            <Countdown setReSend={resendToken} />
          </Grid>

          <Grid item lg={12}>
            <Styles.ConfirmButton
              name="button"
              type="submit"
              disabled={loading || disabled}
            >
              {texts.confirmToken}
            </Styles.ConfirmButton>
          </Grid>

          <Grid item lg={12}>
            <ButtonOutlined
              loading={loading}
              disabled={loading}
              onClick={goBack}
            >
              {texts.back}
            </ButtonOutlined>
          </Grid>
        </FormHolder>
      </div>
    </Styles.Content>
  );
}

export default React.memo(StepTwo);
