import { CircularProgress, Grid } from "@material-ui/core";
import React, { useCallback, useEffect, useState } from "react";
import { connect } from "react-redux";
import { IntegratedComponentHolder } from "../../../components";
import { customModal } from "../../modals/utils";

import { HeaderRegister, Filters, TableUsers, ModalEdit } from "../components";
import ModalBlock from "../components/ModalBlock";
import ModalRegister from "../components/ModalRegister";
import { Creators } from "../reduxSagas";

import { Creators as GlobalCreators } from "../../globalReduxSagas";

import Styles from "../styles/Styles";
const pageSize = 8;
function RegisterAndQuery({
  searchUsersAmcred,
  userList,
  lastFilter,
  isFetching,
  getRolesList,
  rolesList,
  newUserAmcred,
  unblockUserAmcred,
  blockUserAmcred,
  userInfos,
  editUserAmcred,
}) {
  const [isMounted, setIsMounted] = useState(false);
  const [page, setPage] = useState(0);
  const [minH, setHeight] = useState("100vh");

  const searchUsers = (filters, user) => {
    if (!user) {
      setPage(0);
    }

    searchUsersAmcred({
      searchParameters: filters,
      page: {
        size: pageSize,
        page: !user ? 0 : page,
      },
    });
  };

  const Mount = useCallback(() => {
    setIsMounted(true);
    getRolesList();
    searchUsersAmcred({
      searchParameters: {},
      page: { size: pageSize, page: 0 },
    });
  }, [getRolesList, searchUsersAmcred]);

  useEffect(Mount, [Mount]);

  useEffect(() => {
    if (isMounted) {
      const newH = document.getElementById("header-menu");
      setHeight(newH?.offsetHeight);
    }
  }, [isMounted]);

  const openNewUserModal = () => {
    customModal.setInfos(
      "REALIZAR NOVO CADASTRO DE USUÁRIO AMCRED",
      [],
      null,
      null,
      <>
        <ModalRegister
          roleList={rolesList}
          onSubmit={(data) => {
            customModal.close();
            newUserAmcred(data, () => {
              customModal.setInfos(
                "CADASTRO REALIZADO",
                [],
                null,
                null,
                <ModalBlock
                  rolesList={rolesList}
                  user={{
                    ...data,
                    fullName: data?.firstName + " " + data?.lastName,
                  }}
                  viewOnly
                  onSubmit={() => customModal.close()}
                />
              );
              searchUsers(lastFilter?.searchParameters);
            });
          }}
        />
      </>
    );
  };
  const openEditUserModal = (user) => {
    customModal.setInfos(
      "EDITAR USUÁRIO",
      [],
      null,
      null,
      <>
        <ModalEdit
          user={user}
          userInfos={userInfos}
          roleList={rolesList}
          onSubmit={(data) => {
            customModal.close();
            editUserAmcred({ ...data, id: user?.id }, () => {
              customModal.setInfos(
                "EDIÇÃO REALIZADA",
                [`Usuário editado com sucesso!`],
                {
                  label: "Ok",
                  onClick: () => {
                    customModal.close();
                  },
                },
                null,
                null,
                null,
                null,
                0
              );
              searchUsers(lastFilter?.searchParameters);
            });
          }}
        />
      </>,
      null,
      null,
      0
    );
  };
  const openBlockModal = (user) => {
    customModal.setInfos(
      "BLOQUEAR ACESSO",
      [],
      null,
      null,
      <>
        <ModalBlock
          block
          user={user}
          onSubmit={() => {
            blockUserAmcred(user?.id, () => {
              customModal.close();
              customModal.setInfos(
                "Sucesso!",
                ["Usuário bloqueado com sucesso!"],
                {
                  onClick: () => {
                    customModal.close();
                  },
                  label: "Ok",
                }
              );
              searchUsers(lastFilter?.searchParameters, true);
            });
          }}
        />
      </>
    );
  };

  const openUnblock = (user) => {
    customModal.setInfos(
      "DESBLOQUEAR ACESSO",
      [],
      null,
      null,
      <>
        <ModalBlock
          user={user}
          onSubmit={() => {
            unblockUserAmcred(user?.id, () => {
              customModal.close();
              customModal.setInfos(
                "Sucesso!",
                ["Usuário Desbloqueado com sucesso!"],
                {
                  onClick: () => {
                    customModal.close();
                  },
                  label: "Ok",
                }
              );
              searchUsers(lastFilter?.searchParameters);
            });
          }}
        />
      </>
    );
  };

  const clearFilter = () => {};

  return (
    <Styles.Container minH={minH}>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <HeaderRegister onClickRegister={openNewUserModal} />
        </Grid>
        <Grid item xs={12}>
          <Filters
            roleList={rolesList}
            onSubmit={(data) => searchUsers(data)}
            onClear={clearFilter}
            userInfos={userInfos}
          />
        </Grid>
        <Grid item xs={12}>
          <IntegratedComponentHolder
            showReload={!isFetching && !Array.isArray(userList?.elements)}
            reloadMessage={"Nenhum dado encontrado"}
            showEmpty={!isFetching && userList?.elements?.length < 1}
            emptyMessage={"Lista Vazia"}
            maxSize={"62rem"}
          >
            {isFetching ? (
              <div
                style={{
                  width: "100%",
                  height: "40vh",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <CircularProgress color="primary" />
              </div>
            ) : (
              <TableUsers
                list={userList?.elements}
                onBlock={openBlockModal}
                onUnblock={openUnblock}
                onEdit={openEditUserModal}
                page={page + 1}
                setPage={(a) => {
                  setPage(a - 1);

                  searchUsersAmcred(
                    {
                      searchParameters: lastFilter?.searchParameters,
                      page: { size: pageSize, page: a - 1 },
                    },
                    userList
                  );
                }}
                totalPages={userList?.pageTotal}
              />
            )}
          </IntegratedComponentHolder>
        </Grid>
      </Grid>
    </Styles.Container>
  );
}

function mapStateToProps(redux) {
  const {
    isFetching: isFetchingPage,
    userList,
    lastFilter,
  } = redux.registerAndQuery;
  const { isFetching: isFetchingGlobal, rolesList, userInfos } = redux.global;

  return {
    isFetching: isFetchingPage || isFetchingGlobal,
    userList,
    lastFilter,
    rolesList,
    userInfos,
  };
}

function mapDispatchToProps(dispatch) {
  const {
    searchUsersAmcred,
    newUserAmcred,
    unblockUserAmcred,
    blockUserAmcred,
    editUserAmcred,
  } = Creators;
  const { getRolesList } = GlobalCreators;

  return {
    searchUsersAmcred: function (data) {
      return dispatch(searchUsersAmcred(data));
    },
    newUserAmcred: function (data, callback) {
      return dispatch(newUserAmcred(data, callback));
    },
    editUserAmcred: function (data, callback) {
      return dispatch(editUserAmcred(data, callback));
    },
    blockUserAmcred: function (data, callback) {
      return dispatch(blockUserAmcred(data, callback));
    },
    unblockUserAmcred: function (data, callback) {
      return dispatch(unblockUserAmcred(data, callback));
    },
    getRolesList: function () {
      return dispatch(getRolesList());
    },
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(React.memo(RegisterAndQuery));
