import React, { useCallback, useEffect, useRef, useState } from "react";
import Styles from "../styles/Styles";
import { connect } from "react-redux";

import CreditForm from "../components/CreditForm";
import { Grid } from "@material-ui/core";

import { Creators } from "../reduxSagas";
import { FormHolder } from "../../../FormConfig";
import AnalysisScore from "../components/AnalysisScore";

import SubmitScore from "../components/SubmitScore";
import { customModal } from "../../modals/utils";

function CreditAnalysis({
  getCreditAnalysis,
  isFetching,
  getInputValues,
  inputs,
  analysisScore,
  getLastAnalysis,
  sendAnalysisResponse,
  lastInputs,
}) {
  const [form, setForm] = useState(null);
  const [isMounted, setIsMounted] = useState(false);
  const scoreRef = useRef(null);
  const [minH, setHeight] = useState("100vh");
  const [type, setType] = useState(null);
  const [checkValue, setCheckValue] = useState(false);
  const [creditType, setCreditType] = useState(null);
  const Mount = useCallback(() => {
    setIsMounted(true);
    getInputValues();
    getLastAnalysis();
  }, [getLastAnalysis, getInputValues]);

  useEffect(Mount, [Mount]);

  useEffect(() => {
    if (form) {
      if (creditType === 52 && type === 1) {
        form?.setFormValue("situationOfTheActivityPropertyId", -1);
        form?.setValue("situationOfTheActivityPropertyId", -1);

        form?.setFormValue("stitchTypeId", -1);
        form?.setValue("stitchTypeId", -1);

        form?.setFormValue("numberOfEmployees", null);
        form?.setValue("numberOfEmployees", null);

        form?.setFormValue("constitutionId", -1);
        form?.setValue("constitutionId", -1);
      }
    }
  }, [creditType, type, form]);

  useEffect(() => {
    if (isMounted) {
      const floatKeys = [
        "monthlyFamilyIncome",
        "totalMonthlyIncome",
        "loanAmount",
        "monthlyFee",
      ];
      const newH = document.getElementById("header-menu");
      setHeight(newH?.offsetHeight);

      scoreRef.current = { ...analysisScore, ref: form };
      if (lastInputs) {
        setType(parseInt(lastInputs?.registrationTypeId));

        for (var chave in lastInputs) {
          if (lastInputs.hasOwnProperty(chave)) {
            if (form?.findInput(chave)) {
              var valorChave = lastInputs[chave];
              if (chave === "birtdayDate") {
                const dateA = new Date(valorChave);
                form?.setFormValue(chave, dateA);
                form?.setValue(chave, dateA);
              } else if (chave === "registrationTypeId") {
                form?.setFormValue(chave, parseInt(valorChave));
                form?.setValue(chave, parseInt(valorChave));
              } else if (floatKeys.includes(chave)) {
                form?.setFormValue(chave, Number(valorChave).toFixed(2));
                form?.setValue(chave, Number(valorChave).toFixed(2));
              } else {
                form?.setFormValue(chave, valorChave);
                form?.setValue(chave, valorChave);
              }
            }
          }
        }
      } else {
        form?.clearAllValues();
      }

      setCheckValue(true);
    }
  }, [isMounted, analysisScore, lastInputs, form]);

  const submit = (data) => {
    customModal.setInfos(
      "REALIZAR ANÁLISE DE CRÉDITO",
      [
        "Confirmar os dados preenchidos no formulário e gerar score a partir do alvo de análise?",
      ],
      {
        label: "REALIZAR ANÁLISE",
        onClick: () => {
          setCheckValue(false);
          getCreditAnalysis(data, () => {
            form?.clearAllValues();
          });
          customModal?.close();
        },
      },
      {
        label: "VOLTAR",
        onClick: () => {
          customModal?.close();
        },
      },
      null,
      null,
      null,
      1
    );
  };

  const confirmAnalysis = (data) => {
    sendAnalysisResponse(
      { ...data, ...scoreRef?.current, ...lastInputs },
      () => {
        scoreRef?.current?.form?.clearAllValues();

        scoreRef.current = null;
        customModal.setInfos(
          "Sucesso",
          [
            `${
              parseInt(data?.signalCreditRelease) === 1
                ? "Sinalização para Liberação de Crédito"
                : parseInt(data?.signalCreditRelease) === 0
                ? "Sinalização para Não Liberação de Crédito"
                : "Sinalização indefinida para Liberação de Crédito"
            } enviada com sucesso!`,
          ],
          { onClick: () => customModal.close(), label: "OK" },
          null,
          null,
          null,
          null,
          1
        );
      }
    );
  };

  return (
    <Styles.Container minH={minH}>
      <Grid container spacing={1}>
        <>
          <FormHolder formRef={setForm} onSubmit={submit} clearOnSubmit={true}>
            <Grid item xs={12} sm={12} md={12}>
              <CreditForm
                checkValue={checkValue}
                type={type}
                setType={setType}
                form={form}
                inputs={inputs}
                analysisScore={analysisScore}
                isFetching={isFetching}
                lastInputs={lastInputs}
                creditType={creditType}
                setCreditType={setCreditType}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={7}>
              <AnalysisScore
                scoreValue={analysisScore?.score}
                isFetching={isFetching}
              />
            </Grid>
          </FormHolder>
        </>
        <FormHolder onSubmit={confirmAnalysis} clearOnSubmit={false}>
          <Grid item xs={12} sm={12} md={5}>
            <SubmitScore
              analysisScore={analysisScore}
              isFetching={isFetching}
            />
          </Grid>
        </FormHolder>
      </Grid>
    </Styles.Container>
  );
}

function mapStateToProps(state) {
  const {
    isFetching: isFetchingPage,
    inputs,
    analysisScore,
    lastInputs,
  } = state?.creditAnalysis;

  return {
    isFetching: isFetchingPage,
    inputs,
    analysisScore,
    lastInputs,
  };
}

function mapDispatchToProps(dispatch) {
  const {
    getCreditAnalysis,
    getInputValues,
    getLastAnalysis,
    sendAnalysisResponse,
  } = Creators;

  return {
    getCreditAnalysis: function (data, form) {
      return dispatch(getCreditAnalysis(data, form));
    },
    getInputValues: function () {
      return dispatch(getInputValues());
    },
    getLastAnalysis: function () {
      return dispatch(getLastAnalysis());
    },
    sendAnalysisResponse: function (data, form) {
      return dispatch(sendAnalysisResponse(data, form));
    },
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(React.memo(CreditAnalysis));
