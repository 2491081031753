import createReducers from "../../../store/helpers/createPageReducer";
import blockUserAssociated from "./blockUserAmcred";
import editUserAssociated from "./editUserAssociated";
import newUserAssociated from "./newUserAmcred";
import searchUsersAssociated from "./searchUsersAmcred";
import unblockUserAssociated from "./unblockUserAmcred";

const { Creators, reducers, sagas } = createReducers(
  [
    {
      name: "searchUsersAssociated",
      params: ["data"],
      function: (state, { data }) => ({
        ...state,
        isFetching: true,
        lastFilter: data,
      }),
      sagaFunction: searchUsersAssociated,
    },
    {
      name: "searchUsersAssociatedSuccess",
      params: ["userAssociatedList"],

      function: (state, { userAssociatedList }) => ({
        ...state,
        isFetching: false,
        userAssociatedList,
      }),
    },
    {
      name: "searchUsersAssociatedFailure",
      function: (state) => ({ ...state, isFetching: false }),
    },

    {
      name: "newUserAssociated",
      params: ["data", "callback"],
      function: (state) => ({
        ...state,
        isFetching: true,
      }),
      sagaFunction: newUserAssociated,
    },
    {
      name: "newUserAssociatedSuccess",
      function: (state) => ({
        ...state,
        isFetching: false,
      }),
    },
    {
      name: "newUserAssociatedFailure",
      function: (state) => ({ ...state, isFetching: false }),
    },

    {
      name: "editUserAssociated",
      params: ["data", "callback"],
      function: (state) => ({
        ...state,
        isFetching: true,
      }),
      sagaFunction: editUserAssociated,
    },
    {
      name: "editUserAssociatedSuccess",
      function: (state) => ({
        ...state,
        isFetching: false,
      }),
    },
    {
      name: "editUserAssociatedFailure",
      function: (state) => ({ ...state, isFetching: false }),
    },

    {
      name: "blockUserAssociated",
      params: ["data", "callback"],
      function: (state) => ({ ...state, isFetching: false }),
      sagaFunction: blockUserAssociated,
    },
    {
      name: "unblockUserAssociated",
      params: ["data", "callback"],
      function: (state) => ({ ...state, isFetching: false }),
      sagaFunction: unblockUserAssociated,
    },
  ],
  {
    isFetching: false,
    userAssociatedList: null,
    lastFilter: null,
  }
);

export { Creators, reducers, sagas };
