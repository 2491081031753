import React from "react";
import { connect } from "react-redux";
import { Styles } from "../styles";

import {
  ButtonText,
  CustomText,
  Input,
  InputEmail,
  Select,
} from "../../../components";
import { Fonts } from "../../../config";
import { Grid } from "@material-ui/core";

import { FormHolder } from "../../../FormConfig";
import { Validations } from "../../../lib";
import filters from "../../../lib/Filters";
import { customModal } from "../../modals/utils";
import { GetRoleByName } from "../../../services";

function ModalRegister({ isFetching, roleList, onSubmit, userInfos }) {
  return (
    <Styles.ModalContent>
      <Styles.HeaderModal>
        <CustomText fontFamily={Fonts.medium} fontSize={1.75}>
          Informe abaixo os dados do usuário a ser cadastrado:
        </CustomText>
      </Styles.HeaderModal>

      <FormHolder onSubmit={onSubmit}>
        <Grid container spacing={1}>
          <Grid item xs={6}>
            <Input
              color={1}
              required={"*Campo Obrigatório"}
              name="firstName"
              inputLabel="NOME USUÁRIO "
              forceError={"*Campo Obrigatório"}
            />
          </Grid>
          <Grid item xs={6}>
            <Input
              color={1}
              required={"*Campo Obrigatório"}
              name="lastName"
              inputLabel="SOBRENOME USUÁRIO "
              forceError={"*Campo Obrigatório"}
            />
          </Grid>
          <Grid item xs={6}>
            <Input
              color={1}
              required={"*Campo Obrigatório"}
              name="cpf"
              inputLabel="CPF"
              validation={Validations.isCPF}
              forceError={"*Campo Obrigatório"}
              mask={filters.cpfMask}
            />
          </Grid>
          <Grid item xs={6}>
            <Select
              color={1}
              required={"*Campo Obrigatório"}
              name="roleId"
              inputLabel="Perfil"
              forceError={"*Campo Obrigatório"}
              options={roleList}
            />
          </Grid>
          <Grid item xs={12}>
            <InputEmail
              color={1}
              name="email"
              required={"*Campo Obrigatório"}
              forceError={"*Campo Obrigatório"}
              inputLabel="E-mail"
            />
          </Grid>
        </Grid>
        <Styles.FormButtons>
          <ButtonText
            fullWidth={false}
            onClick={() => {
              customModal.close();
            }}
            color={GetRoleByName(userInfos?.role?.name)}
          >
            Voltar
          </ButtonText>
          <Styles.FormButton fullWidth={false} type="submit">
            CONFIRMAR CADASTRO
          </Styles.FormButton>
        </Styles.FormButtons>
      </FormHolder>
    </Styles.ModalContent>
  );
}

function mapStateToProps(redux) {
  const { isFetching } = redux.registerAndQuery;
  return { isFetching };
}

function mapDispatchToProps(dispatch) {
  return {};
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(React.memo(ModalRegister));
