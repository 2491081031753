import React from "react";
import { connect } from "react-redux";
import Title from "../../../components/strings/Title";
import { Colors, Fonts, Texts } from "../../../config";
import { InputPassword, InputEmail, CustomText } from "../../../components";
import { FormHolder } from "../../../FormConfig";
import { Styles } from "../styles";
import { Creators } from "../reduxSagas";
import { navigateTo } from "../../../navigation/navigate";
import { Images } from "../../../config";
import { useVersion } from "../../../lib";

function Login({ loginRequest, isFetching }) {
  const texts = Texts["pt-BR"].login;
  const version = useVersion();
  const onSubmit = (data) => {
    loginRequest(data);
  };

  return (
    <>
      <Styles.Container>
        <Styles.BackgroundImage src={Images.Background} />
        <Styles.Content>
          <Styles.Header>
            <Styles.AmcredLogo src={Images.Logo} />
          </Styles.Header>
          <Title>{texts.title}</Title>
          <Styles.LoginSubtitle>{texts.subtitle}</Styles.LoginSubtitle>
          <Styles.Line />
          <FormHolder onSubmit={onSubmit}>
            <InputEmail
              placeholder="Insira seu e-mail"
              name="username"
              nextInput="password"
              required={texts.userMessage}
              inputLabel={texts.email}
              requiredField
            />
            <InputPassword
              placeholder="Insira sua senha"
              name="password"
              required={texts.passwordMessage}
              inputLabel={texts.password}
              requiredField
            />
            <Styles.HyperLink $underline onClick={navigateTo.ForgotPassword}>
              {texts.forgotPass}
            </Styles.HyperLink>

            <Styles.ConfirmButton
              loading={isFetching}
              type="submit"
              name="enter"
            >
              {texts.enter}
            </Styles.ConfirmButton>
            <Styles.AskAccessButton
              onClick={navigateTo.RegisterRequest}
              loading={isFetching}
              name="askAccess"
            >
              {texts.firstAccess}
            </Styles.AskAccessButton>
          </FormHolder>
          <CustomText
            fontSize={1.5}
            fontFamily={Fonts.medium}
            textColor={Colors.red}
            styles={{ display: "flex", justifyContent: "center" }}
          >
            AMCRED {process.env.REACT_APP_ENV + " v"}
            {version}
          </CustomText>
        </Styles.Content>
      </Styles.Container>
    </>
  );
}

function mapStateToProps(state) {
  const { isFetching } = state.login;
  return {
    isFetching,
  };
}

function mapDispatchToProps(dispatch) {
  const { loginRequest } = Creators;
  return {
    loginRequest: function (data, captcha) {
      return dispatch(loginRequest(data, captcha));
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(React.memo(Login));
