import { call, put } from "redux-saga/effects";
import { api } from "../../../services";
import { Creators } from "./index";
import { Alerts } from "../../../lib";

export default function* userNewPasswordRequest({ data, form, color }) {
  try {
    yield call(api.newUserPassword, data);

    yield put(Creators.userNewPasswordSuccess());
    Alerts.alertSuccess("Senha alterada com sucesso!", color);
    //form();
  } catch (response) {
    Alerts.alertError("Não foi possível atualizar a senha. ", color);
    yield put(Creators.userNewPasswordFailure());
  }
}
