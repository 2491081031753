import createReducers from "../../../store/helpers/createPageReducer";
import userNewPassword from "./userNewPassword";

const { Creators, reducers, sagas } = createReducers(
  [
    {
      name: "userNewPasswordRequest",
      params: ["data", "form", "color"],
      function: (state) => {
        return {
          ...state,
          isFetching: true,
        };
      },
      sagaFunction: userNewPassword,
    },
    {
      name: "userNewPasswordSuccess",
      function: (state) => ({ ...state, isFetching: false }),
    },
    {
      name: "userNewPasswordFailure",
      function: (state) => ({ ...state, isFetching: false }),
    },
  ],
  {
    isFetching: false,
  }
);

export { Creators, reducers, sagas };
