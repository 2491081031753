import createReducers from "../../../store/helpers/createPageReducer";
import blockUserAmcred from "./blockUserAmcred";
import editUserAmcred from "./editUserAmcred";
import newUserAmcred from "./newUserAmcred";
import searchUsersAmcred from "./searchUsersAmcred";
import unblockUserAmcred from "./unblockUserAmcred";

const { Creators, reducers, sagas } = createReducers(
  [
    {
      name: "searchUsersAmcred",
      params: ["data"],
      function: (state, { data }) => ({
        ...state,
        isFetching: true,
        lastFilter: data,
      }),
      sagaFunction: searchUsersAmcred,
    },
    {
      name: "searchUsersAmcredSuccess",
      params: ["userList"],

      function: (state, { userList }) => ({
        ...state,
        isFetching: false,
        userList,
      }),
    },
    {
      name: "searchUsersAmcredFailure",
      function: (state) => ({ ...state, isFetching: false }),
    },

    {
      name: "newUserAmcred",
      params: ["data", "callback"],
      function: (state) => ({
        ...state,
        isFetching: true,
      }),
      sagaFunction: newUserAmcred,
    },
    {
      name: "newUserAmcredSuccess",
      function: (state) => ({
        ...state,
        isFetching: false,
      }),
    },
    {
      name: "newUserAmcredFailure",
      function: (state) => ({ ...state, isFetching: false }),
    },

    {
      name: "editUserAmcred",
      params: ["data", "callback"],
      function: (state) => ({
        ...state,
        isFetching: true,
      }),
      sagaFunction: editUserAmcred,
    },
    {
      name: "editUserAmcredSuccess",
      function: (state) => ({
        ...state,
        isFetching: false,
      }),
    },
    {
      name: "editUserAmcredFailure",
      function: (state) => ({ ...state, isFetching: false }),
    },

    {
      name: "blockUserAmcred",
      params: ["data", "callback"],
      function: (state) => ({ ...state, isFetching: false }),
      sagaFunction: blockUserAmcred,
    },
    {
      name: "unblockUserAmcred",
      params: ["data", "callback"],
      function: (state) => ({ ...state, isFetching: false }),
      sagaFunction: unblockUserAmcred,
    },
  ],
  {
    isFetching: false,
    userList: null,
    lastFilter: null,
  }
);

export { Creators, reducers, sagas };
