import React from "react";
import styled from "styled-components";
import FontStyles from "../styles/fontsStyles";
import SkeletonLoader from "../others/SkeletonLoader";
import Text from "../strings/Text";

const Dot = styled.div(({ theme, color }) => {
  const { spacing } = theme;
  return {
    height: spacing(0.725),
    width: spacing(0.725),
    backgroundColor: color,
    borderRadius: "50%",
    margin: spacing(0, 1),
    flexShrink: 0,
    marginTop: "-1px",
  };
});

const Box = styled.div(({ theme, color }) => {
  const { spacing } = theme;
  return {
    height: spacing(0.725),
    width: spacing(0.725),
    backgroundColor: color,
    margin: spacing(0, 1),
    flexShrink: 0,
    marginTop: "-1px",
  };
});

const Line = styled.div(({ theme, color }) => {
  const { spacing } = theme;
  return {
    height: spacing(0.725),
    width: spacing(2.5),
    backgroundColor: color,
    borderRadius: "0%",
    margin: spacing(0, 1),
    flexShrink: 0,
  };
});

const DotLine = styled.div(({ theme }) => {
  const { spacing, palette: colors } = theme;
  return {
    width: spacing(2.5),
    borderColor: colors.text.secondary,
    borderTopStyle: "dashed",
    borderWidth: spacing(0.25),
    margin: spacing(0, 1),
    flexShrink: 0,
  };
});

const DotLoader = styled(SkeletonLoader)(({ theme }) => {
  const { spacing } = theme;
  return {
    height: spacing(0.725),
    width: spacing(0.725),
    margin: spacing(1),
    flexShrink: 0,
  };
});

const Description = styled(Text)(({ theme }) => {
  return {
    ...FontStyles.medium12,
    color: theme.palette.text.primary,
    margin: 0,
    opacity: 0.88,
    letterSpacing: 0,
  };
});

const Container = styled.div(({ theme, noMargin }) => {
  const { spacing } = theme;
  return {
    flexDirection: "row",
    display: "flex",
    alignItems: "center",
    marginRight: spacing(1.5),
    flexShrink: 0,
    marginTop: spacing(1.25),
  };
});

const DescriptionWithSub = styled.span(({ theme }) => {
  const { palette: colors } = theme;
  return {
    ...FontStyles.roman12,
    color: colors.text.primary,
    fontWeight: "bold",
    display: "block",
  };
});

function ChartDescription({
  loading,
  children,
  color,
  subDescription = "",
  box = false,
  line = false,
  dotLine = false,
  loadingWidth = 100,
  noMargin,
}) {
  return (
    <Container noMargin={noMargin}>
      {loading ? (
        <DotLoader variant="circle" />
      ) : line ? (
        <Line color={color} />
      ) : dotLine ? (
        <DotLine />
      ) : box ? (
        <Box color={color} />
      ) : (
        <Dot color={color} />
      )}
      <Description>
        {loading ? (
          <SkeletonLoader width={loadingWidth} />
        ) : (
          <>
            {subDescription ? (
              <DescriptionWithSub>{children}</DescriptionWithSub>
            ) : (
              children
            )}
            {subDescription}
          </>
        )}
      </Description>
    </Container>
  );
}
export default ChartDescription;
