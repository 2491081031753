import React from "react";
import { connect } from "react-redux";
import { ButtonContained, CustomText } from "../../../components";
import { Spacing } from "../../../config";
import fonts from "../../../config/fonts";
import { navigateTo } from "../../../navigation/navigate";

function NotFound({ userInfos, isLoggedIn }) {
  const getRoute = () => {
    if (!isLoggedIn) {
      navigateTo.Landing();
    } else if (userInfos?.role?.name === "ROLE_AMCRED_GESTOR") {
      navigateTo.MainPanel();
    } else if (userInfos?.role?.name === "ROLE_INSTITUICAO_ASSOCIADA_GESTOR") {
      navigateTo.DashboardInstitutions();
    } else {
      navigateTo.CreditAnalysis();
    }
  };

  return (
    <>
      <div
        style={{
          width: "100%",
          height: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
          gap: Spacing(4),
        }}
      >
        <CustomText
          fontFamily={fonts.bold}
          fontSize={6}
          styles={{ opacity: 0.2 }}
        >
          404 - Pagina não encontrada
        </CustomText>

        <ButtonContained fullWidth={false} onClick={() => getRoute()}>
          VOLTAR PARA O INICIO
        </ButtonContained>
      </div>
    </>
  );
}
function mapStateToProps({ router, global }) {
  const { userInfos, isLoggedIn } = global;

  return {
    userInfos,
    isLoggedIn,
  };
}

function mapDispatchToProps(dispatch) {
  return {};
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(React.memo(NotFound));
