import { Base64 } from "../lib";
import { history } from "../store";
import Constants from "../config/constants";

export const prefix = "";

export const paths = {
  login: prefix + "/",
  home: prefix + "/",
  mainPanel: prefix + "/main_panel_amcred",
  forgotPassword: prefix + "/forgot_password",
  registerRequest: prefix + "/access_request",
  profile: prefix + "/perfil",
  registerAndQueryInstitutions: prefix + "/register_and_query_institutions",
  registerAndQueryAmcred: prefix + "/register_and_query_amcred",
  generalReport: prefix + "/general_report",
  detailReport: prefix + "/details_report",
  accessRequests: prefix + "/access_requests",
  dashboardAmcred: prefix + "/main_panel_amcred",
  dashboardInstitutions: prefix + "/main_panel_associated_institution",
  registerAndQueryAssociated:
    prefix + "/register_and_query_associated_institution",
  queriesHistory: prefix + "/queries_history",
  creditAnalysis: prefix + "/credit_analysis",
  registerAndQueryInstitution: prefix + "/register_and_query_institutions",
};

export const navigateTo = {
  Home: () => navigate(paths.home),
  MainPanel: () => navigate(paths.mainPanel),
  RegisterAndQueryInstitutions: () =>
    navigate(paths.registerAndQueryInstitutions),
  RegisterAndQueryAmcred: () => navigate(paths.registerAndQueryAmcred),
  GeneralReport: () => navigate(paths.generalReport),
  DetailReport: () => navigate(paths.detailReport),
  Landing: () => navigate(paths.login),
  ForgotPassword: () => navigate(paths.forgotPassword),
  Perfil: () => navigate(paths.profile),
  AccessRequests: () => navigate(paths.accessRequests),
  DashboardAmcred: () => navigate(paths.dashboardAmcred),
  DashboardInstitutions: () => navigate(paths.dashboardInstitutions),
  RegisterAndQueryAssociated: () => navigate(paths.registerAndQueryAssociated),
  CreditAnalysis: () => navigate(paths.creditAnalysis),
  QueriesHistory: () => navigate(paths.queriesHistory),

  RegisterRequest: () => navigate(paths.registerRequest),
  RegisterAndQueryInstitution: () =>
    navigate(paths.registerAndQueryInstitution),
  exampleWithParams: (queryParam) =>
    navigate(`/example/${Base64.encode(queryParam)}`),
};

export const replaceTo = {
  Home: () => replace(paths.home),
  MainPanel: () => replace(paths.mainPanel),
  Landing: () => replace(paths.login),
  RegisterAndQueryAmcred: () => replace(paths.registerAndQueryAmcred),
  ForgotPassword: () => replace(paths.forgotPassword),
  Perfil: () => replace(paths.profile),
  AccessRequests: () => replace(paths.accessRequests),
  RegisterAndQueryInstitution: () => replace(paths.registerAndQueryInstitution),
  DashboardAmcred: () => replace(paths.dashboardAmcred),
  DashboardInstitutions: () => replace(paths.dashboardInstitutions),
  GeneralReport: () => replace(paths.generalReport),
  DetailReport: () => replace(paths.detailReport),
  RegisterAndQueryAssociated: () => replace(paths.registerAndQueryAssociated),
  CreditAnalysis: () => replace(paths.creditAnalysis),
  QueriesHistory: () => replace(paths.queriesHistory),
  RegisterRequest: () => replace(paths.registerRequest),
  exampleWithParams: (queryParam) =>
    replace(`/example/${Base64.encode(queryParam)}`),
};

export function navigate(pathname) {
  const element = document.getElementById(
    Constants.ELEMENTS.IDS.applicationDefaultScroll
  );
  if (element) {
    element.scrollTop = 0;
  }
  history.push({ pathname });
}

export function replace(pathname) {
  const element = document.getElementById(
    Constants.ELEMENTS.IDS.applicationDefaultScroll
  );
  if (element) {
    element.scrollTop = 0;
  }
  history.replace({ pathname });
}

export function goBack() {
  history.goBack();
}

export function goFoward() {
  history.goForward();
}
