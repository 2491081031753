import React from "react";
import { DashboardInstitutions } from "../features";

function DashboardInstitutionsPage() {
  return (
    <>
      <DashboardInstitutions />
    </>
  );
}

export default DashboardInstitutionsPage;
