import {
  isAfter as isAfterFns,
  isBefore as isBeforeFns,
  set as setFns,
  format as fnsFormat,
} from "date-fns";

function compareDates(selectedDate, testDate, isAfter, withDay) {
  const testFunction = isAfter ? isAfterFns : isBeforeFns;
  const secDate = setFns(selectedDate, {
    hours: 0,
    minutes: 0,
    milliseconds: 0,
    seconds: 0,
  });
  const tstDate = setFns(testDate, {
    hours: 0,
    minutes: 0,
    milliseconds: 0,
    seconds: 0,
  });

  if (testFunction(secDate, tstDate)) {
    return {
      isValid: false,
      errorMessage: `A data não pode ser ${
        isAfter ? "posterior" : "inferior"
      } a ${
        withDay
          ? fnsFormat(testDate, "dd/MM/yyyy")
          : fnsFormat(testDate, "MM/yyyy")
      }`,
    };
  } else {
    return { isValid: true };
  }
}

function validToken(newState) {
  return /[A-ZÇ]/g.test(newState);
}

const isDATE = (time, minDate, maxDate) => {
  const [day, month, year] = time.split("/");
  let date = new Date(month + "/" + day + "/" + year);

  if (!date) {
    return { isValid: false, errorMessage: "Insira uma data" };
  } else if (isNaN(date.getTime())) {
    return { isValid: false, errorMessage: "Insira uma data válida" };
  }

  if (minDate) {
    const { isValid, errorMessage } = compareDates(date, minDate, false, true);
    if (!isValid) {
      return { isValid, errorMessage };
    }
  }

  if (maxDate) {
    const { isValid, errorMessage } = compareDates(date, maxDate, true, true);
    if (!isValid) {
      return { isValid, errorMessage };
    }
  }
  return { isValid: true, errorMessage: " " };
};

const isEMAIL = (email) => {
  let errorMessage = "E-mail inválido";
  if (!email) {
    return { isValid: false, errorMessage: "Insira um email" };
  }

  if (/^[^\s@]+@[^\s@]+\.\w{2,3}$/.test(email)) {
    return { isValid: true, errorMessage };
  }
  return { isValid: false, errorMessage };
};

function hasSpecialCharacters(password) {
  return /\W|_/g.test(password) ? 1 : 0;
}

function hasUppercase(password) {
  return /[A-ZÇ]/g.test(password) ? 1 : 0;
}

function hasNumber(password) {
  return /\d/g.test(password) ? 1 : 0;
}

function hasLowercase(password) {
  return /[a-zç]/g.test(password) ? 1 : 0;
}

function validPassword(password) {
  if (password.length < 6) {
    return {
      isValid: false,
      errorMessage: "Senha deve ter no mínimo 6 caracteres",
    };
  }
  if (password.length > 20) {
    return {
      isValid: false,
      errorMessage: "Senha deve ter no máximo 20 caracteres",
    };
  }
  if (
    hasSpecialCharacters(password) +
      hasLowercase(password) +
      hasNumber(password) +
      hasUppercase(password) <
    4
  ) {
    return {
      isValid: false,
      errorMessage:
        "Senha inválida. A senha deve conter: \n Letras maiúsculas, minúsculas, números e caracteres especiais",
    };
  }

  return { isValid: true, errorMessage: "Senha válida" };
}
function removeMask(value) {
  return value ? `${value}`.replace(/[^\d]/g, "") : value;
}
const isPhone = (phone) => {
  let errorMessage = "Número de telefone inválido";
  if (!phone) {
    return { isValid: false, errorMessage: "Insira um número de telefone" };
  }

  let a;

  phone.length >= 17 ? (a = phone.substring(0, phone.length - 1)) : (a = phone);

  const withoutMask = removeMask(a);

  if (withoutMask) {
    if (withoutMask.length === 10 || withoutMask.length === 11) {
      return { isValid: true, errorMessage };
    }
  }
  return { isValid: false, errorMessage };
};

const isFullName = (value) => {
  let errorMessage = "Nome inválido";
  const isValid = value
    .trim()
    .match(/^[A-zÀ-Ÿ][A-zÀ-ÿ']+\s([A-zÀ-ÿ']\s?)*[A-zÀ-Ÿ][A-zÀ-ÿ']+$/);

  return isValid
    ? { isValid: true, errorMessage }
    : {
        isValid: false,
        errorMessage:
          "O campo deve conter um nome completo valido. Com nome, sobrenome e não pode conter números.",
      };
};
const isCNPJ = (cnpj) => {
  let clearCnpj = "";
  let errorMessage = "CNPJ inválido";
  if (cnpj) {
    let a;
    a = cnpj.replace(/[^\d]/g, "");

    a.length >= 15
      ? (clearCnpj = a.substring(0, a.length - 1))
      : (clearCnpj = a);
  } else {
    return { isValid: false, errorMessage: "Insira um número de CNPJ" };
  }

  if (clearCnpj === "") {
    return { isValid: false, errorMessage: "Insira um número de CNPJ" };
  }

  if (clearCnpj.length !== 14) {
    return { isValid: false, errorMessage };
  }
  if (
    clearCnpj === "00000000000000" ||
    clearCnpj === "11111111111111" ||
    clearCnpj === "22222222222222" ||
    clearCnpj === "33333333333333" ||
    clearCnpj === "44444444444444" ||
    clearCnpj === "55555555555555" ||
    clearCnpj === "66666666666666" ||
    clearCnpj === "77777777777777" ||
    clearCnpj === "88888888888888" ||
    clearCnpj === "99999999999999"
  ) {
    return { isValid: false, errorMessage };
  }

  let size = clearCnpj.length - 2;
  let numbers = clearCnpj.substring(0, size);
  const digits = clearCnpj.substring(size);

  let sum = 0;
  let position = size - 7;

  for (let i = size; i >= 1; i--) {
    sum += parseInt(numbers.charAt(size - i), 10) * position--;
    if (position < 2) {
      position = 9;
    }
  }

  let result = sum % 11 < 2 ? 0 : 11 - (sum % 11);
  if (result !== parseInt(digits.charAt(0), 10)) {
    return { isValid: false, errorMessage };
  }

  size += 1;
  numbers = clearCnpj.substring(0, size);
  sum = 0;
  position = size - 7;
  for (let i = size; i >= 1; i--) {
    sum += parseInt(numbers.charAt(size - i), 10) * position--;
    if (position < 2) {
      position = 9;
    }
  }

  result = sum % 11 < 2 ? 0 : 11 - (sum % 11);
  if (result !== parseInt(digits.charAt(1), 10)) {
    return { isValid: false, errorMessage };
  }

  return { isValid: true, errorMessage };
};

const isCPF = (cpf) => {
  if (!cpf) {
    return { isValid: false, errorMessage: "CPF Invalido" };
  }

  let clearCpf = "";
  let errorMessage = "CPF Invalido";
  if (cpf) {
    clearCpf = cpf.replace(/[^\d]/g, "");
  } else {
    return { isValid: false, errorMessage: "CPF Invalido" };
  }

  let sum = 0;
  let rest;
  if (
    clearCpf.length !== 11 ||
    clearCpf === "00000000000" ||
    clearCpf === "11111111111" ||
    clearCpf === "22222222222" ||
    clearCpf === "33333333333" ||
    clearCpf === "44444444444" ||
    clearCpf === "55555555555" ||
    clearCpf === "66666666666" ||
    clearCpf === "77777777777" ||
    clearCpf === "88888888888" ||
    clearCpf === "99999999999"
  ) {
    return { isValid: false, errorMessage };
  }

  for (let i = 1; i <= 9; i++) {
    sum += parseInt(clearCpf.substring(i - 1, i), 10) * (11 - i);
  }

  rest = (sum * 10) % 11;

  if (rest === 10 || rest === 11) {
    rest = 0;
  }

  if (rest !== parseInt(clearCpf.substring(9, 10), 10)) {
    return { isValid: false, errorMessage };
  }

  sum = 0;

  for (let i = 1; i <= 10; i++) {
    sum += parseInt(clearCpf.substring(i - 1, i), 10) * (12 - i);
  }

  rest = (sum * 10) % 11;

  if (rest === 10 || rest === 11) {
    rest = 0;
  }

  if (rest !== parseInt(clearCpf.substring(10, 11), 10)) {
    return { isValid: false, errorMessage };
  }

  return { isValid: true, errorMessage };
};

const isNumber = (value) => {
  let errorMessage = "Apenas valores numéricos";
  const isValid = parseFloat(
    value
      .replaceAll(".", "")
      .replace("R$ ", "")
      .replace(" %", "")
      .replaceAll(",", ".")
  );

  return isValid.toString().length > 0 && !isNaN(isValid)
    ? { isValid: true, errorMessage }
    : {
        isValid: false,
        errorMessage:
          "O campo deve conter um valor numérico ('0,00', 'R$ 00,00, '00,00%).",
      };
};

const isPositiveNumber = (value) => {
  let errorMessage = "Apenas valores positivos";
  if (parseFloat(value) <= 0) {
    return { isValid: false, errorMessage };
  } else {
    return { isValid: true, errorMessage };
  }
};

const maxYear = (value) => {
  let errorMessage = "Insira um ano igual ou menor ao atual";

  return parseInt(value) > new Date()
    ? { isValid: false, errorMessage }
    : { isValid: true, errorMessage };
};

const cnsValidation = (cns) => {
  let errorMessage = "Insira um CNS valido!";
  if (cns === (null || undefined)) {
    return { isValid: false, errorMessage };
  }
  let value = cns.replace(/\D/g, "");

  if (value?.length !== 15) {
    return { isValid: false, errorMessage };
  }

  let soma;
  let resto, dv;
  let pis = "";
  let resultado = "";

  if (
    parseInt(value.substring(0, 1)) >= 1 &&
    parseInt(value.substring(0, 1)) <= 2
  ) {
    pis = value.substring(0, 11);

    soma =
      parseInt(value.substring(0, 1)) * 15 +
      parseInt(value.substring(1, 2)) * 14 +
      parseInt(value.substring(2, 3)) * 13 +
      parseInt(value.substring(3, 4)) * 12 +
      parseInt(value.substring(4, 5)) * 11 +
      parseInt(value.substring(5, 6)) * 10 +
      parseInt(value.substring(6, 7)) * 9 +
      parseInt(value.substring(7, 8)) * 8 +
      parseInt(value.substring(8, 9)) * 7 +
      parseInt(value.substring(9, 10)) * 6 +
      parseInt(value.substring(10, 11)) * 5;

    resto = soma % 11;
    dv = 11 - resto;

    if (dv === 11) {
      dv = 0;
    }

    if (dv === 10) {
      soma =
        parseInt(value.substring(0, 1)) * 15 +
        parseInt(value.substring(1, 2)) * 14 +
        parseInt(value.substring(2, 3)) * 13 +
        parseInt(value.substring(3, 4)) * 12 +
        parseInt(value.substring(4, 5)) * 11 +
        parseInt(value.substring(5, 6)) * 10 +
        parseInt(value.substring(6, 7)) * 9 +
        parseInt(value.substring(7, 8)) * 8 +
        parseInt(value.substring(8, 9)) * 7 +
        parseInt(value.substring(9, 10)) * 6 +
        parseInt(value.substring(10, 11)) * 5 +
        2;

      resto = soma % 11;
      dv = 11 - resto;
      resultado = pis + "001" + dv;
    } else {
      resultado = pis + "000" + dv;
    }

    if (value !== resultado) {
      return { isValid: false, errorMessage };
    } else {
      return { isValid: true, errorMessage };
    }
  } else if (
    parseInt(value.substring(0, 1)) >= 7 &&
    parseInt(value.substring(0, 1)) <= 9
  ) {
    if (value.length !== 15) {
      return { isValid: false, errorMessage };
    }

    let resto, soma;

    soma =
      parseInt(value.substring(0, 1)) * 15 +
      parseInt(value.substring(1, 2)) * 14 +
      parseInt(value.substring(2, 3)) * 13 +
      parseInt(value.substring(3, 4)) * 12 +
      parseInt(value.substring(4, 5)) * 11 +
      parseInt(value.substring(5, 6)) * 10 +
      parseInt(value.substring(6, 7)) * 9 +
      parseInt(value.substring(7, 8)) * 8 +
      parseInt(value.substring(8, 9)) * 7 +
      parseInt(value.substring(9, 10)) * 6 +
      parseInt(value.substring(10, 11)) * 5 +
      parseInt(value.substring(11, 12)) * 4 +
      parseInt(value.substring(12, 13)) * 3 +
      parseInt(value.substring(13, 14)) * 2 +
      parseInt(value.substring(14, 15)) * 1;

    resto = soma % 11;

    if (resto !== 0) {
      return { isValid: false, errorMessage };
    } else {
      return { isValid: true, errorMessage };
    }
  } else {
    return { isValid: false, errorMessage };
  }
};

const inputDate = (data, maxDate, minDate) => {
  if (isNaN(Date.parse(data))) {
    return { isValid: false, errorMessage: "Insira uma Data Valida" };
  } else {
    if (data > maxDate) {
      return {
        isValid: false,
        errorMessage: `Insira uma Data anterior a ${new Date(
          maxDate
        ).toLocaleDateString("pt-BR")}`,
      };
    } else if (data < minDate) {
      return {
        isValid: false,
        errorMessage: `Insira uma Data posterior a ${new Date(
          minDate
        ).toLocaleDateString("pt-BR")}`,
      };
    }
    return { isValid: true, errorMessage: "" };
  }
};

const biggerThanZero = (value, type, maxNumber = null) => {
  let number = value;
  if (typeof number === "string") {
    number = value.replaceAll(".", "").replaceAll(",", ".");
  }

  if (maxNumber && Math.abs(number) >= maxNumber) {
    return {
      isValid: false,
      errorMessage: `Valor deve ser menor que ${maxNumber}`,
    };
  }

  if (type === "float") {
    if (String(number).replace(".", "").replace(",", "").length > 16) {
      return {
        isValid: false,
        errorMessage: "Valor deve conter menos de 16 dígitos",
      };
    }
  } else {
    if (Math.abs(number) >= 9007199254740991) {
      return {
        isValid: false,
        errorMessage: "Valor deve ser menor que 9 quadrilhões",
      };
    }
  }

  number = isNaN(number) ? NaN : parseFloat(number);

  if (number > 0) {
    return { isValid: true, errorMessage: "" };
  } else {
    return {
      isValid: false,
      errorMessage: "O valor deve ser maior que zero",
    };
  }
};

const isCEP = (value) => {
  const cep = value.replace(/[^0-9]/gi, "");
  let errorMessage = "Cep inválido";

  if (cep.length >= 8) {
    return { isValid: true, errorMessage };
  } else {
    return {
      isValid: false,
      errorMessage: "O campo deve conter um cep de 8 dígitos.",
    };
  }
};
const validations = {
  isEMAIL,
  validPassword,
  isDATE,
  isPhone,
  isFullName,
  isCNPJ,
  isCPF,
  validToken,
  hasUppercase,
  isNumber,
  maxYear,
  cnsValidation,
  inputDate,
  isPositiveNumber,
  biggerThanZero,
  isCEP,
};

export default validations;
