import { call, put } from "redux-saga/effects";
import { api } from "../../../services";

import { Creators } from "./index";
import { Alerts } from "../../../lib";

export default function* getCreditAnalysis({ data, form }) {
  try {
    const submitData = {
      registrationTypeId: parseInt(data?.registrationTypeId),
      cep: data?.cep,
      cpfOrCnpj: data?.cpfOrCnpj,
      birthdayDate: data?.birtdayDate,
      spcConceptId: parseInt(data?.spcConceptId),
      schoolEducationId: parseInt(data?.schoolEducationId),
      situationOfTheResidencePropertyId: parseInt(
        data?.situationOfTheResidencePropertyId
      ),
      numberOfPeopleInTheHousehold: parseInt(
        data?.numberOfPeopleInTheHousehold
      ),
      monthlyFamilyIncome: data?.monthlyFamilyIncome,
      totalMonthlyIncome: data?.totalMonthlyIncome,
      constitutionId: parseInt(data?.constitutionId),
      situationOfTheActivityPropertyId:
        data?.situationOfTheActivityPropertyId === "-1"
          ? null
          : parseInt(data?.situationOfTheActivityPropertyId),
      stitchTypeId:
        data?.stitchTypeId === "-1" ? null : parseInt(data?.stitchTypeId),
      numberOfEmployees: parseInt(data?.numberOfEmployees),
      loanAmount: data?.loanAmount,
      monthlyFee: data?.monthlyFee,
      numberOfInstallments: data?.numberOfInstallments,
      loanPurposeId: parseInt(data?.loanPurposeId),
    };
    const response = yield call(api.getCreditAnalysis, submitData);

    yield put(
      Creators.getCreditAnalysisSuccess({
        ...response?.data?.response,
        ...submitData,
      })
    );
    form();
  } catch (response) {
    Alerts.alertError("Não foi possível realizar a analise de credito. ");
    yield put(Creators.getCreditAnalysisFailure());
  }
}
