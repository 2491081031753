import React from "react";
import {
  FormControl,
  FormHelperText,
  Chip,
  withTheme,
} from "@material-ui/core";
import { formConnector } from "../../FormConfig";
import styled from "styled-components";
import FontStyles from "../styles/fontsStyles";
import Text from "../strings/Text";

const ChipSelect = styled(Chip)(({ theme, selected, first }) => {
  const { spacing } = theme;
  return {
    paddingInline: spacing(1.25),
    marginRight: spacing(0.6),
    marginBottom: spacing(0.6),
    backgroundColor: selected ? "#E2F4FF" : "#E6E6E6",
    color: selected ? "#3BA1E5" : "#00000054",
    border: selected ? `1px solid #0099FF` : `1px solid #393939`,
    ...FontStyles.semibold12,
    ":focus": {
      backgroundColor: selected ? "#E2F4FF" : "#E6E6E6",
    },
    opacity: 1,
  };
});

const ListContainer = styled.div(() => {
  return {
    width: "100%",
    display: "flex",
    flexWrap: "wrap",
  };
});

const Label = styled(Text)(({ theme, $withValue, $witherror }) => {
  const { palette: colors } = theme;

  return {
    ...FontStyles.semibold14,
    textTransform: "uppercase",
    position: "relative",
    top: 0,
    left: 0,
    color: $withValue
      ? $witherror
        ? colors.error.main
        : colors.text.primary
      : colors.text.primary,
    transition: ".2s",
    pointerEvents: "none",
    alignItems: "center",
    display: "flex",
    overflow: "hidden",
  };
});

const Description = styled(Text)(({ theme }) => {
  const { palette: colors, spacing } = theme;

  return {
    ...FontStyles.regular14,
    marginBlock: spacing(0.6),
    color: colors.text.secondary,
    opacity: 0.55,
  };
});

function ChipList(props) {
  const {
    inputLabel,
    description,
    required,
    disableError,
    options,
    labelsmall,
    multiple,
  } = props;

  const { value, error, setConfigs, ref } = formConnector.useStringValue(props);

  const handleSelectSimple = (item) => {
    if (value === item.value) {
      setConfigs(null);
    } else {
      setConfigs(item.value);
    }
  };

  const handleSelectMultiple = (item) => {
    let newList = [...value];
    const index = newList.indexOf(item.value);
    if (index > -1) {
      newList.splice(index, 1);
    } else {
      newList.push(item.value);
    }
    setConfigs(newList);
  };

  return (
    <FormControl required={required} error={error}>
      <>
        <Label
          $withValue={Boolean(value) || value === 0}
          $witherror={Boolean(error) ? true : undefined}
          labelsmall={labelsmall}
        >
          {inputLabel}
        </Label>
        {description && <Description>{description}</Description>}
        <ListContainer>
          {options?.map((item, index) => (
            <ChipSelect
              first={index === 0}
              inputRef={ref}
              label={item.label}
              onClick={() =>
                multiple ? handleSelectMultiple(item) : handleSelectSimple(item)
              }
              selected={
                multiple ? value.includes(item.value) : value === item.value
              }
            />
          ))}
        </ListContainer>
      </>
      {disableError ? null : (
        <FormHelperText>{error ? error : " "}</FormHelperText>
      )}
    </FormControl>
  );
}

export default withTheme(ChipList);
