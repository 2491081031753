import React from "react";
import { Switch, Route } from "react-router-dom";
import { Images, PagesConfig, Themes } from "../config";
import Pages from "../pages";
import { Creators as globalCreators } from "../features/globalReduxSagas";
import { connect } from "react-redux";
import { Styles } from "./styles";
import { Menu, Header } from "../features";
import { ThemeProvider } from "../components";
import { CircularProgress } from "@material-ui/core";

function Routes({
  isLoggedIn,
  isFetching,
  getStatus,
  getUserInfos,
  userInfos,
}) {
  const mount = React.useCallback(() => {
    getStatus(userInfos);
  }, [getStatus, userInfos]);
  React.useEffect(mount, [mount]);
  const [menu, setMenu] = React.useState(false);

  return isFetching ? (
    <CircularProgress />
  ) : isLoggedIn ? (
    <ThemeProvider theme={Themes.Light}>
      <Styles.InitialBackground $didMount={!isFetching}>
        <Menu openMenu={setMenu} isOpen={menu} />
        <Styles.LoggedContainer
          $Image={`url(${Images.BackgroundRed})`}
          id="scroll-container"
        >
          <Header openMenu={setMenu} isOpen={menu} />
          <Switch>
            {PagesConfig.logged.map((item, index) => {
              if (item.pages) {
                return item.pages.map((page, index) => {
                  const Component = Pages[page.name];
                  return (
                    <Route
                      key={page.navigationId}
                      exact={true}
                      path={page.path}
                      component={() => <Component />}
                    />
                  );
                });
              }
              return null;
            })}
          </Switch>
        </Styles.LoggedContainer>
      </Styles.InitialBackground>
    </ThemeProvider>
  ) : (
    <ThemeProvider theme={Themes.Light}>
      <Styles.InitialBackground $didMount={!isFetching}>
        <Switch>
          {PagesConfig.notLogged.map((item, index) => (
            <Route
              key={item.path + index}
              exact={true}
              path={item.path}
              component={Pages[item.name]}
            />
          ))}
        </Switch>
      </Styles.InitialBackground>
    </ThemeProvider>
  );
}

function mapStateToProps({ global, router }) {
  const { isLoggedIn, isFetchingUserInfos: isFetching, userInfos } = global;
  const { pathname } = router.location;
  return {
    isLoggedIn,
    pathname,
    isFetching,
    userInfos,
  };
}

function mapDispatchToProps(dispatch) {
  const { getGlobalStatus, getUserInfos } = globalCreators;
  return {
    getStatus: (userInfos) => dispatch(getGlobalStatus(userInfos)),
    getUserInfos: () => dispatch(getUserInfos()),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(React.memo(Routes));
