import React from "react";
import { RegisterAndQueryInstitution } from "../features";

function RegisterAndQueryInstitutionPage() {
  return (
    <>
      <RegisterAndQueryInstitution />
    </>
  );
}

export default RegisterAndQueryInstitutionPage;
