export default function getFullGeneralReport(response) {
  const data = response?.data?.response?.map((item) => {
    return {
      fullName: item.fullName,
      roleName: item.roleName,
      queryQuantity: item.queryQuantity,
      totalValue: item.totalValue,
    };
  });

  return { ...response?.data, list: data };
}
