import styled from "styled-components";
import { Container as DefaultContainer, IconButton } from "@material-ui/core";
import { MediaQueries } from "../../../config";
function hexToRGB(hex, alpha) {
  var r = parseInt(hex.slice(1, 3), 16),
    g = parseInt(hex.slice(3, 5), 16),
    b = parseInt(hex.slice(5, 7), 16);

  if (alpha) {
    return "rgba(" + r + ", " + g + ", " + b + ", " + alpha + ")";
  } else {
    return "rgb(" + r + ", " + g + ", " + b + ")";
  }
}
function hexToRGBDarker(hex, alpha = 100) {
  let r = parseInt(hex.slice(1, 3), 16),
    g = parseInt(hex.slice(3, 5), 16),
    b = parseInt(hex.slice(5, 7), 16);
  r = parseInt(r * 0.94);
  g = parseInt(g * 0.94);
  b = parseInt(b * 0.94);
  if (alpha) {
    return "rgba(" + r + ", " + g + ", " + b + ", " + alpha + ")";
  } else {
    return "rgb(" + r + ", " + g + ", " + b + ")";
  }
}
const Container = styled.div(({ theme }) => {
  return {
    position: "relative",
    display: "flex",
    width: "100%",
    backgroundColor: "#FFFFFF",

    zIndex: 1,
    height: "min-content",
    justifyContent: "space-between",
  };
});

const Content = styled(DefaultContainer)(({ theme, $maxWidth }) => {
  const { spacing } = theme;
  return {
    maxWidth: "100vw",
    display: "flex",
    width: "100%",
    padding: spacing(0.5),
    paddingLeft: spacing(0),
    alignItems: "center",
    justifyContent: "space-between",
    zIndex: 2,
  };
});

const FirstContentGroup = styled.div(({ theme }) => {
  const { spacing } = theme;
  return {
    display: "flex",
    alignItems: "center",
    padding: spacing(3.75, 6.25),
    paddingLeft: 0,
    [MediaQueries.smDown]: {
      padding: spacing(1.25, 0),
    },
  };
});

const UserContentGroup = styled.div(({ theme, $color }) => {
  const { spacing, palette: colors } = theme;
  return {
    display: "flex",
    alignItems: "center",
    paddingBlock: spacing(2.75),
    paddingLeft: spacing(5),
    flex: 1,
    maxWidth: spacing(37),
    [MediaQueries.smDown]: {
      paddingBlock: spacing(0.5),
      paddingLeft: spacing(3),
      maxWidth: "50vw",
    },
    background:
      $color === 2
        ? colors.text.primary
        : $color === 1
        ? `linear-gradient(270deg, ${hexToRGBDarker(
            colors.background.associated,
            100
          )} 0%, ${hexToRGB(colors.background.associated, 100)} 100%)`
        : `linear-gradient(0deg, ${hexToRGBDarker(
            colors.background.secondary,
            100
          )} 0%, ${hexToRGB(colors.background.secondary, 100)} 100%)`,
  };
});

const Rectangle = styled(IconButton)(({ theme }) => {
  const { palette: colors, spacing } = theme;
  return {
    display: "none",

    [MediaQueries.smDown]: {
      display: "inline-block",
      minWidth: 0,
      borderRadius: 0,
      padding: spacing(0.3),
      backgroundColor: colors.text.primary,
      width: "auto",
      height: "auto",
      marginBlock: spacing(0.3),
      color: "white",
    },
  };
});

const RectangleTwo = styled.div(({ theme }) => {
  const { palette: colors, spacing } = theme;
  return {
    display: "inline-block",
    width: spacing(3),
    height: spacing(2.625),
    backgroundColor: colors.background.secondary,
    [MediaQueries.smDown]: {
      display: "none",
    },
  };
});

const LineBody = styled.div(({ theme }) => {
  const { palette: colors, spacing } = theme;
  return {
    backgroundColor: "transparent",

    borderLeft: spacing(1) + "px solid " + colors.text.primary,
    paddingLeft: spacing(0.5),
    height: "100%",
    marginRight: spacing(2),
    [MediaQueries.smDown]: {
      display: "none",
    },
  };
});
const SubLine = styled.div(({ theme }) => {
  const { palette: colors, spacing } = theme;
  return {
    backgroundColor: colors.text.primary,
    width: spacing(0.5),
    height: "100%",
  };
});
const Styles = {
  Container,
  Content,
  FirstContentGroup,
  UserContentGroup,
  Rectangle,
  RectangleTwo,
  LineBody,
  SubLine,
};

export default Styles;
