import Colors from "./colors";
import ChartsColors from "./chartColors";
import Dimensions from "./dimensions";
import Fonts from "./fonts";
import PagesConfig from "./pagesConfig";
import Texts from "./texts";
import Themes from "./theme";
import Constants from "./constants";
import Images from "./images";
import SVG from "./svg";
const { spacing: Spacing, breakpoints: bp, MediaQueries } = Themes.Light;

export {
  Colors,
  Dimensions,
  Fonts,
  PagesConfig,
  Images,
  Spacing,
  Texts,
  Themes,
  MediaQueries,
  Constants,
  ChartsColors,
  SVG,
  bp,
};
