import createReducers from "../../../store/helpers/createPageReducer";
import fullReport from "./fullReport";
import reportDetailList from "./reportDetailList";
import reportDetailListInstitution from "./reportDetailListInstitution";
import getValuesDatail from "./getValuesDatail";
import getValuesInstitutionDatail from "./getValuesInstitutionDatail";
const { Creators, reducers, sagas } = createReducers(
  [
    {
      name: "reportDetailListRequest",
      params: ["data"],
      function: (state, { data }) => {
        return {
          ...state,
          isFetching: true,
          lastFilter: data,
        };
      },
      sagaFunction: reportDetailList,
    },
    {
      name: "reportDetailListSuccess",
      params: ["detailReportList"],
      function: (state, { detailReportList }) => ({
        ...state,
        isFetching: false,
        detailReportList,
      }),
    },
    {
      name: "reportDetailListFailure",
      function: (state) => ({ ...state, isFetching: false }),
    },

    {
      name: "getValuesDatail",
      params: ["data"],
      function: (state, { data }) => {
        return {
          ...state,
          isFetchingFullValue: true,
          lastFilter: data,
        };
      },
      sagaFunction: getValuesDatail,
    },
    {
      name: "getValuesDatailSuccess",
      params: ["totalValues"],
      function: (state, { totalValues }) => ({
        ...state,
        isFetchingFullValue: false,
        totalValues,
      }),
    },
    {
      name: "getValuesDatailFailure",
      function: (state) => ({ ...state, isFetchingFullValue: false }),
    },

    {
      name: "getValuesInstitutionDatail",
      params: ["data", "reportType"],
      function: (state, { data, reportType }) => {
        return {
          ...state,
          isFetchingFullValue: true,
          lastFilter: data,
        };
      },
      sagaFunction: getValuesInstitutionDatail,
    },
    {
      name: "getValuesInstitutionDatailSuccess",
      params: ["totalValues"],
      function: (state, { totalValues }) => ({
        ...state,
        isFetchingFullValue: false,
        totalValues,
      }),
    },
    {
      name: "getValuesInstitutionDatailFailure",
      function: (state) => ({ ...state, isFetchingFullValue: false }),
    },

    {
      name: "reportDetailListInstitution",
      params: ["data"],
      function: (state, { data }) => {
        return {
          ...state,
          isFetching: true,
          lastFilter: data,
        };
      },
      sagaFunction: reportDetailListInstitution,
    },
    {
      name: "reportDetailListInstitutionSuccess",
      params: ["detailReportList"],
      function: (state, { detailReportList }) => ({
        ...state,
        isFetching: false,
        detailReportList,
      }),
    },
    {
      name: "reportDetailListInstitutionFailure",
      function: (state) => ({ ...state, isFetching: false }),
    },

    {
      name: "fullReportDetail",
      params: ["data", "id", "callback"],
      function: (state, { data, id, callback }) => {
        return {
          ...state,
          isFetching: true,
        };
      },
      sagaFunction: fullReport,
    },
    {
      name: "fullReportDetailSuccess",
      params: ["detailReportList"],
      function: (state, { detailReportList }) => ({
        ...state,
        isFetching: false,
      }),
    },
    {
      name: "fullReportDetailFailure",
      function: (state) => ({ ...state, isFetching: false }),
    },
  ],
  {
    isFetching: false,
    isFetchingFullValue: false,
    lastFilter: null,
    detailReportList: null,
    totalValues: null,
  }
);

export { Creators, reducers, sagas };
