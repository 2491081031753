import React from "react";
import { GeneralReportPage } from "../features";

function GeneralReport() {
  return (
    <>
      <GeneralReportPage />
    </>
  );
}

export default GeneralReport;
