import React from "react";
import Styles from "../styles/Styles";
import { Pagination, Table } from "../../../components";
import HeaderExportList from "./HeaderExportList";
import { GetRoleByName } from "../../../services";
import { Filters } from "../../../lib";

function ReportList({
  page,
  setPage,
  totalPages,
  userInfos,
  isFetching,
  detailReportList,
  exportData,
  totalValues,
  sort,
  lastFilter,
  user,
  roleId,
  setSortKey,
  setSortDirection,
  sortKey,
  sortDirection,
}) {
  let type = "";
  const sortContent = (key, orderType) => {
    setSortKey(key);
    setSortDirection(orderType);
    type = orderType ? "ASC" : "DESC";

    sort(lastFilter, user, key, type);
  };

  const formatTime = (date) => {
    var data = new Date(date);

    var dia = data.getDate();
    var mes = data.getMonth() + 1;
    var ano = data.getFullYear();
    var horas = data.getHours();
    var minutos = data.getMinutes();

    dia = dia < 10 ? "0" + dia : dia;
    mes = mes < 10 ? "0" + mes : mes;
    horas = horas < 10 ? "0" + horas : horas;
    minutos = minutos < 10 ? "0" + minutos : minutos;

    var dataString =
      dia + "/" + mes + "/" + ano + " - " + horas + ":" + minutos;
    return dataString;
  };

  return (
    <Styles.CardContainer>
      <HeaderExportList
        isFetching={isFetching}
        exportData={exportData}
        detailReportList={detailReportList}
        role={GetRoleByName(userInfos?.role?.name)}
        totalValues={totalValues}
      />
      <Styles.Line />
      <Table
        currentSortKey={sortKey}
        sortDirection={sortDirection}
        id="reportTable"
        sortLast={true}
        type={type}
        headers={{
          table:
            GetRoleByName(userInfos?.role?.name) === 2
              ? [
                  "NOME DA INSTITUIÇÃO ASSOCIADA",
                  "CNPJ",
                  "USUÁRIO",
                  "DATA E HORA",
                ]
              : GetRoleByName(userInfos?.role?.name) === 1
              ? [
                  "NOME DO USUÁRIO",
                  "PERFIL",
                  "CLIENTE CONSULTADO",
                  "LIBERAÇÃO DE CRÉDITO",
                  "DATA E HORA",
                ]
              : [
                  "NOME DA INSTITUIÇÃO ASSOCIADA",
                  "CNPJ",
                  "USUÁRIO",
                  "DATA E HORA",
                  "VALOR DA CONSULTA",
                ],
          keys:
            GetRoleByName(userInfos?.role?.name) === 1
              ? ["fullName", "roleName", "cpfCnpj", "released", "createAt"]
              : ["companyName", "companyCnpj", "userName", "createAt", "value"],
        }}
        data={detailReportList?.elements}
        sortContent={sortContent}
        renderItemColumns={(item) =>
          GetRoleByName(userInfos?.role?.name) === 1
            ? [
                item.fullName,
                item.roleName,
                item?.cpfCnpj,
                item?.released,
                formatTime(item.createAt),
              ]
            : [
                item.companyName,
                item.companyCnpj,
                item.userName,
                formatTime(item.createAt),
                Filters.convertMoneyTextMask(item.value),
              ]
        }
        color={GetRoleByName(userInfos?.role?.name)}
      />
      <Pagination
        page={page}
        setPage={setPage}
        totalPages={totalPages}
        color={GetRoleByName(userInfos?.role?.name)}
      />
    </Styles.CardContainer>
  );
}

export default ReportList;
