import React from "react";
import {
  ButtonOutlined,
  CustomText,
  Input,
  InputDate,
  Select,
} from "../../../components";
import Styles from "../styles/Styles";
import Grid from "@material-ui/core/Grid";

import { Colors, Spacing, Texts } from "../../../config";
import { Filters, Validations } from "../../../lib";
import { Delete } from "@material-ui/icons";
import { customModal } from "../../modals/utils";
import fonts from "../../../config/fonts";
function CreditForm({
  analysisScore,
  inputs,
  form,
  isFetching,
  lastInputs,
  type,
  setType,
  creditType,
  setCreditType,
  checkValue,
}) {
  const texts = Texts["pt-BR"].CreditAnalysis;

  return (
    <Styles.CardContainer>
      <Styles.CardHeader>
        <Styles.CardTitle>{texts.creditForm.title}</Styles.CardTitle>
      </Styles.CardHeader>
      <Styles.Line />

      <Grid
        container
        spacing={1}
        style={{ justifyContent: "flex-end", alignItems: "flex-end" }}
      >
        <Grid item xs={12} sm={4} md={4} lg={3}>
          <Select
            color={1}
            readOnly={Boolean(analysisScore)}
            defaultValue={type}
            onChange={(data) => {
              if (form?.getInputRef("cpfOrCnpj") && !lastInputs) {
                form?.setValue("cpfOrCnpj", "");

                form?.updateInput("cpfOrCnpj");
              }
              if (checkValue) {
                setType(data);
              }
            }}
            required={texts.validationField}
            options={inputs?.registrationType ? inputs?.registrationType : []}
            name="registrationTypeId"
            inputLabel={texts.creditForm.type}
          />
        </Grid>

        <React.Fragment>
          <Grid item xs={12} sm={4} md={4} lg={2}>
            <React.Fragment>
              <Input
                color={1}
                readOnly={!type || Boolean(analysisScore)}
                required={texts.validationField}
                name="cpfOrCnpj"
                inputLabel={
                  type === 2 ? texts.creditForm.cnpj : texts.creditForm.cpf
                }
                mask={
                  type === 2
                    ? Filters.cnpjMask
                    : type === 1
                    ? Filters.cpfMask
                    : null
                }
                validation={type === 2 ? Validations.isCNPJ : Validations.isCPF}
              />
            </React.Fragment>
          </Grid>
        </React.Fragment>
        <React.Fragment>
          <Grid item xs={12} sm={4} md={4} lg={3}>
            <Select
              color={1}
              readOnly={Boolean(analysisScore)}
              required={texts.validationField}
              options={inputs?.schoolEducation ? inputs?.schoolEducation : []}
              name="schoolEducationId"
              inputLabel={
                type === 2
                  ? texts.creditForm.schoolCnpj
                  : texts.creditForm.school
              }
            />
          </Grid>
        </React.Fragment>
        <React.Fragment>
          <Grid item xs={12} sm={4} md={4} lg={2}>
            <InputDate
              color={1}
              disabled={Boolean(analysisScore) ? true : false}
              required={texts.validationField}
              name="birtdayDate"
              maxDate={new Date()}
              inputLabel={
                type === 2
                  ? texts.creditForm.companyDate
                  : texts.creditForm.birthday
              }
            />
          </Grid>
        </React.Fragment>

        <Grid item xs={6} sm={4} md={4} lg={2}>
          <Input
            color={1}
            name="cep"
            required={texts.validationField}
            inputLabel={
              type === 2 ? texts.creditForm.cepCnpj : texts.creditForm.cep
            }
            mask={Filters.cepMask}
            validation={Validations.isCEP}
          />
        </Grid>

        <Grid item xs={6} sm={4} md={4} lg={3}>
          <Select
            color={1}
            readOnly={Boolean(analysisScore)}
            required={texts.validationField}
            options={inputs?.loanPurpose ? inputs?.loanPurpose : []}
            name="loanPurposeId"
            inputLabel={texts.creditForm.loanFinality}
            onChange={(value) => {
              //setCreditType(value);
            }}
          />
        </Grid>

        <Grid
          style={{ paddingLeft: Spacing(0.5) }}
          item
          xs={12}
          sm={6}
          md={4}
          lg={3}
        >
          <Input
            validation={(value) => Validations.biggerThanZero(value, "float")}
            color={1}
            readOnly={Boolean(analysisScore)}
            required={texts.validationField}
            name="loanAmount"
            inputLabel={texts.creditForm.loanAmount}
            startAdornment={
              <CustomText styles={{ paddingRight: Spacing(1) }} fontSize={1.75}>
                R$
              </CustomText>
            }
            mask={(value) => Filters.convertNumberInputMask(value, false, true)}
            maskToSubmit={Filters.converterTextoParaFloat}
          />
        </Grid>

        <Grid
          style={{ alignItems: "flex-end" }}
          container
          item
          xs={12}
          sm={6}
          md={4}
          lg={3}
        >
          <Grid
            style={{ paddingRight: Spacing(0.5) }}
            item
            xs={6}
            sm={6}
            md={7}
          >
            <Input
              color={1}
              readOnly={Boolean(analysisScore)}
              required={texts.validationField}
              name="monthlyFee"
              inputLabel={texts.creditForm.monthlyFee}
              startAdornment={
                <CustomText
                  styles={{ paddingRight: Spacing(1) }}
                  fontSize={1.75}
                >
                  %
                </CustomText>
              }
              mask={(value) => {
                return Filters.convertNumberInputMask(value, false, true);
              }}
              maskToSubmit={Filters.converterTextoParaFloat}
              validation={(value) =>
                Validations.biggerThanZero(value, "float", 999.99)
              }
            />
          </Grid>

          <Grid style={{ paddingLeft: Spacing(0.5) }} item xs={6} sm={6} md={5}>
            <Input
              color={1}
              readOnly={Boolean(analysisScore)}
              required={texts.validationField}
              name="numberOfInstallments"
              inputLabel={texts.creditForm.numbInstallments}
              mask={Filters.clearStringOnlyNumbers}
              maskToSubmit={Filters.converterTextoParaFloat}
              validation={(value) =>
                Validations.biggerThanZero(value, "int", 999)
              }
            />
          </Grid>
        </Grid>
        <Grid item xs={6} sm={4} md={4} lg={3}>
          <Select
            color={1}
            readOnly={
              //(creditType === 52 && (type === 1 || type === null)) ||
              Boolean(analysisScore)
            }
            // disabled={creditType === 52 && (type === 1 || type === null)}
            required={texts.validationField}
            options={inputs?.constitution ? inputs?.constitution : []}
            name="constitutionId"
            inputLabel={texts.creditForm.constitution}
          />
        </Grid>
        <Grid item xs={6} sm={4} md={3}>
          <Input
            color={1}
            readOnly={Boolean(analysisScore)}
            name="numberOfPeopleInTheHousehold"
            inputLabel={texts.creditForm.numPeople}
            mask={Filters.clearStringOnlyNumbers}
            maskToSubmit={Filters.converterTextoParaFloat}
            validation={(value) => Validations.biggerThanZero(value, "int")}
          />
        </Grid>

        <Grid item xs={12} sm={4} md={3}>
          <Select
            color={1}
            readOnly={Boolean(analysisScore)}
            required={texts.validationField}
            options={
              inputs?.situationOfTheResidenceProperty
                ? inputs?.situationOfTheResidenceProperty
                : []
            }
            name="situationOfTheResidencePropertyId"
            inputLabel={texts.creditForm.residence}
          />
        </Grid>

        <Grid item xs={12} sm={4} md={3}>
          <Input
            color={1}
            readOnly={Boolean(analysisScore)}
            name="totalMonthlyIncome"
            inputLabel={texts.creditForm.totalReceipt}
            mask={(value) => Filters.convertNumberInputMask(value, false, true)}
            maskToSubmit={Filters.converterTextoParaFloat}
            validation={(value) => Validations.biggerThanZero(value, "float")}
            startAdornment={
              <CustomText styles={{ paddingRight: Spacing(1) }} fontSize={1.75}>
                R$
              </CustomText>
            }
          />
        </Grid>

        <Grid item xs={12} sm={4} md={3}>
          <Input
            color={1}
            readOnly={Boolean(analysisScore)}
            name="monthlyFamilyIncome"
            inputLabel={texts.creditForm.familiarWealth}
            validation={(value) => Validations.biggerThanZero(value, "float")}
            mask={(value) => Filters.convertNumberInputMask(value, false, true)}
            maskToSubmit={Filters.converterTextoParaFloat}
            startAdornment={
              <CustomText styles={{ paddingRight: Spacing(1) }} fontSize={1.75}>
                R$
              </CustomText>
            }
          />
        </Grid>

        <Grid item xs={12} sm={4} md={3}>
          <Select
            color={1}
            readOnly={
              // (creditType === 52 && (type === 1 || type === null)) ||
              Boolean(analysisScore)
            }
            // disabled={creditType === 52 && (type === 1 || type === null)}
            required={
              creditType === 52 && (type === 1 || type === null)
                ? null
                : texts.validationField
            }
            options={
              inputs?.situationOfTheActivityProperty
                ? inputs?.situationOfTheActivityProperty
                : []
            }
            name="situationOfTheActivityPropertyId"
            inputLabel={texts.creditForm.residenceSituation}
          />
        </Grid>

        <Grid item xs={6} sm={4} md={3}>
          <Select
            color={1}
            readOnly={
              //(creditType === 52 && (type === 1 || type === null)) ||
              Boolean(analysisScore)
            }
            //disabled={creditType === 52 && (type === 1 || type === null)}
            required={
              creditType === 52 && (type === 1 || type === null)
                ? null
                : texts.validationField
            }
            options={inputs?.stitchType ? inputs?.stitchType : []}
            name="stitchTypeId"
            inputLabel={texts.creditForm.point}
          />
        </Grid>

        <Grid style={{ paddingRight: Spacing(0.5) }} item xs={6} sm={4} md={3}>
          <Input
            color={1}
            readOnly={
              //(creditType === 52 && (type === 1 || type === null)) ||
              Boolean(analysisScore)
            }
            //disabled={creditType === 52 && (type === 1 || type === null)}
            name="numberOfEmployees"
            inputLabel={texts.creditForm.numberWorkers}
            mask={Filters.clearStringOnlyNumbers}
            maskToSubmit={Filters.converterTextoParaFloat}
            validation={(value) => Validations.biggerThanZero(value, "int")}
          />
        </Grid>

        <Grid item xs={6} sm={4} md={3}>
          <Select
            color={1}
            readOnly={Boolean(analysisScore)}
            options={inputs?.spcConcept ? inputs?.spcConcept : []}
            name="spcConceptId"
            inputLabel={texts.creditForm.spc}
          />
        </Grid>

        <Grid
          style={{ alignSelf: "flex-end", paddingBottom: Spacing(4) }}
          item
          xs={6}
          sm={4}
          md={2}
        >
          <ButtonOutlined
            disabled={Boolean(analysisScore) || isFetching}
            loading={isFetching}
            startIcon={<Delete />}
            alternative
            type="clear"
            onClick={() => {
              customModal.setInfos(
                "LIMPAR CAMPOS DO FORMULÁRIO",
                [
                  "Confirmar limpeza de campos do formulário de preenchimento da Análise de Crédito?",
                ],
                {
                  label: "SIM, LIMPAR",
                  onClick: () => {
                    form?.clearAllValues();
                    customModal?.close();
                  },
                },
                {
                  label: "VOLTAR",
                  onClick: () => {
                    customModal?.close();
                  },
                },
                null,
                null,
                null,
                1
              );
            }}
          >
            LIMPAR
          </ButtonOutlined>
        </Grid>
      </Grid>
      <CustomText
        textColor={Colors.orange}
        fontSize={1.5}
        fontFamily={fonts.semibold}
        styles={{ textAlign: "flex-end" }}
      >
        * Campos Obrigatórios
      </CustomText>
    </Styles.CardContainer>
  );
}

export default React.memo(CreditForm);
