import styled from "styled-components";
import { FontStyles, Text } from "../../../components";
import { MediaQueries } from "../../../config";
import Button from "@material-ui/core/Button";
const PersonInfo = styled(Button)((props) => ({
  display: "flex",
  flexDirection: "column",
  width: "100%",
  padding: 0,
}));
const InfoRow = styled.div((props) => ({
  display: "flex",
  flexDirection: "column",
  width: "100%",
  padding: 0,
}));

const Email = styled(Text)(({ theme, $color }) => ({
  color: theme.palette.text.tertiary,
  ...FontStyles.bold12,
  marginBottom: 0,
  marginTop: 0,
  textAlign: "left",
  [MediaQueries.smDown]: {
    ...FontStyles.bold10,
  },
}));

const Name = styled(Text)(({ theme, $color }) => ({
  color: theme.palette.text.tertiary,
  ...FontStyles.bold16,
  marginBottom: 0,
  marginTop: -5,
  textAlign: "left",
  // textOverflow: "ellipsis",
  // whiteSpace: "nowrap",
  // overflow: "hidden",
  [MediaQueries.smDown]: {
    ...FontStyles.bold12,
  },
}));

const Styles = {
  PersonInfo,
  Name,
  Email,
  InfoRow,
};

export default Styles;
