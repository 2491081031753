import React from "react";
import { connect } from "react-redux";
import { Texts } from "../../../config";
import { Swiper } from "../../../components";
import { Styles } from "../styles";

import { navigateTo } from "../../../navigation/navigate";
import { Creators } from "../reduxSagas";
import { StepOne, StepTwo, StepThree } from "../components";
import { customModal } from "../../modals/utils";
import { Alerts } from "../../../lib";

function ForgotPassword({
  resetPasswordRequest,
  resetPasswordCodeRequest,
  resetNewPasswordRequest,
  goBackCode,
  validCode,
  token,
  isFetching,
}) {
  const texts = Texts["pt-BR"].forgotPassword;
  const [formData, setFormData] = React.useState({});

  const FinishStepOne = (data, Callback) => {
    setFormData({ ...formData, ...data });
    resetPasswordRequest(data, Callback);
  };

  const FinishStepTwo = (data) => {
    resetPasswordCodeRequest({ ...formData, code: data });
    setFormData({ ...formData, code: data });
  };

  const FinishStepThree = (data, Callback) => {
    resetNewPasswordRequest({ ...formData, ...data }, Callback, token);
    setFormData({ ...formData, ...data, auth: token });
  };

  const ConfirmModalPassword = () => {
    customModal.setInfos(
      texts.passwordConfirmatiom.modalTitle,
      [texts.passwordConfirmatiom.modalText],
      {
        label: texts.passwordConfirmatiom.buttomModal,
        onClick: () => {
          customModal.close();
          goBackCode();
          navigateTo.Landing();
        },
      },
      null,
      null,
      true
    );
  };

  const CancelModalPassword = () => {
    customModal.setInfos(
      texts.modalCanel.modalTitle,
      [texts.modalCanel.modalText],
      {
        label: texts.modalCanel.buttomModal,
        onClick: () => {
          customModal.close();
          goBackCode();
          navigateTo.Landing();
        },
      },
      {
        label: texts.modalCanel.cancel,
        onClick: () => {
          customModal.close();
        },
      },
      null,
      false
    );
  };

  return (
    <>
      <Styles.Container>
        {validCode === false ? (
          <Swiper
            screens={[
              ({ goNext }) => (
                <StepOne
                  loading={isFetching}
                  title={texts.Title}
                  text={texts.emailConfirmatiom.text}
                  subText={texts.emailConfirmatiom.subText}
                  onSubmit={(data) => {
                    FinishStepOne(data, goNext);
                  }}
                  goBack={() => navigateTo.Landing()}
                />
              ),
              ({ goNext, goPrevious }) => (
                <StepTwo
                  loading={isFetching}
                  title={texts.Title}
                  text={texts.tokenConfirmatiom.textToken}
                  onSubmit={(data) => {
                    FinishStepTwo(data);
                  }}
                  resendToken={() => {
                    resetPasswordRequest(formData, () => {
                      Alerts.alertSuccess(
                        "Um novo token foi enviado ao seu email"
                      );
                    });
                  }}
                  goBack={() => {
                    goPrevious();
                  }}
                />
              ),
            ]}
          />
        ) : (
          <StepThree
            title={texts.Title}
            loading={isFetching}
            text={texts.passwordConfirmatiom.textNewPassword}
            onSubmit={(data) => {
              FinishStepThree(data, () => ConfirmModalPassword());
            }}
            goBack={CancelModalPassword}
          />
        )}
      </Styles.Container>
    </>
  );
}

function mapStateToProps(state) {
  const { isFetching, validCode, token } = state.recoveryPassword;
  return {
    isFetching,
    validCode,
    token,
  };
}
function mapDispatchToProps(dispatch) {
  const {
    resetPasswordRequest,
    resetPasswordCodeRequest,
    resetNewPasswordRequest,
    goBackCode,
  } = Creators;
  return {
    resetPasswordRequest(email, callback) {
      return dispatch(resetPasswordRequest(email, callback));
    },
    resetPasswordCodeRequest(code) {
      return dispatch(resetPasswordCodeRequest(code));
    },
    resetNewPasswordRequest(data, callback, token) {
      return dispatch(resetNewPasswordRequest(data, callback, token));
    },
    goBackCode() {
      return dispatch(goBackCode());
    },
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(ForgotPassword);
