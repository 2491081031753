import { call, put } from "redux-saga/effects";
import { api } from "../../../services";
import { Creators } from "./index";

import { Alerts } from "../../../lib";

export default function* registerRequest({ data, callback }) {
  try {
    const requestData = {
      user: {
        firstName: data?.name ? data?.name : null,
        lastName: data?.lastName ? data?.lastName : null,
        email: data?.email ? data?.email : null,
        cpf: data?.cpf ? data?.cpf : null,
      },
      company: {
        cnpj: data?.cnpj ? data?.cnpj : null,
        name: data?.companyName ? data?.companyName : null,
      },
    };

    yield call(api.registerRequest, requestData);

    yield put(Creators.registerRequestSuccess());
    callback();
  } catch (response) {
    Alerts.alertError(
      response?.data?.log
        ? response?.data?.log
        : "Não foi possível concluir a solicitação de acesso. Tente novamente mais tarde "
    );

    yield put(Creators.registerRequestFailure());
  }
}
