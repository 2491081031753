import { Grid } from "@material-ui/core";
import React, { useCallback, useEffect, useState } from "react";
import { connect } from "react-redux";
import { CustomText, IntegratedComponentHolder } from "../../../components";

import { Filters, DataCards } from "../components";

import { Creators } from "../reduxSagas";

import Styles from "../styles/Styles";
import { Fonts, SVG } from "../../../config";
import { Creators as GlobalCreators } from "../../globalReduxSagas";
import { ToCSV } from "../../../lib";

function DashboardAmcred({
  searchDashboardAmcred,
  dashboardData,
  dashLastFilter,
  isFetching,
  companiesList,
  getCompanies,
}) {
  const [isMounted, setIsMounted] = useState(false);
  const [minH, setHeight] = useState("100vh");

  const searchUsers = (filters, user) => {
    searchDashboardAmcred(filters);
  };

  const Mount = useCallback(() => {
    const now = new Date();
    const year = now.getFullYear();
    const month = now.getMonth() + 1; // Os meses em JavaScript são indexados de 0 a 11

    const begin = new Date(year, month - 1, 1);
    const end = now;

    setIsMounted(true);
    getCompanies();
    searchDashboardAmcred({
      begin: begin,
      end: end,
    });
  }, [searchDashboardAmcred, getCompanies]);

  useEffect(Mount, [Mount]);

  useEffect(() => {
    if (isMounted) {
      const newH = document.getElementById("header-menu");
      setHeight(newH?.offsetHeight);
    }
  }, [isMounted]);

  const clearFilter = () => {};

  const formatFilter = (obj) => {
    function formatDate(date) {
      const day = String(date.getDate()).padStart(2, "0");
      const month = String(date.getMonth() + 1).padStart(2, "0");
      const year = String(date.getFullYear());
      return day + "/" + month + "/" + year;
    }
    const result = [];

    if (obj.companyIds && Array.isArray(obj.companyIds)) {
      const companiesNames = obj.companyIds?.map((item) => {
        if (companiesList?.find((comp) => comp?.value === item)) {
          return companiesList?.find((comp) => comp?.value === item)?.label;
        } else {
          return "";
        }
      });
      const companyIdsStr = "Instituições: " + companiesNames.join(", ");
      result.push(companyIdsStr);
    }

    if (obj.begin && obj.begin instanceof Date) {
      const formattedBegin = formatDate(obj.begin);

      if (obj.end && obj.end instanceof Date) {
        const formattedEnd = formatDate(obj.end);
        result.push("Período:" + formattedBegin + " até " + formattedEnd);
      } else {
        result.push("Período:" + formattedBegin);
      }
    }

    return result;
  };

  const exportData = (data) => {
    let editedData = { ...data };
    if (editedData?.totalReceivable) {
      editedData.totalReceivable = editedData.totalReceivable
        ?.toString()
        ?.replace(".", ",");
    }
    if (editedData?.accessValue) {
      editedData.accessValue = editedData?.accessValue?.map((i) => {
        return {
          name: i?.name,
          value: i?.value?.toString()?.replace(".", ","),
        };
      });
    }

    let filters = formatFilter(dashLastFilter);

    ToCSV.dash(
      editedData,
      "Dados instituições associadas",
      "Dashboard_Amcred",
      {
        total: "Total de instituições associadas",
        totalAccess: "Total de consultas",
        totalReceivable: "Total a receber (R$)",
        accessNumber: [
          "Instituições",
          "Quantidade",
          "NÚMERO DE CONSULTAS POR INSTITUIÇÃO",
        ],
        accessValue: [
          "Instituições",
          "Valor (R$)",
          "VALOR TOTAL DE CONSULTAS POR INSTITUIÇÃO",
        ],
      },
      filters
    );
  };
  return (
    <Styles.Container minH={minH}>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Filters
            onSubmit={(data) => searchUsers(data, dashboardData)}
            onClear={clearFilter}
            companiesList={companiesList}
          />
        </Grid>
        <Grid item xs={12}>
          <Styles.Content>
            <Styles.HeaderCard>
              <CustomText fontFamily={Fonts.bold} fontSize={1.75}>
                EXPORTAÇÃO DE DADOS
              </CustomText>
              <Styles.NewUser
                startIcon={<SVG.Export />}
                fullWidth={false}
                onClick={() => exportData(dashboardData?.response)}
                disabled={!Boolean(dashboardData?.response) || isFetching}
                loading={isFetching}
              >
                Exportar
              </Styles.NewUser>
            </Styles.HeaderCard>
          </Styles.Content>
        </Grid>
        <Grid item xs={12}>
          <IntegratedComponentHolder
            showReload={!isFetching && !dashboardData?.data}
            reloadMessage={"Nenhum dado encontrado"}
            showEmpty={!isFetching && !dashboardData?.data}
            emptyMessage={"Nenhum dado encontrado"}
            maxSize={"62rem"}
          >
            <DataCards data={dashboardData?.data} />
          </IntegratedComponentHolder>
        </Grid>
      </Grid>
    </Styles.Container>
  );
}

function mapStateToProps(redux) {
  const {
    isFetching: isFetchingPage,
    dashboardData,
    dashLastFilter,
  } = redux.dashboardAmcred;
  const { isFetching: isFetchingGlobal, companiesList } = redux.global;

  return {
    isFetching: isFetchingPage || isFetchingGlobal,
    dashboardData,
    dashLastFilter,
    companiesList,
  };
}

function mapDispatchToProps(dispatch) {
  const { searchDashboardAmcred } = Creators;
  const { getCompanies } = GlobalCreators;
  return {
    searchDashboardAmcred: function (data) {
      return dispatch(searchDashboardAmcred(data));
    },
    getCompanies: function () {
      return dispatch(getCompanies());
    },
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(React.memo(DashboardAmcred));
