import { call, put } from "redux-saga/effects";
import { Creators } from ".";
import { api, ResponseError } from "../../services";

export default function* getFilterAssociatedUsers() {
  try {
    const response = yield call(api.getFilterAssociatedUsers);

    yield put(Creators.getFilterAssociatedUsersSuccess(response));
  } catch (response) {
    const errorResponse = new ResponseError(response);
    errorResponse.alertMessage();
    yield put(Creators.getFilterAssociatedUsersFailure());
  }
}
