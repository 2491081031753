import React from "react";
import styled from "styled-components";
import FontStyles from "../styles/fontsStyles";

const GridData = styled.tr(({ $open, $risk, $first, $last }) => ({
  backgroundColor:
    $risk === "BLACK"
      ? "#E8E8E8"
      : $risk === "RED"
      ? "#FFEAE4"
      : $risk === "YELLOW"
      ? "#FFF7D9"
      : $risk === "GREEN"
      ? "#D1FBD5"
      : "#DBF1FF",

  borderBottom: $last && $open && "4px solid #f6f6f6;",
}));

const ColumnItem = styled.td(
  ({ theme, $first, $last, noPadding, $risk, $span, $open, $extra }) => ({
    ...FontStyles.medium12,
    colspan: $span ? $span : "7",
    color: theme.palette.text.primary,
    padding: noPadding
      ? 0
      : $first
      ? theme.spacing(1, 1, 1, 2)
      : $last
      ? theme.spacing(1, 2, 1, 1)
      : theme.spacing(1),
    marginBlock: theme.spacing(2),
    margin: noPadding && 0,
    borderLeft:
      $first &&
      `3px solid ${
        $risk === "BLACK"
          ? "#393939"
          : $risk === "RED"
          ? "#DF281B"
          : $risk === "YELLOW"
          ? "#E9A505"
          : $risk === "GREEN"
          ? "#42B14C"
          : "#0099FF"
      }${$extra ? "29" : ""}`,
    borderBottom: $open && $first && "4px solid #f6f6f6;",
  })
);

const ItemContainer = styled.div(({ $last, $click }) => ({
  display: "flex",
  flexDirection: "row",
  width: "100%",
  justifyContent: $last ? "flex-end" : "flex-start",
  cursor: $click && "pointer",
}));

function GridItemTable({
  renderItemColumns,
  renderSubColumns,
  item,
  index,
  headers,
  clickPosition,
}) {
  const [open, setOpen] = React.useState(false);
  const renderSub = (data) => {
    const allList = [];

    data.map((subItem, key) => {
      let a = [...subItem.dailyMonitoring];
      a?.reverse()?.map((item, index) => {
        allList.push({
          ...item,
          dateTime: subItem.dateTime,
          key: key + "_" + index,
        });
        return item;
      });
      return subItem;
    });

    return allList.reverse().map((valueRender, key) => (
      <GridData
        $risk={valueRender?.priority}
        key={valueRender?.key + "tr" + key}
        $open={open}
        $last={key === allList.length - 1 ? true : false}
      >
        {renderSubColumns(valueRender).map((data, keyA) => (
          <ColumnItem
            $risk={valueRender?.priority}
            key={key + "-" + keyA}
            $first={keyA === 0}
            $last={
              keyA === renderSubColumns(valueRender).length - 1 ? true : false
            }
            $extra
          >
            <ItemContainer
              $last={
                keyA === renderSubColumns(valueRender).length - 1 ? true : false
              }
            >
              {data}
            </ItemContainer>
          </ColumnItem>
        ))}
      </GridData>
    ));
  };
  return (
    <>
      <GridData $risk={item.priority} key={index} $open={!open} $last>
        {renderItemColumns(item).map((itemToRender, key) => (
          <ColumnItem
            $risk={item.priority}
            $small={headers.table.length > 7 ? true : false}
            key={key}
            $first={key === 0 ? true : false}
            $open={!open}
            $last={key === renderItemColumns(item).length - 1 ? true : false}
          >
            {key === clickPosition ? (
              <ItemContainer
                onClick={() => {
                  item?.monitorings && renderSubColumns && setOpen(!open);
                }}
                $click={item?.monitorings && renderSubColumns}
                $last={
                  key === renderItemColumns(item).length - 1 ? true : false
                }
              >
                {itemToRender}
              </ItemContainer>
            ) : (
              <ItemContainer
                $last={
                  key === renderItemColumns(item).length - 1 ? true : false
                }
              >
                {itemToRender}
              </ItemContainer>
            )}
          </ColumnItem>
        ))}
      </GridData>

      {renderSubColumns && open && <>{renderSub(item?.monitorings)}</>}
    </>
  );
}

export default React.memo(GridItemTable);
