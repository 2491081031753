import React from "react";
import { Images, Texts } from "../../../config";
import { FormHolder } from "../../../FormConfig";
import { Styles } from "../styles";
import { Grid } from "@material-ui/core";
import Title from "../../../components/strings/Title";
import { ButtonOutlined, InputPassword } from "../../../components";
import { Validations } from "../../../lib";

function StepThree({ goBack, onSubmit, title, text, loading }) {
  const texts = Texts["pt-BR"].forgotPassword.passwordConfirmatiom;

  const sendNewPassword = (data) => {
    onSubmit(data);
  };

  return (
    <>
      <Styles.Content>
        <Styles.Header>
          <Styles.AmcredLogo src={Images.Logo} />
        </Styles.Header>
        <Title>{title}</Title>
        <Styles.Subtitle>{text}</Styles.Subtitle>
        <Styles.LineBottom></Styles.LineBottom>
        <FormHolder onSubmit={sendNewPassword}>
          <InputPassword
            name="password"
            nextInput="newPassword"
            autoComplete={"new-password"}
            inputLabel={texts.newPassword}
            validation={Validations.validPassword}
            variant="outlined"
          />
          <InputPassword
            name="newPassword"
            nextInput="password"
            autoComplete={"new-password"}
            inputLabel={texts.confirmNewPassword}
            required={texts.inputPasswordRequiredMessage}
            doubleValidation={(value, getFieldValue) => {
              const isValid = value === getFieldValue("password");
              return {
                isValid,
                errorMessage: "Senhas não conferem.",
              };
            }}
            variant="outlined"
          />
          <Grid item lg={12}>
            <Styles.ConfirmButton
              name="connect"
              type="submit"
              loading={loading}
              disable={loading}
            >
              {texts.confirm}
            </Styles.ConfirmButton>
          </Grid>
          <Grid item lg={12}>
            <ButtonOutlined
              loading={loading}
              disable={loading}
              onClick={goBack}
            >
              {texts.back}
            </ButtonOutlined>
          </Grid>
        </FormHolder>
      </Styles.Content>
    </>
  );
}

export default React.memo(StepThree);
